import React from 'react';

interface RadioToggleNakedProps {
    value: any
    onChangeHandler: any
    checked: boolean
    styles?: any
    index: any
}


class RadioToggleNaked extends React.Component<RadioToggleNakedProps> {

    onChange(e:any){
        this.props.onChangeHandler(e)
    }

    render() {
        
        const css = this.props.styles ? this.props.styles : ''

        return(
            <div className={css + ' radio-toggle'}>
                <label htmlFor= { `radio-toggle-${this.props.index}` }>
                    <input 
                        className="radio-toggle__input" 
                        type="checkbox"
                        id={ `radio-toggle-${this.props.index}`}
                        value= { this.props.value } 
                        checked = { this.props.checked }
                        onChange={ (e:any) => {
                            this.onChange(e)
                        }} 
                    />
                    <span className="radio-toggle__mask">
                        <span className="radio-toggle__custom"></span>
                    </span>
                </label>
            </div>
        );
    };
}

export default RadioToggleNaked;