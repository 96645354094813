import { ChannelHistory, MachineHistory, NestHistory, WorkbenchHistoryExport, NestHistoryExport, PartHistoryExport, AttributeHistoryExport } from './../../../store/statistics/types';
import { AttributeQdas, QdasMeasurementExportEntry } from './../../qdas/qdas-types';
import { MeasurementChartItem, MeasurementFlatItem, PartHistory, AttributeHistory } from '../../../store/statistics/types';
import { UsedDelimiterEnum, UsedUnitsEnum } from '../../../store/system/types';
import { StatisticsLocalState,StatisticsLocalProps} from '../Statistics';

import { QdasAttribute, QdasMeasurementTimeBlock, QdasNest, QdasRow, QdasValidMeasure, QdasWorkbench } from './types';
import { ExportTypeEnum } from './list';
import { ExportPdf } from './pdf/export-pdf';
import { ExportExcelJs } from './excel/export-excel-js';
import { ExportJson } from './json/export-json';
import { ExportQdas } from './qdas/export-qdas';
import { webapi } from '../../../api/web-api';
import { PartQdas } from '../../qdas/qdas-types';
import { namingObjectType } from '../../settings/naming/naming-types';
import JSZip from 'jszip';
import saveAs from 'file-saver';
// @ts-ignore
import platform from 'platform-detect';

namespace ExportHelper {

    export function getIsoTimeFormated( timestamp: number ) {
        return new Date( timestamp ).toISOString().slice(0, 19).replace(":", "-").replace(":", "-").replace("T", "_");
    }

    export async function getQdasWorkbAndNests( measurements: MeasurementFlatItem[] ) {

        let qdasWorkbenches: QdasWorkbench[] = [];
        let qdasNests: QdasNest[] = [];
        for (const m of measurements) {
            if (!qdasWorkbenches.find(q=>q.historyId===m.workbenchHistoryUuid)) {
                const wb = await getHistoryWorkbench(m.workbenchHistoryUuid);
                qdasWorkbenches.push({
                    uuid: wb.workbenchUuid,
                    historyId: wb.uuid,
                    name: wb.name,
                    ref: wb.reference
                });
            }
            if (!qdasNests.find(q=>q.historyId===m.nestHistoryUuid)) {
                const n = await getHistoryNest(m.nestHistoryUuid);
                qdasNests.push({
                    uuid: n.nestUuid,
                    historyId: n.uuid,
                    name: n.name,
                    workbenchUuid: n.workbenchUuid,
                    ref: n.reference
                });
             }
        }
        qdasWorkbenches = qdasWorkbenches.sort((wb1, wb2) => wb1.ref < wb2.ref  ? 1 : -1 ); // sort by ref
        qdasNests = qdasNests.sort( (n1, n2) => n1.ref < n2.ref ? 1 : -1 ) // sort by ref
        .filter((obj,ind,ary)=>!ind || obj.ref !== ary[ind - 1].ref); // delete multiples

        return { wb: qdasWorkbenches, n: qdasNests }
    }

    export function getQdasTimeBlocks(
            measurements:MeasurementFlatItem[], 
            attributes: QdasAttribute[], 
            workbenches: QdasWorkbench[], 
            nests: QdasNest[]
        ) {

        let qdasMeasurementTimeBlocks: QdasMeasurementTimeBlock[] = [];
        const sortedMeasurements = measurements.sort((tb1,tb2)=>tb1.measurementTimestamp<tb2.measurementTimestamp ? -1 : 1); // sort: oldest first

        // First Run  create placeholders for features (invalid measurement as default)
        
        for (const measurement of sortedMeasurements) {
            const existingTimeBlock = qdasMeasurementTimeBlocks.find( tb => tb.timestamp === measurement.measurementTimestamp );
            if (!existingTimeBlock) {
                let data: QdasMeasurementExportEntry[] = [];
                if ( measurement.data ) {
                        data = JSON.parse(measurement.data);
                }
                const features = attributes.map(a=>{
                    let singleFeature = {
                        featureRef: a.ref,
                        featureValue: a.attribute.nominalSize, 
                        valid: QdasValidMeasure.INVALID,
                        featureUuid: a.attribute.attributeUuid
                    }
                    if ( a.attribute.attributeUuid === measurement.featureUuid ) {
                        return { ...singleFeature,
                            featureValue: measurement.featureValue,
                            valid: QdasValidMeasure.VALID
                        };
                    } else return singleFeature;
                })
                const newTimeBlock: QdasMeasurementTimeBlock = {
                    timestamp: measurement.measurementTimestamp,
                    nestRef: nests.find(n=>n.uuid===measurement.nestUuid)?.ref ?? '',
                    wbRef: workbenches.find(wb=>wb.uuid===measurement.workbenchUuid)?.ref ?? '',
                    data: data,
                    features
                }
                qdasMeasurementTimeBlocks.push( newTimeBlock );
            } else {
                const newFeatures = existingTimeBlock.features.map( f => {
                    if ( f.featureUuid === measurement.featureUuid ) {
                        return { ...f,
                            featureValue: measurement.featureValue,
                            valid: QdasValidMeasure.VALID
                        };
                    } else return f;
                });
                qdasMeasurementTimeBlocks = qdasMeasurementTimeBlocks.map( tb => {
                    if ( tb.timestamp === measurement.measurementTimestamp ) {
                        return { ...tb, features: newFeatures };
                    } else {
                        return tb;
                    }
                });
            } 
        }

        return qdasMeasurementTimeBlocks;
    }


    export async function getHistoryPart(uuid: string) {
        const uri = `/partHistory/${uuid}`;
        const result =  await webapi.client.get(uri);
        return result.data as PartHistory;
    }

    export async function getHistoryWorkbench(uuid: string) {
        const uri = `/workbenchHistory/${uuid}`;
        const result =  await webapi.client.get(uri);
        return result.data as MachineHistory;
    }

    export async function getHistoryNest(uuid: string) {
        const uri = `/nestHistory/${uuid}`;
        const result =  await webapi.client.get(uri);
        return result.data as NestHistory;
    }

    export async function getHistoryAttribute(uuid: string) {
        const uri = `/attributeHistory/${uuid}`;
        const result =  await webapi.client.get(uri);
        return result.data as AttributeHistory;
    }

    export async function getHistoryChannelList() {
        const uri = `/channelHistories`;
        const result =  await webapi.client.get(uri);
        return result.data as ChannelHistory[];
    }

    export function getQdasPart(histPart: PartHistory) {
        let array: PartQdas[] = [];
        if (histPart.dataQdas) {
            array = JSON.parse(histPart.dataQdas) as PartQdas[];
        } 
        const sortedArray = array.sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
        const qdasRowArray = sortedArray.map<QdasRow>( r => ({ key: '', ref: '', value: r.textRow}) );
        return qdasRowArray;
    }

    export function getQdasAttribute(histAtt: AttributeHistory) {
        let array: AttributeQdas[] = [];
        if (histAtt.dataQdas) {
            array = JSON.parse(histAtt.dataQdas) as AttributeQdas[];
        }
        const sortedArray = array.sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
        const qdasRowArray = sortedArray.map<QdasRow>( r => ({ key: '', ref: '', value: r.textRow}) );
        return qdasRowArray;
    }

    function filterMeasurementHistory(partHistoryIdItems: MeasurementFlatItem[] ) {

        const exportItems: MeasurementFlatItem [] = [];
        const conflictItems: MeasurementFlatItem [] = [];

        const startItem = partHistoryIdItems[0];
        exportItems.push(startItem);

        const { attsHistory } = startItem;
        for (let i = 1; i < partHistoryIdItems.length; i++) {
            let flag = true;
            const item = partHistoryIdItems[i];
            // check if attributes and their histories are the same
            if (item.attsHistory!==attsHistory) {
                flag = false;
            } else {
                const sameWbItem = exportItems.find( eItem => eItem.workbenchUuid===item.workbenchUuid);
                if ( sameWbItem && sameWbItem.workbenchHistoryUuid!==item.workbenchHistoryUuid ) {
                    flag = false;
                }
                const sameNestItem = exportItems.find( eItem => eItem.nestUuid===item.nestUuid);
                if ( sameNestItem && sameNestItem.nestHistoryUuid!==item.nestHistoryUuid ) {
                    flag = false;
                }
            }
            if (flag) {
                exportItems.push(item);
            } else {
                conflictItems.push(item);
            }
        }
        return { exportItems, conflictItems };
    }

    export async function handleExport (event: any, statsThis: { state: StatisticsLocalState; props:StatisticsLocalProps}, measurementCharts: MeasurementChartItem[], namingObject: namingObjectType) {
        const { filteredItems } = statsThis.state;
        const { device, channels, system } = statsThis.props;
        const { usedDelimiter, usedUnits } = system.settings;
        const isMM = usedUnits === UsedUnitsEnum.MM;
        const isDecimalPoint = usedDelimiter === UsedDelimiterEnum.DecimalPoint;
        switch (event.name) {
            case ExportTypeEnum.JSON:
                const channelHistories = await getHistoryChannelList();
                console.log('channelHistories: ', channelHistories)
                const jsonExport = new ExportJson(filteredItems, device, channels, namingObject, channelHistories);
                jsonExport.prepare({
                    isDecimalPoint: isDecimalPoint,
                    isMM: isMM
                });
                const fromDateTimestamp = jsonExport.minDate;
                const toDateTimestamp = jsonExport.maxDate;
                const items = await statsThis.props.thunkGetMeasurementsExport({ fromDateTimestamp, toDateTimestamp });
                const partHistoryItems = await (await webapi.client.get('/partHistoryExport')).data as PartHistoryExport[];
                const attributeHistoryItems = await (await webapi.client.get('/attributeHistoryExport')).data as AttributeHistoryExport[];
                const wbHistoryItems = await (await webapi.client.get('/workbenchHistoryExport')).data as WorkbenchHistoryExport[];
                const nestHistoryItems = await (await webapi.client.get('/nestHistoryExport')).data as NestHistoryExport[];
                await jsonExport.createExport(items,[],[],system.serviceEndpoint, { partHistoryItems, wbHistoryItems, nestHistoryItems, attributeHistoryItems });
                jsonExport.saveExport();
                break;
            case ExportTypeEnum.PDF:
                const pdfExport = new ExportPdf(filteredItems, device, channels, namingObject, measurementCharts);
                pdfExport.prepare({
                    isDecimalPoint: isDecimalPoint,
                    isMM: isMM
                });
                pdfExport.createExport();
                pdfExport.saveExport();
                break;
            case ExportTypeEnum.EXCEL:
                const excelExport = new ExportExcelJs(filteredItems, device, channels, namingObject, measurementCharts);
                excelExport.prepare({
                    isDecimalPoint: isDecimalPoint,
                    isMM: isMM
                });
                excelExport.createExport()
                .then(()=>excelExport.saveExport());
                break;
            case ExportTypeEnum.QDAS:

                // STEP 1: Gruppiere nach Parts

                const sortedItems = filteredItems.sort( (a,b)=> a.measurementTimestamp < b.measurementTimestamp ? -1 : 1 );

                const exportedPartHistoryIds = sortedItems
                .map(f=>f.partHistoryId).sort().filter((val,ind,ary)=>!ind || val !== ary[ind - 1]);
                
                // Nach Bauteil-Historie chronologisch sortieren
                // Messwerte von einem Bauteil chronologisch sortieren
                // Leere Ausgabeliste erstellen
                // Messwertliste iterieren bis Liste leer
                    // ? Merkmal-Id schon in Ausgabeliste === Konflikt
                    // ? Maschinen-Historie in der Liste !== Konflikt
                    // ? Merkmal-Histoirie nicht in der Liste === Konflikt
                    // Wenn kein Konflikt, dann verschiebe Messwert in die Ausgabeliste
                    // Ausgabeliste als Datei exportieren und wieder leeren

                // if OS is Android or iOS, a zip-File ist created
                const zip = new JSZip();

                // STEP 2: Iteriere über Part History Ids
                for (const partHistoryId of exportedPartHistoryIds) {
                    let partHistoryIdItems = [...sortedItems.filter(f=>f.partHistoryId===partHistoryId)]
                    .sort( (a,b)=> a.measurementTimestamp < b.measurementTimestamp ? -1 : 1 );
                    const historyPart = await getHistoryPart( partHistoryId );

                    let progress = true;
                    do {
                        const { exportItems, conflictItems } = filterMeasurementHistory(partHistoryIdItems);
                        partHistoryIdItems = conflictItems;
                        let histAttributes: QdasAttribute[] = [];
                        let workbenches: MachineHistory[] = [];
                        try {
                            const attrHistoryIds = JSON.parse(exportItems[0].attsHistory) as string[];
                            for (let i = 0; i < attrHistoryIds.length; i++) {
                                const attrHist = await getHistoryAttribute(attrHistoryIds[i]);
                                histAttributes.push({ref: String(i+1), attribute: attrHist})
                            }
                            const wbHistIds = exportItems.map(e=>e.workbenchHistoryUuid)
                                .filter((string,index,array)=>!index || string !== array[index - 1]); // delete multiples
                            for (const wbHistId of wbHistIds) {
                                const wbHistory = await getHistoryWorkbench(wbHistId);
                                workbenches.push(wbHistory);
                            }                      
                        } catch (error) {
                            console.log('att error: ', error)
                        }

                        const qdasWbNs = await getQdasWorkbAndNests(exportItems);
                        const qdasExport = new ExportQdas(exportItems, device, channels, namingObject, historyPart, histAttributes, qdasWbNs.wb, qdasWbNs.n);
                        qdasExport.prepare({
                            isDecimalPoint: true,
                            isMM: isMM
                        });
                        try {
                            await qdasExport.createExport();
                            if ( platform.android || platform.ios ) {
                                zip.file(qdasExport.getFileName(),qdasExport.getFileText());
                            } else {
                                await qdasExport.saveExport();
                            }
                        } catch (error) {
                            console.log('qdas export error: ', error)
                        }
                        if ( conflictItems.length=== 0 ) {
                            progress = false;
                        }
                    } while (progress)
                }
                // generate zip file
                if ( platform.android || platform.ios ) {
                    zip.generateAsync({type:"blob"})
                    .then(function (blob) {
                        const fileName = getIsoTimeFormated(new Date().getTime());
                        saveAs(blob, `q-das_${fileName}.zip`);
                    });
                }
                break;
            default:
                break;
        }
    }

    export function checkReference(value: string | null): string {
        return value === null ? 'missing reference' : value;
    }

    function getString( str:string, length: number ) {
        let retString = str;
        let wasTooLong = false;
        let countLongWord = 0;
        for (let i = 0; i < retString.length; i++) {
            const char = retString[i];
            if (char === ' ') {
                countLongWord = 0;
            } else {
                countLongWord++;
            }
            if (countLongWord === length) {
                retString = retString.slice(0,i) + ' ' + retString.slice(i,retString.length);
                wasTooLong = true;
                break;
            }
        }
        return { retString, wasTooLong };
    }
    
    export function addLineBreak( string:string, length: number): string {
        // this function helps the long names to get a linebreak if single words are to long
        let hasTooLongWords = true;
        let string2reduce = string;
        while (hasTooLongWords) {
            const { retString, wasTooLong } = getString(string2reduce, length);
            string2reduce = retString;
            hasTooLongWords = wasTooLong;
        }
        return string2reduce;
    }

    
}

export default ExportHelper;