import { MeasurementFlatItem, UuidAndName } from "../../../store/statistics/types";
import { StatisticsFilter } from "./types";

namespace SortFilterHelper {

    export enum SortKey {
        TIME = 'measurementTimestamp',
        PART = 'partName',
        MACHINE = 'workbenchName',
        NEST = 'nestName',
        FEATURE = 'featureName'
    }

    export type SortOptions = {
        sortByFirst: SortKey,
        sortBySecond: SortKey,
        order: 'ASC' | 'DESC',
    }

    export function compareByKeys( orderBy: 'ASC' | 'DESC', field: string = 'partName', field2: string = 'measurementTimestamp') {
        const order = orderBy==='ASC' ? 1 : -1;
        return function (a: any, b: any,) {
            const first = (a as any)[field];
            const first2 = (a as any)[field2];
            const first3 = (a as any)['uuid'];
            const second = (b as any)[field];
            const second2 = (b as any)[field2];
            const second3 = (a as any)['uuid'];
            let comparison = 0;
            const firstLC = String(first + first2 + first3).toLocaleLowerCase();
            const secondLC = String(second + second2 + second3).toLocaleLowerCase();
            if (firstLC > secondLC) {
            comparison = order;
            } else if (firstLC < secondLC) {
            comparison = -order;
            }
            return comparison;
        }
      }

      export function createOptionsFromKeyAndOrder(key:string, order: 'ASC'|'DESC'): SortOptions  { 
          let sortByFirst: SortKey = SortKey.TIME;
          let sortBySecond: SortKey = SortKey.FEATURE;
          switch (key) {
              case 'time':
                  break;
              case 'part':
                sortByFirst = SortKey.PART;
                sortBySecond = SortKey.TIME;
                  break;
              case 'machine':
                sortByFirst = SortKey.MACHINE;
                sortBySecond = SortKey.TIME;
                  break;
              case 'nest':
                sortByFirst = SortKey.NEST;
                sortBySecond = SortKey.TIME;
                  break;
              case 'Feature':
                sortByFirst = SortKey.FEATURE;
                sortBySecond = SortKey.TIME;
                  break;
              default:
                  console.log('warning::wrongSortKey::key',key)
                  break;
          }
          const options: SortOptions =  {
              sortByFirst: sortByFirst,
              sortBySecond: sortBySecond,
              order: order,
          };
          return options;
      }

      export function recalcRangeParams(items: MeasurementFlatItem[]){
        let firstDate = 0;
        let lastDate = 0;
        let machinesList: string[] = [];
        let partsList: string[] = [];
        let machines: UuidAndName[] = [];
        let parts: UuidAndName[] = [];
        if (items.length !== 0) {
            for (let i = 0; i < items.length; i++) {
                const m = items[i];
                if (firstDate === 0) {
                firstDate = m.measurementTimestamp;
                }
                if (m.measurementTimestamp <= firstDate) {
                firstDate = m.measurementTimestamp;
                }
                if (m.measurementTimestamp >= lastDate) {
                lastDate = m.measurementTimestamp;
                }
                machinesList.push(m.workbenchUuid);
                partsList.push(m.partId);
            }
            const cleanWb = new Set(machinesList);
            const cleanParts = new Set(partsList);       
            machines =  Array.from(cleanWb).map((m) => {
                const n = items.find(f => f.workbenchUuid === m);
                return { uuid: n?.workbenchUuid ?? '', name: n?.workbenchName ?? '' };
            })      
            parts =  Array.from(cleanParts).map((m) => {
                const n = items.find(f => f.partId === m);
                return { uuid: n?.partId ?? '', name: n?.partName ?? '' };
            })
        }
        return {
            firstDate,
            lastDate,
            machines,
            parts
        }
    }

    export function updateFilteredItems(filterOptions: StatisticsFilter, sortOptions: SortOptions, items: MeasurementFlatItem[]){
        // update filteredItems from current filterOptions

        const { selectedAttributes, startTimestamp, endTimestamp } = filterOptions;
        const { order, sortByFirst, sortBySecond } = sortOptions;
        // data from last thunk query
        // const { items } = this.props.measurements;
        
        // 1. Filter
        let filtered = [...items.filter( item => item.featureValue !== 0)]; // make copy, the new ref of the array gets changed and causes the StatBody to rerender
        let wfilter: any = '';
        let pfilter: any = '';
        selectedAttributes.forEach((item: any) => {
            if (item.type === 'workbench') wfilter = [...wfilter, item.uuid]
            if (item.type === 'part') pfilter = [...pfilter, item.uuid]
        })

        // Part Filter
        if (pfilter !== '') {
            filtered = filtered.filter((f:any) => {
              return pfilter.includes(f.partId);
            });
        }
        // Workbench Filter
        else if (wfilter !== '') {
        filtered = filtered.filter((f:any) => {
            return wfilter.includes(f.workbenchUuid);
        });
        }
        // Date Filter, timestamps are in UTC/GMT time
        if (startTimestamp) filtered = filtered.filter(f=>f.measurementTimestamp>=startTimestamp);
        if (startTimestamp) console.log('startTimestamp: ', new Date(startTimestamp).toUTCString());
        if (endTimestamp) filtered = filtered.filter(f=>f.measurementTimestamp<=endTimestamp);
        if (endTimestamp) console.log('startTimestamp: ', new Date(endTimestamp).toUTCString());

        // 2. Sort
        const filteredAndSortedItems = filtered.sort(compareByKeys(order, sortByFirst, sortBySecond));

        // 3. Set the beginning of the startDate and the ending of the endDate + machines + parts
        const newRanges = recalcRangeParams(items);        
        const filterOptionsNew: StatisticsFilter = {
            ...filterOptions,
            ...newRanges
        };

        return {
            filteredItems: filteredAndSortedItems,  
            filterOptions: filterOptionsNew
        };
    }

}

export default SortFilterHelper