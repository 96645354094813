import { SensorSettingEnum } from "../types/sensor-setting-enum";
const math = require( 'mathjs');

export interface Sensor2PointConfigInterface {
    masterSize: number;
    refrences: {
        rp1: number;
        rp2: number;
    };
    setting: SensorSettingEnum;
}
export class Sensor2Point {
    private readonly PRECISION = 14;
    private _masterSize: number;
    private _zeroValues: number[];
    private _sensorValues: number[];
    private _sensorSetting: SensorSettingEnum;
    
    constructor(config: Sensor2PointConfigInterface) {
        this._masterSize = config.masterSize;
        const {rp1, rp2 } = config.refrences;
        this._zeroValues = [rp1, rp2];
        this._sensorValues = [0,0];
        this._sensorSetting = config.setting;
    }

    public get MasterSize(): number {
        return this._masterSize;
    }

    public setReferenceValues(an: number = 0, bn: number=0): void {
        this._zeroValues = [an, bn];
    }
    public getReferenceValues(): number[] {
        return this._zeroValues;
    }


    public calcReferenced(Ta: number, Tb: number): number {
        this._sensorValues = [Ta, Tb];
        const termA =  math.chain(this._sensorValues[0]).subtract(this._zeroValues[0]).done();
        const termB =  math.chain(this._sensorValues[1]).subtract(this._zeroValues[1]).done();
        
        let retValue = 0;
        if (this._sensorSetting !== SensorSettingEnum.INNER) {
            retValue = math.chain(this._masterSize).add(termA).add(termB).done();
        } else {
            retValue = math.chain(this._masterSize).subtract(termA).subtract(termB).done();
        }
        return math.round(retValue,this.PRECISION);
    }

    public calc (masterSize: number, setting: SensorSettingEnum, Ta:number, Tb:number, Ra:number, Rb:number): number {
        // console.log(`### debug masterSize:${masterSize} setting:${setting}values:${Ta}/${Tb} references:${Ra}/${Rb}`);
        this._masterSize = masterSize;
        this._sensorSetting = setting;
        this._zeroValues = [Ra, Rb];
        return this.calcReferenced(Ta, Tb)
    }

}