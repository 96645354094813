import React from 'react';
import { qdasLengthCatalog, qdasMandatoryRow } from '../../statistics/export/data/constants';
import { QdasEntry } from '../qdas-types';

type PopUpRowType = {
    q: QdasEntry,
    i: number,
    inputClassName: string,
    handleDelete: (_: string)=>void,
    inputPlaceholder: string,
    // missingMandatKeys: string[],
    handleChange: (qdasRowUuid: string, text: string ) => void
}


const PopUpRow = ({
            q,
            i,
            inputClassName,
            handleDelete,
            inputPlaceholder,
            // missingMandatKeys,
            handleChange
        }: PopUpRowType
    ) => {
        
        // const isMissing = missingMandatKeys.find(m=>m===q.uuid.slice(0,5));

        const isMandatory = qdasMandatoryRow.find(m=>m.key===q.uuid.slice(0,5));

        const inputStyle: React.CSSProperties | undefined =
        q.readonly
            ? { backgroundColor: 'rgba(0,0,0,0)' }
            // : isMissing
            //     ? { backgroundColor: '#F1334F' }
                : isMandatory && q.text.trim().length === 5
                    ? { backgroundColor: '#F1334F' }
                    : undefined;
        
        const handleOnChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
            const inputText = e.target.value;
            const key = inputText.slice(0,5);
            const lengthCatEntry = qdasLengthCatalog.find(entry=>entry.key===key);
            let maxInputLength = 256;
            if (lengthCatEntry) {
                maxInputLength = lengthCatEntry.maxLength;
            }
            if (inputText.length <= maxInputLength) {
                if ( !isMandatory || (isMandatory && inputText.length > 5)) {
                    handleChange( q.uuid, inputText );
                }
            }
        }

        return (
        <div key={`${q.uuid}${i}`}
        className="row"
            >
            <div className="col-10" >
                <input 
                    type="text" 
                    id={`input${i}${q.uuid}`}
                    value={ q.text }  
                    placeholder={inputPlaceholder}
                    className={`input-${inputClassName} mb-3`}
                    style={inputStyle}
                    disabled={q.readonly}
                    onClick={e=>e.stopPropagation()}
                    onChange={e=>handleOnChange(e)}
                />
            </div>
            { !q.readonly && !isMandatory &&
                <div className="col-2 d-flex flex-col justify-content-end">
                    <button
                        className="btn btn--icon btn--icon-delete"
                        style={{ backgroundColor: '#F1334F'}}
                        type="button"
                        onClick={e => {e.stopPropagation();handleDelete(q.uuid)}} >
                        {/* delete */}
                    </button>
                </div>
            }
        </div>
    )
}

export default PopUpRow;
