import jsPDF, { CellConfig } from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { AttributeItem } from "../../../store/attributes/types";
import { MachineItem, NestItem } from "../../../store/machines/types";
import { PartItem } from "../../../store/parts/types";
import { logo } from "./assets";
import { customLogo } from "../../../components/statistics/export/data/custom-logo";
import FormatNumber from "../../../helper/format-number";
import EH from '../../statistics/export/export-helper';

interface HeaderProps extends CellConfig {
  id: string;
}

interface LastMeasurement {
  items: AttributeItem[];
  part: PartItem | null;
  machine: MachineItem | null;
  timestamp: number | null;
  nest: NestItem | null;
}

export interface ExportTranslations {
  report: string;
  date: string;
  machine: string;
  part: string;
  nest: string;
  result: string;
  measurements: string;
  feature: string;
  upperTolerance: string;
  lowerTolerance: string;
  measurement: string;
  ok: string;
  nok: string;
  startDate: string;
  value: string;
  diagram: string;
  evaluation: string;
  exportetFrom: string;
  unitMm: string;
  unitInch: string;
}

export class MeasurementsPdfExport {
  private _trans: ExportTranslations;
  private _isUnitMM: boolean;
  private _unitFactor: number;
  private _isDecimalPoint: boolean;
  private _lastMeasurement: LastMeasurement | null = null;
  /**
   *
   */
  constructor(
    translations: ExportTranslations,
    isUnitMM: boolean,
    isDecimalPoint: boolean
  ) {
    this._trans = translations;
    this._isUnitMM = isUnitMM;
    this._unitFactor = isUnitMM ? 1 : 1 / 25.4;
    this._isDecimalPoint = isDecimalPoint;
  }

  setData(data: LastMeasurement) {
    this._lastMeasurement = data;
  }

  exportProtocol(name: string = "measurement", lang: string = "en") {
    // prepare filename
    const timeCode = new Date().toISOString().replaceAll(":", "-");
    const filename = `${name}-${timeCode.slice(0, 19)}.pdf`;

    const mLeft = 15;

    let dateLocaleDate = "";
    let dateLocaleTime = "";
    let machineName = "";
    let nestName = "";
    let partName = "";

    if (this._lastMeasurement !== null) {
      if (this._lastMeasurement.timestamp !== null) {
        const tsd = new Date(this._lastMeasurement.timestamp);
        const ts = tsd.getTime() - tsd.getTimezoneOffset() * 60 * 1000;
        const dat = new Date(ts).toISOString().split("T");
        dateLocaleDate = dat[0];
        dateLocaleTime = dat[1];
      }
      if (this._lastMeasurement.machine !== null) {
        machineName = this._lastMeasurement.machine.name;
      }
      if (this._lastMeasurement.nest !== null) {
        nestName = this._lastMeasurement.nest.name;
      }
      if (this._lastMeasurement.part !== null) {
        partName = this._lastMeasurement.part.name;
      }
    }

    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "portrait",
    });

    const data: RowInput[] = [];
    let okFlag = true;
    if (this._lastMeasurement?.items) {
      this._lastMeasurement.items.forEach((m) => {
        const ot = Number(m.config.ot);
        const ut = Number(m.config.ut);
        const val =
          m.measurement.attributeValue === null
            ? 0
            : m.measurement.attributeValue;
        if (okFlag) {
          if (m.measurement.attributeValue !== null && m.measurement.attributeValue !== 0) { // FIXME: 0 valid
            if (val > ot || val < ut) {
              okFlag = false;
            }
          }
        }
        const ri: RowInput = [
          EH.addLineBreak(m.name, 34),
          this.formatNumber(ut, m.config.decimalPlaces),
          this.formatNumber(ot, m.config.decimalPlaces),
          this.formatNumber(val, m.config.decimalPlaces),
          '',
          m.measurement.measurementChart,
        ];
        // [1, '2020-10-10 12:12:02', 1.232, 'Rasp1'],
        if (val !== 0) {
          data.push(ri);
        }
      });
    }

    // logo
    doc.addImage(customLogo, "png", mLeft, 10, 56, 25);
    // titel "Messprotokoll"
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    doc.text(this._trans.report.toUpperCase(), mLeft, 60);
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text(dateLocaleDate, 210 - 34, 60, {align: "right"});
    doc.text(dateLocaleTime.slice(0, 8), 210 - 14, 60, {align: "right"});

    doc.setFont("helvetica", "bold");
    doc.text(this._trans.machine.toUpperCase(), mLeft, 75);
    doc.setFont("helvetica", "normal");
    doc.text(machineName, mLeft + 40, 75);

    doc.setFont("helvetica", "bold");
    doc.text(this._trans.nest.toUpperCase(), mLeft, 85);
    doc.setFont("helvetica", "normal");
    doc.text(nestName, mLeft + 40, 85);

    doc.setFont("helvetica", "bold");
    doc.text(this._trans.part.toUpperCase(), mLeft, 95);
    doc.setFont("helvetica", "normal");
    doc.text(partName, mLeft + 40, 95);

    // ergebnis
    doc.setFont("helvetica", "bold");
    doc.text(this._trans.result.toUpperCase(), mLeft, 115);
    doc.setFontSize(12);
    if (okFlag) {
      doc.setTextColor(70, 130, 76);
      doc.text(this._trans.ok.toUpperCase(), mLeft + 40, 115);
    } else {
      doc.setTextColor(173, 54, 65);
      doc.text(this._trans.nok.toUpperCase(), mLeft + 40, 115);
    }


    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    // doc.setTextColor(145,150,155)
    // doc.text(this._isUnitMM ? this._trans.unitMm : this._trans.unitInch, mLeft, 130);
    const head = [
      [
        this._trans.feature.toUpperCase(),
        this.formatHeaderWithUnit(this._trans.lowerTolerance.toUpperCase()),
        this.formatHeaderWithUnit(this._trans.upperTolerance.toUpperCase()),
        this.formatHeaderWithUnit(this._trans.measurement.toUpperCase()),
        this._trans.diagram.toUpperCase(),
      ],
    ];

    // const atTop = 1;
    // const atWidth = 25;
    // const atHeight = 5;

    autoTable(doc, {
      head: head,
      body: data,
      margin: { top: 130 },
      headStyles: {
        fillColor: [255, 255, 255],
        fontStyle: "bold",
        fontSize: 10,
        textColor: [145, 150, 155],
      },
      bodyStyles: {
        fontSize: 12,
        textColor: [25, 27, 29],
        fontStyle: "bold",
      },
      alternateRowStyles: {
        fillColor: [145, 150, 155],
        fontStyle: "bold",
      },

      didDrawCell: (data) => {
        if (data.section === "body" && data.column.index === 4) {
          const x = data.cursor ? data.cursor.x + 1.8 : 0
          const y = data.cursor ? data.cursor.y + 1.7 : 0
          const d = data.row.raw as RowInput[]
          doc.addImage(d[5].toString(), x, y, data.cell.width - 2 * 1.8, 5)
        }
      },
    });

    doc.save(filename);
  }

  formatHeaderWithUnit(title: string): string {
    let retValue = title.trim();
    const unit = this._isUnitMM ? this._trans.unitMm : this._trans.unitInch;
    // const unitLen = unit.length;
    // const titleLen = retValue.length;
    // const diff = titleLen - unitLen;
    // let padCount = 0;
    // if (diff > 0) {
    //   padCount = Math.round(diff);  
    // }
    // retValue = retValue + '\n' + " ".repeat(padCount)+ unit
    retValue = retValue + '\n' + unit;
    return retValue;
  }

  export(name: string = "measurement", lang: string = "en") {
    // prepare filename
    const timeCode = new Date().toISOString().replaceAll(":", "-");
    const filename = `${name}-${timeCode.slice(0, 19)}.pdf`;
    const tablePadding = 35;
    const tableMargin = 10;

    // Default export is a4 paper, portrait, using millimeters for units
    const doc = new jsPDF({
      putOnlyUsedFonts: true,
      orientation: "landscape",
    });
    doc.setFontSize(8);

    const headers = this.createHeadersMeasurements();
    const data = this.generateData(lang);

    console.log("HEADERS", headers);
    console.log("DATA", data);

    doc.table(tableMargin, tablePadding, data, headers, {
      autoSize: false,
    });
    this.addFootersAndHeaders(doc, lang, filename);
    doc.save(filename);
  }

  private addFootersAndHeaders(doc: jsPDF, lang: string, filename: string) {
    const pageStr = lang === "de" ? "Seite" : "Page";
    const exportStr = lang === "de" ? "Exportiert am" : "Exported at";
    const fromStr = lang === "de" ? "von" : "from";

    const marginTop = 5;
    const marginBottom = 5;
    const marginRight = 5;

    const pageCount = doc.internal.pages.length - 1;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    doc.setFont("helvetica");

    doc.setFontSize(8);
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.addImage(logo, 10, 2, 74, 27);
      doc.text(filename, pageWidth / 2, marginTop, {
        align: "center",
      });
      doc.text(
        exportStr + " " + new Date().toLocaleString(lang),
        pageWidth - marginRight,
        marginTop,
        {
          align: "right",
        }
      );
      doc.text(
        pageStr + " " + String(i) + " " + fromStr + " " + String(pageCount),
        pageWidth / 2,
        pageHeight - marginBottom,
        {
          align: "center",
        }
      );
    }
  }

  private generateData(lang: string) {
    var result = [];
    var data: any = {
      partname: "",
      machine: "",
      nestname: "",
      feature: "",
      ut: "",
      lt: "",
      value: "",
      diagram: "",
    };
    if (
      this._lastMeasurement !== null &&
      this._lastMeasurement.items !== null
    ) {
      for (var i = 0; i < this._lastMeasurement.items.length; i += 1) {
        const item = this._lastMeasurement.items[i];
        data.id = new Date(item.timestamp).toLocaleDateString(lang);
        data.partname = this._lastMeasurement.part?.name;
        data.nestname = this._lastMeasurement.nest?.name;
        data.machine = this._lastMeasurement.machine?.name;
        data.feature = item.name;
        data.ut = this.formatNumber(item.config.ot, item.config.decimalPlaces);
        data.lt = this.formatNumber(item.config.ut, item.config.decimalPlaces);
        data.value = this.formatNumber(
          item.measurement.attributeValue,
          item.config.decimalPlaces
        );
        data.diagram = "***";
        result.push(Object.assign({}, data));
      }
      console.log("#### data data", data);
    }
    return result;
  }

  private formatNumber(
    measurementValue: any,
    postDecmalPlaces: number = 2
  ): string {
      return new FormatNumber(this._isUnitMM,this._isDecimalPoint).formatFixed(measurementValue, postDecmalPlaces);
  }

  private createHeadersMeasurements(): HeaderProps[] {
    const list: HeaderProps[] = [];
    const h = [
      "Startdate",
      "Part",
      "Machine",
      "Nest",
      "Feature",
      "UT",
      "LT",
      "Value",
      "Diagram",
    ];
    list.push({
      id: "id",
      name: "id",
      prompt: "Startdate",
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "partname",
      name: "partname",
      prompt: h[1],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "machine",
      name: "machine",
      prompt: h[2],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "nestname",
      name: "nestname",
      prompt: h[3],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "feature",
      name: "feature",
      prompt: h[4],
      width: 55,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "ut",
      name: "ut",
      prompt: h[5],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "lt",
      name: "lt",
      prompt: h[6],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "value",
      name: "value",
      prompt: h[7],
      width: 35,
      align: "center",
      padding: 0,
    });
    list.push({
      id: "diagram",
      name: "diagram",
      prompt: h[8],
      width: 35,
      align: "center",
      padding: 0,
    });
    return list;
  }
}
