import { ChannelItem, LOAD_CHANNELS, UPDATE_CHANNEL, MeasureObject, UPDATE_MEASUREMENT } from "./types";

const loadChannels = (channels: ChannelItem[]) => {
  return {
    type: LOAD_CHANNELS,
    payload: channels
  };
}

const updateChannel = (channel: ChannelItem) => {
  return {
    type: UPDATE_CHANNEL,
    payload: channel
  }
}

const updateMeasurement = (data: MeasureObject) => {
  return {
    type: UPDATE_MEASUREMENT,
    payload: data
  }
}

export { loadChannels, updateChannel, updateMeasurement } 