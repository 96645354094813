import { cloneDeep } from 'lodash';
import { REHYDRATE, RehydrateAction } from 'redux-persist';
// @ts-ignore
import platform from 'platform-detect'

import { 
  APP_LOADED, 
  UPDATE_SESSION, 
  SystemState, 
  SystemActionTypes, 
  OVERLAY_TOGGLE, 
  OVERLAY_CLOSE,
  OVERLAY_LOAD_COMPONENT, 
  OVERLAY_SUB_TOGGLE,
  OVERLAY_SUB_LOAD_COMPONENT,
  OVERLAY_POSITION_TOGGLE,
  CHANGE_ATTRIBUTES_MODE, 
  UPDATE_TEMP_ATTRIBUTE, 
  SET_ATTRIBUTE_EDIT_FORM_MODE,
  attributeEditFormMode,
  TOGGLE_SLIDER,
  CLOSE_SLIDER,
  SET_INIT_SLIDE_INDEX,
  SAVE_SETTINGS,
  UsedUnitsEnum,
  MEASUREMENT_COUNTER_INCREASE,
  MEASUREMENT_COUNTER_RESET,
  VALIDATION_LOAD,
  VALIDATION_RESET,
  UsedDelimiterEnum,
  SET_ATTRIBUTE_LOADER,
  SET_MACHINES_LOADER,
  SET_PARTS_LOADER,
  SET_STATS_LOADER
} from "./types";

const initialState: SystemState = {
  platform: platform,
  loading: {
    attributes:false,
    parts: false,
    machines: false,
    stats: false,
  },
  appName: 'Promess',
  hostEndpoint: '',
  serviceEndpoint: '',
  webApiEndpoint: '',
  realApiEndpoint: '',
  // webApiEndpoint: '',
  // realApiEndpoint: '',
  userName: '',
  userRole: 'Workman',
  loggedIn: false,
  appLoaded: false,
  session: "",
  showMenu: false,
  overlay: {
    main: {
      show: false,
      component: null,
      positionFixed: true
    },
    sub: {
      show: false,
      component: null,
    },
    validation: {
      show: false,
      notification: []
    },
    dropdown: {
      show: false,
      name: null
    }
  },
  attributesMode: 'disabled',
  attributeEditFormMode: attributeEditFormMode.DEFAULT,
  slider: {
    show: false,
    initialSlideIndex: 0,
    items: []
  },
  settings :{
    appDarkMode: true,
    usedUnits: UsedUnitsEnum.MM,
    usedDelimiter: UsedDelimiterEnum.DecimalPoint,
    advancedMode: false,
    advancedModePin: '0000',
    allowUserStatsDeletion: false,
    language: 'en',
    timeAutoSync: true,
    qumoActiveFeatures: []
  },
  currentMachine: {
    machine: {
      name: null,
      uuid: null,
      imageUrl: null
    },
    nest: {
      name: null,
      uuid: null
    },
    isDefault: false
  },
  measurementCounter: 0,
  lastMeasurement: {
    items: [],
    part: null,
    nest: null, 
    machine: null,
    timestamp: null
  },
  tempAttribute: null,
  tempAttributeEdited: false,
  qdasMeasureExportEntries: [],
  isQumoOccupied: false
};

interface PeristStore {
  system: SystemState
}

export function systemReducer(
  state = initialState,
  action: SystemActionTypes | RehydrateAction
): SystemState {
  switch (action.type) {
    case REHYDRATE:
    return {
      ...state, settings : action.payload ? (action.payload as PeristStore).system.settings : state.settings
    }

    case SET_ATTRIBUTE_LOADER: {
      return {
        ...state,
        loading: {
          ...state.loading,
          attributes: action.payload
        }
      };
    }

    case SET_MACHINES_LOADER: {
      return {
        ...state,
        loading: {
          ...state.loading,
          machines: action.payload
        }
      };
    }

    case SET_PARTS_LOADER: {
      return {
        ...state,
        loading: {
          ...state.loading,
          parts: action.payload
        }
      };
    }

    case SET_STATS_LOADER: {
      return {
        ...state,
        loading: {
          ...state.loading,
          stats: action.payload
        }
      };
    }

    case APP_LOADED: {
      return {
        ...state,
        appLoaded: action.payload
      };
    }

    case UPDATE_SESSION: {
      // console.log('### update session', action.payload)
      return {
        ...state,
        ...action.payload
      };
    }

    //*******************************************************
    // Attributes
    //*******************************************************

      case CHANGE_ATTRIBUTES_MODE: {
        return {
          ...state,
          attributesMode: action.payload
        }
      }

      case SET_ATTRIBUTE_EDIT_FORM_MODE: {
        return {
          ...state,
          attributeEditFormMode: action.payload
        }
      }

      case UPDATE_TEMP_ATTRIBUTE: {
        if (action.payload.edited === null) {
          return {
            ...state,
            tempAttribute: action.payload.attribute,
          }
        } else {
          return {
            ...state,
            tempAttribute: action.payload.attribute,
            tempAttributeEdited: action.payload.edited
          }
        }
      }

    case SET_INIT_SLIDE_INDEX: {
      return {
        ...state,
        slider: {
          ...state.slider,
          initialSlideIndex: action.payload,
        }
      }
    }

    case TOGGLE_SLIDER: {
      return {
        ...state,
        slider: {
          ...state.slider,
          show: !state.slider.show
        }
      }
    }

    case CLOSE_SLIDER: {
      return {
        ...state,
        slider: {
          ...state.slider,
          show: false
        }
      }
    }

    case SAVE_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      }
    }

    case MEASUREMENT_COUNTER_RESET: {
      return {
        ...state,
        measurementCounter: 0
      }
    }

    case MEASUREMENT_COUNTER_INCREASE: {
      const count = state.measurementCounter + 1
      return {
        ...state,
        measurementCounter: count
      }
    }

    //*******************************************************
    // overlay
    //*******************************************************

      	// validation
      	//--------------------------------------------------------

			case VALIDATION_LOAD: {
				const newState = {...state}
				newState.overlay.validation = {
					show: true,
					notification: action.payload
				}
				
				return {
					...newState
				}
			}

			case VALIDATION_RESET: {
				const newState = {...state}
				newState.overlay.validation = {
					show: false,
					notification: []
				}

				return {
					...newState
				}
			}
			

		// overlay
		//--------------------------------------------------------

			case OVERLAY_TOGGLE: {

				//let's clear the overlay on close
				let newState
				const isToBeClosed = state.overlay.main.show
				
				if(isToBeClosed){
					newState = {
					...state,
					overlay: {
						...state.overlay,
						main: {
						show:!state.overlay.main.show,
						component: null,
            positionFixed: true
						},
					}
					}
				} else {
					newState = {
					...state,
					overlay: {
						...state.overlay,
						main: {
						...state.overlay.main,
						show:!state.overlay.main.show,
						}
					}
					}
				}
				
				return newState
      }

		case OVERLAY_CLOSE: {
      const newState = cloneDeep(state)
      newState.overlay.main.show = false
      newState.overlay.main.component = null
      newState.slider.show= false
      newState.attributeEditFormMode = attributeEditFormMode.DEFAULT
			return newState
    }
    
		case OVERLAY_LOAD_COMPONENT: {
			return {
				...state,
				overlay: {
				...state.overlay,
				main: {
					...state.overlay.main,
					component: action.payload
				}
				}
			};
		}

    case OVERLAY_SUB_TOGGLE: {
      let newState
      const isToBeClosed = state.overlay.main.show
      
      if(isToBeClosed){
        newState = {
          ...state,
          overlay: {
            ...state.overlay,
            sub: {
              show:!state.overlay.sub.show,
              component: null
            }
          }
        }
      } else {
        newState = {
          ...state,
          overlay: {
            ...state.overlay,
            sub: {
              ...state.overlay.sub,
              show:!state.overlay.sub.show,
            }
          }
        }
      }
      
      return newState
    }

    case OVERLAY_SUB_LOAD_COMPONENT: {
      return {
        ...state,
        overlay: {
          ...state.overlay,
          sub: {
            ...state.overlay.sub,
            component: action.payload
          }
        }
      };
    }

    case OVERLAY_POSITION_TOGGLE: {
      return {
        ...state,
        overlay: {
          ...state.overlay,
          main: {
            ...state.overlay.main,
            positionFixed: !state.overlay.main.positionFixed
          }
        }
      };
    }

    default:
      return state;
  }
}
