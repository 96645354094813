//*******************************************************
// will work only with a global state, use redux ;)
//*******************************************************

import React from 'react'

interface CounterProps {
    min: number,
    max: number,
    current: number,
    onIncrement: any,
    onDecrement: any,
    isMillimeter: boolean
}

interface CounterState {
    minReached:boolean,
    maxReached:boolean
}

class Counter extends React.Component<CounterProps, CounterState> {

    constructor(props:CounterProps){
        super(props);
        this.state = {
            minReached: this.props.current === this.props.min ? true : false,   
            maxReached: this.props.current === this.props.max ? true : false   
        }
    }

    componentDidUpdate(prevProps:CounterProps){
        if(prevProps.current !== this.props.current){
            this.setState({
                minReached: this.props.current === this.props.min ? true : false,   
                maxReached: this.props.current === this.props.max ? true : false   
            })
        }
    }

    increment(e:any){
        e.preventDefault()
        this.props.onIncrement()
    }

    decrement(e:any){
        e.preventDefault()
        this.props.onDecrement()
    }

    prevent(e:any){
        e.preventDefault()
    }

    render() {

        return(
            <div className = "counter group-btn w-100">
                <button disabled = { this.state.minReached } onClick={(e:any) => {this.decrement(e)}} className="btn btn--function w-25">-</button>
                <span className="btn btn--dark btn--disable-hover w-50">{ this.props.isMillimeter ?  this.props.current : this.props.current + 2}</span>
                <button disabled = { this.state.maxReached } onClick={(e:any) => {this.increment(e)}} className="btn btn--function w-25">+</button>
            </div>
        );
    };
}

export default Counter;