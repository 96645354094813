import i18next from "i18next";

interface LoadingOverlayProps {
    show: boolean
    uploadProgress?: null | number
    text1?: undefined | string
    text2?: undefined | string
    text3?: undefined | string
}

const LoadingOverlay = ( { show, uploadProgress, text1, text2, text3 }: LoadingOverlayProps) => {
    
    const popupCss = show ? ' popup__overlay--active' : '';

    return(
        <div className={'popup'} onClick={ e => e.stopPropagation() }>
            <div className={ 'popup__overlay' + popupCss } >
                <div className="d-flex flex-column align-items-center">
                    <div className='popup_spinner' />
                    { uploadProgress && <p className="h3 mt-4">{ i18next.t('UploadProgress') + ': ' + uploadProgress + '%'}</p>}
                    { text1 && <p>{ i18next.t(text1) }</p>}
                    { text2 && <p>{ i18next.t(text2) }</p>}
                    { text3 && <p>{ i18next.t(text3) }</p>}
                </div>
            </div>
        </div>
    );

}

export default LoadingOverlay;