import { MachineItem, LOAD_MACHINES, UPDATE_MACHINE, DELETE_MACHINE, ADD_MACHINE, NestItem, ADD_MACHINE_NEST, DELETE_MACHINE_NEST, UPDATE_MACHINE_NEST } from "./types";

const loadMachines = (machines: MachineItem[]) => {
  return {
    type: LOAD_MACHINES,
    payload: machines
  };
}

const deleteMachine = (id: string) => {
  return {
    type: DELETE_MACHINE,
    payload: id
  }
}

const deleteMachineNest = (parentId: string, id: string) => {
  return {
    type: DELETE_MACHINE_NEST,
    payload: {parentId: parentId, id: id}
  }
}

const addMachine = (machine: MachineItem) => {
  return {
    type: ADD_MACHINE,
    payload: machine
  }
}

const addMachineNest = (nest: NestItem) => {
  return {
    type: ADD_MACHINE_NEST,
    payload: nest
  }
}


const updateMachine = (machine: MachineItem) => {
  return {
    type: UPDATE_MACHINE,
    payload: machine
  }
}

const updateMachineNest = (nest: NestItem) => {
  return {
    type: UPDATE_MACHINE_NEST,
    payload: nest
  }
}

export { loadMachines, addMachine, deleteMachine, updateMachine, addMachineNest, deleteMachineNest, updateMachineNest } 