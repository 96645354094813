import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadMachines, updateMachine, addMachine, deleteMachine, addMachineNest, deleteMachineNest, updateMachineNest } from "./actions";
import { MachineItem, NestItem } from './types';
import { AppState } from "../../store/reducers";
import { webapi } from '../../api/web-api';
import { SetMachinesLoader } from "../system/action";


export const thunkGetMachines  = (machines:MachineItem[]): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkGetMachinesInvoked')
  await dispatch(SetMachinesLoader(true));
  const asyncResp: MachineItem[] = await getStorageMachines();
  await dispatch(loadMachines(asyncResp));
  dispatch(SetMachinesLoader(false));
}
  

export const thunkUpdateMachine = (machine: MachineItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkUpdateMachine', machine)
  const asyncResp: MachineItem = await putStorageMachine(machine);
  // console.log('::: thunkUpdateMachine-asyncResp', asyncResp);
  dispatch(
    updateMachine(
      asyncResp
    )
  );
}

export const thunkUpdateMachineRef = (machine: MachineItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: MachineItem = 
  await putStorageMachineRef(machine);  
  dispatch(
    updateMachine(
      asyncResp
    )
  );
}

export const thunkUpdateMachineNest = (nest: NestItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: NestItem = await putStorageMachineNest(nest);
  dispatch(
    updateMachineNest(
      asyncResp
    )
  );
}

export const thunkUpdateMachineNestRef = (nest: NestItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: NestItem = 
  await putStorageMachineNestRef(nest);
  dispatch(
    updateMachineNest(
      asyncResp
    )
  );
}

export const thunkDeleteMachine = (id: string): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkDeleteMachine', id)
  await delStorageMachine(id);
  // console.log('::: thunkDeleteMachine-DONE');
  dispatch(
    deleteMachine(
      id
    )
  );
}

export const thunkDeleteMachineNest = (nest: NestItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkDeleteMachineNest', nest.uuid)
  await delStorageMachineNest(nest.uuid);
  // console.log('::: thunkDeleteMachineNest-DONE');
  dispatch(
    deleteMachineNest(
      nest.workbenchUuid,
      nest.uuid
    )
  );
}

export const thunkCreateMachine = (machine: MachineItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkCreateMachine',machine)
  const asyncResp: MachineItem = await postStorageMachine(machine);
  // console.log('::: thunkCreateMachine-asynResp',asyncResp);
  // console.log('');
  dispatch(
    addMachine(
      asyncResp
    )
  );
}

export const thunkCreateMachineNest = (nest: NestItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkCreateNest',nest)
  const asyncResp: NestItem = await postStorageNest(nest);
  // console.log('::: thunkCreateNest-asynResp',asyncResp);
  // console.log('');
  dispatch(
    addMachineNest(
      asyncResp
    )
  );
}


function getStorageMachines(): Promise<MachineItem[]> {
    // console.log('getStorageMachines invoked');
    return new Promise<MachineItem[]>((resolve, reject) => {
      const uri =  '/workbenches';
      webapi.client.get<MachineItem[]>(uri).then((resp) => {      
        // console.log('::: getStorageMachines', resp.data.length)
        resolve(resp.data);
      }).catch((err) => {
        reject(err);
      })
  
    });
  }  

function postStorageMachine(attr: MachineItem): Promise<MachineItem> {
  // console.log('getStorageMachine invoked');
  return new Promise<MachineItem>((resolve, reject) => {
    const uri =  '/workbench';
    webapi.client.post<MachineItem>(uri, attr).then((resp) => {      
      // console.log('::: postStorageMachine', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })
  });
}

function postStorageNest(nest: NestItem): Promise<NestItem> {
  // console.log('getStorageNest invoked');
  return new Promise<NestItem>((resolve, reject) => {
    const uri =  '/nest';
    webapi.client.post<NestItem>(uri, nest).then((resp) => {      
      // console.log('::: postStorageNest', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })
  });
}

function putStorageMachine(machine: MachineItem): Promise<MachineItem> {
  return new Promise<MachineItem>((resolve, reject) => {
    const uri =  '/workbench/' + machine.uuid;
    webapi.client.put<MachineItem>(uri, machine).then((resp) => {      
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageMachine Err: ', err);
      reject(err);
    })
  });
}

function putStorageMachineRef(machine: MachineItem): Promise<MachineItem> {
  return new Promise<MachineItem>((resolve, reject) => {
    const uri =  '/workbenchRef/' + machine.uuid;
    webapi.client.put<MachineItem>(uri, machine).then((resp) => {      
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageMachine Err: ', err);
      reject(err);
    })
  });
}

function putStorageMachineNest(nest: NestItem): Promise<NestItem> {
  return new Promise<NestItem>((resolve, reject) => {
    const uri =  '/nest/' + nest.uuid;
    webapi.client.put<NestItem>(uri, nest).then((resp) => {      
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageMachineNest Err: ', err);
      reject(err);
    })
  });
}

function putStorageMachineNestRef(nest: NestItem): Promise<NestItem> {
  return new Promise<NestItem>((resolve, reject) => {
    const uri =  '/nestRef/' + nest.uuid;
    webapi.client.put<NestItem>(uri, nest).then((resp) => {      
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageMachineNest Err: ', err);
      reject(err);
    })
  });
}

function delStorageMachine(id: string): Promise<void> {
  // console.log('getStorageParts invoked');
  return new Promise<void>((resolve, reject) => {
    const uri =  '/workbench/' +id;
    webapi.client.delete(uri).then((resp) => {      
      // console.log('::: deleteStorageMachine', resp)
      resolve();
    }).catch((err) => {
      // console.log('::: delStorageMachine Err: ', err);
      reject(err);
    })
  });
}

function delStorageMachineNest(id: string): Promise<void> {
  // console.log('getStorageParts invoked');
  return new Promise<void>((resolve, reject) => {
    const uri =  '/nest/' +id;
    webapi.client.delete(uri).then((resp) => {      
      // console.log('::: deleteStorageMachineNest', resp)
      resolve();
    }).catch((err) => {
      // console.log('::: delStorageMachineNest Err: ', err);
      reject(err);
    })
  });
}

