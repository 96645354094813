import React from 'react';
import { Translation } from 'react-i18next';
import Moment from 'moment';
import CSS from 'csstype';
import Measurement from '../ui/Measurement';
import FormatNumber from '../../helper/format-number'; 
import { getFormatNameLabel } from './helper/statisticsHelpers';
import { AppSettings, UsedDelimiterEnum, UsedUnitsEnum } from '../../store/system/types';
import NamingHelper from '../settings/naming/naming-helper';
import NamingContext from '../../context/naming/naming-context';
import { MACHINE, NEST } from '../../constants/lang/translation-keys';
import { PageItem } from './StatisticsBody';
import { MeasurementFlatItem } from '../../store/statistics/types';

type TableBodyProps = {
    items: PageItem[]
    settings: AppSettings
    showSingleDeletePopUp: (statsThis: any, item: MeasurementFlatItem) => void
    statsThis: any
}

export const TableBody: React.FC<TableBodyProps> = (props) => {
    const { items, settings, showSingleDeletePopUp, statsThis } = props;
    const { usedUnits, usedDelimiter, advancedMode, allowUserStatsDeletion } = settings;
    const isDelimiterPoint = usedDelimiter === UsedDelimiterEnum.DecimalPoint;
    const isUnitMillimeter = usedUnits === UsedUnitsEnum.MM;
    const isUserAllowedToDelete = advancedMode || allowUserStatsDeletion;
    const fN = new FormatNumber(isUnitMillimeter,isDelimiterPoint);

    const [uiState, setUiState] = React.useState({
        viewportWidth: window.innerWidth,
        chartWrapperWidth: 0,
        chartWrapperHeight: 0
    })
    const chartWrapper = React.useRef<HTMLTableCellElement>(null);
    const chartHeightCss = {
        height: `${uiState.chartWrapperWidth}px`,
        width: `${uiState.chartWrapperHeight}px`,
        marginTop: `-${uiState.chartWrapperWidth / 2}px`,
        marginLeft: `-${uiState.chartWrapperHeight / 2}px`
    };


    const { naming } = React.useContext(NamingContext.Context);
    const getNaming = ( key: string ) => NamingHelper.getNaming( key, naming );

    React.useEffect(()=>{
        window.addEventListener("resize", ()=>updateWindowSizeDependencies());
        return window.removeEventListener("resize", ()=>updateWindowSizeDependencies());
    },[]);
    
    React.useEffect(()=>{
        updateWindowSizeDependencies();
    },[items]);

    const updateWindowSizeDependencies = () => {
        const clientWidth = chartWrapper.current?.clientWidth ?? 20
        const clientHeight = chartWrapper.current?.clientHeight ?? 20
        setUiState({
            chartWrapperWidth: clientWidth - 20,
            chartWrapperHeight: clientHeight - 20,
            viewportWidth: window.innerWidth
            })
    }

    const MapRow = ( { pageItem, index, hidden }:{ pageItem: PageItem, index: number, hidden: boolean }) => {

        const {
            featureDecimalPlaces,
            featureValue,
            featureOT,
            featureUT,
            imageUrl,
            partName,
            workbenchName,
            nestName,
            measurementTimestamp,
            featureName,
            featureNominal
        } = pageItem.item;
        const decimalPlaces = Number(featureDecimalPlaces);
        let mValue:any = featureValue ? Number(featureValue) : null;
        let utValue:any = featureUT ? Number(featureUT) : null;
        let otValue:any = featureOT ? Number(featureOT) : null;
        if (mValue !== null) {
            mValue = fN.formatFixed(mValue,decimalPlaces);
        }
        if (utValue !== null) {
            utValue = fN.formatFixed(utValue,decimalPlaces);
        }
        if (otValue !== null) {
            otValue = fN.formatFixed(otValue,decimalPlaces);
        }
        const image: CSS.Properties = {
            backgroundImage: `url(${imageUrl})`,
        }
        //lets build the name string
        const name = getFormatNameLabel(partName)


        const config = {
            ot: featureOT,
            nm: featureNominal,
            ut: featureUT,
            upperWarnLimit: pageItem.attHistory?.upperWarnLimit,
            lowerWarnLimit: pageItem.attHistory?.lowerWarnLimit,
            withWarnlimits: pageItem.attHistory?.withWarnlimits
        }


        return (
            <Translation key={`${pageItem.item.featureUuid}-${index}-${hidden ? 'extra' : ''}`}>{t =>
                <>
                    <tr hidden={hidden}>
                        <td className="td-time" data-label={t('Time')}>
                            {
                                // seems weird but it needs to seat here. 
                                // only for mobile
                                isUserAllowedToDelete &&
                                uiState.viewportWidth <= 992 &&
                                <button
                                    className="btn btn--pin btn--pin-trash d-lg-none"
                                    onClick={() => showSingleDeletePopUp(statsThis, pageItem.item)}
                                ></button>
                            }
                            {Moment(measurementTimestamp).format('HH:mm:ss')}<br />
                            <span className="hsmall text-grey">{Moment(measurementTimestamp).format('DD.M.YYYY')}</span>
                        </td>
                        <td className="td-part" data-label={t('Part')}>
                            <div className="d-flex align-items-center" key={`image-${index}`}>
                                <span className="circle-small mr-2" style={image}></span>
                                <span className="strong cell-limiter cell-limiter--with-image">{name}</span>
                            </div>
                        </td>
                        <td data-label={getNaming(MACHINE)}>
                            <span className="cell-limiter">{workbenchName}</span>
                        </td>
                        <td data-label={getNaming(NEST)}>
                            <span className="cell-limiter">{nestName}</span>
                        </td>
                        <td data-label={t('Feature')}>
                            <span className="cell-limiter">{featureName}</span>
                        </td>
                        <td data-label={t('LowerTolerance')}>
                            {utValue}
                            <br />
                            <span className="hsmall text-grey">{t(usedUnits)}</span>
                        </td>
                        <td data-label={t('UpperTolerance')}>
                            {otValue}
                            <br />
                            <span className="hsmall text-grey">{t(usedUnits)}</span>
                        </td>
                        <td data-label={t('Value')}>
                            {mValue}
                            <br />
                            <span className="hsmall text-grey">{t(usedUnits)}</span>
                        </td>
                        <td className="chart-container" ref={chartWrapper}>
                            <div className="chart__horizontal" style={chartHeightCss}>
                                <Measurement
                                    item={{ config }}
                                    value={featureValue}
                                    show={true}
                                />
                            </div>
                            {
                                isUserAllowedToDelete &&
                                uiState.viewportWidth > 992 &&
                                <button
                                    className="btn btn--pin btn--pin-trash"
                                    onClick={() => showSingleDeletePopUp(statsThis, pageItem.item)}
                                ></button>
                            }
                        </td>
                    </tr>
                    <tr
                        key={`${pageItem.item.featureUuid}-${index}-space`}
                        className="tr-space"
                    >
                    </tr>
                </>
            }</Translation>
        )
    }

    return (
        <tbody>
            { // in horizontal mode use component for chart naming
                items.length !== 0 && uiState.viewportWidth > 992 && <MapRow pageItem={items[0]} index={-1} hidden />
            }
            {
                items.length !== 0 && items.map(( pageItem, index ) => {
                    const params = { pageItem, index };
                    return <MapRow {...params} hidden={false} key={`${pageItem.item.featureUuid}-${index}`} />
                })
            }
        </tbody>
    )
}
