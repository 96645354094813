export const isStringUniqueInTheCollection = (name:string, target:any, collection:any, itemId:string) => {
    return collection.every((item:any, index:number) => {
        const id = item.id ? item.id : item.uuid
        if(id === itemId) return true
        return (item[target]).trim() !== (name).trim()
    })
}

export const hasDuplicates = (collection:any) => {
    let seen = new Set();
    return collection.some((item:any) => seen.size === seen.add((item.name).trim()).size)
}