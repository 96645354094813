import React from 'react';
import Button from '../ui/Button';
import { AttributeItem, AttributesState } from '../../store/attributes/types';
import { PartItem } from '../../store/parts/types';
import { thunkGetAttributesByPartId } from '../../store/attributes/thunks';
import { thunkNavigateAttribute, thunkCreatePart } from '../../store/parts/thunks';
import { getAttribute } from '../../store/attributes/selectors';
import { getPart, getPartsAll } from '../../store/parts/selectors';
import { connect } from 'react-redux';
import Attribute from './Attribute';
import { overlayToggle, overlayLoadComponent, changeAttributeMode, updateSession, measurementCounterIncrease, setAttributeEditFormMode, updateTempAttribute, validationLoad } from '../../store/system/action';
import AttributeEditForm from './AttributeEditForm';
import Dropdown from '../ui/Dropdown/Dropdown';
import { currentMachine, SystemState, UsedDelimiterEnum, UsedUnitsEnum } from '../../store/system/types';
import { attributeModeTypes } from '../../store/attributes/types';
import { v4 as uuidv4 } from 'uuid'
import { thunkAsyncCreateAttribute, thunkUpdateAttribute, thunkDeleteAttribute } from '../../store/attributes/thunks';
import { thunkCreateMeasurement, thunkGetLastStatistics } from '../../store/statistics/thunks';
import { addAttribute } from '../../store/attributes/actions'
import { MachineItem } from '../../store/machines/types';
import { Translation, withTranslation } from 'react-i18next';
import { ChannelItem, MeasureObject } from '../../store/channels/types';
import { MessageContext } from '../../context';
import { Subscription } from 'rxjs';
import { MeasurementItem, FeatureValueItem, SensorValueItem } from '../../store/statistics/types';
import { AppState } from '../../store/reducers';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment'
import { ExportTranslations, MeasurementsPdfExport } from './export/measurements-pdf-export';
import { attributeEditFormMode } from "../../store/system/types";
import { buildAnEmptyAttributeObject, isQdasMandatoryFieldsValid } from './AttributeHelpers';
import { getPartLabel, newPart } from '../parts/partHelpers';
import PartEditForm from '../parts/PartEditForm'
import { confirmAlert } from 'react-confirm-alert';
import MachineEditForm from '../machines/MachineEditForm';
import { AppConfig } from '../../constants/app-config';
import i18n from '../../constants/i18n';
import { QumoFeatureItem } from '../../context/qumo-features/qumo-features-types';
import QdasPopUp from '../qdas/QdasPopUp';
import QumoFeaturesContext from '../../context/qumo-features/qumo-features-context';
import NamingHelper from '../settings/naming/naming-helper';
import { NamingContextType } from '../../context/naming/naming-context-types';
import { CHOOSE_MACHINE, CREATE_MACHINE, CREATE_MACHINE_TO_ACCEPT_MEASUREMENT, MACHINE, NEST } from '../../constants/lang/translation-keys';
import { isNull } from 'lodash';


interface AttributesProps {
    match: any
    getPart: any
    getPartsAll: any
    getAttribute: any
    thunkGetAttributesByPartId: any
    location: any
    system: SystemState
    history: any
    parts: PartItem[]
    machines: MachineItem[]
    channelItems: ChannelItem[]
    attributes: AttributesState
    thunkDeleteAttribute: any
    thunkAsyncCreateAttribute: any
    overlayToggle: typeof overlayToggle
    overlayLoadComponent: typeof overlayLoadComponent
    changeAttributeMode: typeof changeAttributeMode
    thunkCreateMeasurement: any,
    thunkUpdateAttribute: any
    addAttribute: any
    updateSession: any
    measurementCounterIncrease: typeof measurementCounterIncrease
    setAttributeEditFormMode: typeof setAttributeEditFormMode
    thunkCreatePart: any
    i18n: any
    t: any;
    updateTempAttribute: typeof updateTempAttribute
    namingContext: NamingContextType
    validationLoad: any
}

interface AttributesLocalState {
    partId: string | null,
    part: PartItem | null
    channels: number[],
    timestamp: null | number,
    bottomBarOpened: boolean
    chartWrapperWidth: any
    chartWrapperHeight: any
    savingMeasurement: boolean
}

class Attributes extends React.Component<AttributesProps, AttributesLocalState> {

    constructor(props: AttributesProps) {
        super(props);
        this.state = {
            partId: '',
            part: null,
            channels: [ NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN ],
            timestamp: null,
            bottomBarOpened: false,
            chartWrapperWidth: null,
            chartWrapperHeight: null,
            savingMeasurement: false
        }
    }


    //*******************************************************
    // lifecycle
    //*******************************************************

    static contextType = MessageContext;
    private subChannels?: Subscription;
    chartWrapper: any = React.createRef()

    componentDidMount() {

        //lets get the current part id
        this.loadCurrentPartIdToLocalState()

        // and evaluate the current attribute mode in the redux
        setTimeout(() => {
            this.props.changeAttributeMode(this.getCurrentMode(this.props.location.pathname))
        }, 100);

        this.handleChannelsSubscription()

    }


    componentDidUpdate(prevProps: AttributesProps) {

        //lets check if the route changed
        if (prevProps.location.pathname !== this.props.location.pathname) {

            const id = this.props.location.pathname.split('/part/')[1]
            this.loadCurrentPartIdToLocalState(id)

            const currentMode = this.getCurrentMode(this.props.location.pathname)
            this.props.changeAttributeMode(currentMode)

            //this will handle evtl. opened edit mode
            this.handleLeave(this.props.location)
            this.handleChannelsSubscription()
        }

        // if attributes changed or got loaded lets check if we can run the measurement 
        // or do we have to reference first
        if (prevProps.attributes !== this.props.attributes && this.props.attributes.items) {
            const currentMode = this.getCurrentMode(this.props.location.pathname)
            this.props.changeAttributeMode(currentMode);
        }

        // lets handle the subscription after the component update
        if (prevProps.system.attributesMode !== 'measure' && this.props.system.attributesMode === 'measure') {
            this.handleChannelsSubscription()
        }
    }

    componentWillUnmount() {
        this.stopChannelsSubscription()
    }


    //*******************************************************
    // config
    //*******************************************************


    getCurrentMode(path: string) {
        let mode: attributeModeTypes
        if (path.includes('edit')) {
            mode = this.props.system.settings.advancedMode ? 'edit-active' : 'edit'
        } else {
            if (this.isAtLeastOneAttributeReferenced()) {
                mode = 'measure'
            } else {
                mode = 'reference'
            }
        }
        return mode
    }

    isAtLeastOneAttributeReferenced() {
        let isPossible = false
        if (this.props.attributes.items) {
            this.props.attributes.items.every((attribute: AttributeItem) => {
                if (attribute.reference.timestamp === 0) {
                    isPossible = false
                } else {
                    isPossible = true
                }
                return attribute.reference.timestamp === 0
            })
        } else {
            isPossible = true
        }
        return isPossible
    }

    isSavingPossible() {
        if (!this.props.attributes.items) return false
        return this.isAtLeastOneAttributeMeasured() && this.isMachineChosen()
    }

    isAtLeastOneAttributeMeasured() {
        let isSavingPossible = false
        const { attributes } = this.props
        attributes.items.every((attribute: AttributeItem) => {
            if (Number(attribute.measurement.attributeValue) !== 0) {
                isSavingPossible = true
                return false
            } else {
                return true
            }
        })
        return isSavingPossible
    }

    isMachineChosen() {
        return this.props.system.currentMachine.machine.uuid !== null
    }

    atLeastOneMachineExist() {
        return this.props.machines.length > 0
    }

    loadCurrentPartIdToLocalState(id: string | null = null): any {

        if (this.props.parts.length === 0) {
            return
        }

        let idEvaluated

        if (localStorage.getItem('promess-current-partId') && this.props.getPart(localStorage.getItem('promess-current-partId'))) {
            idEvaluated = localStorage.getItem('promess-current-partId')
        } else if (id !== null) {
            idEvaluated = id
        } else {
            const idFromParams = this.props.match.params.id
            idEvaluated = idFromParams ? idFromParams : this.props.parts[0].id
        }

        const part = this.props.getPart(idEvaluated)
        if (part) {
            this.setState({
                partId: idEvaluated,
                part
            })
        }


        if (
            (this.state.partId === '' && this.props.attributes.items.length < 1) ||
            (this.state.partId !== '' && this.state.partId !== idEvaluated) ||
            idEvaluated !== localStorage.getItem('promess-current-partId') ||
            this.props.attributes.items.length < 1
        ) {
            this.props.thunkGetAttributesByPartId(idEvaluated);
            this.setState({
                partId: idEvaluated,
            })

            localStorage.setItem('promess-current-partId', idEvaluated);
        }
    }


    //*******************************************************
    // user interaction
    //*******************************************************

    buttonGroupToggleEmiter(value: any) {
        this.props.changeAttributeMode(value)
        setTimeout(() => {
            this.handleChannelsSubscription()
        }, 200);
    }

    onDropdownChangeMachine(currentMachine: any) {

        // console.log('******** currentMachine *********', currentMachine.item ? currentMachine.item.imageUrl : currentMachine.imageUrl);

        const updatedSession = {
            ...this.props.system
        }

        // we have 2 scenarios
        // 1. clicked on the subitem  
        if (currentMachine.item) {
            updatedSession.currentMachine.machine.name = currentMachine.item.name
            updatedSession.currentMachine.machine.uuid = currentMachine.item.uuid
            updatedSession.currentMachine.machine.imageUrl = currentMachine.item.imageUrl
            updatedSession.currentMachine.nest.name = currentMachine.subItem.name
            updatedSession.currentMachine.nest.uuid = currentMachine.subItem.uuid
            updatedSession.currentMachine.isDefault = false;

            // 2. clicked on the item with no subitems but an existing nest
        } else {
            updatedSession.currentMachine.machine.name = currentMachine.name
            updatedSession.currentMachine.machine.uuid = currentMachine.uuid
            updatedSession.currentMachine.machine.imageUrl = currentMachine.imageUrl
            updatedSession.currentMachine.nest.name = currentMachine.nests[0].name
            updatedSession.currentMachine.nest.uuid = currentMachine.nests[0].uuid
            updatedSession.currentMachine.isDefault = true;
        }

        this.props.updateSession(updatedSession);
    }

    onDropdownChange(listItem: any) {
        const id = listItem.id

        // save the current PART in the localstorage 
        // for navigation purposes
        //--------------------------------------------------------

        localStorage.setItem('promess-current-partId', id);


        const history = this.props.history
        const path = this.props.system.attributesMode === 'edit-active' ? `/part-edit/${id}` : `/part/${id}`;
        // console.log(this.props.system.attributesMode);
        // console.log(path);

        history.push(path)
    }

    toggleEditMode() {
        const mode = this.props.system.attributesMode === 'edit' ? 'edit-active' : 'edit'
        this.props.changeAttributeMode(mode)
    }

    handleSave( selfThis: this, qdasData?: string ) {

        if (!selfThis.isSavingPossible()) {

            let message = ''
            if (!selfThis.isMachineChosen()) message = 'PleaseChooseAMachineFirst'
            if (!selfThis.isAtLeastOneAttributeMeasured()) message = 'PleaseMeasureAtLeastOneAttributeToSaveTheMeasurement'
            if (!selfThis.atLeastOneMachineExist()) message = 'YouNeedToCreateAMachineInOrderToSaveAMeasurement'

            if(message){
                confirmAlert({
                    closeOnEscape: true,
                    customUI: ({ onClose }) => {
                        return (
                            <Translation>{t =>
                                <div className='react-confirm-content w-100'>
                                    <div className="row mb-3">
                                        <div className="col-10 d-flex align-items-center">
                                            <h2 className="hsmall strong text-uppercase mb-0 text-left">{this.getNaming(message)}</h2>
                                        </div>
                                        <div className="col-2">
                                            <button onClick={onClose} className="btn btn--icon btn--icon-close btn--action-dark ml-auto"></button>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className={!selfThis.atLeastOneMachineExist() ? 'd-none' : 'col-8 ml-auto mr-auto'}>
                                            <button
                                                type="button"
                                                className='btn w-100'
                                                onClick={onClose}
                                            >
                                                {t('Understood')}
                                            </button>
                                        </div>
                                        <div className={selfThis.atLeastOneMachineExist() ? 'd-none' : 'col-8 ml-auto mr-auto'}>
                                            <button
                                                type="button"
                                                className='btn w-100 btn--white'
                                                onClick={() => { selfThis.addMachine(onClose) }}
                                            >
                                                {this.getNaming(CREATE_MACHINE)}
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </Translation>
                        );
                    },
                })
            }
        } else {
            selfThis.save( qdasData )
        }
    }

    save( qdasData?: string ) {
        this.setState({savingMeasurement:true})
        const { machine, nest } = this.props.system.currentMachine
        const { partId, part } = this.state;

        const lastMeasurement = {
            items: cloneDeep(this.props.attributes.items),
            machine: machine,
            part: part,
            nest: nest,
            timestamp: new Date().getTime()
        }
        // for the drawer save last measurement (readonly only for the drawer)
        this.props.updateSession({
            ...this.props.system,
            lastMeasurement
        })


        // FIXME: nogo für 'n/a'
        const newItem: MeasurementItem = {
            uuid: uuidv4(),
            workbenchUuid: machine.uuid ? machine.uuid : '',
            workbenchName: machine.name ? machine.name : 'n/a',
            nestUuid: nest.uuid ? nest.uuid : '',
            nestName: nest.name ? nest.name : 'n/a',
            partId: partId ? partId : 'n/a',
            partName: `${part?.designNumber} / ${part?.partNumber}`,
            featurevalues: [],
            measurementTimestamp: new Date().getTime(),
            imageUrl: '',
            data: qdasData ?? ''
        }

        this.props.attributes.items.forEach(item => {
            const featureValue = item.measurement.attributeValue;
            if (Number(featureValue) !== 0) {
                if ( isNull(featureValue) || isNaN(Number(featureValue)) || Number(featureValue) === 0 ) {
                    console.log('featureValue is null or NaN'); // TODO: fatal error logging
                }
                const sensors: SensorValueItem[] = []
                for (const s of item.sensors) {
                    const integerId = s.reference.channelId;
                    if (integerId !== null && integerId !== undefined) {
                        const ch = this.props.channelItems[integerId];
                        sensors.push({
                            uuid: uuidv4(),
                            channelUuid: ch.uuid,
                            value: s.reference.measurementValue,
                        })
                    }
                    
                }
                const fv: FeatureValueItem = {
                    uuid: uuidv4(),
                    measurementTimestamp: item.measurement.timestamp,
                    featureUuid: item.uuid,
                    featureName: item.name,
                    featureValue: item.measurement.attributeValue ? item.measurement.attributeValue : 0,
                    featureDecimalPlaces: item.config.decimalPlaces,
                    sensors: sensors,
                    measurementChart: item.measurement.measurementChart
                }
                newItem.featurevalues.push(fv)
            } else {
                const fv: FeatureValueItem = {
                    uuid: '',
                    measurementTimestamp: item.measurement.timestamp,
                    featureUuid: item.uuid,
                    featureName: item.name,
                    featureValue: 0,
                    featureDecimalPlaces: item.config.decimalPlaces,
                    sensors: [],
                    measurementChart: ''
                }
                newItem.featurevalues.push(fv)
            }
        })

        this.props.thunkCreateMeasurement(newItem).then(() => {
            this.props.measurementCounterIncrease()
            this.resetAllMeasurements()
            .then(() => {
                this.setState({savingMeasurement:false})
            })
        })
    }

    getNaming(key: string){
        return NamingHelper.getNaming(key, this.props.namingContext.naming);
    }

    pdfExport() {
        const translations: ExportTranslations = {
            report: this.props.t('Report'),
            date: this.props.t('Date'),
            machine: this.getNaming(MACHINE),
            part: this.props.t('Part'),
            nest: this.getNaming(NEST),
            result: this.props.t('Result'),
            measurements: this.props.t('Measurements'),
            feature: this.props.t('Feature'),
            upperTolerance: this.props.t('UpperTolerance'),
            lowerTolerance: this.props.t('LowerTolerance'),
            measurement: this.props.t('Measurement'),
            ok: this.props.t('Ok'),
            nok: this.props.t('Nok'),
            startDate: this.props.t('StartDate'),
            value: this.props.t('Value'),
            diagram: this.props.t('Diagram'),
            evaluation: this.props.t('Eval.'),
            exportetFrom: this.props.t('ExportedFrom'),
            unitMm: this.props.t('Millimeter'),
            unitInch: this.props.t('Inch')
        }
        const isUnitMillimeter = this.props.system.settings.usedUnits === UsedUnitsEnum.MM;
        const isDecimalPoint = this.props.system.settings.usedDelimiter === UsedDelimiterEnum.DecimalPoint
        const exportToPdf = new MeasurementsPdfExport(translations, isUnitMillimeter, isDecimalPoint);
        // console.log('last', this.props.system.lastMeasurement)
        exportToPdf.setData(this.props.system.lastMeasurement);
        exportToPdf.exportProtocol('measurements', this.props.i18n.language);
    }


    //*******************************************************
    // helpers
    //*******************************************************

    resetAllMeasurements() {
        return new Promise((resolve, reject) => {
            Promise.all(
                this.props.attributes.items.map(item => {
                    if (Number(item.measurement.attributeValue) !== 0) {
                        const newAttribute: AttributeItem = { ...item }
                        newAttribute.measurement.attributeValue = 0
                        newAttribute.measurement.timestamp = 0
                        return this.props.thunkUpdateAttribute(newAttribute, true)
                    }
                    return false
                })
            )
            .then(
                () => { resolve('done')}
            )
            .catch(() => reject('problems with reseting attributes'))
        })
    }

    startChannelsSubscription() {
        if (this.subChannels) {
            this.subChannels.unsubscribe();
        }
        this.subChannels = this.context.onChannels().subscribe((msg: MeasureObject) => {
            this.setState({
                channels: msg.values,
                timestamp: msg.timeStamp
            })
        });
    }

    stopChannelsSubscription() {
        this.subChannels?.unsubscribe()
    }

    handleChannelsSubscription() {
        //handle the subscription
        if (this.props.system.attributesMode === 'measure') {
            console.log('handleChannelsSubscription::measure started');
            this.startChannelsSubscription()
        } else {
            console.log('handleChannelsSubscription::measure stopped');
            this.stopChannelsSubscription()
        }
    }

    handleLeave(location: any) {

        // console.log('handle leave');

        // if user was editing
        // and wants to leave the edit mode via navigation or browser back button
        // we need to delete the item that was being edited
        if (this.props.system.overlay.main.show) {

            //if attributeEditForm opened and sensor matching active let's switch from sensor matching to feature editing
            if (this.props.system.attributeEditFormMode === attributeEditFormMode.CONFIG) {
                this.props.setAttributeEditFormMode(attributeEditFormMode.DEFAULT)
            }

            //attribute mode
            // if(location.pathname !== '/') this.props.changeAttributeMode('disabled')

            //find all draft attributes
            const attributesAdded: AttributeItem[] = this.props.attributes.items.filter(attribute => attribute.isDraft === true)

            //and delete them
            attributesAdded.forEach(attribute => {
                this.props.thunkDeleteAttribute(attribute)
            })
        }
    }

    addPart = () => {

        this.props.history.push('/parts');

        this.props.overlayLoadComponent(<PartEditForm history={this.props.history} item={ {...newPart, id: uuidv4()} } />);
        this.props.overlayToggle();
    }

    addAttribute(redirect: boolean = false) {

        // if redirect to edit screen
        //--------------------------------------------------------

        if (redirect) this.props.history.push(`/part-edit/${this.state.partId}`)

        // build and add an empty attribute
        //--------------------------------------------------------

        const attributeID = uuidv4()
        const newAttr = buildAnEmptyAttributeObject(this.state.partId, attributeID)


        // store the attribute in redux as a temporaryObject
        //--------------------------------------------------------

            this.props.updateTempAttribute(newAttr, true);


        // save the attribute and its sensors and open the overlay
        //--------------------------------------------------------                

        setTimeout(() => {
            this.props.overlayLoadComponent(<AttributeEditForm attributeId={attributeID} usedDelimeter={this.props.system.settings.usedDelimiter} />)
            this.props.overlayToggle();
        }, 300);
    }

    addMachine(callback: any = null) {
        if (callback) callback()
        this.props.history.push('/machines')
        this.props.overlayLoadComponent(<MachineEditForm addingNewMachine={true} />)
        this.props.overlayToggle()
    }

    hasLastMeasurement() {
        return this.props.system.lastMeasurement.items.length !== 0
    }

    

    renderDrawer() {

        // fallback
        if (!this.state.bottomBarOpened) {
            return false
        }

        if (!this.hasLastMeasurement()) {
            return (
                <Translation>{t =>
                    <div>{t('LastMeasurementNotAvailable')}</div>
                }</Translation>
            )
        }

        // render
        return (
            <div>
                <p className="hsmall strong text-uppercase">
                    <Translation>{t => t('LastMeasurementResult')}</Translation>
                </p>
                <div className="d-flex mb-4">
                    <span className="mr-3 badge badge--fluid badge--secondary strong">
                        {moment(this.props.system.lastMeasurement.timestamp).format(AppConfig.time.fullFormat(i18n.language))}
                    </span>
                    <span className="badge badge--fluid badge--secondary strong mr-3">{this.props.system.lastMeasurement.machine?.name}</span>
                    <span className="badge badge--fluid badge--secondary strong">
                        {this.props.system.lastMeasurement.part?.designNumber} / {this.props.system.lastMeasurement.part?.partNumber}
                    </span>
                </div>
                <div className="d-flex flex-wrap">
                    {this.props.system.lastMeasurement.items.map((item: AttributeItem, index) => {
                        return (
                            <Attribute
                                key={index}
                                attribute={{ ...item, readonly: true }}
                                styleString='attribute'
                                index={index}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }


    //*******************************************************
    // render
    //*******************************************************

    render() {

        const part: PartItem | null = this.state.part ? this.state.part : null
        const { currentMachine }: { currentMachine: currentMachine } = this.props.system
        const isAdvancedMode = this.props.system.settings.advancedMode


        // dropdowns
        //--------------------------------------------------------
        // const DropdownLabelParts = part?.designNumber ? `${part?.partNumber } / ${ part?.designNumber }` : part?.partNumber
        const DropdownLabelParts = part ? getPartLabel(part) : ''
        let dropdownLabelMachine = this.getNaming(CHOOSE_MACHINE)

        if (currentMachine.machine.name && currentMachine.nest.name) {
            const afterfixMachine = currentMachine.machine.name.length > AppConfig.characterLimits.machineNameBtn ? '...' : ''
            const afterfixNest = currentMachine.nest.name.length > AppConfig.characterLimits.machineNestNameBtn ? '...' : ''
            const machineLabel = currentMachine.machine.name.slice(0, AppConfig.characterLimits.machineNameBtn) + afterfixMachine
            const nestLabel = currentMachine.nest.name.slice(0, AppConfig.characterLimits.machineNestNameBtn) + afterfixNest

            dropdownLabelMachine = `${machineLabel}/${nestLabel}`
        }
        if (currentMachine.machine.name && currentMachine.isDefault) dropdownLabelMachine = currentMachine.machine.name



        // modes
        //--------------------------------------------------------

        const isEditMode = this.props.system.attributesMode.includes('edit')
        const isReferenceMode = this.props.system.attributesMode.includes('reference')


        // mobile pocket
        //--------------------------------------------------------

        const mobilePocketCss = !isEditMode ? 'app__main-content--mobile-pocket' : ''


        // saving button
        //--------------------------------------------------------

        let saveButtonClasses = ' disabled'
        if(this.isMachineChosen() && this.isAtLeastOneAttributeMeasured()) saveButtonClasses = ''
        if(this.state.savingMeasurement) saveButtonClasses = ' btn--loading';

        const acceptMeasurement = {
            measureHandler: (qdasData: string) => this.handleSave( this, qdasData ),
            show: !isEditMode && this.props.attributes.items.length !== 0 && !isReferenceMode && !this.state.bottomBarOpened,
            saveButtonClasses,
            isQdasMandatoryFieldsValid: ()=>isQdasMandatoryFieldsValid(this)
        }


        return (
            <>
                <Translation>{
                    t =>
                        <div className="app__main">
                            <div className="app__main-header">
                                <div className="container-fluid d-flex">
                                    {
                                        part?.imageUrl &&
                                        <img className="flag icon icon--round icon--for-btn mr-2" src={part.imageUrl} alt="" />
                                    }
                                    {
                                        this.props.parts.length !== 0 &&
                                        <Dropdown
                                            items={this.props.parts}
                                            label={DropdownLabelParts}
                                            description={t('ChoosePart')}
                                            onChangeHandler={(e: any) => this.onDropdownChange(e)}
                                            styles='mr-3 dropdown--parts'
                                        />
                                    }
                                    {
                                        this.props.parts.length !== 0 &&
                                        !isEditMode &&
                                        isReferenceMode &&
                                        this.isAtLeastOneAttributeReferenced() &&
                                        <div className="col-md-3 col-xl-2 ml-auto pr-0">
                                            <Button
                                                styles="btn w-100"
                                                show={true}
                                                label={t('Measure')}
                                                clickHandler={() => { this.buttonGroupToggleEmiter('measure') }}
                                            />
                                        </div>
                                    }

                                    {
                                        this.props.parts.length !== 0 &&
                                        !isEditMode &&
                                        !isReferenceMode &&
                                        <div className="col-md-3 col-xl-2 ml-auto pr-0">
                                            <Button
                                                styles="btn w-100"
                                                show={true}
                                                label={t('Reference')}
                                                clickHandler={() => { this.buttonGroupToggleEmiter('reference') }}
                                            />
                                        </div>
                                    }

                                    {
                                        this.props.parts.length !== 0 &&
                                        <Button
                                            styles='btn btn--icon btn--icon-plus ml-auto'
                                            isActive={false}
                                            clickHandler={(e: any) => { this.addAttribute() }}
                                            disabled={false}
                                            show={isEditMode && this.props.attributes.items.length < 8}
                                        />
                                    }
                                </div>
                            </div>
                            <div className={'app__main-content ' + mobilePocketCss}>
                                <div className="container-fluid">
                                    {
                                        // if no parts
                                        //--------------------------------------------------------
                                        !this.props.system.loading.attributes && this.props.parts.length === 0 && isAdvancedMode &&
                                        <div className="row pt-5">
                                            <div className="col-12 text-center">
                                                <h2 className="h3">{t('NoPartsDefinedYet')}</h2>
                                                <Button
                                                    show={true}
                                                    label={t('DefineAPart')}
                                                    styles='btn d-inline-block'
                                                    clickHandler={this.addPart}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {
                                        // if no attributes on measure screen 
                                        //--------------------------------------------------------
                                        this.props.parts.length !== 0 &&
                                        this.props.attributes.items.length === 0 &&
                                        !isEditMode &&
                                        <div className="row pt-5">
                                            <div className="col-12 text-center">
                                                <h2 className="h3">{t('NoAttributesCreated')}</h2>
                                                {
                                                    isAdvancedMode &&
                                                    <Button
                                                        styles='btn btn d-inline-block'
                                                        isActive={false}
                                                        clickHandler={(e: any) => { this.addAttribute(true) }}
                                                        disabled={false}
                                                        show={true}
                                                        label={t('CreateAttribute')}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    }
                                    {

                                        // if no attributes on attribute edit
                                        //--------------------------------------------------------
                                        (this.props.parts.length !== 0 && this.props.attributes.items.length === 0 && isEditMode) &&
                                        <div className="row pt-5">
                                            <div className="col-12 text-center">
                                                <h2 className="h3">{t('NoAttributesCreated')}</h2>
                                                {
                                                    isAdvancedMode &&
                                                    <Button
                                                        styles='btn btn d-inline-block'
                                                        isActive={false}
                                                        clickHandler={(e: any) => { this.addAttribute() }}
                                                        disabled={false}
                                                        show={true}
                                                        label={t('CreateAttribute')}
                                                    />
                                                }
                                            </div>
                                        </div>

                                    }
                                    {
                                        this.props.attributes.items.length > 0 &&
                                        this.props.parts.length !== 0 &&
                                        <div className="d-flex flex-wrap">
                                            {this.props.attributes.items.map((attribute, index) => {

                                                if(attribute.isDeleted) return null
                                                
                                                return (
                                                    <Attribute
                                                        key={index}
                                                        attribute={attribute}
                                                        channels={this.state.channels}
                                                        channelsTimestamp={this.state.timestamp}
                                                        styleString='attribute'
                                                        clickHandler={() => { 
                                                            // console.log('clickHandler')
                                                         }}
                                                        index={index}
                                                        isMeasurementDisabled = {this.state.savingMeasurement}
                                                    />
                                                )
                                            })}
                                        </div>
                                    }

                                    {!isEditMode && !isReferenceMode &&
                                    
                                        <div className={`app__bottom-bar ${this.state.bottomBarOpened ? 'active' : ''}`} >
                                            <div className="app__bottom-bar__inner">
                                                {
                                                    currentMachine.machine.imageUrl && !this.state.bottomBarOpened &&
                                                    <img className="flag icon icon--round icon--for-btn mr-2 align-self-center d-none d-md-flex" src={currentMachine.machine.imageUrl} alt="" />
                                                }
                                                
                                                {
                                                    this.props.machines.filter(machine => !machine.isDeleted).length === 0
                                                        ?
                                                            <Button
                                                            styles={'btn mb-1 mb-md-0 w-md-auto mr-auto align-self-center'}
                                                            label={this.getNaming(CREATE_MACHINE_TO_ACCEPT_MEASUREMENT)}
                                                            show={true}
                                                            clickHandler={() => { this.addMachine() }}
                                                            />
                                                        :
                                                            <Dropdown
                                                                items={ this.props.machines.filter(machine => !machine.isDeleted) }
                                                                label={t(dropdownLabelMachine)}
                                                                description={this.getNaming(CHOOSE_MACHINE)}
                                                                onChangeHandler={(e: any) => this.onDropdownChangeMachine(e)}
                                                                styles='mr-2 mb-1 mb-md-0 app__bottom-bar-machine-dropdown align-self-center'
                                                                corners={false}
                                                                hide={isEditMode || this.props.attributes.items.length === 0 || isReferenceMode || this.state.bottomBarOpened}
                                                            />
                                                }

                                                

                                                {
                                                    this.props.machines.filter(machine => !machine.isDeleted).length !== 0 &&
                                                        <QumoFeaturesContext.Context.Consumer>
                                                            {   value =>
                                                                    <>
                                                                        {
                                                                        this.props.system.settings.qumoActiveFeatures.find( f => f==='QDAS' ) &&
                                                                        value?.features?.find( (f: QumoFeatureItem) => f.id==='QDAS' )?.active
                                                                        
                                                                            ?
                                                                                <QdasPopUp
                                                                                    label={'Q-DAS'}
                                                                                    description={'Q-DAS'}
                                                                                    t={t}
                                                                                    currPartId={localStorage.getItem('promess-current-partId')}
                                                                                    acceptMeasurement={acceptMeasurement}
                                                                                />
                                                                            :
                                                                                <Button
                                                                                styles={'btn mb-1 mb-md-0 w-100 w-md-auto mr-auto max-w-40 align-self-center' + saveButtonClasses}
                                                                                label={t('AcceptMeasurement')}
                                                                                show={!isEditMode && this.props.attributes.items.length !== 0 && !isReferenceMode && !this.state.bottomBarOpened}
                                                                                clickHandler={() => { this.handleSave( this ) }}
                                                                                />
                                                                        }
                                                                    </>
                                                            }
                                                        </QumoFeaturesContext.Context.Consumer>
                                                }

                                                

                                                <span className={`app__bottom-bar-opener-wrapper ml-auto ${this.state.bottomBarOpened ? 'active' : ''}`}>
                                                    
                                                        <Button
                                                        styles='btn w-100 w-md-auto mt-3 mt-md-0 mr-3 d-none d-md-block'
                                                        label={t('PdfExportMeasurement')}
                                                        show={!isEditMode && this.props.attributes.items.length !== 0 && !isReferenceMode}
                                                        disabled={!this.hasLastMeasurement()}
                                                        clickHandler={() => {
                                                            this.pdfExport()
                                                            this.state.bottomBarOpened && this.setState({ bottomBarOpened: false })
                                                        }}
                                                        />
                                                    
                                                    <span
                                                        className="app__bottom-bar-opener"
                                                        onClick={() => {
                                                            this.setState({ bottomBarOpened: !this.state.bottomBarOpened })
                                                        }}
                                                    >
                                                    </span>
                                                </span>

                                            </div>

                                            <div className={`export-tab ${this.state.bottomBarOpened ? 'active' : ''}`} >
                                                <div className="export-tab__inner">
                                                    <Button
                                                        styles='btn mb-3 d-md-none'
                                                        label={t('PdfExportMeasurement')}
                                                        show={this.state.bottomBarOpened}
                                                        disabled={false}
                                                        clickHandler={() => {
                                                            this.pdfExport()
                                                            this.state.bottomBarOpened && this.setState({ bottomBarOpened: false })
                                                        }}
                                                    />
                                                    {
                                                        this.state.bottomBarOpened &&
                                                        <div className="export-tab__close" onClick={() => this.setState({ bottomBarOpened: false })}></div>
                                                    }
                                                    {this.renderDrawer()}
                                                </div>
                                            </div>
                                        </div>
                                       
                                    }
                                </div>
                            </div>
                        </div>
                }

                </Translation>
            </>
        );
    };
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {
        system: state.system,
        parts: getPartsAll(state),
        getPart: getPart(state),
        attributes: state.attributes,
        getAttribute: getAttribute(state),
        machines: state.machines.items,
        channelItems: state.channels.items
    }
};

const dispatchToProps = {
    thunkNavigateAttribute,
    thunkGetAttributesByPartId,
    thunkDeleteAttribute,
    thunkAsyncCreateAttribute,
    overlayToggle,
    overlayLoadComponent,
    changeAttributeMode,
    addAttribute,
    updateSession,
    thunkCreateMeasurement,
    thunkUpdateAttribute,
    measurementCounterIncrease,
    thunkGetLastStatistics,
    setAttributeEditFormMode,
    thunkCreatePart,
    updateTempAttribute,
    validationLoad
};
export default connect(mapStateToProps, dispatchToProps)(withTranslation()(Attributes));
