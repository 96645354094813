import React from "react";
import {
  AttributeItem,
  AttributeTypeEnum,
  SensorItem,
} from "../../store/attributes/types";
import Button from "../ui/Button";
import { connect } from "react-redux";
import { AppState } from "../../store/reducers";
import Measurement from "../ui/Measurement";
import AttributeReferenceInfo from "./AttributeReferenceInfo";
import {
  overlayLoadComponent,
  overlayToggle,
  toggleSlider,
  setInitialSliderSlideIndex,
  updateTempAttribute,
} from "../../store/system/action";
import { SystemState, UsedDelimiterEnum, UsedUnitsEnum } from "../../store/system/types";
import {
  thunkDeleteAttribute,
  thunkUpdateAttribute,
} from "../../store/attributes/thunks";
import AttributeEditForm from "./AttributeEditForm";
import { Translation } from "react-i18next";
import moment from "moment";
import {
  Sensor3PointConfigInterface,
  Sensor2PointConfigInterface,
  Sensor1PointConfigInterface,
} from "../../calc";
import {
  Sensor1Point,
  Sensor2Point,
  Sensor3Point,
  SensorSettingEnum,
} from "../../calc";
import { confirmAlert } from "react-confirm-alert"; // Import
import { ChannelItem } from "../../store/channels/types";
import DeleteMask from "../ui/DeleteMask";
import * as htmlToImage from 'html-to-image';
import { cloneDeep } from "lodash";
import { AppConfig } from "../../constants/app-config";
import i18n from "../../constants/i18n";
import FormatNumber from "../../helper/format-number";
import QdasPopUp from "../qdas/QdasPopUp";
import QumoFeaturesContext from '../../context/qumo-features/qumo-features-context';
import { QumoFeatureContextType, QumoFeatureItem } from '../../context/qumo-features/qumo-features-types';
import { NamingContextType } from "../../context/naming/naming-context-types";
import { serviceApi } from "../../api/service-api";

interface CalcResult {
  merkmal: number,
  sensor1: {
    value: number
    reference: number
  },
  sensor2: {
    value: number
    reference: number
  },
  sensor3: {
    value: number
    reference: number
  }
}


interface AttributeProps {
  attribute: AttributeItem;
  styleString: string;
  clickHandler: any;
  system: SystemState;
  channelItems: ChannelItem[];
  thunkDeleteAttribute: any;
  index: number;
  channels: number[];
  channelsTimestamp: number;
  overlayToggle: typeof overlayToggle;
  overlayLoadComponent: typeof overlayLoadComponent;
  toggleSlider: typeof toggleSlider;
  setInitialSliderSlideIndex: typeof setInitialSliderSlideIndex;
  updateTempAttribute: typeof updateTempAttribute
  thunkUpdateAttribute: any
  isMeasurementDisabled?: boolean
  namingContext: NamingContextType
}

interface AttributeLocalState {
  result: CalcResult | null;
  timestamp: number;
  dataFlow: boolean;
}

class Attribute extends React.Component<AttributeProps, AttributeLocalState> {
  private calcP3: Sensor3Point;
  private calcP2: Sensor2Point;
  private calcP1: Sensor1Point;

  static contextType = QumoFeaturesContext.Context;

  constructor(props: AttributeProps) {
    super(props);
    const { attribute } = this.props
    const { config, sensors } = attribute;
    const configP1: Sensor1PointConfigInterface = {
      masterSize: Number(config.masterSize),
      refrences: {
        rp1: this.getFirstReferenceFromSensor(sensors),
      },
      setting:
        config.type === AttributeTypeEnum.INNER
          ? SensorSettingEnum.INNER
          : SensorSettingEnum.OUTER,
    };
    const configP2: Sensor2PointConfigInterface = {
      masterSize: Number(config.masterSize),
      refrences: {
        rp1: this.getRefrenceFromSensor(sensors, 1),
        rp2: this.getRefrenceFromSensor(sensors, 2),
      },
      setting:
        config.type === AttributeTypeEnum.INNER
          ? SensorSettingEnum.INNER
          : SensorSettingEnum.OUTER,
    };
    const configP3: Sensor3PointConfigInterface = {
      masterSize: Number(config.masterSize),
      angles: {
        p1: config.angles[0],
        p2: config.angles[1],
        p3: config.angles[2],
      },
      refrences: {
        rp1: this.getRefrenceFromSensor(sensors, 1),
        rp2: this.getRefrenceFromSensor(sensors, 2),
        rp3: this.getRefrenceFromSensor(sensors, 3),
      },
      setting:
        config.type === AttributeTypeEnum.INNER
          ? SensorSettingEnum.INNER
          : SensorSettingEnum.OUTER,
    };

    this.calcP1 = new Sensor1Point(configP1);
    this.calcP2 = new Sensor2Point(configP2);
    this.calcP3 = new Sensor3Point(configP3);

    // console.log(`Merkmal:${attribute.name}::configP3:${JSON.stringify(configP3)}`);
    

    this.state = {
      result: null,
      timestamp: this.props.channelsTimestamp,
      dataFlow: false,
    };
  }

  chart: any = React.createRef()

  private getFirstReferenceFromSensor(sensors: SensorItem[]): number {
    let refValue = 0;
    const pos1 = sensors[0];
    const pos2 = sensors[1];
    if (pos1.reference.channelId && pos1.reference.channelId > 0) {
      refValue = pos1.reference.referenceValue;
    } else if (pos2.reference.channelId && pos2.reference.channelId > 0) {
      refValue = pos2.reference.referenceValue;
    }
    return refValue;
  }

  private getRefrenceFromSensor(sensors: SensorItem[], index: number): number {
    let refValue = 0;
    if (index <= sensors.length) {
      refValue = sensors[index - 1].reference.referenceValue;
    } else {
      console.log("getRefrenceFromSensorFailed::reason:index error");
    }
    return refValue;
  }

  //*******************************************************
  // lifecycle methods
  //*******************************************************

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // enable dataflow if value reseted
    if (this.props.attribute.measurement.timestamp === 0) {
      this.setState({
        dataFlow: true,
      });
    }

    //if channels update lets update the state
    // where the results are saved
    if (this.props.channels !== nextProps.channels && this.state.dataFlow) {
      // const ts = new Date().getTime()
      // const c = this.calc()
      // const diff = (new Date().getTime() - this.props.channelsTimestamp)
      // console.log('old Props: ', this.props.channelsTimestamp);
      // console.log('new Props: ', nextProps.channelsTimestamp);
      this.setState({
        result: this.calc(),
        timestamp: nextProps.channelsTimestamp,
      });
    }


  }

  componentDidMount() {
    if (this.props.attribute.measurement.attributeValue === 0) {
      this.setState({
        dataFlow: true,
      });
    }
  }

  /**
   * Feature main calc routine
   * @returns CalcResult Interface
   */
  calc(): CalcResult {
    const { channels, channelItems, attribute } = this.props;
    const { sensors } = attribute;
    const { sensorsCount, masterSize } = attribute.config;

    const masterSizeNum = Number(masterSize);

    const realtimeChannelValues = channels.map((v, idx) => {
      const val = v * channelItems[idx + 1].inversFactor;
      const val2 = channelItems[idx + 1].invers ? -1 * val : val;
      // console.log(`TEST:idx${idx}:v:${v}:val:${val2}`)
      return val2;
    })


    const setting =
      this.props.attribute.config.type === AttributeTypeEnum.INNER
        ? SensorSettingEnum.INNER
        : SensorSettingEnum.OUTER;

    let merkmal = 0;
    let v1 = 0;
    let v2 = 0;
    let v3 = 0;
    let rp1 = 0;
    let rp2 = 0;
    let rp3 = 0;

    const cIdPos1 = sensors[0].reference.channelId === null ? null : Number(sensors[0].reference.channelId);
    const cIdPos2 = sensors[1].reference.channelId === null ? null : Number(sensors[1].reference.channelId);
    const cIdPos3 = sensors[2].reference.channelId === null ? null : Number(sensors[2].reference.channelId);

    if (sensorsCount === 2) {
      // Zwei Positionen
      let isStopLimit = false;

      // console.log("Pos1: ", cIdPos1, "   Pos2: ", cIdPos2);
      if (cIdPos1 === 0) {
        isStopLimit = true;
        v1 = cIdPos2 ? realtimeChannelValues[cIdPos2 - 1] : 0;
      }
      if (cIdPos2 === 0) {
        isStopLimit = true;
        v1 = cIdPos1 ? realtimeChannelValues[cIdPos1 - 1] : 0;
      }
      if (isStopLimit) {
        const firstRefValue = this.getFirstReferenceFromSensor(sensors);
        merkmal = this.calcP1.calc(
          masterSizeNum,
          setting,
          v1,
          firstRefValue ? firstRefValue : 0
        );
      } else {
        v1 = cIdPos1 ? realtimeChannelValues[cIdPos1 - 1] : 0;
        v2 = cIdPos2 ? realtimeChannelValues[cIdPos2 - 1] : 0;

        let rp1 = this.getRefrenceFromSensor(sensors, 1);
        let rp2 = this.getRefrenceFromSensor(sensors, 2);

        rp1 = rp1 ? rp1 : 0;
        rp2 = rp2 ? rp2 : 0;
        // console.log(`v1:${v1}v2:${v2}`);
        // console.log("show refs", this.calcP2.getReferenceValues());

        // merkmal = this.calcP2.calcReferenced(v1, v2);
        merkmal = this.calcP2.calc(
          masterSizeNum,
          setting,
          v1,
          v2,
          rp1,
          rp2
        );
      }
    } else if (sensorsCount === 3) {
      // Drei Positionen
      v1 = cIdPos1 ? realtimeChannelValues[cIdPos1 - 1] : 0;
      v2 = cIdPos2 ? realtimeChannelValues[cIdPos2 - 1] : 0;
      v3 = cIdPos3 ? realtimeChannelValues[cIdPos3 - 1] : 0;

      // alert(`v1:${v1} v2:${v2} v3:${v3}`)

      let rp1 = this.getRefrenceFromSensor(sensors, 1);
      let rp2 = this.getRefrenceFromSensor(sensors, 2);
      let rp3 = this.getRefrenceFromSensor(sensors, 3);
      rp1 = rp1 ? rp1 : 0;
      rp2 = rp2 ? rp2 : 0;
      rp3 = rp3 ? rp3 : 0;

      rp1 = rp1 ? rp1 : 0;
      rp2 = rp2 ? rp2 : 0;

      merkmal = this.calcP3.calc(
        masterSizeNum,
        setting,
        v1,
        v2,
        v3,
        rp1,
        rp2,
        rp3
      );
      // console.log(`Merkmal:${attribute.name}::MS:${masterSizeNum}::Setting:${setting}::v1:${v1}::v2:${v2}::v3:${v3}::rp1:${rp1}::rp2:${rp2}::rp3:${rp3}`)
    }


    return {
      merkmal: merkmal,
      sensor1: {
        value: v1,
        reference: rp1
      },
      sensor2: {
        value: v2,
        reference: rp2
      },
      sensor3: {
        value: v3,
        reference: rp3
      }
    };
  }

  //*******************************************************
  // interaction
  //*******************************************************

  deleteAttribute = (attr: any) => {
    this.props.thunkDeleteAttribute(attr)
  }

  handleDelete(e: any) {
    e.stopPropagation();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteMask
            nameOfDeletedItem={this.props.attribute.name}
            delete={() => this.deleteAttribute(this.props.attribute)}
            onClose={() => onClose()}
          />
        );
      },
    });
  }

  handleEditClick() {
    if (this.props.system.attributesMode === "edit-active") {

      // store the attribute in redux as a temporaryObject
      //--------------------------------------------------------
      const tempAttribute = cloneDeep(this.props.attribute)
      this.props.updateTempAttribute(tempAttribute, false)


      // save the attribute and its sensors and open the overlay
      //--------------------------------------------------------                

      setTimeout(() => {
        this.props.overlayLoadComponent(<AttributeEditForm attributeId={this.props.attribute.uuid} usedDelimeter={this.props.system.settings.usedDelimiter} />)
        this.props.overlayToggle();
      }, 300);
    }
  }

  measureAttribute() {

    const updatedAttribute = { ...this.props.attribute }
    updatedAttribute.measurement.timestamp = this.state.timestamp

    if (this.state.result) {
      updatedAttribute.measurement.attributeValue = this.state.result.merkmal
      updatedAttribute.sensors[0].reference.measurementValue = this.state.result.sensor1.value
      updatedAttribute.sensors[1].reference.measurementValue = this.state.result.sensor2.value
      updatedAttribute.sensors[2].reference.measurementValue = this.state.result.sensor3.value
    }

    // we need the chart in always exact size to be saved as png. 
    // for this we will create a node (invisible to user) and use the
    // htmlToImage library to extract a png from it
    // then save the dataUrl to the measurement and remove the node ;)

    const { config, measurement } = updatedAttribute;
    serviceApi.client.post('/get-base64-png', { config, measurement, warnLimitRecog: true }) // warnLimitRecog: here warn limits can be deactivated in general in the frontend, SERVICE has not to be updated
    // axios.post('https://192.168.230.1:5013/get-base64-png', { config, measurement, warnLimitRecog: false }) // DEV
    .then( res => {
      const imgUrl = res.data.data;

    // this.getSVGfromChart().then((imgUrl) => {

      updatedAttribute.measurement.measurementChart = imgUrl;

      this.props.thunkUpdateAttribute(updatedAttribute, true) // update attribut without timestamp/history change
      console.log(':::::::::::::::::::::', updatedAttribute);
      this.setState({ dataFlow: false })
    })
  }

  getSVGfromChart() {
    return new Promise((resolve, reject) => {
      if (this.chart.current) {

        //create the node
        const chart = document.createElement('div')
        chart.setAttribute('style', 'width: 200px; height: 50px; position: fixed; top:0; left:0; z-index: -10000;')
        chart.innerHTML = `<div style="width: 50px; height: 200px; position: absolute; top:50px; left:0; z-index: -10000; transform: rotate(-90deg); transform-origin: top left">${this.chart.current.innerHTML}</div>`
        document.body.appendChild(chart);


        //create svg from node
        htmlToImage.toPng(chart)
          .then(function (dataUrl) {
            // if you need some immediate preview of the images
            // var img = new Image();
            // img.src = dataUrl;
            // document.body.appendChild(img);
            chart.remove()
            resolve(dataUrl)

          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
            reject(error)
          });
      } else {
        reject('no chart exists')
      }
    })
  }

  resetAttribute() {
    const updatedAttribute = {
      ...this.props.attribute,
    };
    updatedAttribute.measurement.timestamp = 0;
    updatedAttribute.measurement.attributeValue = 0;
    this.props.thunkUpdateAttribute(updatedAttribute, true);
    this.setState({ dataFlow: true });
  }

  //*******************************************************
  // Reference
  //*******************************************************

  loadSlider = (e: any) => {
    // lets pass the current slide index
    // to open the slider at the right slide (according to the attribute)
    this.props.setInitialSliderSlideIndex(this.props.index);
    this.props.toggleSlider();
  };

  //*******************************************************
  // icon
  //*******************************************************

  getIconMarkup(attribute: AttributeItem) {
    const settingCSS = attribute.config.setting
      ? " taster--" + attribute.config.setting.toLowerCase()
      : " ";
    const typeCSS = attribute.config.type
      ? " taster--" + attribute.config.type.toLowerCase()
      : " ";

    let iconStyle = `taster taster--icon taster--children-${attribute.config.sensorsCount} ${settingCSS} ${typeCSS}`;
    let iconMarkup = [];
    for (let i = 1; i <= attribute.config.sensorsCount; i++) {
      const css = `taster__item taster__item--${i}`;
      iconMarkup.push(<div key={i} className={css}></div>);
    }

    return (
      <div className={iconStyle}>
        <div className="taster__wrapper">{iconMarkup}</div>
      </div>
    );
  }

  //*******************************************************
  // helpers
  //*******************************************************

  getDate(timestamp: any) {
    const date = moment(timestamp)
    const today = moment()

    if (date.isSame(today, 'day')) {
      return moment(timestamp).format(AppConfig.time.hourFormat(i18n.language))
    } else {
      return moment(timestamp).format(AppConfig.time.fullFormat(i18n.language))
    }
  }

  //*******************************************************
  // render
  //*******************************************************

  render() {

    const { measurement, reference } = this.props.attribute;
    const readonly = !!this.props.attribute.readonly;
    const { attributesMode, settings } = this.props.system;

    // Units
    const isUnitMillimeter = settings.usedUnits === UsedUnitsEnum.MM;
    const isDecPoint = settings.usedDelimiter === UsedDelimiterEnum.DecimalPoint;
    const decimalPlaces = Number(this.props.attribute.config.decimalPlaces);

    //results
    const referenceTimestamp = reference.timestamp;
    const measurementTimestamp = measurement.timestamp;
    const measurementSavedValue = measurement.attributeValue;
    const measurementValueRaw = !this.state.dataFlow ? Number(measurementSavedValue) : Number(this.state.result?.merkmal);
    const measurementValueFormated = new FormatNumber(isUnitMillimeter,isDecPoint).formatFixed(measurementValueRaw, decimalPlaces)

    //is reference mode
    const isReferenceMode = attributesMode === "reference";
    //is edit mode
    const isEditMode = attributesMode.includes("edit");
    //is edit active mode
    const isEditActiveMode = attributesMode === "edit-active";
    //is Measure mode
    const isMeasureMode = attributesMode === "measure";
    // show hide units
    const cssUnits = "hmini mb-0 font-regular";
    // if referencing was run
    const isReferenced = reference.timestamp !== 0;
    // this will evaluate if we are ready to show the measurement data flow (the live chart)
    const isReadyForData = isReferenced && isMeasureMode;

    //card styles
    const activeModeCssHandle = attributesMode === "edit-active" ? " card--edit" : "";
    const { ot, ut, } = this.props.attribute.config
    const result = measurement.attributeValue
    let cardColorCssHandle = " card--danger";
    if (result && result >= ut && result <= ot) cardColorCssHandle = " card--success";
    if (this.state.dataFlow || isReferenceMode || isEditMode) cardColorCssHandle = "";
    if (readonly) cardColorCssHandle = "";

    // icon
    const iconMarkup = this.getIconMarkup(this.props.attribute);

    const context = this.context as QumoFeatureContextType;

    return (
      <>
        <Translation>
          {(t) => (
            <div
              className={this.props.styleString}
              onClick={(e: any) => {
                this.handleEditClick();
              }}
            >
              <div className={"card card--grid" + activeModeCssHandle + cardColorCssHandle}>
                <div className="card__inner">
                  <h2 className="card__title h3 mb-2 text-ellipsis">{this.props.attribute.name}</h2>
                  <div className="card__icon mb-2">{iconMarkup}</div>
                  {isReadyForData && (
                    <div className="card__value">
                      {
                        // FIXME: 0 must be displayed
                        readonly && Number(measurement.attributeValue) !== 0 &&
                        <>
                          <h2 className="h2 mb-0 text-ellipsis">{measurementValueFormated}</h2>
                          <p className={cssUnits}>{isUnitMillimeter ? t("Millimeter") : t("Inch")}</p>
                        </>
                      }
                      {
                        !readonly && this.props.channels && !isNaN(this.props.channels[0]) &&
                          <>
                            <h2 className="h2 mb-0 text-ellipsis"> {measurementValueFormated}{" "}</h2>
                            <p className={cssUnits}>{isUnitMillimeter ? t("Millimeter") : t("Inch")}</p>
                          </>
                      }

                    </div>
                  )}

                  {
                    this.props.channels && !isNaN(this.props.channels[0]) &&
                      <div className='card__chart' ref={this.chart}>
                        <Measurement
                          item={this.props.attribute}
                          // FIXME: 0 must be displayed
                          value={measurement.attributeValue && readonly ? measurement.attributeValue : measurementValueRaw}
                          show={(isReadyForData && !readonly) || (readonly && Number(measurement.attributeValue) !== 0)}
                        />
                      </div>
                  }


                  <AttributeReferenceInfo
                    timestamp={isReferenced ? this.getDate(referenceTimestamp) : ""}
                    show={(!isReadyForData && isReferenceMode) || (!isReferenced && !isEditMode)}
                  />
                </div>

                <Button
                  styles="btn btn--pin btn--pin-trash btn--pinned"
                  clickHandler={(e: any) => { this.handleDelete(e) }}
                  show={isEditActiveMode}
                />
                { isEditActiveMode && 
                  this.props.system.settings.qumoActiveFeatures.find( f => f==='QDAS' ) &&
                  context.features?.find( (f: QumoFeatureItem) => f.id==='QDAS' )?.active &&
                  <QdasPopUp
                      attribute={this.props.attribute}
                      label={'Q-DAS'}
                      description={'Q-DAS'}
                      pinButton
                      t={t}
                  />
                }
              </div>

              <Button
                styles="btn btn--card w-100 mt-2"
                clickHandler={(e: any) => { this.measureAttribute() }}
                show={isReadyForData && this.state.dataFlow && !readonly}
                label={t("Measure")}
                disabled={this.props.isMeasurementDisabled}
              />

              <Button
                styles="btn btn--card btn--grey w-100 mt-2 text-center"
                icon="btn--reload"
                show={!this.state.dataFlow && !isReferenceMode && !isEditMode && !readonly}
                labelLight={moment(measurementTimestamp).format(AppConfig.time.date_format)}
                label={moment(measurementTimestamp).format(AppConfig.time.hourFormat(i18n.language))}
                clickHandler={(e: any) => { this.resetAttribute() }}
                disabled={this.props.isMeasurementDisabled}
              />

              <Button
                styles="btn btn--card w-100 mt-2"
                clickHandler={(e: any) => { this.loadSlider(e); }}
                // show={isReferenceMode || (!isReferenced && !isEditMode)} if the reference button should be shown also in measurement mode
                show={isReferenceMode && !readonly}
                label={t("Referencing")}
              />

              <Button
                styles="btn btn--reset w-100 mt-2"
                show={!isReferenced && !isEditMode && !isReferenceMode && !readonly}
                label={t('PleaseReference')}
              />
            </div>
          )}
        </Translation>
      </>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
  return {
    system: state.system,
    channelItems: state.channels.items
  };
};

const dispatchToProps = {
  overlayToggle,
  overlayLoadComponent,
  thunkDeleteAttribute,
  toggleSlider,
  setInitialSliderSlideIndex,
  updateTempAttribute,
  thunkUpdateAttribute
};

export default connect(mapStateToProps, dispatchToProps)(Attribute);
