import React from 'react';
import { connect, useDispatch } from "react-redux";
import { Translation } from 'react-i18next';
import { overlayClose, overlayPositionToggle } from '../../../store/system/action';
import { sha256 } from 'js-sha256'
import { thunkUpdatePin } from '../../../store/rasp/thunks'
import { onKeyDown } from './PinHelpers';
import { AppState } from '../../../store/reducers';
// @ts-ignore
import platform from 'platform-detect';


type PinEditFormProps = {
    platform: typeof platform
    overlayPositionToggle: typeof overlayPositionToggle
}

const PinEditForm: React.FC<PinEditFormProps> = ( props: PinEditFormProps ) => {

    const { platform, overlayPositionToggle } = props;

    const dispatch = useDispatch();
    
    // local state
    //--------------------------------------------------------

        const [pin, setPin] = React.useState<string[]>(['', '', '', ''])
        const [validation, setValidation] = React.useState<string>('')
        const [prevKey, setPrevKey] = React.useState<string>('')


    // vars
    //--------------------------------------------------------

        const inputs = [0, 1, 2, 3]
        const refs = [
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null)
        ]


    // methods
    //--------------------------------------------------------
        
        const handleSubmit = () => {
            const newPin = pin.join('')
            const newPinSha = sha256.hex(newPin);
            if(!isValid()){
                setValidation('AllOfThePINFieldsMustBeFilled')
            } else {
                dispatch(thunkUpdatePin(1, newPinSha))
                dispatch(overlayClose())
            }
        }

        const handleCancel = () => {
            dispatch(overlayClose())
        }

        const onChange = (e:any, index:number) => {
            setValidation('')
            e.preventDefault()
            const value = Number(e.target.value)
            if(isNaN(value)) return false
            const newPin = [...pin]
            newPin[index] = e.target.value
            setPin(newPin)
            console.log(value, index);
            if(value && index !== 3){
                refs[index + 1].current?.focus() 
            }
        }

        const disablePin = () => {
            const newPinSha = sha256.hex('0000');
            dispatch(thunkUpdatePin(1, newPinSha))
            dispatch(overlayClose())
        }

        const isValid = () => {
            return pin.every(isNotAnEmptyString);
        }

        const isNotAnEmptyString = (item:any) => item !== ''

        const getValue = (index:number) => {
            return pin[index]
        }

        const onFocusBlur = () => {
            const isMobile = platform.android || platform.ios;
            isMobile && overlayPositionToggle()
        }



    //*******************************************************
    // Render
    //*******************************************************

        return(
            <Translation>{ t =>
                <div className="container-fluid app__overlay-form">
                    <form 
                        className="h-100 d-flex flex-column" 
                        onSubmit={ () => handleSubmit() } 
                        autoComplete="off"
                    >
                        <div className="row">
                            <div className="col-12 text-right d-flex">
                                
                                <button 
                                    type="button" 
                                    onClick={() => {disablePin()}} 
                                    className="btn btn--white d-inline-block mr-auto"
                                >
                                    {t('disablePIN')}
                                </button>

                                <button 
                                    className="btn btn--icon btn--icon-close bg-action-dark mr-3" 
                                    onClick={ () => handleCancel() }
                                    type="button"
                                ></button>
                                
                                <button 
                                    className="btn btn--icon btn--icon-check btn--negative" 
                                    type="submit"
                                    onClick={ () => { handleSubmit() } }
                                ></button>
                            </div>
                        </div>

                        <div className="row mt-auto mb-auto pb-md-5 pb-3">
                            <div className="col-12">
                                <h2 className="h1 text-center">PIN</h2>
                                <p className="col-10 col-md-8 col-lg-6 ml-auto mr-auto text-center">{t('PinEditFormLabel')}</p>
                                <div className="pin">
                                    {
                                        inputs.map((input, index) => {
                                            return (
                                                <div className="pin__item" key={index}>
                                                    <input 
                                                        type="password" 
                                                        pattern="[0-9]*"
                                                        inputMode="numeric"
                                                        maxLength={1} 
                                                        className="input-blue" 
                                                        onChange={ (e) => onChange(e, input) } 
                                                        value={ getValue(input) }
                                                        onFocus={ e => { e.currentTarget.select(); onFocusBlur(); } }
                                                        onBlur={ () => onFocusBlur() }
                                                        onKeyDown={ e => onKeyDown(prevKey, setPrevKey, refs, e, input) }
                                                        ref={refs[input]}
                                                        autoFocus = {input === 0}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {   
                                    validation &&
                                    <div className="text-center text-dark-danger-negative pt-3">
                                        { t(validation) }
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            }</Translation>
        );
    }

const mapStateToProps = (state: AppState) => ({
     platform: state.system.platform,
})

const dispatchToProps = {
    overlayPositionToggle,
};
      
export default connect(mapStateToProps, dispatchToProps)(PinEditForm);
