import axios, { AxiosInstance }  from 'axios';
import https from 'https';

class WebApi {
  client: AxiosInstance;
  /**
   * WebApi access class
   */
  constructor() {
    this.client = axios.create({
      httpsAgent: new https.Agent({  
        rejectUnauthorized: false
      })
    })
  }
  setBaseUrl (baseUrl:string) {
    this.client.defaults.baseURL = baseUrl;
  }
}

export const webapi = new WebApi();