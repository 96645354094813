import { DELETE_MEASUREMENT, LOAD_STATISTICS, StatisticsActionTypes } from "./types";
// import update from 'immutability-helper';

import { StatisticsState } from "./types";

const initialState: StatisticsState = {
  measurements: {
    items: [],
    skip:0,
    take: 10,
    firstDate : 0,
    lastDate: 0,
    lastPage: 0,
    count:0,
    fromDate:'',
    toDate:'',
    workbenches: [],
    parts: []
  }

};

export function statisticsReducer(
  state = initialState,
  action: StatisticsActionTypes
): StatisticsState {
  switch (action.type) {
    case LOAD_STATISTICS: {
      return Object.assign({}, state , {measurements : action.payload})
    }
    case DELETE_MEASUREMENT: {
      
        const {measurements} = state
        const filteredMeasurementItems = state.measurements.items.filter(
          item => item.uuid !== action.payload
        );


      return Object.assign({}, state , {measurements : {
        ...measurements, 
        count: measurements.count - 1,
        items: filteredMeasurementItems
      }})
      
      
    }
    default:
      return state;
  }
}
