import { xyPoint } from "../types/x-y-point-interface";
import { triple } from "../types/triple";
import { SensorSettingEnum } from "../types/sensor-setting-enum";
const math = require("mathjs");

export interface Sensor3PointConfigInterface {
  masterSize: number;
  angles: {
    p1: number;
    p2: number;
    p3: number;
  };
  refrences: {
    rp1: number;
    rp2: number;
    rp3: number;
  };
  setting: SensorSettingEnum;
}

export class Sensor3Point {
  // private readonly OFFSET = 5;
  private _angularPitch: number[];
  private _masterSize: number;
  private _zeroValues: number[];
  private _sensorValues: number[];
  private _sensorSetting: SensorSettingEnum;

  constructor(config: Sensor3PointConfigInterface) {
    this._sensorSetting = config.setting;
    this._masterSize = config.masterSize;
    const { p2, p3 } = config.angles;
    this._angularPitch =  [0, p2, p3];
    const { rp1, rp2, rp3 } = config.refrences;
    if (this._sensorSetting === SensorSettingEnum.OUTER) {
      this._zeroValues = [rp1, rp2, rp3];
    } else {
      this._zeroValues = [-rp1, -rp2, -rp3];
    }
    this._sensorValues = [0, 0, 0];
  }

  public get resultingAngularPitch(): triple<number> {
    let a, b, c: number;
    [a, b, c] = this._angularPitch;
    return new triple(a, a + b, a + b + c);
  }

  public calcReferenced(s1: number, s2: number, s3: number): number {
    if (this._sensorSetting === SensorSettingEnum.OUTER) {
      this.SensorValues = [ s1, s2, s3 ];
    } else {
      this.SensorValues = [ -s1, -s2, -s3 ];
    }
    const r = this.calculateRadius();
    // const offset = this._masterSize / 2;
    // const erg = r - offset;
    // console.log(`### erg:${erg}`)
    let retValue = 0;
    retValue = r * 2;
    // console.log(`### this._sensorSetting:${this._sensorSetting}`);
    // console.log(`### r:${r}`);
    // console.log(`### retValue:${retValue}`);
    return retValue;
  }

  public calc(
    masterSize: number,
    setting: SensorSettingEnum,
    Ta: number,
    Tb: number,
    Tc: number,
    Ra: number,
    Rb: number,
    Rc: number
  ): number {
    // console.log(
    //   `### debug masterSize:${masterSize} setting:${setting} values:${Ta}/${Tb}/${Tc}references:${Ra}/${Rb}/${Rc}`
    // );
    this._masterSize = masterSize;
    this._sensorSetting = setting;
    this._zeroValues = [Ra, Rb, Rc];
    return this.calcReferenced(Ta, Tb, Tc);
  }

  public get MasterSize(): number {
    return this._masterSize;
  }

  public setReferenceValues(
    an: number = 0,
    bn: number = 0,
    cn: number = 0
  ): void {
    this._zeroValues = [an, bn, cn];
  }

  private set SensorValues(value: number[]) {
    this._sensorValues = value;
  }

  public get SensorCoordinates(): triple<xyPoint> {
    let t1: xyPoint = { x: 0, y: 0 };
    let t2: xyPoint = { x: 0, y: 0 };
    let t3: xyPoint = { x: 0, y: 0 };
    t1 = this.calcSensorCoordinate(
      this._sensorValues[0],
      this._zeroValues[0],
      this._angularPitch[0]
    );
    t2 = this.calcSensorCoordinate(
      this._sensorValues[1],
      this._zeroValues[1],
      this._angularPitch[0] + this._angularPitch[1]
    );
    t3 = this.calcSensorCoordinate(
      this._sensorValues[2],
      this._zeroValues[2],
      360 - this._angularPitch[2] // 360 - beta
    );
    return new triple<xyPoint>(t1, t2, t3);
  }

  private calcSensorCoordinate(
    sensorVal: number,
    refVal: number,
    angularPitch: number
  ): xyPoint {
    const radiant = math
      .chain(angularPitch)
      .multiply(Math.PI / 180)
      .done();
    const masterRadius = this._masterSize / 2;
    const val = math.chain(sensorVal).add(masterRadius).subtract(refVal).done();
    const x = math.chain(radiant).cos().multiply(val).done();
    const y = math.chain(radiant).sin().multiply(val).done();
    return { x: x, y: y };
  }

  private calculateRadius(): number {
    const sc = this.SensorCoordinates;
    const T1 = new triple(1, -sc.a.x, -sc.a.y);
    const T2 = new triple(1, -sc.b.x, -sc.b.y);
    const T3 = new triple(1, -sc.c.x, -sc.c.y);

    const t1result = -1 * (Math.pow(T1.b, 2) + Math.pow(T1.c, 2));
    const t2result = -1 * (Math.pow(T2.b, 2) + Math.pow(T2.c, 2));
    const t3result = -1 * (Math.pow(T3.b, 2) + Math.pow(T3.c, 2));

    // Eleminate A
    const T1A = new triple(1, -sc.a.x, -sc.a.y); // same as t1
    const T2A = new triple(0, T2.b - T1.b, T2.c - T1.c);
    const T3A = new triple(0, T3.b - T1.b, T3.c - T1.c);

    const t1Aresult = -1 * (Math.pow(T1A.b, 2) + Math.pow(T1A.c, 2));
    const t2Aresult = t2result - t1result;
    const t3Aresult = t3result - t1result;

    // Eleminate B
    const a1 = T1A.a * T2A.b - T2A.a * T1A.b;
    const b1 = T1A.b * T2A.b - T2A.b * T1A.b;
    const c1 = T1A.c * T2A.b - T2A.c * T1A.b;

    const T1B = new triple(a1, b1, c1);

    const a2 = T2A.a * T1A.b;
    const b2 = T2A.b * T1A.b;
    const c2 = T2A.c * T1A.b;
    const T2B = new triple(a2, b2, c2);

    const a3 = T3A.a * T2B.b - T2B.a * T3A.b;
    const b3 = T3A.b * T2B.b - T2B.b * T3A.b;
    const c3 = T3A.c * T2B.b - T2B.c * T3A.b;

    const T3B = new triple(a3, b3, c3);

    const t1Bresult = t1Aresult * T2A.b - t2Aresult * T1A.b;
    const t2Bresult = t2Aresult * T1A.b;
    const t3Bresult = t3Aresult * T2B.b - t2Bresult * T3A.b;

    // calculate results
    const calcC = t3Bresult / T3B.c;
    const calcB = (t2Bresult - T2B.c * calcC) / T2B.b;
    const calcA = (t1Bresult - T1B.c * calcC - calcB * T1B.b) / T1B.a;

    const Xm = calcB / 2;
    const Ym = calcC / 2;

    // console.log(`### Xm:${Xm}:::Ym${Ym}`)

    const term = Math.pow(Xm, 2) + Math.pow(Ym, 2) - calcA;
    const r = Math.sqrt(term);

    // console.log(`### R:${r} A:${calcA}`)

    return r;
  }
}
