import { 
  SystemState,
  APP_LOADED, 
  UPDATE_SESSION, 
  OVERLAY_TOGGLE, 
  OVERLAY_CLOSE,
  OVERLAY_LOAD_COMPONENT, 
  OVERLAY_SUB_TOGGLE, 
  OVERLAY_SUB_LOAD_COMPONENT, 
  OVERLAY_POSITION_TOGGLE, 
  CHANGE_ATTRIBUTES_MODE,
  SET_ATTRIBUTE_EDIT_FORM_MODE, 
  SET_INIT_SLIDE_INDEX,
  attributeEditFormMode,
  TOGGLE_SLIDER,
  CLOSE_SLIDER,
  AppSettings,
  SAVE_SETTINGS,
  MEASUREMENT_COUNTER_INCREASE,
  MEASUREMENT_COUNTER_RESET,
  VALIDATION_LOAD,
  VALIDATION_RESET,
  SET_ATTRIBUTE_LOADER,
  SET_MACHINES_LOADER,
  SET_PARTS_LOADER,
  SET_STATS_LOADER,
  UPDATE_TEMP_ATTRIBUTE
} from "./types";
import { attributeModeTypes, AttributeItem } from "../attributes/types";

export function SetAppLoaded(newState: boolean) {
  return {
    type: APP_LOADED,
    payload: newState
  };
}

export function updateSession(newSession: SystemState) {
  return {
    type: UPDATE_SESSION,
    payload: newSession
  };
}

export function SetAttributeLoader(state:boolean) {
  return {
    type: SET_ATTRIBUTE_LOADER,
    payload: state
  };
}

export function SetMachinesLoader(state:boolean) {
  return {
    type: SET_MACHINES_LOADER,
    payload: state
  };
}

export function SetPartsLoader(state:boolean) {
  return {
    type: SET_PARTS_LOADER,
    payload: state
  };
}

export function SetStatsLoader(state:boolean) {
  return {
    type: SET_STATS_LOADER,
    payload: state
  };
}

export function saveSettings(settings:AppSettings) {
  return {
    type: SAVE_SETTINGS,
    payload: settings
  }  
}

export function measurementCounterReset(){
  return {
    type: MEASUREMENT_COUNTER_RESET,
  }
}

export function measurementCounterIncrease(){
  return {
    type: MEASUREMENT_COUNTER_INCREASE,
  }
}

//*******************************************************
// validation
//*******************************************************

	export function validationLoad(validation:string[]){
		return {
			type: VALIDATION_LOAD,
			payload: validation
		}
	}

	export function validationReset(){
		return {
			type: VALIDATION_RESET,
		}
  }

//*******************************************************
// Attributes 
//*******************************************************

  export function changeAttributeMode(mode:attributeModeTypes) {
    return {
      type: CHANGE_ATTRIBUTES_MODE,
      payload: mode
    };
  }

  export function updateTempAttribute(attribute: AttributeItem | null, edited: boolean | null) {
    return {
      type: UPDATE_TEMP_ATTRIBUTE,
      payload: { attribute, edited }
    };
  }

  export function setAttributeEditFormMode(mode:attributeEditFormMode) {
    return {
      type: SET_ATTRIBUTE_EDIT_FORM_MODE,
      payload: mode
    };
  }

	export function toggleSlider(){
    return {
      type: TOGGLE_SLIDER,
    }
	}	

	export function closeSlider(){
    return {
      type: CLOSE_SLIDER,
    }
	}	
	  
	export function setInitialSliderSlideIndex(index:number){
    	return {
			type: SET_INIT_SLIDE_INDEX,
			payload: index
    	}
    }	


//*******************************************************
// Overlays
//*******************************************************

  export function overlayToggle() {
    return {
      type: OVERLAY_TOGGLE
    };
  }

  export function overlayClose() {
    return {
      type: OVERLAY_CLOSE
    };
  }

  export function overlayLoadComponent(newComponent: any) {
    return {
      type: OVERLAY_LOAD_COMPONENT,
      payload: newComponent,
    };
  }

  export function overlaySubToggle() {
    return {
      type: OVERLAY_SUB_TOGGLE
    };
  }

  export function overlaySubLoadComponent(newComponent: any) {
    return {
      type: OVERLAY_SUB_LOAD_COMPONENT,
      payload: newComponent
    };
  }

  export function overlayPositionToggle() {
    return {
      type: OVERLAY_POSITION_TOGGLE
    };
  }
