import { LOAD_ATTRIBUTES, UPDATE_ATTRIBUTE, AttributesState, AttributesActionTypes, DELETE_ATTRIBUTE, ADD_ATTRIBUTE, UPDATE_LIVE_DATA, AttributeItem,  } from "./types";
import update from 'immutability-helper';

const initialState: AttributesState = {
  items : [],
};

const calcReducer = (accumulator: any, item: any) => {
  return accumulator + item
}

export function attributesReducer(
  state = initialState,
  action: AttributesActionTypes
): AttributesState {
  switch (action.type) {
    
    case LOAD_ATTRIBUTES: {
      return Object.assign({}, state , {
			  items : action.payload
      })
    }

    case DELETE_ATTRIBUTE: {
      const filteredParts = state.items.filter(
        attr => attr.uuid !== action.payload
      );
      return update(state, {items: {$set: filteredParts}});
    }

    case ADD_ATTRIBUTE: {
      const newAttr:AttributeItem = { ...action.payload }
      const newItems = [
        ...state.items,
        newAttr
      ]
      return { items: newItems }
    }

    case UPDATE_ATTRIBUTE: {
      // const attrIndex = state.items.findIndex(
      //   attribute => attribute.uuid === action.payload.uuid
      // )
      // return update(state, { items: {[attrIndex]: {$set: action.payload}}})
      
      //*******************************************************
      // TODO!!!
      // recheck the object replacement method this below works
      //*******************************************************

      const newItems = state.items.map(item => {
        if(item.uuid === action.payload.uuid){
          return {
            ...item,
            ...action.payload
          }
        } else {
          return item
        }
      })
      return { items: newItems }
    }

    case UPDATE_LIVE_DATA: {
      const newItems = state.items.map(item => {
        return {
          ...item,
          live : {
            timestamp: 0,
            attributeValue: action.payload.values.reduce(calcReducer,0)
          }
        }
      });
      return { items: newItems }
    }

    default:
      return state;
  }
}
