export default class FormatNumber {
    public static readonly MM_PER_INCH = 25.4;
    protected _isUnitMM: boolean;
    protected _isDecimalPoint: boolean;
    constructor(isUnitMM:boolean, isDecimalPoint: boolean,) {
        this._isUnitMM = isUnitMM
        this._isDecimalPoint = isDecimalPoint
    }
    formatFixed(valueMM: number, decPlaces: number):string {
        // fallback if valueMM is string
        let val = Number(valueMM);
        // if INCH, convert value
        if (!this._isUnitMM) {
            val = val / FormatNumber.MM_PER_INCH;
        };
        let dP = Number(decPlaces)
        if (!this._isUnitMM) dP = dP + 2;
        let returnValue = '';
        const returnValueNumb = ( Math.trunc(val * Math.pow(10,dP)) / Math.pow(10,dP) );
        if ( !isNaN(returnValueNumb)) {
            returnValue = this._isDecimalPoint
                ? returnValueNumb.toFixed(dP)
                : returnValueNumb.toFixed(dP).replace(".", ",");
        }

        return returnValue;
    }


}