import { AppConfig } from "../../../constants/app-config"
import { FEATURE, MACHINE, NEST, PART } from "../../../constants/lang/translation-keys"
import { UuidAndName } from "../../../store/statistics/types"

export const statisticsTableHeader = [
    {
        name: 'time',
        label: 'Time',
        sort: true,
        width: 5,
    },
    {
        name: 'part',
        label: PART,
        sort: true,
        width: 20,
    },
    {
        name: 'machine',
        label: MACHINE,
        sort: true,
        width: 15,
    },
    {
        name: 'nest',
        label: NEST,
        sort: true,
        width: 15,
    },
    {
        name: 'Feature',
        label: FEATURE,
        sort: true,
        width: 5,
    },
    {
        name: 'lowerTolerance',
        label: 'Lower Tolerance',
        sort: false,
        width: 5,
    },
    {
        name: 'upperTolerance',
        label: 'Upper Tolerance',
        sort: false,
        width: 5,
    },
    {
        name: 'value',
        label: `${FEATURE} Value`,
        sort: false,
        width: 10,
    },
]

// transform the part name label 
// (we are getting name/bauteilnr/designnr in one call)
//--------------------------------------------------------

    export const getFormatNameLabel = (name:string) => {
        if (name) {
            let realName:string = ''
            const nameArray = name.replace(/\s/g,'').split('/') 
            nameArray.forEach((item:string) => {
                if(item !== ''){
                    realName = realName ? `${realName} ${AppConfig.separators.nameSeparator} ${item}` : item;
                }
            })                    
            return realName;
        } else {
            return '';
        }
    }

    export const formatThePartsArray = (parts:UuidAndName[]) => {
        let newParts:UuidAndName[] = []
        parts.forEach((part:UuidAndName) => {
            newParts = [
                ...newParts,
                {
                    ...part,
                    name: getFormatNameLabel(part.name)
                }
            ];
        })
        return newParts
    }