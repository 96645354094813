import { AttributeHistoryExport, PartHistoryExport } from './../../../store/statistics/types';
import { ChannelHistory, MeasurementExportItem, MeasurementFlatItem, NestHistoryExport, WorkbenchHistoryExport } from '../../../store/statistics/types';
import {Dictionary} from 'lodash'
import FNH from '../../../helper/format-number';
import { Device } from "../../../store/rasp/types";
import { PartItem } from '../../../store/parts/types';
import { MachineItem } from '../../../store/machines/types';
import NamingHelper from '../../settings/naming/naming-helper';
import { namingObjectType } from '../../settings/naming/naming-types';
import { ChannelItem } from '../../../store/channels/types';


export type ExportBaseSettings = {
    isMM: boolean;
    isDecimalPoint: boolean;
}

export type HistoriesExportType = {
    partHistoryItems: PartHistoryExport []
    attributeHistoryItems: AttributeHistoryExport[]
    wbHistoryItems: WorkbenchHistoryExport[]
    nestHistoryItems: NestHistoryExport[]
}

export abstract class ExportBaseAbstract {
    protected _items: MeasurementFlatItem[];
    protected _device: Device;
    protected _channels: ChannelItem[];
    protected _channelHistories: ChannelHistory[];
    protected _partHistoryItems: PartHistoryExport[];
    protected _attributeHistoryItems: AttributeHistoryExport[];
    protected _wbHistoryItems: WorkbenchHistoryExport[];
    protected _nestHistoryItems: NestHistoryExport[];
    protected _namingObject: namingObjectType;

    protected _blob: Blob = new Blob()

    private _dictionary: Dictionary<string> = {};
    private _settings: ExportBaseSettings;

    private _minDate : number = 0;
    private _maxDate : number = 0;


    constructor(items: MeasurementFlatItem[], device: Device, channels: ChannelItem[], namingObject: namingObjectType, channelHistories?: ChannelHistory[], historyExport?: HistoriesExportType) {
        this._items = items;
        this._device = device;
        this._channels = channels;
        this._channelHistories = channelHistories ? channelHistories : [];
        this._partHistoryItems = historyExport ? historyExport.partHistoryItems : [];
        this._attributeHistoryItems = historyExport ? historyExport.attributeHistoryItems : [];
        this._wbHistoryItems = historyExport ? historyExport.wbHistoryItems : [];
        this._nestHistoryItems = historyExport ? historyExport.nestHistoryItems : [];
        this._namingObject= namingObject;
        this._settings = {isMM: true, isDecimalPoint: true};
        this.setMinMaxDate();
    }

    get currentDictionary(): Dictionary<string> {
        return this._dictionary;
    }

    set updateDictionary(dict: Dictionary<string>) {
        for (const key of Object.keys(dict)) {
            const value = NamingHelper.getNaming(dict[key], this._namingObject);
            dict[key] = value;
        }
        this._dictionary = dict;
    }

    get currentSettings():ExportBaseSettings {
        return this._settings
    }

    get maxDate(): number  {
        return this._maxDate;
    }

    get minDate(): number  {
        return this._minDate;
    }

    get exportBlob(): Blob {
        return this._blob;
    }

    private setMinMaxDate() {
        let maxDate = new Date().getTime();
        let minDate = maxDate;
        
        if (this._items.length > 0) {
            for (let index = 0; index < this._items.length; index++) {
                const m = this._items[index];
                if (m.measurementTimestamp > maxDate) {
                    maxDate = m.measurementTimestamp;
                  }
                  if (m.measurementTimestamp < minDate) {
                    minDate = m.measurementTimestamp;
                  }
            }
        }
        this._minDate = minDate;
        this._maxDate = maxDate;
    }

    prepare(settings: ExportBaseSettings) {
        this._settings = settings;
    }

    formatNumber(valueMM: number, decPlaces: number){
        return new FNH(this._settings.isMM, this._settings.isDecimalPoint).formatFixed(valueMM,decPlaces);
    }

    abstract createExport( items?: MeasurementExportItem[], parts?: PartItem[], machines?: MachineItem[], urlToQumo?: string, historyExport?: HistoriesExportType ): void;
    abstract saveExport(filename: string): void

}