import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadParts, addPart, updatePart, deletePart } from "./action";
import { SetAppLoaded } from '../system/action';
import { PartItem } from './types';
import { AppState } from "../../store/reducers";
import { webapi } from '../../api/web-api';
import { push } from 'connected-react-router';
import { SetPartsLoader } from "../system/action";
import { AppConfig } from "../../constants/app-config";

function sleep(t:number):Promise<[]> {
  return new Promise<[]>(resolve=>{
    setTimeout(() => {
      resolve([]);
    }, t);
  });
}

export const thunkGetParts  = (parts:PartItem[]): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  await dispatch(SetPartsLoader(true));
  const tasks : Promise<any>[] = [];
  tasks.push(getStorageParts());
  tasks.push(sleep(AppConfig.connection.delay));
  const asyncResp = await (await Promise.all(tasks)).reduce((prevVal,currVal)=>prevVal.concat(currVal));
  await dispatch(loadParts(asyncResp));
  await dispatch(SetAppLoaded(true))
  dispatch(SetPartsLoader(false));
}

export const thunkCreatePart = (part: PartItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkCreatePart',part)
  const asyncResp: PartItem = await postStoragePart(part);
  // console.log('::: thunkCreatePart-asynResp',asyncResp);
  // console.log('');
  dispatch(addPart(asyncResp));
}

export const thunkAsyncCreatePart = (part: PartItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  return new Promise<PartItem>(async (resolve, reject) => {
    try {
      // console.log('::: thunkAsyncCreatePart',part)
      const asyncResp: PartItem = await postStoragePart(part);
      // console.log('::: thunkCreatePart-asynResp',asyncResp);
      // console.log('');
      dispatch(
        addPart(
          asyncResp
        )
      );
      resolve(asyncResp);
        
    } catch (error) {
      reject(error)
    }
  });
  
}

export const thunkUpdatePart = (part: PartItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkUpdatePart', part)
  const asyncResp: PartItem = await putStoragePart(part);
  const attributesCount = asyncResp.attributes.length;
  // console.log('::: thunkUpdatePart-asyncResp', asyncResp);
  // console.log('');
  const newPart = {...asyncResp, attributesCount};
  // console.log('::: thunkUpdatePart-newPart', newPart);
  dispatch(
    updatePart(
      newPart
    )
  );
}

export const thunkDeletePart = (id: string): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkDeletePart', id)
  await delStoragePart(id);
  // console.log('::: thunkDeletePart-DONE');
  // console.log('');
  dispatch(
    deletePart(
      id
    )
  );
}

export const thunkNavigatePart = (id: string): ThunkAction<void,AppState,unknown,Action<any>> => (dispatch) => {
  dispatch(push('/part/'+id));
}

export const thunkNavigateAttribute = (parentId: string, id: string): ThunkAction<void,AppState,unknown,Action<any>> => (dispatch) => {
  dispatch(push(`/channels/${parentId}/${id}`));
}

function getStorageParts(): Promise<PartItem[]> {
  // console.log('getStorageParts invoked');
  return new Promise<PartItem[]>((resolve, reject) => {
    const uri =  '/parts';
    webapi.client.get<PartItem[]>(uri).then((resp) => {      
      // console.log('::: getStorageParts', resp.data.length)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })

  });
}

function postStoragePart(part: PartItem): Promise<PartItem> {
  // console.log('getStorageParts invoked');
  return new Promise<PartItem>((resolve, reject) => {
    const uri =  '/part';
    webapi.client.post<PartItem>(uri, part).then((resp) => {      
      // console.log('::: postStoragePart', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })
  });
}

function putStoragePart(part: PartItem): Promise<PartItem> {
  // console.log('getStorageParts invoked');
  return new Promise<PartItem>((resolve, reject) => {
    const uri =  '/part/' + part.id;
    webapi.client.put<PartItem>(uri, part).then((resp) => {      
      // console.log('::: putStoragePart result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstoragePart Err: ', err);
      reject(err);
    })
  });
}

function delStoragePart(id: string): Promise<void> {
  // console.log('getStorageParts invoked');
  return new Promise<void>((resolve, reject) => {
    const uri =  '/part/' + id;
    webapi.client.delete(uri).then((resp) => {      
      // console.log('::: deleteStoragePart', resp)
      resolve();
    }).catch((err) => {
      // console.log('::: delStoragePart Err: ', err);
      reject(err);
    })
  });
}
