import { QdasMeasurementExportEntry } from './../../components/qdas/qdas-types';
import { attributeModeTypes, AttributeItem } from "../attributes/types";
import { PartItem } from "../parts/types";
import { MachineItem, NestItem } from "../machines/types";

// Describing the shape of the system's slice of state
export interface SystemState {
  platform: any,
  loading: {
    attributes:boolean,
    parts: boolean,
    machines: boolean,
    stats: boolean
  },
  appName: string,
  hostEndpoint: string,
  serviceEndpoint: string,
  webApiEndpoint: string,
  realApiEndpoint: string,
  loggedIn: boolean,
  appLoaded: boolean,
  userName: string,
  userRole: string,
  showMenu: boolean,
  overlay: {
	  main: {
      show: boolean,
      component: any,
      positionFixed: boolean
    },
    sub: {
      show: boolean,
      component: any,
    },
    validation: {
      show: boolean,
      notification: string[]
    },
    dropdown: {
      show: boolean
      name: string | null
    }
  }
	attributesMode: attributeModeTypes,
  attributeEditFormMode: attributeEditFormMode
  slider: {
    show: boolean,
    initialSlideIndex: number,
    items: AttributeItem[]
  },
  settings: AppSettings,
  currentMachine: currentMachine,
  measurementCounter: number,
  qdasMeasureExportEntries: QdasMeasurementExportEntry [],
  isQumoOccupied: boolean

	// depricated
	session?: string,
  profiles?: string[],
  lastMeasurement: {
    items: AttributeItem[],
    part: PartItem | null,
    machine: MachineItem | null
    timestamp: number | null
    nest: NestItem | null
  }
  tempAttribute: AttributeItem | null
  tempAttributeEdited: boolean
}

export interface currentMachine {
  machine: {
    name: string | null,
    imageUrl: string | null,
    uuid: string | null
  }
  nest: {
    name: string | null
    uuid: string | null
  },
  isDefault: boolean
}

export interface AppSettings {
  appDarkMode: boolean
  usedUnits: UsedUnitsEnum
  usedDelimiter: UsedDelimiterEnum
  advancedMode: boolean
  advancedModePin: string
  allowUserStatsDeletion: boolean
  language: 'en' | 'de'
  timeAutoSync: boolean
  qumoActiveFeatures: string[]
}

export enum UsedUnitsEnum {
  MM = 'mm',
  INCH = 'inch'
}

export enum UsedDelimiterEnum {
  DecimalPoint = 'dp',
  DecimalKomma = 'dc'
}

export enum attributeEditFormMode {
    DEFAULT = 'DEFAULT',
    CONFIG = 'TASTER_CONFIG',
}

 // Available actions
  export const APP_LOADED = "APP_LOADED";
  export const UPDATE_SESSION = "UPDATE_SESSION";
  export const OVERLAY_TOGGLE = "OVERLAY_TOGGLE";
  export const OVERLAY_CLOSE = "OVERLAY_CLOSE";
  export const OVERLAY_LOAD_COMPONENT = "OVERLAY_LOAD_COMPONENT";
  export const OVERLAY_LOAD_COMPONENT_MANUAL = "OVERLAY_LOAD_COMPONENT_MANUAL";
  export const OVERLAY_SUB_TOGGLE = "OVERLAY_SUB_TOGGLE";
  export const OVERLAY_SUB_LOAD_COMPONENT = "OVERLAY_SUB_LOAD_COMPONENT";
  export const OVERLAY_POSITION_TOGGLE = "OVERLAY_POSITION_TOGGLE";
  export const CHANGE_ATTRIBUTES_MODE = "CHANGE_ATTRIBUTES_MODE";
  export const SET_ATTRIBUTE_EDIT_FORM_MODE = "SET_ATTRIBUTE_EDIT_FORM_MODE";
  export const TOGGLE_SLIDER = "TOGGLE_SLIDER";
  export const CLOSE_SLIDER = "CLOSE_SLIDER";
  export const SET_INIT_SLIDE_INDEX = "SET_INIT_SLIDE_INDEX"
  export const SAVE_SETTINGS = "SAVE_SETTINGS"
  export const MEASUREMENT_COUNTER_RESET = "MEASUREMENT_COUNTER_RESET"
  export const MEASUREMENT_COUNTER_INCREASE = "MEASUREMENT_COUNTER_INCREASE"
  export const VALIDATION_LOAD = "VALIDATION_LOAD"
  export const VALIDATION_RESET = "VALIDATION_RESET"
  export const SET_ATTRIBUTE_LOADER = "SET_ATTRIBUTE_LOADER"
  export const SET_MACHINES_LOADER = "SET_MACHINES_LOADER"
  export const SET_PARTS_LOADER = "SET_PARTS_LOADER"
  export const SET_STATS_LOADER = "SET_STATS_LOADER"
  export const UPDATE_TEMP_ATTRIBUTE = "UPDATE_TEMP_ATTRIBUTE"

  interface MeasurementCounterReset {
	  type: typeof MEASUREMENT_COUNTER_RESET
  }

  interface MeasurementCounterIncrease {
	  type: typeof MEASUREMENT_COUNTER_INCREASE
  }

  interface setAttributeEditFormMode {
	  type: typeof SET_ATTRIBUTE_EDIT_FORM_MODE
	  payload: attributeEditFormMode
  }
  
  interface AppLoadedAction {
    type: typeof APP_LOADED
    payload: boolean
  }
  
  interface UpdateSessionAction {
    type: typeof UPDATE_SESSION
    payload: SystemState
  }

  interface SetAttributeLoader {
    type: typeof SET_ATTRIBUTE_LOADER
    payload: boolean
  }

  interface SetMachinesLoader {
    type: typeof SET_MACHINES_LOADER
    payload: boolean
  }

  interface SetPartsLoader {
    type: typeof SET_PARTS_LOADER
    payload: boolean
  }

  interface SetStatsLoader {
    type: typeof SET_STATS_LOADER
    payload: boolean
  }

  interface overlayToggleAction {
    type: typeof OVERLAY_TOGGLE
    payload: boolean
  }

  interface overlayCloseAction {
    type: typeof OVERLAY_CLOSE
  }

  interface overlayLoadComponentAction {
    type: typeof OVERLAY_LOAD_COMPONENT
    payload: boolean
  }

  interface overlaySubToggleAction {
    type: typeof OVERLAY_SUB_TOGGLE
    payload: boolean
  }

  interface overlaySubLoadComponentAction {
    type: typeof OVERLAY_SUB_LOAD_COMPONENT
    payload: boolean
  }

  interface overlayPositionToggleComponentAction {
    type: typeof OVERLAY_POSITION_TOGGLE
  }
  
  interface changeAttributesModeAction {
    type: typeof CHANGE_ATTRIBUTES_MODE
    payload: attributeModeTypes
  }

  interface toggleSliderAction {
    type: typeof TOGGLE_SLIDER
    payload: boolean
  }

  interface closeSliderAction {
    type: typeof CLOSE_SLIDER
  }

  interface setInitSlideIndex {
    type: typeof SET_INIT_SLIDE_INDEX
    payload: number
  }

  interface appSaveSettingsAction {
    type: typeof SAVE_SETTINGS
    payload: AppSettings
  }

  interface validationReset {
    type: typeof VALIDATION_RESET
  }

  interface validationLoad {
    type: typeof VALIDATION_LOAD
    payload: string[]
  }

  interface updateTempAttribute {
    type: typeof UPDATE_TEMP_ATTRIBUTE
    payload: { attribute: AttributeItem | null, edited: boolean }
  }
  
  export type SystemActionTypes = 
    AppLoadedAction | 
    UpdateSessionAction | 
    overlayToggleAction | 
    overlayCloseAction | 
    overlayLoadComponentAction |
    overlaySubToggleAction |
    overlaySubLoadComponentAction | 
    overlayPositionToggleComponentAction | 
	  changeAttributesModeAction |
    setAttributeEditFormMode |
    toggleSliderAction |
    setInitSlideIndex |
    appSaveSettingsAction |
    MeasurementCounterReset |
    MeasurementCounterIncrease |
    validationReset |
    validationLoad |
    closeSliderAction |
    SetAttributeLoader |
    SetMachinesLoader |
    SetPartsLoader | 
    SetStatsLoader |
    updateTempAttribute