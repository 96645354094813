import { ChannelItem } from "../channels/types";

export interface Device {
  deviceId: string;
  name: string;
  channelCount: number;
  isConnected: boolean;
  model?: string;
  version?: string;
  timestamp?: number;
  pin?:string;
  puk?:string;
}

export interface ChannelValue {
  current: number;
  timestamp: number;
}

// Describing the shape of the system's slice of state
export interface RaspState {
  device: Device;
  channels: ChannelItem[];
  results: ChannelValue[];
}

export interface Message {
  user: string;
  message: string;
  timestamp: number;
}

export interface ChatState {
  messages: Message[];
}

// Describing the different ACTION NAMES available
export const LOAD_DEVICE = "LOAD_DEVICE";
export const UPDATE_CONNECTION = "UPDATE_CONNECTION";
export const UPDATE_DEVICE_PIN = "UPDATE_DEVICE_PIN";
export const LOAD_CHANNELS = "LOAD_CHANNELS";
export const LOAD_MEASUREMENTS = "LOAD_MEASUREMENTS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
  
interface LoadDeviceAction {
   type: typeof LOAD_DEVICE;
   payload: RaspState;
 }
 
 interface LoadChannelsAction {
  type: typeof LOAD_CHANNELS;
  payload: ChannelItem[];
}

interface LoadMeasurementsAction {
  type: typeof LOAD_MEASUREMENTS;
  payload: ChannelValue[];
}

 interface SendMessageAction {
  type: typeof SEND_MESSAGE;
  payload: Message;
}

interface DeleteMessageAction {
  type: typeof DELETE_MESSAGE;
  meta: {
    timestamp: number;
  };
}

interface UpdateDeviceConnection {
  type: typeof UPDATE_CONNECTION;
  payload: boolean;
}

interface UpdateDevicePin {
  type: typeof UPDATE_DEVICE_PIN;
  payload: string;
}

 export type RaspActionTypes = LoadDeviceAction | UpdateDeviceConnection | UpdateDevicePin | LoadChannelsAction | LoadMeasurementsAction | SendMessageAction | DeleteMessageAction;