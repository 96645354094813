import { ExportBaseAbstract} from "../export-base"
import {Dictionary} from 'lodash';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { MeasurementChartItem, MeasurementFlatItem } from "../../../../store/statistics/types";
import { customLogo } from "../data/custom-logo";
import { Device } from "../../../../store/rasp/types";
import EH from '../export-helper';
import { namingObjectType } from "../../../settings/naming/naming-types";
import { ChannelItem } from "../../../../store/channels/types";

type TableRowFormat = {
    Date: string;
    Part: string;
    Machine: string;
    Nest: string;
    Feature: string;
    UT: string;
    LT: string;
    Value: string;
    dummy: string;
    Diagram: string;
  }


export class  ExportPdf extends ExportBaseAbstract {
    
    private readonly dictionary: Dictionary<string> = {
      'Date': 'Date',
      'Part': 'Part',
      'Machine': 'Machine',
      'Nest': 'Nest',
      'Feature': 'Feature',
      'Lower Tolerance': 'Lower Tolerance',
      'Upper Tolerance': 'Upper Tolerance',
      'Value': 'Value',
      'Diagram': 'Diagram',
      'mm': 'mm',
      'inch': 'inch',
      "ExportedFromQumoOn": "ExportedFromQumoOn",
      "Page": "Page",
      "of": "of",
    }
    
    private _eval = '';

    private _doc: jsPDF = new jsPDF();

    private measurementCharts: MeasurementChartItem[] = [];

    constructor(items: MeasurementFlatItem[], device: Device, channels: ChannelItem[], namingObject: namingObjectType, measurementCharts: MeasurementChartItem[]) {
            super(items, device, channels, namingObject);
            this.updateDictionary = this.dictionary;
            this.measurementCharts = measurementCharts;
    }

    private createDataTable(): TableRowFormat[] {
        const rows: TableRowFormat[] = [];
        let evalu = "-";
    
        this._items.forEach((m) => {
          const measurementChart = this.measurementCharts.find(c=>c.uuid === m.uuid)?.measurementChart ?? '';
          if (m.featureOT && m.featureUT) {
            evalu =
              m.featureValue >= m.featureUT && m.featureValue <= m.featureOT
                ? "ok"
                : "nok";
          }
          rows.push({
            Date: new Date(m.measurementTimestamp).toLocaleString(),
            Part: EH.addLineBreak(m.partName, 15),
            Machine: EH.addLineBreak(m.workbenchName, 10),
            Nest: EH.addLineBreak(m.nestName, 10),
            Feature: EH.addLineBreak(m.featureName, 30),
            LT: this.formatNumber(m.featureUT as number, m.featureDecimalPlaces), // m.featureOT ? m.featureOT.toString() : "nd",
            UT: this.formatNumber(m.featureOT as number, m.featureDecimalPlaces), // m.featureUT ? m.featureUT.toString() : "nd",
            Value: this.formatNumber(m.featureValue, m.featureDecimalPlaces),  // m.featureValue.toString(),
            dummy: '',
            Diagram: measurementChart,
          });
        });
        this._eval = evalu;
    
        return rows;
      }

    private createDataHeader(unitStr: string): string[] {
      const unit = this.dictionary[unitStr]
      const headerRow: string[] = [
       this.dictionary["Date"],
       this.dictionary["Part"],
       this.dictionary["Machine"],
       this.dictionary["Nest"],
       this.dictionary["Feature"],
       this.dictionary["Lower Tolerance"]+ '\n'+ unit,
       this.dictionary["Upper Tolerance"] + '\n'+ unit,
       this.dictionary["Value"]+ '\n'+ unit,
       this.dictionary["Diagram"],
      ];
      return headerRow;
    }

    createExport() {
        // Preparation
        const ts = new Date();
        const exportedAt = this.dictionary['ExportedFromQumoOn'] + ' ' + ts.toLocaleDateString();
    
        const options: any = { // jsPDF.jsPDFOptions
          orientation: "landscape",
        };
    
        const rows = this.createDataTable();
        
        const headerRow = this.createDataHeader(this.currentSettings.isMM ? 'mm': 'inch');
        const data: any[] = [];
    
        rows.forEach((m) => {
          data.push([
            m.Date.slice(0, 19).replace('T','/'),
            m.Part,
            m.Machine,
            m.Nest,
            m.Feature,
            m.LT,
            m.UT,
            m.Value,
            '',
            m.Diagram,
          ]);
        });
    
        const doc = new jsPDF(options);
        autoTable(doc, {
          head: [headerRow],
          body: data,
          startY: 25,
          headStyles: {
            fillColor: [255, 255, 255],
            fontStyle: "bold",
            fontSize: 10,
            textColor: [145, 150, 155],
          },
          bodyStyles: {
            fontSize: 12,
            textColor: [25, 27, 29],
            fontStyle: "bold",
          },
          alternateRowStyles: {
            fillColor: [145, 150, 155],
            fontStyle: "bold",
          },
          didDrawCell: (data) => {
            if (data.section === "body" && data.column.index === 8) {
              const x = data.cursor ? data.cursor.x + 1.8 : 0
              const y = data.cursor ? data.cursor.y + 1.7 : 0
              const d = data.row.raw as any
              if(!d[9]) return 
              doc.addImage(d[9].toString(), x, y, data.cell.width - 2 * 1.8, 5)
              
            }
    
          },
        });
    
        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = doc.getNumberOfPages();
    
        // var imgData = `data:image/jpeg;base64,${logo};`
    
        const startDate = new Date(this.minDate);
        const endDate = new Date(this.maxDate);
    
        // For each page, print the page number and the total pages
        doc.setFontSize(12);
        for (var i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          //Print Page 1 of 4 for example
    
          doc.text(
            startDate.toLocaleString() +
              " - " +
              endDate.toLocaleString(),
            75,
            15
          );
          doc.text(exportedAt, 297 - 15, 15, { align: "right"});
          doc.text(
            this.dictionary['Page']+ ' ' + String(i) + ' ' + this.dictionary['of'] + ' ' + String(pageCount),
            297 - 15,
            210 - 5,
            { align: "right"}
          );
          doc.addImage(customLogo, "png", 15, 5, 33, 15);
        }
    
        // Save the doc
        this._doc = doc;
        
      }

      saveExport(filename: string = '') {
        const saveName = "results_" + EH.getIsoTimeFormated(new Date().getTime());
        this._doc.save(filename.length > 0 ? filename :saveName + ".pdf");
        
      }
    
}
