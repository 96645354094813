
import '../../scss/components/languageSwitch.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonGroupToggle from './ButtonGroupToggle';
import { AppState } from '../../store/reducers';
import { useDispatch, useSelector } from 'react-redux';


interface LanguageSwitchProps {
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = (props) => {

    const dispatch = useDispatch();
    const { system } = useSelector((state: AppState) => state);
    const { i18n } = useTranslation();
    
    const changeLanguage = (lng: 'de'|'en') => {
        i18n.changeLanguage(lng);
    };
    
    const onChange = (lang: 'de'|'en') => {
        dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, language: lang}} )
        changeLanguage(lang)
    }

    // The following useeffect fixes this issue: If the app is used the first time, the language button shows EN but the displayed texts are in German
    React.useEffect(()=>{
        const lang = system.settings.language;
        changeLanguage(lang);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    return (
            <ButtonGroupToggle 
                options = {[
                    {
                        value: 'de',
                        label: 'DE',
                    },
                    {
                        value: 'en',
                        label: 'EN',
                    },
                ] }
                onChangeHandler = { (lang: 'de'|'en') => { onChange(lang) }}
                show = { true }
                current = { system.settings.language }
                styles = 'btn--badge hsmall'
                wrapperStyles = 'group-btn--even language-switch'
            />
    )
}

export default LanguageSwitch