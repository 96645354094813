import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { sendMessage, updateDevice, updateMeasurements, updateDevicePin } from "./action";
import { Device, ChannelValue } from './types';
import { AppState } from "../../store/reducers";
import { webapi } from '../../api/web-api';

export const thunkSendMessage = (
  message: string
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  const asyncResp = await getDevice(1);
  dispatch(
    sendMessage({
      message,
      user: asyncResp.name,
      timestamp: new Date().getTime()
    })
  );
};

export const thunkGetDevice  = (device:Device): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: Device = await getDevice(1);
  dispatch(
    updateDevice(
      asyncResp
      // {...asyncResp, isConnected: true}
    )
  );
}

export const thunkGetDeviceMeasurements  = (values:ChannelValue[]): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: ChannelValue[] = await getDeviceMeasurements();
  dispatch(
    updateMeasurements(
      asyncResp
    )
  );
}

export const thunkUpdatePin = (id: number, pin: string): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkUpdatePin',pin)
  const asyncResp: string = await putStoragePin(id,pin);
  // console.log('::: thunkUpdatePin-asyncResp new pin:', asyncResp);
  dispatch(
    updateDevicePin(
      asyncResp
    )
  );
}

function getDevice(id: number): Promise<Device> {
  // console.log('getDevice invoked');
  return new Promise<Device>((resolve, reject) => {
    const uri =  '/device/' + id;
    webapi.client.get<Device>(uri).then((resp) => {      
      // console.log('getDevice result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })
  });
}

// FIXME: doppelt ?
function getDeviceMeasurements(): Promise<ChannelValue[]> {
  // console.log('getDeviceMeasurements invoked');
  return new Promise<ChannelValue[]>((resolve, reject) => {
    const uri =  '/measurements';
    webapi.client.get<any>(uri).then((resp) => {      
      // console.log('getDeviceMeasurements result', resp.data)
      const data = resp.data.featurevalues?.map((item: number) => {
        return { timestamp: resp.data.timestamp, current: item}
      })
      
      resolve(data);
    }).catch((err) => {
      reject(err);
    })

  });
}

function putStoragePin(id: number, hash: string): Promise<string> {
  // console.log('putStorageAttribute invoked');
  return new Promise<string>((resolve, reject) => {
    const uri =  '/devicepin/' + id;
    webapi.client.put<string>(uri, {pin: hash}).then((resp) => {      
      // console.log('::: putStorageMachineNest result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageMachineNest Err: ', err);
      reject(err);
    })
  });
}



