import { AttributeItem, AttributeSettingEnum, AttributeTypeEnum, SensorItem } from "../../store/attributes/types"
import { v4 as uuidv4 } from 'uuid'
import { AttributeQdas, PartQdas } from "../qdas/qdas-types";
import { qdasMandatoryRow, QdasType } from "../statistics/export/data/constants";
import { FEATURES, MACHINE, NEST, PART } from "../../constants/lang/translation-keys";
import { MachineItem, NestItem } from "../../store/machines/types";
import NamingHelper from '../settings/naming/naming-helper';

export const isAttributePocketConfigured = (attribute:AttributeItem) => {
    let isAttributePocketConfigured = true
    for (let i = 0; i < attribute.sensors.length; i++) {

        const sensor:SensorItem = attribute.sensors[i];
        console.log('channel: ', i + ' - is Referenced: ', sensor.reference.isReferenced);
        
        if(!sensor.reference.isReferenced) {
            isAttributePocketConfigured = false
            break
        }
        
        // we can have less sensors needed than 3 (we always have three in the store)
        if(i + 1 === Number(attribute.config.sensorsCount)) break
    }
    return isAttributePocketConfigured
}

export const markFirstFreeChannel = (attribute:AttributeItem) => {
    attribute.sensors.some((sensor:SensorItem, index) => {
        if(!sensor.reference.isReferenced){
            attribute.sensors[index].localIsActive = true
            return true
        } else {
            return false
        }
    })
    return attribute
}

export const buildAnEmptyAttributeObject = (partId:any, attributeId:any): AttributeItem => {
    // build the empty attribute object
    const sensors:SensorItem[] = [
        {
            uuid: uuidv4(),
            attributeId: attributeId,
            reference: {
                channelId: null,
                isReferenced: false,
                referenceValue: 0,
                measurementValue:0,
                isInvers:false,
                referenceFactor: 1
            },
            position: 1,
            name: 'taster 1',
        },
        {
            uuid: uuidv4(),
            attributeId: attributeId,
            reference: {
                channelId: null,
                isReferenced: false,
                referenceValue: 0,
                measurementValue: 0,
                isInvers:false,
                referenceFactor: 1
            },
            position: 2,
            name: 'taster 2',
        },
        {
            uuid: uuidv4(),
            attributeId: attributeId,
            reference: {
                channelId: null,
                isReferenced: false,
                referenceValue: 0,
                measurementValue: 0,
                isInvers:false,
                referenceFactor: 1
            },
            position: 3,
            name: 'taster 3',
        }
    ]

    //if saving in the DB
    const newAttr: AttributeItem = {
        uuid: attributeId,
        partId: partId,
        name: '',
        isRequired: true,
        config : {
            pocketConfigFinished: false,
            ot: '',
            nm: '',
            ut: '',
            upperWarnLimit: '',
            lowerWarnLimit: '',
            withWarnlimits: false,
            masterSize: '',
            type: AttributeTypeEnum.OUTER,
            setting: AttributeSettingEnum.DIAMETER,
            angles : [120,120,120],
            sensorsCount : 3,
            decimalPlaces: 3,
        },
        reference : {
            timestamp: 0,
            isReferenced: false
        },
        measurement: {
            timestamp: 0,
            attributeValue: 0,
            measurementChart: ''
        },
        measurements: [],
        isDraft : true,
        isDeleted : false,
        sensors: sensors,
        dataQdas: '',
        timestamp: 0
    }

    return newAttr
}

export const isQdasMandatoryFieldsValid = ( attributesThis: any ) => {
    // check part, attributes, machine and nest for qdas mandatory fields

    let isValid = true;

    let validation = ['MissingQdasEntriesIn']

    const { part } = attributesThis.state;
    if ( part ) {
        if (part.dataQdas) {
            const partQdasData = JSON.parse(part.dataQdas) as PartQdas[];
            for (const row of qdasMandatoryRow) {
                if (row.type===QdasType.PART) {
                    const foundMandatoryField = partQdasData.find( q => q.uuid.slice(0,5)===row.key );
                    if (!foundMandatoryField) {
                        isValid = false;
                        validation.push(PART);
                        break;
                    }
                }
            }
        } else {
            const partHasMandatoryField = qdasMandatoryRow.find(r=>r.type===QdasType.PART);
            if (partHasMandatoryField) {
                isValid = false;
                validation.push(PART);
            }
        }
        
        const { attributes } = part;
        for (const attr of attributes) {
            if (attr.dataQdas) {
                const attrDataQdas = JSON.parse(attr.dataQdas) as AttributeQdas[];
                for (const row of qdasMandatoryRow) {
                    if (row.type===QdasType.ATTRIBUTE) {
                        const foundMandatoryField = attrDataQdas.find( q => q.uuid.slice(0,5)===row.key );
                        if (!foundMandatoryField) {
                            isValid = false;
                            validation.push(FEATURES);
                            break;
                        }
                    }
                }
            } else {
                const attrHasMandatoryField = qdasMandatoryRow.find(r=>r.type===QdasType.ATTRIBUTE);
                if (attrHasMandatoryField) {
                    isValid = false;
                    validation.push(FEATURES);
                }
            }
            
        }
    }
    const { machine, nest } = attributesThis.props.system.currentMachine;
    const currentMachine = attributesThis.props.machines.find((m:MachineItem)=>m.uuid===machine.uuid);
    if (!currentMachine || !currentMachine.reference) {
        isValid = false;
        validation.push(NamingHelper.getNaming(MACHINE,attributesThis.props.namingContext.naming));
    }
    if (currentMachine) {
        const currentNest = currentMachine.nests.find( (n: NestItem) => n.uuid===nest.uuid);
        if ( !currentNest || !currentNest.reference) {
            isValid = false;
            validation.push( NamingHelper.getNaming(NEST,attributesThis.props.namingContext.naming) );
        }
    }

    if (validation.length > 1) attributesThis.props.validationLoad(validation);

    return isValid;
}