
import React from 'react';

interface MeasurementProps {
    item: any
    value: any
    show: boolean
}

interface MeasurementState {
    chart: any | undefined
}

class Measurement extends React.Component<MeasurementProps, MeasurementState> {

    constructor(props:MeasurementProps){
        super(props)
        this.state = {
            chart: 0
        }
    }

    chart:any = React.createRef()

    componentDidMount(){
        if(this.state.chart){
            this.setState({
                chart: {
                    height: this.chart.current.clientHeight
                }
            })
        }
    }
    

    render() {    
        
        // If measurement should be rendered
        //--------------------------------------------------------

            if(this.props.show){

                // config values
                    const { ut, nm, ot, upperWarnLimit, lowerWarnLimit, withWarnlimits } = this.props.item.config;
                    // const { ut, nm, ot, upperWarnLimit, lowerWarnLimit, withWarnlimits } = { ut:20, nm:22, ot:21, upperWarnLimit:20.7, lowerWarnLimit:20.3, withWarnlimits: true }; // DEV
                    const nmPositionBottomREL = (nm - ut) / (ot - ut);  // RELATIVE
                    const nmPositionTopREL = 1 - nmPositionBottomREL; // RELATIVE
                    const nmStyles = {
                        bottom: `${nmPositionBottomREL * 100}%`
                    };

                // warn limits
                    const uwlPositionBottomREL = (upperWarnLimit - ut) / (ot - ut); // RELATIVE
                    const uwlStyles = {
                        bottom: `${uwlPositionBottomREL * 100}%`
                    };

                    const lwlPositionBottomREL = (lowerWarnLimit - ut) / (ot - ut); // RELATIVE
                    const lwlStyles = {
                        bottom: `${lwlPositionBottomREL * 100}%`
                    };

                // show the measurement value
                    const toleranceDeltaABS = ot - ut;   // absolute
                    // const newValue = 2 // DEV
                    const newValueABS = Number(this.props.value); // absolute
                    const deltaABS = nm - newValueABS; // absolute
                    const distanceFromNmABS = Math.abs(deltaABS); // absolute
                    const isHigher = deltaABS < 0;
                    const valueHeightREL = distanceFromNmABS / toleranceDeltaABS; // RELATIVE
                    let valueStyles = {
                        height: `${valueHeightREL * 100}%`,
                        bottom: `${nmPositionBottomREL * 100}%`,
                        backgroundColor : '#2c8446',
                        top: ''
                    };

                // position the fill
                    if(!isHigher){
                        valueStyles.top = `${nmPositionTopREL * 100}%`
                    }

                //mark if measurment is out of the tolerance
                    // const otb = ot - nm
                    // const utb = nm - ut

                    // const owl = upperWarnLimit - nm
                    // const uwl = nm - lowerWarnLimit

                    // const isInWarn = isHigher ? distance <= owl : distance <= uwl

                    const isOutWarn = newValueABS < lowerWarnLimit || upperWarnLimit < newValueABS;
                    // const isOk = isHigher ? distance < otb : distance < utb

                    const isOk = ut <= newValueABS && newValueABS <= ot;

                    if(isOutWarn && withWarnlimits && upperWarnLimit && lowerWarnLimit){
                    // if(!isInWarn && upperWarnLimit && lowerWarnLimit){
                        valueStyles.backgroundColor = 'orange'
                    }

                    if(!isOk){
                        valueStyles.backgroundColor = '#bc283d'
                    }

                    const isNmOutTop = nmPositionBottomREL > 1.33;
                    const isNmOutBottom = nmPositionBottomREL < -0.33;

                return(
                    
                    <div 
                        className="chart"
                        ref = {this.chart}
                    >
                        {/* marker if value is over top border */}
                        <div
                            hidden={ !isNmOutTop}
                            className="chart__value top"
                            style={{ backgroundColor : valueStyles.backgroundColor }}
                        />
                        <div className="chart__inner">
                            <div className="chart__marker chart__marker--ot">
                                {/* {ot} */}
                            </div>
                            {
                                withWarnlimits && upperWarnLimit && 
                                <div className="chart__marker chart__marker--warn" style = {uwlStyles}></div>
                            }
                            {
                                withWarnlimits && lowerWarnLimit &&
                                <div className="chart__marker chart__marker--warn" style = {lwlStyles}></div>
                            }

                            <div className="chart__marker chart__marker--nm" style = {nmStyles}>
                                {/* {nm} */}
                            </div>
                            <div className="chart__marker chart__marker--ut">
                                {/* {ut} */}
                            </div>
                            <div className="chart__value" style={valueStyles}></div>
                        </div>
                        {/* marker if value is under bottom border */}
                        <div
                            className="chart__value bottom"
                            hidden={ !isNmOutBottom}
                            style={{ backgroundColor : valueStyles.backgroundColor }}
                        />
                    </div>
                        
                );


        // if no render needed
        //--------------------------------------------------------

            } else {
                return null
            }
    };
}

export default Measurement