import Moment from 'moment';

namespace DateTimeHelper {

    function getTimestamp (moment:Moment.Moment) {
        // 'moment' is user's choise with LOCAL time. Since the timestamps are all in UTC/GMT time, they must be adapted.
        return new Date(moment.format('YYYY-MM-DD')).getTime() + new Date().getTimezoneOffset() * 60 * 1000;
    };

    export function momentToStartDayTs(moment:Moment.Moment) {
        return getTimestamp(moment);
    }

    export function momentToEndDayTs(moment:Moment.Moment) {
        return getTimestamp(moment) + 86399000; //+ 1day - 1milisecond
    }
}

export default DateTimeHelper;