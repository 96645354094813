import { LOAD_MACHINES, UPDATE_MACHINE, MachinesState, MachinesActionTypes, DELETE_MACHINE, ADD_MACHINE, ADD_MACHINE_NEST, DELETE_MACHINE_NEST, UPDATE_MACHINE_NEST  } from "./types";
import update from 'immutability-helper';

const initialState: MachinesState = {
  items : []
};

export function machinesReducer(
  state = initialState,
  action: MachinesActionTypes
): MachinesState {
  switch (action.type) {
    case LOAD_MACHINES: {
      return Object.assign({}, state , {items : action.payload})
    }

    case DELETE_MACHINE: {
      const filteredMachines = state.items.filter(
        machine => machine.uuid !== action.payload
      );
      return update(state, {items: {$set: filteredMachines}});
    }

    case DELETE_MACHINE_NEST: {
      const machineIndex = state.items.findIndex(
        machine => machine.uuid === action.payload.parentId
      )
      
      const machine = {...state.items[machineIndex]}
      const filteredNests = machine.nests.filter(
        nests => nests.uuid !== action.payload.id
      );
      
      const newMachine = {
        ...machine,
        nests: [...filteredNests]
      }
      return update(state, { items: {[machineIndex]: {$set: newMachine}}})
    }

    case ADD_MACHINE: {
      const newMachine = update(action.payload, {uuid: {$set: action.payload.uuid}})
      return update(state, {items: {$push: [newMachine]}});
    }

    case UPDATE_MACHINE: {
      const newItems = state.items.map(machine => {
        if(machine.uuid === action.payload.uuid){
          return {
            ...machine,
            ...action.payload
          }
        } else {
          return machine
        }
      })
      
      return {
        items: newItems
      }
    }

    case UPDATE_MACHINE_NEST: {

      const machineIndex = state.items.findIndex(
        machine => machine.uuid === action.payload.workbenchUuid
      )
      const machine = {...state.items[machineIndex]}
      
      const nestIndex  = machine.nests.findIndex(
        nest => nest.uuid === action.payload.uuid
      )
      machine.nests[nestIndex] = {...action.payload}
      return update(state, { items: {[machineIndex]: {$set: machine}}})
    }

    case ADD_MACHINE_NEST: {
      // console.log('machine state:::::::', state);
      const machineIndex = state.items.findIndex(
        machine => machine.uuid === action.payload.workbenchUuid
      )
      const machine = {...state.items[machineIndex]}
      
      machine.nests.push(action.payload);
      return update(state, { items: {[machineIndex]: {$set: machine}}})
    }

    default:
      return state;
  }
}
