import { MeasureObject } from "../channels/types"

// Describing the shape of the system's slice of state
export interface AttributesState {
  items : AttributeItem[]
}
export interface AttributeItem {
  uuid:string,
  partId: string,
  isRequired: boolean,  // TODO: deprecated, always true ???
  name: string,
  config: AttributeConfigItem,
  sensors: SensorItem[],
  isDraft: boolean,
  isDeleted: boolean
  reference : {
    timestamp: number,
    isReferenced: boolean
  },
  measurement : {
    timestamp: number,
    attributeValue: number | null,
    measurementChart: any
  },
  measurements : AttributeMeasurementItem[],  // TODO: deprecated
  live? : { // TODO: deprecated ???
    timestamp: number,
    attributeValue: number,
  },
  dataQdas: string,
  timestamp: number,
  readonly?:boolean
}

// TODO: depricated
export interface AttributeMeasurementItem {
  uuid: string
  machineName: string
  attributeUuid: string
  attributeValue: number | null
  measurementTimestamp: number
}

export interface SensorItem {
  uuid: string;
  position: number;
  name: string,
  attributeId: string;
  localIsActive?: boolean
  reference: {
      channelId: number | null;
      isReferenced: boolean;
      referenceValue: number;
      measurementValue:number;
      // new 14102020
      isInvers: boolean;
      referenceFactor: number;
  }
}

export interface SensorDataBaseItem {
  uuid: string;
  position: number;
  name: string,
  attributeId: string;
  channelId: string | null;
  isReferenced: boolean;
  localIsActive?: boolean;
  referenceValue?: number;
}

export interface AttributeDatabaseItem {
  uuid:string,
  partId: string,
  isRequired: boolean,
  name: string,
  isDraft: boolean,

  angleP1: number;
  angleP2: number;
  angleP3: number;

  ot: number | string;  
  ut: number | string;
  nominalSize: number | string;
  masterSize: number | string;
  upperWarnLimit: number | string
  lowerWarnLimit: number | string
  withWarnlimits: boolean
  decimalPlaces: number
  pocketConfigFinished: boolean
  
  setting: AttributeSettingEnum;
  type: AttributeTypeEnum;
  sensorsCount: number;
  
  referenceTimestamp: number;
  isReferenced: boolean;
  measurementTimestamp: number;
  measurementValue: number | null;
  measurementChart: string | null;

  dataQdas: string | null;
  
  // sensors: AttributeSensorItem[],
  sensor_1_name: string;
  sensor_1_isReferenced: boolean;
  sensor_1_channelId: number | null;
  sensor_1_referenceValue: number;
  sensor_1_measurementValue: number;
  sensor_1_isInvers: boolean;
  sensor_1_referenceFactor: number;
  
  sensor_2_name: string;
  sensor_2_isReferenced: boolean;
  sensor_2_channelId: number | null;
  sensor_2_referenceValue: number;
  sensor_2_measurementValue: number;
  sensor_2_isInvers: boolean;
  sensor_2_referenceFactor: number;

  sensor_3_name: string;
  sensor_3_isReferenced: boolean;
  sensor_3_channelId: number | null;
  sensor_3_referenceValue: number;
  sensor_3_measurementValue: number;
  sensor_3_isInvers: boolean;
  sensor_3_referenceFactor: number;

}

export interface AttributeSensorItem {
  // FIXME: remove uuid, parentId
  uuid: string,
  parentId: string,
  position: number,
  name: string,
  reference: {
    isReferenced: boolean
    channelId: string | null
  },
  localIsActive: boolean
}

export interface AttributeConfigItem {
    pocketConfigFinished: boolean
    angles: number[]
    masterSize: number | string
    ot: number | string  
    nm: number | string
    ut: number| string
    upperWarnLimit: number | string
    lowerWarnLimit: number | string
    withWarnlimits: boolean
    setting: AttributeSettingEnum
    type: AttributeTypeEnum
    sensorsCount: number
    decimalPlaces: number
  }

  export enum AttributeSettingEnum {
    LENGTH = 'LENGTH',
    DIAMETER = 'DIAMETER',
  }

  export enum AttributeTypeEnum {
    OUTER = 'OUTER',
    INNER = 'INNER',
  }

    // Describing the different ACTION NAMES available
    export const LOAD_ATTRIBUTES = "LOAD_ATTRIBUTES"
    export const UPDATE_ATTRIBUTE ="UPDATE_ATTRIBUTE"
    export const ADD_ATTRIBUTE ="ADD_ATTRIBUTE"
    export const DELETE_ATTRIBUTE ="DELETE_ATTRIBUTE"
    export const UPDATE_LIVE_DATA ="UPDATE_LIVE_DATA"
    

    interface LoadAttributesAction {
      type: typeof LOAD_ATTRIBUTES;
      payload: AttributeItem[];
    }

    interface DeleteAttributeAction {
      type: typeof DELETE_ATTRIBUTE;
      payload: string;
    }

    interface AddAttributeAction {
      type: typeof ADD_ATTRIBUTE;
      payload: AttributeItem;
    }
    
    interface UpdateAttributeAction {
      type: typeof UPDATE_ATTRIBUTE;
      payload: AttributeItem;
    }

    interface UpdateLiveDataAction {
      type: typeof UPDATE_LIVE_DATA;
      payload: MeasureObject;
    }

    
    export type AttributesActionTypes = LoadAttributesAction | AddAttributeAction 
    | DeleteAttributeAction | UpdateAttributeAction | UpdateLiveDataAction;

	//KRZYSZTOF: Please leave in place i will take care of that: 
    export enum AttributeModeTypes {
      MEASURE = 'MEASURE',
      REFERENCE = 'REFERENCE', 
      EDIT = 'EDIT',
      EDIT_ACTIVE = 'EDIT_ACTIVE',
      DISABLED = 'DISABLED',  
    }
    export type attributeModeTypes = 'measure' | 'reference' | 'edit' | 'edit-active' | 'disabled'