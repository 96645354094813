import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { setAttributeEditFormMode } from '../../../store/system/action';
import { attributeEditFormMode, UsedDelimiterEnum, UsedUnitsEnum } from '../../../store/system/types';
import { SensorItem, AttributeItem } from '../../../store/attributes/types';
import Measurement from '../Measurement';
import { isAttributePocketConfigured } from '../../attributes/AttributeHelpers';
import { updateTempAttribute } from '../../../store/system/action';
import FormatNumber from '../../../helper/format-number';
import { AppConfig } from '../../../constants/app-config';


interface TasterChannelProps {
    channel: SensorItem
    index: number
    attributeId: string
    updateTempAttribute?: any
    setAttributeEditFormMode?: any
    attribute?: any
    viewOnly?: boolean
    channels?: any
    measure? : boolean
    channelValue? : number
    system?: any
    angleHandler?:any
    angles?: any[]
    angleValidation?: any[]
}

interface TasterChannelState {
    css_mode: string
}

class TasterChannel extends React.Component<TasterChannelProps, TasterChannelState> {

    constructor(props:TasterChannelProps){
        super(props)
        this.state = {
            css_mode: ''
        }
    }

    componentDidMount(){
        this.setState({
            css_mode : this.handleChannelStyles()
        })
    }

    componentDidUpdate(prevProps:TasterChannelProps){

        if(prevProps.attribute !== this.props.attribute){
            console.log('attribute changed');
            // console.log(prevProps.channel.reference.isReferenced);
            // console.log(this.props.channel.reference.isReferenced);   
        }
        

        if(prevProps.channel.reference.isReferenced !== this.props.channel.reference.isReferenced){
            console.log(prevProps.channel.reference.isReferenced !== this.props.channel.reference.isReferenced);
            
            this.handleChannelStyles()
        }

        if(prevProps.system.attributeEditFormMode !== this.props.system.attributeEditFormMode && this.props.system.attributeEditFormMode === attributeEditFormMode.DEFAULT){
            // console.log('---update!!!!!!!!', this.props.index, this.props.system?.attributeEditFormMode);
            // console.log(this.props.channel.localIsActive);

            // //first reset the active channel
            // this.setState({
            //     css_mode: this.state.css_mode === 'taster__item--active' ? '' : this.state.css_mode
            // }) 

            // if(this.props.system?.attributeEditFormMode === attributeEditFormMode.CONFIG){

            // }
            
            // if(this.props.system?.attributeEditFormMode === attributeEditFormMode.DEFAULT && !this.isAssigned()) {
            //     this.setState({
            //         css_mode: ''
            //     }) 
            // } else if(this.isAssigned()){
            //     console.log('this.isAssigned()', this.isAssigned());
                
            //     this.setState({
            //         css_mode: 'taster__item--loaded'   
            //     })
                
            // } else if(this.props.channel.localIsActive ) {
            //     console.log('this.props.channel.localIsActive', this.props.channel.localIsActive);
            //     console.log('this.props.system?.attributeEditFormMode !== attributeEditFormMode.DEFAULT', this.props.system?.attributeEditFormMode !== attributeEditFormMode.DEFAULT);
                
            //     this.setState({
            //         css_mode: 'taster__item--active'
            //     }) 
            // }
            // console.log('');
        }
    }

    isAssigned(){
        return this.props.channel.reference.isReferenced
    }

    isActive(){
        // return false
        return this.props.channel.localIsActive
    }

    handleChannelStyles(){
        console.log('handleChannelStyles()');
        
        let css_mode = ''
        if(this.isAssigned()) css_mode = 'taster__item--loaded'
        else if(this.props.channel.localIsActive) css_mode = 'taster__item--active'
        return css_mode
    }

    onClickHandler(e:any){
        
        e.preventDefault()

        //dont react on click if it's a view only taster (referencing)
        if(this.props.viewOnly) return

        // for updating the attribute
        let newAttribute:AttributeItem = {
            ...this.props.attribute
        } 

        // if not in the config mode open it and mark the clicked channel
        //--------------------------------------------------------

            if(this.props.system?.attributeEditFormMode === attributeEditFormMode.DEFAULT ) {
                if(!this.isActive() && !this.props.channel.reference.isReferenced){
                    newAttribute.sensors[this.props.index].localIsActive = true
                    this.props.updateTempAttribute(newAttribute, true);
                }
                this.props.setAttributeEditFormMode(attributeEditFormMode.CONFIG) 
                return 
            }


        // if in config mode
        //--------------------------------------------------------

            if(!this.isActive()){
                newAttribute.sensors.forEach( (sensor:any) => { sensor.localIsActive = false })
                newAttribute.sensors[this.props.index].localIsActive = true
            }
            
            // remove the assigned channel
            if(this.isAssigned()){
                newAttribute.sensors[this.props.index].reference.isReferenced = false
                newAttribute.sensors[this.props.index].reference.channelId = null
            }

            // after removing a channel from taster channel 
            // lets mark this in the attribute so we can update the css of the Rasp Sensors
            if(!isAttributePocketConfigured(newAttribute)){
                newAttribute.config.pocketConfigFinished = false
            }
            
            this.props.updateTempAttribute(newAttribute, true);
    }

    onChangeHandler(e:any){
        e.preventDefault()
        this.props.angleHandler({
            value: e.target.value,
            index: this.props.index
        })

        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
        // Implement Validation!!!!
        //////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////
    }

    render() {

        // mode css
        //--------------------------------------------------------
            let css_mode = ''
            if(this.isAssigned()) css_mode = 'taster__item--loaded'
            else if(this.props.channel.localIsActive && this.props.system.attributeEditFormMode !== attributeEditFormMode.DEFAULT) css_mode = 'taster__item--active'


        // position
        //--------------------------------------------------------
            const css_position = this.props.channel.position ? this.props.channel.position : this.props.index + 1


        // show hide inputs
        //--------------------------------------------------------

            const showInput = (index:any) => {
                return Number(index) !== 0
            }

        const css = `taster__item taster__item--${css_position} ${css_mode}`

        // decimal places and measurement
        //--------------------------------------------------------

            const isUnitMillimeter = this.props.system?.settings.usedUnits === UsedUnitsEnum.MM;
            const isDecimalPoint = this.props.system?.settings.usedDelimiter === UsedDelimiterEnum.DecimalPoint            
            const decimalPlaces = Number(this.props.attribute.config.decimalPlaces);
            const measurementValue = this.props.measure && this.props.channelValue ? this.props.channelValue : this.props.channel.reference.referenceValue;
            const decimalPlacesForReference = decimalPlaces < AppConfig.features.fixedDecimal ? decimalPlaces + 1 : decimalPlaces;
            const measurementValueFormated = new FormatNumber(isUnitMillimeter,isDecimalPoint).formatFixed(measurementValue, decimalPlacesForReference);


        // measurement config
        //--------------------------------------------------------

            const measurementConfig = {
                config:{  
                    ot: 2.5,
                    nm: 0,
                    ut: -2.5,
                }
            }

            const styles = this.props.angleValidation ? this.props.angleValidation[this.props.index] + ' input-blue taster__angle' : 'input-blue taster__angle'
            const isAnschlag = this.props.channel.reference.channelId === 0
            const channelStyles = isAnschlag ? 'is-anschlag' : ''
            const infoChannelStyle = this.props.viewOnly ? ' btn--info' : ''
            
        

        return(
            
            <div 
                className={ css }
            >
                <button 
                    className={"btn btn--icon btn--icon-plus " + channelStyles + infoChannelStyle }
                    onClick={ (e:any) => {this.onClickHandler(e)} }
                >
                    {!isAnschlag &&
                        <span className="h3 mb-0">
                            { this.props.channel.reference.isReferenced && this.props.channel.reference.channelId }
                        </span>
                    }
                </button>

                {
                    // for view only (like in reference slider)
                    //--------------------------------------------------------
                }

                { this.props.viewOnly && 
                    <div className="taster__item-values">
                        <span className="btn btn--info btn--seamless">
                            {  measurementValueFormated }
                        </span>
                        <Measurement 
                            show = { true }
                            value = { measurementValue }
                            item = { measurementConfig }
                        />
                    </div>
                }

                {
                    // standard
                    //--------------------------------------------------------
                }

                { showInput(this.props.index) &&  this.props.angles && (
                    <span className="taster__angle-wrapper">
                        <input 
                            type="text" 
                            name="" 
                            id={'test'}
                            value={this.props.angles[this.props.index]}
                            className={styles}
                            onChange={(e) => {this.onChangeHandler(e)}}
                        />
                    </span>
                )}
            </div>
        );
    };
}

const mapStateToProps = (state: AppState, props: TasterChannelProps) => ({
    system: state.system,
    channels: state.channels
});

const dispatchToProps = {
    setAttributeEditFormMode,
    updateTempAttribute
};

export default connect(mapStateToProps, dispatchToProps)(TasterChannel);