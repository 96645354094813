import React from 'react';
import { Translation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256'
import { confirmAlert } from 'react-confirm-alert';
import PukForm from './PukForm';
import { onKeyDown } from './PinHelpers';

type Props = {
    currentPin: any
    handleCancel: any
    handleSubmit: any
    resetPin?: any
    hideForgotPin?: boolean
}

const PinForm: React.FC<Props> = (props) => {

    // local state
    //--------------------------------------------------------

        const [pin, setPin] = React.useState<string[]>(['', '', '', ''])
        const [validation, setValidation] = React.useState<string>('')
        const {t} = useTranslation();
        const [prevKey, setPrevKey] = React.useState<string>('')


    // vars
    //--------------------------------------------------------
    
        const inputs = [0, 1, 2, 3]
        const refs = [
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null)
        ]



    // methods
    //--------------------------------------------------------
        
        const handleSubmit = (e:any) => {
            e.preventDefault()
            const newPin = pin.join('')
            const newPinSha = sha256.hex(newPin)

            console.log(props.currentPin);
            console.log(newPinSha);

            if(!props.currentPin) props.handleSubmit()

            if(props.currentPin === newPinSha) {
                console.log('correct');
                props.handleSubmit()    
            } else {
                setValidation(t('PinNotCorrect'))
                setPin(['', '', '', ''])
                refs[0].current?.focus() 
            }
        }

        const handleCancel = (e:any) => {
            e.preventDefault()
            props.handleCancel()
        }

        const onChange = (e:any, i:number) => {
            e.preventDefault()
            if(validation) setValidation('')

            const value = Number(e.target.value)
            if(isNaN(value)) return false

            const newPin = [...pin]
            newPin[i] = e.target.value
            setPin(newPin)   

            if(value && i !== 3){
                refs[i + 1].current?.focus() 
                refs[i + 1].current?.select() 
            } 
        }

        const getValue = (index:number) => {
            return pin[index]
        }

        const forgotPin = () => {
            props.handleCancel()
            setTimeout(() => {
                confirmAlert({
                    closeOnEscape: true,
                    customUI: ({ onClose }) => confirmAlertCustomUI(onClose),
                })
            }, 100);
        }

        const resetPinFromPuk = () => {
            const resetedPinSha = sha256.hex('0000')
            props.resetPin(1, resetedPinSha)
        }

        const confirmAlertCustomUI = (onClose:any) => {  
            return (
                <Translation>
                    {(t) => (
                        <div className='react-confirm-content'>
                            <h2 className="hsmall strong text-uppercase">{t('PUK Verification')}</h2>
                            <PukForm 
                                closeModal = {() => {onClose()}} 
                                handleSubmit = { () => { props.handleSubmit()}}
                                resetPin = {() => resetPinFromPuk()}
                            />
                        </div>
                    )}
                </Translation>
            )
        }


    //*******************************************************
    // Render
    //*******************************************************

        return(
            <Translation>
                { t => 
                    <form onSubmit={ (e) => handleSubmit(e) }>
                        <div className="row">
                            <div className="pin mb-3">
                                {
                                    inputs.map((input, index) => {
                                        return (
                                            <div key={index} className="pin__item">
                                                <input 
                                                    type="password" 
                                                    pattern="[0-9]*"
                                                    inputMode="numeric"
                                                    maxLength={1} 
                                                    className="input-blue" 
                                                    onChange={ (e) => onChange(e, input) } 
                                                    value={ getValue(input) }
                                                    onFocus={e => e.currentTarget.select()}
                                                    onKeyDown={e => onKeyDown(prevKey, setPrevKey, refs, e, input)}
                                                    ref={refs[input]}
                                                    autoFocus = {input === 0}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                !props.hideForgotPin && 
                                <div className="col-12 mb-3">
                                    <button 
                                        onClick = { () => { forgotPin() }}
                                        className="btn btn--reset btn--small ml-auto mr-auto p-0"
                                        tabIndex={-1}
                                        type="button"
                                    >
                                        {t('PinForgot')}
                                    </button>
                                </div>
                            }
                            { 
                            validation && 
                                <div className="col-12 mb-3 mt-3 h3 text-dark-danger-negative text-center">
                                    {validation}
                                </div>
                            }
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <span 
                                    className="btn btn--white btn--white-negative w-100" 
                                    onClick={(e) => {handleCancel(e)}}
                                    tabIndex={-1}
                                >
                                    {t('Cancel')}
                                </span>
                            </div>
                            <div className="col-6">
                                <input type="submit" className="btn btn--white w-100" value="Ok" />
                            </div>
                        </div>
                    </form>
                }
            </Translation>
        );
    }

    export default PinForm;
