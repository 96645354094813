import React from 'react';
import { QumoFeatureContextType } from './qumo-features-types';

const condtextDefaultValue: QumoFeatureContextType = { features: [], setFeatures: ()=>{} };

const Context = React.createContext(condtextDefaultValue);

const Provider = (props: any) => {

    const [features, setFeatures] = React.useState(condtextDefaultValue.features);

    return (
        <Context.Provider value={{features, setFeatures}}>
            {props.children}
        </Context.Provider>
    )

}

const exportObj = { Context, Provider }; // fix waring: "Assign object to a variable before exporting as module default import/no-anonymous-default-export"
export default exportObj;