import { HistoriesExportType } from './../export-base';
import { ChannelHistory, MeasurementExportItem } from '../../../../store/statistics/types';
import { MeasurementFlatItem } from '../../../../store/statistics/types';
import { ExportBaseAbstract} from "../export-base"
import {Dictionary} from 'lodash'
import { BasicExport } from './basic-export';
import { Device } from "../../../../store/rasp/types";
import { saveAs } from "file-saver";
import { namingObjectType } from '../../../settings/naming/naming-types';
import ConnectionHelper from '../../../../api/connection-helper';
import { PartItem } from '../../../../store/parts/types';
import { MachineItem } from '../../../../store/machines/types';
import { ChannelItem } from '../../../../store/channels/types';
import EH from '../export-helper';

export class ExportJson extends ExportBaseAbstract{
    
    private readonly dictionary: Dictionary<string> = {'key1': 'name1', 'key2': 'name2'}
    
    constructor(items: MeasurementFlatItem[], device: Device, channels: ChannelItem[], namingObject: namingObjectType, channelHistories: ChannelHistory[]) {
            super(items, device, channels, namingObject, channelHistories );
            this.updateDictionary = this.dictionary;
    }

    async createExport( items: MeasurementExportItem[], parts?: PartItem[], machines?: MachineItem[], urlToQumo?: string, historyExport?: HistoriesExportType ) {
        
        const deviceDetails = Object.assign({}, this._device);
        console.log('deviceDetails: ', deviceDetails)
        // get device details
        if (urlToQumo) {
            try {
                const result: any = await ConnectionHelper.getQumoServiceInfo(urlToQumo);
                deviceDetails.deviceId = result.device.deviceID;
                deviceDetails.name = result.device.hostname;
                deviceDetails.model = result.device.release;
            } catch (error) {
                console.log('createExport - error: ', error);
            }
        }
        
        const exp = new BasicExport();
        exp.updateDevice(deviceDetails, this._channels, this._channelHistories);
        exp.updateMachines(items, historyExport?.wbHistoryItems ?? [], historyExport?.nestHistoryItems ?? []);
        exp.updatePartsAndFeatures(items, historyExport?.partHistoryItems ?? [], historyExport?.attributeHistoryItems ?? []);
        exp.updateMeasurements(items)
        this._blob = new Blob([JSON.stringify(exp.data())])
    }

    async saveExport(filename: string = '') {
        const saveName = "results_" + EH.getIsoTimeFormated(new Date().getTime());
        saveAs(this._blob, filename.length > 0 ? filename :saveName + ".json");
    }
    
}