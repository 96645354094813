import React from 'react';
import { Translation } from 'react-i18next';
import Moment from 'moment';
import { getFormatNameLabel } from './helper/statisticsHelpers';
import { AppSettings, UsedDelimiterEnum, UsedUnitsEnum } from '../../store/system/types';
import FormatNumber from '../../helper/format-number';

import NamingContext from '../../context/naming/naming-context';
import NamingHelpers from '../settings/naming/naming-helper';
import { FEATURE, MACHINE, NEST, PART } from '../../constants/lang/translation-keys';
import { MeasurementFlatItem } from '../../store/statistics/types';

type DeletePopUpProps = {
    handleDeleteAction: (statsThis: any, uuid: string) => void
    onClose: () => void
    statsThis: any
    measurement: MeasurementFlatItem
    settings: AppSettings
}

export const DeletePopUpSingle = (props: DeletePopUpProps) => {
    
    const { 
        handleDeleteAction,
        onClose,
        statsThis,
        measurement,
        settings
        } = props;
    const { usedUnits, usedDelimiter } = settings;
    const isUnitMillimeter = usedUnits === UsedUnitsEnum.MM;
    const isDelimiterPoint = usedDelimiter === UsedDelimiterEnum.DecimalPoint;
    const { featureValue, featureDecimalPlaces, measurementTimestamp, partName, workbenchName, nestName, featureName, uuid } = measurement;
    const featureValueConv = new FormatNumber(isUnitMillimeter,isDelimiterPoint).formatFixed(featureValue,featureDecimalPlaces);

    const { naming } = React.useContext(NamingContext.Context);
    const getNaming = ( key: string ) => NamingHelpers.getNaming( key, naming );

    const handleDeleteButton = async (uuid:string) => {
        await handleDeleteAction(statsThis, uuid);
        onClose();
    }

    return (
        <Translation>
            {t =>
                <div className='react-confirm-content'>
                    <h3 className="h2">{t('DeleteMeasurement')}</h3>
                    <div className="text-left mb-3">
                        <div className="mb-1">
                            <strong>{t('Time')}</strong>: {Moment(measurementTimestamp).format('DD.M.YYYY - HH:mm:ss')}
                        </div>
                        <div className="mb-1">
                            <strong>{getNaming(PART)}</strong>: {getFormatNameLabel(partName)}
                        </div>
                        <div className="mb-1">
                            <strong>{getNaming(MACHINE)}</strong>: {workbenchName}
                        </div>
                        <div className="mb-1">
                            <strong>{getNaming(NEST)}</strong>: {nestName}
                        </div>
                        <div className="mb-1">
                            <strong>{getNaming(FEATURE)}</strong>: {featureName}
                        </div>
                        <div className="mb-1">
                            <strong>{t('Value')}</strong>: {featureValueConv}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn--white w-100" onClick={onClose}>{t('Cancel')}</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn--danger w-100" onClick={() => {
                                handleDeleteButton(uuid)
                                onClose()
                            }}>{t('Yes')}</button>
                        </div>
                    </div>
                </div>
            }
        </Translation>
    )
}
