import {AppState} from '../reducers'
import { AttributeItem } from './types';

// all
export function getAttributesAll(state: AppState): AttributeItem[] {
    return state.attributes.items;
}

export function getAttribute(state: AppState): (uuid?:string) => AttributeItem | null {
    return (uuid?:  string): AttributeItem | null => {
        const attrItem = getAttributesAll(state).find(item => item.uuid === uuid);
        if (!attrItem) {
            return null;
        }
        return attrItem
    }
}