import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadAttributes, updateAttribute, addAttribute, deleteAttribute } from "./actions";
import { incrPartAttributesCount } from '../parts/action';
import { AttributeItem, AttributeDatabaseItem, AttributeConfigItem, SensorItem } from './types';
import { AppState } from "../../store/reducers";
import { SetAttributeLoader } from "../system/action";
import { webapi } from '../../api/web-api';


export const thunkGetAttributesByPartId  = (partId: string): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  await dispatch(SetAttributeLoader(true));
  const asyncResp: AttributeItem[] = await getStorageAttributesByPartId(partId);
  await dispatch(loadAttributes(asyncResp));
  dispatch(SetAttributeLoader(false));
}

export const thunkUpdateAttribute = (attribute: AttributeItem, noHistory = false): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkUpdateAttribute', attribute)
  const attrMapped = mapAttrFlat(attribute);
  // console.log('::: maped Attribute to store', attrMapped);
  
  const asyncResp: AttributeItem = await putStorageAttribute(attrMapped, noHistory);
  // console.log('::: thunkUpdateAttribute-asyncResp', asyncResp);
  dispatch(
    updateAttribute(
      asyncResp
    )
  );
}

export const thunkDeleteAttribute = (attr: AttributeItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkDeleteAttribute', id)
  await delStorageAttribute(attr.uuid);
  // console.log('::: thunkDeleteAttribute-DONE');
  // console.log('');
  dispatch(
    deleteAttribute(
      attr.uuid
    )
  );
  dispatch(
    incrPartAttributesCount( -1, attr.partId )
  )
}



export const thunkCreateAttributeNoRedux = (attr: AttributeItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkCreateAttributeNoRedux',attr)
  const attrMapped = mapAttrFlat(attr);
  // const asyncResp: AttributeItem = 
  await postStorageAttribute(attrMapped);
  // console.log('::: thunkCreateAttributeNoRedux',asyncResp);
}

export const thunkAsyncCreateAttribute = (attr: AttributeItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  return new Promise<AttributeItem>(async (resolve, reject) => {
    // console.log('::: thunkAsyncCreateAttribute',attr)
    const attrMapped = mapAttrFlat(attr);
    try {
      const asyncResp: AttributeItem = await postStorageAttribute(attrMapped);
      // console.log('::: thunkAsyncCreateAttribute-asynResp',asyncResp);
      // console.log('');
      dispatch(
        addAttribute(
          asyncResp
        )
      );
      dispatch(
        incrPartAttributesCount( 1, attr.partId )
      )
      resolve(asyncResp);
        
    } catch (error) {
      reject(error)      
    }
  });
}

export const thunkResetAttributeRefByChannel = ( channelId: string ): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  return new Promise<any>( async (resolve, reject) => {
    try {
      const asyncResp: any = await putResetRefAttributeChannel(channelId);
      // console.log('::: thunkResetAttributeRefByChannel-asynResp', asyncResp);
      // console.log('');
      resolve(asyncResp);
    } catch (error) {
      reject(error)      
    }
  });
}

function getStorageAttributesByPartId(partId: string): Promise<AttributeItem[]> {
  // console.log('getStorageAttribute invoked');
  return new Promise<AttributeItem[]>((resolve, reject) => {
    const uri =  '/attributesbypart/' + partId;
    webapi.client.get<AttributeItem[]>(uri).then((resp) => {      
      // console.log('::: getStorageAttributes for:', partId, resp.data.length)
      const items: AttributeItem[] = [];
      if (resp && resp.data) {
        const {data} = resp;
        if (data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            const it = data[index];
            const attConfig: AttributeConfigItem = {
              ...it.config,
              pocketConfigFinished: Boolean(it.config.pocketConfigFinished),
              sensorsCount: Number(it.config.sensorsCount),
              decimalPlaces: Number(it.config.decimalPlaces)
            }
            const attSensors:SensorItem[] = it.sensors.map((s)=>  {
              return {
              uuid: String(s.uuid),
              position: Number(s.position),
              name: String(s.name),
              attributeId: String(s.attributeId),
              localIsActive: Boolean(s.localIsActive),
              reference: {
                  channelId: s.reference.channelId === null ? null : Number(s.reference.channelId),
                  isReferenced: Boolean(s.reference.isReferenced),
                  referenceValue: Number(s.reference.referenceValue),
                  measurementValue: Number(s.reference.measurementValue),
                  // new 14102020
                  isInvers: Boolean(s.reference.isInvers),
                  referenceFactor: Number(s.reference.referenceFactor)
              }
            }
            })
            const att:AttributeItem = {
              uuid:String(it.uuid),
              partId: String(it.partId),
              isRequired: Boolean(it.isRequired),
              name: String(it.name),
              config: attConfig,
              sensors: attSensors,
              isDraft: Boolean(it.isDraft),
              isDeleted: Boolean(it.isDeleted),
              reference : {
                timestamp: Number(it.reference.timestamp),
                isReferenced: Boolean(it.reference.isReferenced)
              },
              measurement : {
                timestamp: Number(it.measurement.timestamp),
                attributeValue: it.measurement.attributeValue === null ? null : Number(it.measurement.attributeValue),
                measurementChart: it.measurement.measurementChart
              },
              measurements : [],
              live : it.live,
              dataQdas: it.dataQdas,
              timestamp: Number(it.timestamp),
              readonly: Boolean(it.readonly)
            }
            items.push(att);
          }

        }
      }
      resolve(items);
      // resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })

  });
}

function postStorageAttribute(attr: AttributeDatabaseItem): Promise<AttributeItem> {
  // console.log('getStorageParts invoked');
  return new Promise<AttributeItem>((resolve, reject) => {
    const uri =  '/attribute';
    webapi.client.post<AttributeItem>(uri, attr).then((resp) => {      
      // console.log('::: postStorageAttribute', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })
  });
}

function putStorageAttribute(attribute: AttributeDatabaseItem, noHistory: boolean): Promise<AttributeItem> {
  // console.log('putStorageAttribute invoked');
  return new Promise<AttributeItem>((resolve, reject) => {
    const uriLink = noHistory ? '/attributereset/' : '/attribute/';
    const uri = uriLink + attribute.uuid;
    webapi.client.put<AttributeItem>(uri , attribute).then((resp) => {      
      // console.log('::: putStorageAttribute result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageAttribute Err: ', err);
      reject(err);
    })
  });
}

function delStorageAttribute(id: string): Promise<void> {
  // console.log('getStorageParts invoked');
  return new Promise<void>((resolve, reject) => {
    const uri =  '/attribute/'+ id;
    webapi.client.delete(uri).then((resp) => {      
      // console.log('::: deleteStorageAttribute', resp)
      resolve();
    }).catch((err) => {
      // console.log('::: delStorageAttribute Err: ', err);
      reject(err);
    })
  });
}

function mapAttrFlat(attr: AttributeItem): AttributeDatabaseItem {
  // console.log('attr:',attr);
  
  const retValue: AttributeDatabaseItem = {
    uuid: attr.uuid,
    partId: attr.partId,
    isRequired: attr.isRequired,
    name: attr.name,
    isDraft: attr.isDraft,
  
    angleP1: attr.config.angles[0] ? attr.config.angles[0] : 0,
    angleP2: attr.config.angles[1] ? attr.config.angles[1] : 120,
    angleP3: attr.config.angles[2] ? attr.config.angles[2] : 120,
  
    ot: attr.config.ot,
    ut: attr.config.ut,
    nominalSize: attr.config.nm,
    masterSize: attr.config.masterSize,
    upperWarnLimit: attr.config.upperWarnLimit,
    lowerWarnLimit: attr.config.lowerWarnLimit,
    withWarnlimits: attr.config.withWarnlimits,
    decimalPlaces: attr.config.decimalPlaces,
    pocketConfigFinished: attr.config.pocketConfigFinished,
    
    setting: attr.config.setting,
    type: attr.config.type,
    sensorsCount: attr.config.sensorsCount,
    
    referenceTimestamp: attr.reference.timestamp,
    isReferenced: attr.reference.isReferenced,

    measurementTimestamp: attr.measurement.timestamp,
    measurementValue: attr.measurement.attributeValue,
    measurementChart: attr.measurement.measurementChart,

    dataQdas: attr.dataQdas,

    sensor_1_name: attr.sensors[0] ? attr.sensors[0].name : '',
    sensor_1_isReferenced: attr.sensors[0] ? attr.sensors[0].reference.isReferenced : false,
    sensor_1_channelId: attr.sensors[0] ? attr.sensors[0].reference.channelId : null,
    sensor_1_referenceValue: attr.sensors[0] ? attr.sensors[0].reference.referenceValue : 0,
    sensor_1_measurementValue: attr.sensors[0] ? attr.sensors[0].reference.measurementValue : 0,
    sensor_1_isInvers: attr.sensors[0] ? attr.sensors[0].reference.isInvers : false,
    sensor_1_referenceFactor: attr.sensors[0] ? attr.sensors[0].reference.referenceFactor: 1,

    sensor_2_name: attr.sensors[1] ? attr.sensors[1].name : '',
    sensor_2_isReferenced: attr.sensors[1] ? attr.sensors[1].reference.isReferenced : false,
    sensor_2_channelId: attr.sensors[1] ? attr.sensors[1].reference.channelId : null,
    sensor_2_referenceValue: attr.sensors[1] ? attr.sensors[1].reference.referenceValue : 0,
    sensor_2_measurementValue: attr.sensors[1] ? attr.sensors[1].reference.measurementValue : 0,
    sensor_2_isInvers: attr.sensors[0] ? attr.sensors[0].reference.isInvers : false,
    sensor_2_referenceFactor: attr.sensors[0] ? attr.sensors[0].reference.referenceFactor: 1,

    sensor_3_name: attr.sensors[2] ? attr.sensors[2].name : '',
    sensor_3_isReferenced: attr.sensors[2] ? attr.sensors[2].reference.isReferenced : false,
    sensor_3_channelId: attr.sensors[2] ? attr.sensors[2].reference.channelId : null,
    sensor_3_referenceValue: attr.sensors[2] ? attr.sensors[2].reference.referenceValue : 0,
    sensor_3_measurementValue: attr.sensors[2] ? attr.sensors[2].reference.measurementValue : 0,
    sensor_3_isInvers: attr.sensors[0] ? attr.sensors[0].reference.isInvers : false,
    sensor_3_referenceFactor: attr.sensors[0] ? attr.sensors[0].reference.referenceFactor: 1,

  }
  return retValue;
}

function putResetRefAttributeChannel( channelId: string): Promise<string> {
  // console.log('putResetRefAttributeChannel invoked');
  return new Promise<string>((resolve, reject) => {
    const uri = `/attributeResetRefChannel/${channelId}`;
    webapi.client.put<string>(uri).then((resp) => {      
      // console.log('::: putResetRefAttributeChannel result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putResetRefAttributeChannel Err: ', err);
      reject(err);
    })
  });
}