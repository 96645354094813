import { History } from "history";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { systemReducer } from "./system/reducers";
import {raspReducer} from './rasp/reducers';
import {partsReducer} from './parts/reducer';
import { channelsReducer } from './channels/reducer';
import { attributesReducer} from './attributes/reducer';
import  {machinesReducer } from './machines/reducer';
import { statisticsReducer } from "./statistics/reducer";

import { SystemState } from "./system/types";
import { RaspState } from "./rasp/types";
import { PartsState } from "./parts/types";
import { ChannelsState} from './channels/types';
import { AttributesState } from './attributes/types';
import { MachinesState } from './machines/types';
import { StatisticsState } from "./statistics/types";


export interface AppState {
    router: any;
    system: SystemState;
    rasp: RaspState;
    parts: PartsState;
    channels: ChannelsState;
    attributes: AttributesState,
    machines: MachinesState,
    statistics: StatisticsState,
  }

  const rootReducer = (history: History) =>
	combineReducers({
        router: connectRouter(history),
        system: systemReducer,
        rasp: raspReducer,
        parts: partsReducer,
        channels: channelsReducer,
        attributes: attributesReducer,
        machines: machinesReducer,
        statistics: statisticsReducer,
      });
  
export default rootReducer;
      