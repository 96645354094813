import React from 'react';

interface IconSortProps {
    fill: string
}


const IconSort = (props:IconSortProps) => (

    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
        <g id="FINAL" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="STATISTIK" transform="translate(-814.000000, -201.000000)" fill={props.fill}>
                <g id="system-/-transfer-2" transform="translate(814.000000, 201.000000)">
                    <path d="M10.6,2.6 L7.6,0.2 L7.6,1.99940004 L-0.2,1.99940004 L-0.2,3.19939976 L7.6,3.19939976 L7.6,5 L10.6,2.6 Z M4,8.8 L4,7 L1,9.4 L4,11.8 L4,10 L11.8,10 L11.8,8.8 L4,8.8 Z" id="system-/-transfer" transform="translate(5.800000, 6.000000) rotate(90.000000) translate(-5.800000, -6.000000) "></path>
                </g>
            </g>
        </g>
    </svg>
)

export default IconSort