import { createBrowserHistory } from "history";
import * as localforage from "localforage";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { PersistConfig, persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";

const persistConfig: PersistConfig<any> = {
	key: "promess-rasp",
	version: 1,
	storage: localforage,
    // blacklist: [],
    whitelist: ['system'],
};

// const logger = (createLogger as any)();
const history = createBrowserHistory();

let dev = process.env.NODE_ENV === "development";
dev = true;

// let middleware = dev ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);
let middleware = applyMiddleware(thunk);

if (dev) {
	middleware = composeWithDevTools(middleware);
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const storePersistor = () => {
	const store = createStore(persistedReducer, {}, middleware) as any;
	const persistor = persistStore(store);
	return { store, persistor };
};

export default storePersistor;

export { history };
