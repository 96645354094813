import React from 'react';
import TasterChannel from './TasterChannel';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import { setAttributeEditFormMode } from '../../../store/system/action'
import { AttributeItem } from '../../../store/attributes/types';

interface TasterProps {
    showInputs: boolean
    hideChannelsDeleteIcons?: boolean
    attributeId: any
    attribute: AttributeItem | null
    viewOnly?: boolean
    measure? : boolean
    channelValues?: number[]
    setAttributeEditFormMode?: any
    angleHandler?: any
    angles?:any[]
    angleValidation?: any[]
}

interface TasterState {
    // sensors: any[]
}

class Taster extends React.Component<TasterProps, TasterState> {

    render() {

        // taster types
        //--------------------------------------------------------

            const cssInputs = this.props.showInputs ? 'taster--show-input' : ''

            let css = `
                taster 
                taster--children-${this.props.attribute?.config.sensorsCount}
                ${cssInputs}
            `

            if(this.props.attribute?.config.type) css += ` taster--${this.props.attribute.config.type.toLowerCase()}`
            if(this.props.attribute?.config.setting) css += ` taster--${this.props.attribute.config.setting.toLowerCase()} `
            if(this.props.viewOnly) css += ' taster--view-only'
            if(this.props.hideChannelsDeleteIcons) css += ' taster--hideChannelsDeleteIcons '


        // render
        //--------------------------------------------------------

            if(!this.props.attribute) return null

            return(
                <div className={ css }>
                    <div className="taster__wrapper">
                        {
                            this.props.attribute.sensors.map((sensor:any, i:any) => {
                                
                                //show only amount of channels defined in the config
                                if(this.props.attribute &&  i + 1 <= this.props.attribute.config.sensorsCount){

                                    const rv = this.props.channelValues ? this.props.channelValues[sensor.reference.channelId - 1] : 0
                                    const cv = sensor.reference.channelId === 0 ? 0 : rv
                                    
                                    return (
                                        <TasterChannel 
                                            attribute = { this.props.attribute }
                                            channel = { sensor }
                                            attributeId = { this.props.attributeId }
                                            key={i} 
                                            index={i}
                                            viewOnly = {this.props.viewOnly}
                                            measure = { this.props.measure }
                                            channelValue = { cv }
                                            angleHandler = {(e:any) => this.props.angleHandler(e)}
                                            angles = {this.props.angles}
                                            angleValidation ={this.props.angleValidation}
                                        />
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                    </div>
                </div>
            );
    };
}

const mapStateToProps = (state: AppState, props:TasterProps) => {
    return {}
}

const dispatchToProps = {
    setAttributeEditFormMode,
};

export default connect(mapStateToProps, dispatchToProps)(Taster);