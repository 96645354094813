
import React from 'react';
import dark from './../../../img/icons-png/icon-machines-darkmode.png'
import light from './../../../img/icons-png/icon-machines-lightmode.png'

interface IconMachinesProps {
    light: boolean
}

const IconMachines = (props:IconMachinesProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="machine"/>
)

export default IconMachines