import React from 'react'
import { Translation } from 'react-i18next'
import IconSort from '../ui/Icons/IconSort'
import { statisticsTableHeader } from './helper/statisticsHelpers';

import NamingHelper from '../settings/naming/naming-helper';
import NamingContext from '../../context/naming/naming-context';

type TableHeaderProps = {
    statisticsSortBy: string
    appDarkMode: boolean
    statisticsOrder: string
    handleSort: (_:any)=>void
}

export function TableHeader({statisticsSortBy, appDarkMode, statisticsOrder, handleSort}:TableHeaderProps) {

    const { naming } = React.useContext(NamingContext.Context);
    const getNaming = ( key: string ) => NamingHelper.getNaming( key, naming );

    const newHeaderLabels = statisticsTableHeader.map( item => {
        return { ...item, label: getNaming(item.label) };
    })

    return (
        <Translation>
                {
                    t =>
                        <thead>
                            <tr className="tr text-uppercase">
                                {
                                    newHeaderLabels.map((item: any, index: number) => {
                                        const activeColor = appDarkMode ? 'white' : 'black'
                                        const css = item.name === statisticsSortBy ? 'active' : ''
                                        const color = item.name === statisticsSortBy ? activeColor : '#989DA2'
                                        const spanCss = item.name === 'value' ? 'text-ellipsis limit' : ''
                                        const headerCellWidth = {
                                            width: `${item.width}%`,
                                        };

                                        return (
                                            <th
                                                key={item.name + '-' + index}
                                                className={css + ' ' + (statisticsOrder).toLowerCase()}
                                                onClick={() => {
                                                    if (!item.sort) return false
                                                    handleSort(item)
                                                }
                                                }
                                                style={headerCellWidth}
                                            >
                                                <span className="d-flex">
                                                    <span className={spanCss}>{item.label}</span>
                                                    {item.sort &&
                                                        <span className="ml-2">
                                                            <IconSort fill={color} />
                                                        </span>
                                                    }
                                                </span>
                                            </th>
                                        )
                                    })
                                }

                                <th>{t('Chart')}</th>
                            </tr>
                        </thead>
                }
            </Translation>
    )
}
