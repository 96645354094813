import {AppState} from '../reducers'
import { MachineItem } from './types';

// all
export function getMachinesAll(state: AppState): MachineItem[] {
    // console.log('getMachinesall:::::: ',state.machines.items);
    return state.machines.items.filter(item => !item.isDeleted);
}

export function getMachine(state: AppState): (uuid?:string) => MachineItem | null {
    return (uuid?:  string): MachineItem | null => {
        const attrItem = getMachinesAll(state).find(item => item.uuid === uuid);
        console.log('getMachine:::::: ', attrItem);
        
        if (!attrItem) {
            return null;
        }
        return attrItem
    }
}
export function getMachines(state: AppState): (name?:string) => MachineItem[] {
    return (name:  string = ''): MachineItem[] => {
        return getMachinesAll(state).filter((item)=> {
            return item.name.toUpperCase().indexOf(name.toUpperCase()) >=0;
        });
    }
}
