import React from 'react';
import { Link } from 'react-router-dom';
import { NamingContextType } from '../../../context/naming/naming-context-types';
import LoggedInHeader from './LoggedInHeader';

interface Props {
  appName: string
  currentUser: string
  show: boolean
  namingContext: NamingContextType
}

interface PropsLogged {
  currentUser: string
}

const LoggedOutView = (props: PropsLogged) => {
	if (!props.currentUser) {
		return (
			<ul className="nav">
				<li className="nav__item nav__item--settings">
					<Link to="/settings">
					Settings
					</Link>
				</li>
				<li className="nav__item nav__item--login">
					<Link to="/login">
					Sign in
					</Link>
				</li>
			</ul>
		);
	}
	return null;
};

class Header extends React.Component<Props> {
	
  	render() {
		
		const showCss = this.props.show ? 'app__sidebar--show' : ''

		return (
			<nav className={'app__sidebar ' + showCss}>
				<div className="app__sidebar-inner">
					<LoggedOutView currentUser={this.props.currentUser} />
					<LoggedInHeader currentUser={this.props.currentUser} namingContext={this.props.namingContext} />
				</div>
			</nav>
		);
  	}
}

export default Header
