import {AppState} from '../reducers'
import { ChannelItem } from './types';

// all
export function getChannelsAll(state: AppState): ChannelItem[] {
    return state.channels.items;
}

export function getChannel(state: AppState): (id?:number) => ChannelItem | null {
    return (id?:  number): ChannelItem | null => {
        const channelItem = getChannelsAll(state).find(item => item.id === id);
        if (!channelItem) {
            return null;
        }
        return channelItem
    }
}
