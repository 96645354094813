import { QdasEntry, QdasMachineEntry, QdasMeasurementEntry, QdasMeasurementExportEntry } from '../qdas-types';
import { webapi } from '../../../api/web-api';
import { MachineItem } from '../../../store/machines/types';
import { AttributeItem } from '../../../store/attributes/types';
import { getOptionalQdasPartEntries, getOptionalQdasAttrEntries } from './getOptionalEntries';

export async function refreshQdasPartEntries(selfThis: any){
    const list: QdasEntry[] = [];
    if (selfThis.props.part) {
        list.push({
            uuid: 'K1001',
            position: 0,
            readonly: true,
            text: `K1001 ${selfThis.props.part.partNumber}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K1002',
            position: 1,
            readonly: true,
            text: `K1002 ${selfThis.props.part.name}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K1041',
            position: 2,
            readonly: true,
            text: `K1041 ${selfThis.props.part.designNumber}`,
            isDeleted: false
        });
    }
    
    let lastPosition = 2;
    const optionalEntries = await getOptionalQdasPartEntries(selfThis);
    for (const entry of optionalEntries) {
        if ( entry.position > lastPosition) {
            lastPosition = entry.position;
        }
        list.push({
            uuid: entry.uuid,
            position: entry.position,
            readonly: false,
            text: entry.textRow,
            isDeleted: false
        });
    }

    selfThis.setState({ qdasEntriesTemp: list });
}

async function getQdasAttrIndex(attribute: AttributeItem){
    const uri = `/attributesbypart/${attribute.partId}`;
    const attrArray = (await (await webapi.client.get(uri)).data as AttributeItem[])
    let index = NaN;
    for (let i = 0; i < attrArray.length; i++) {
        const a = attrArray[i];
        if (a.uuid===attribute.uuid) {
            index = i;
        }
    }
    return index;
}

export async function refreshQdasAttributeEntries( selfThis: any ){
    const list: QdasEntry[] = [];
    const { attribute } = selfThis.props;

    if (attribute) {
        const index = await getQdasAttrIndex(attribute);
        const position = isNaN(index) ? '' : `${index + 1}`;
        const slashPosition = position ? `/${position}` : '';
        list.push({
            uuid: 'K2001',
            position: 0,
            readonly: true,
            text: `K2001${slashPosition} ${position}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K2002',
            position: 1,
            readonly: true,
            text: `K2002${slashPosition} ${attribute.name}`.slice(0,80),
            isDeleted: false
        });
        list.push({
            uuid: 'K2003',
            position: 1,
            readonly: true,
            text: `K2003${slashPosition} ${attribute.name}`.slice(0,20),
            isDeleted: false
        });
        list.push({
            uuid: 'K2101',
            position: 2,
            readonly: true,
            text: `K2101${slashPosition} ${attribute.config.nm}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K2110',
            position: 3,
            readonly: true,
            text: `K2110${slashPosition} ${attribute.config.ut}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K2111',
            position: 4,
            readonly: true,
            text: `K2111${slashPosition} ${attribute.config.ot}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K2142',
            position: 5,
            readonly: true,
            text: `K2142${slashPosition} ${selfThis.props.t(selfThis.props.system.settings.usedUnits)}`,
            isDeleted: false
        });
        list.push({
            uuid: 'K2213',
            position: 6,
            readonly: true,
            text: `K2213${slashPosition} ${attribute.config.masterSize}`,
            isDeleted: false
        });
        let lastPosition = 6;
        const optionalEntries = await getOptionalQdasAttrEntries( selfThis );
        for (const entry of optionalEntries) {
            if ( entry.position > lastPosition) {
                lastPosition = entry.position;
            }
            list.push({
                uuid: entry.uuid,
                position: entry.position,
                readonly: false,
                text: entry.textRow,
                isDeleted: false
            });
        }
    }
    
    selfThis.setState({ qdasEntriesTemp: list });
}

export async function refreshQdasMachineEntries(selfThis: any){
    const list: QdasMachineEntry[] = [];
    const { machine } = selfThis.props;
    if (machine) {
        const uri = `workbench/${machine.uuid}`;
        const machineDb = (await webapi.client(uri)).data as MachineItem;
        list.push({
            uuid: machineDb.uuid,
            position: 0,
            readonly: false,
            isMachine: true,
            key: `${machineDb.name}`,
            text: machineDb.reference == null ? '' : machineDb.reference
        });
        let pos = 1;
        for (const nest of machineDb.nests) {
            list.push({
                uuid: nest.uuid,
                position: pos,
                readonly: false,
                isMachine: false,
                key: `${nest.name}`,
                text: nest.reference == null ? '' : nest.reference
            });
            pos++;
        }
    }
    selfThis.setState( { qdasMachineEntriesTemp: list, qdasMachineEntriesCurr: list } );
}

export async function refreshQdasMeasurementEntries(selfThis: any){
    try {
        const uri = `/templates`;
        const qdasMeasurementEntries = (await (await webapi.client.get(uri)).data as QdasMeasurementEntry[])
        .sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
        selfThis.setState( { qdasMeasurementEntries, qdasMeasurementEntriesCurr: qdasMeasurementEntries } );
    } catch (error) {
        console.log('refreshQdasMeasurementEntries - Error: ', error);
    }
}

export async function refreshQdasMeasurementExportEntries(selfThis: any, lastQdasDataArray: QdasMeasurementExportEntry[], currPartId?: string | null | undefined ){
    const qdasMeasurementExportEntries: QdasMeasurementExportEntry[] = [];
    try {
        const uri = `/templates`;
        const qdasMeasurementEntries = (await (await webapi.client.get(uri)).data as QdasMeasurementEntry[]).sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
        qdasMeasurementEntries.forEach( e => {
            if ( e.isActive ) {
                if ( e.isRetain && lastQdasDataArray ) {
                        const measureObj = lastQdasDataArray.find( obj => obj.uuid===e.uuid );
                        if ( measureObj ) {
                            qdasMeasurementExportEntries.push({...e, textInput: measureObj.textInput });
                        } else qdasMeasurementExportEntries.push({...e, textInput: '' });
                } else {
                    qdasMeasurementExportEntries.push({...e, textInput: '' });
                }
            }
        });
        selfThis.setState( { qdasMeasurementExportEntries } );
    } catch (error) {
        console.log('refreshQdasMeasurementEntries - Error: ', error);
    }
}