import React from 'react';
import { connect } from 'react-redux';
import Button from '../ui/Button';
import { AppState } from '../../store/reducers';
import { overlayToggle, overlayLoadComponent } from '../../store/system/action';
import { MachineItem, MachinesState } from '../../store/machines/types';
import Machine from './Machine';
import { thunkCreateMachine, thunkGetMachines, thunkDeleteMachine } from '../../store/machines/thunks'
import MachineEditForm from './MachineEditForm';
import { getMachines } from '../../store/machines/selectors';
import { Translation } from 'react-i18next';
import { SystemState } from '../../store/system/types';
import NamingContext from '../../context/naming/naming-context';
import NamingHelper from '../settings/naming/naming-helper';
import { CREATE_MACHINE } from '../../constants/lang/translation-keys';
import { NamingContextType } from '../../context/naming/naming-context-types';

interface PartsProps {
    machines:  MachinesState
    getItems: any
    overlayToggle: any
    overlayLoadComponent: any
    thunkCreateMachine: any
    thunkGetMachines: any
    thunkDeleteMachine: any
    system: SystemState
}

interface PartsLocalState {
    isEditMode: boolean;
    currentFilter:string;
    filterItems: MachineItem[];
}

class Machines extends React.Component<PartsProps, PartsLocalState> {

    constructor(props:PartsProps){
        super(props);
        this.state = {
            isEditMode: this.props.system.settings.advancedMode ? true : false,
            currentFilter: '',
            filterItems: this.props.getItems('')
        }
    }

    static contextType = NamingContext.Context;

    componentWillUnmount(){
        // we need to handle the cancel of part editing/adding 
        // f.ex. someone clicks in the nav while adding a part

        // fail early
        if(!this.props.system.overlay.main.show) return 

        //find the part that is still a draft (flag that we add when a new part is being added)
        const machinesAdded:MachineItem[] = this.props.machines.items.filter((machine) => machine.isDraft === true)
        
        //lets delete all
        machinesAdded.forEach(machine => {
            console.log(machine);
            
            this.props.thunkDeleteMachine(machine.uuid)
        })
    }

    componentDidUpdate(prevProps:PartsProps){
        //if amount of items changed lets update the filtered items counter
        if(this.props.machines.items.length !== prevProps.machines.items.length){
            const filter = this.state.filterItems.length === 1 ? '' : this.state.currentFilter
            this.setState({
                currentFilter: filter,
                filterItems : this.props.getItems(filter)
            })            
        }
    }

    handleFilterEvent = (event: any) => {
        const { value} = event.target;
        this.setState({
            currentFilter: value,
            filterItems : this.props.getItems(value)
        });
    }

    toggleEditMode(e:any) {
        e.preventDefault()
        this.setState({
            isEditMode : !this.state.isEditMode
        })
    }

    addMachine = () => {
        //create the machine
        this.props.overlayLoadComponent(<MachineEditForm addingNewMachine={ true } />)
        this.props.overlayToggle()
    }

    renderHeader = () => {
        const {currentFilter} = this.state;
        let items: MachineItem[] = this.props.getItems(currentFilter);        
        
        if(items.length === 0 && this.state.currentFilter === '') return null

        return(
            <>
            <Translation>
                {
                    t =>
                    <div className="app__main-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-8 d-flex justify-content-end ml-auto">
                                <input 
                                    className="mr-auto mr-md-4 w-60 w-md-auto" 
                                    type="text" 
                                    placeholder={t('Search')+'..'}  
                                    onChange={this.handleFilterEvent}
                                    value = { this.state.currentFilter }
                                />
                                <Button 
                                    styles = 'btn btn--icon btn--icon-plus' 
                                    isActive = { false }
                                    clickHandler = { this.addMachine }
                                    disabled = { false }
                                    show = { true }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Translation>
 
                </>
        );
    }

    renderList = () => {
        const machineCss = this.state.isEditMode ? 'editable--off' : 'editable--on';
        const {currentFilter} = this.state;

        const context = this.context as NamingContextType;
        let items: MachineItem[] = this.props.getItems(currentFilter);        
        return(
            <Translation>{t => 
                <>
                    {
                        items.length === 0 && currentFilter !== '' &&
                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                <h2 className="h3">{t('NoResultsFound')}</h2>
                            </div>
                        </div>
                    }
                    {
                        items.length === 0 && currentFilter === '' &&
                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                <h2 className="h3">{NamingHelper.getNaming('NoMachinesYet', context.naming)}</h2>
                                <Button 
                                    show = { true }
                                    label = {NamingHelper.getNaming(CREATE_MACHINE, context.naming)}
                                    styles = 'btn d-inline-block'
                                    clickHandler = { this.addMachine }
                                />
                            </div>
                        </div>
                    }
                    <div className="app__main-content container-fluid">
                        <div className="parts list">
                            {
                                items.map( machine => {
                                    return (
                                        <Machine 
                                            key = { machine.uuid }   
                                            machine = { machine }
                                            css = { machineCss }
                                            isEditMode = { this.state.isEditMode }
                                            namingContext={context}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            }</Translation>
        );
    }

    render() {
        return(
            <div className="app__main">
                {this.renderHeader()}
                { this.renderList()}                
            </div>
        );
    };
}

const mapStateToProps = (state: AppState) => ({    
    machines : state.machines,
    getItems: getMachines(state),
    system: state.system
});

const dispatchToProps = {
    thunkCreateMachine,
    thunkGetMachines,
    thunkDeleteMachine,
    overlayToggle, 
    overlayLoadComponent,
};

export default connect(mapStateToProps, dispatchToProps)(Machines);