import { AttributeHistory } from "../../../store/statistics/types";
import { QdasMeasurementExportEntry } from "../../qdas/qdas-types";

export type QdasRow = {
    key: string
    ref: string
    value: string
}

export type QdasAttribute = {
    ref: string;
    attribute: AttributeHistory
}

export type QdasNest = {
    ref: string;
    uuid: string;
    historyId: string;
    workbenchUuid: string;
    name: string
}

export type QdasWorkbench = {
    ref: string;
    uuid: string;
    historyId: string;
    name: string
}

export enum QdasValidMeasure {
    VALID = '0',
    INVALID = '255'
}

export type QdasMeasurementTimeBlock = {
    timestamp: number;
    nestRef: string;
    wbRef: string;
    data: QdasMeasurementExportEntry[];
    features: QdasFeatureItem[]
}

export type QdasFeatureItem = {
    featureRef: string;
    featureValue: number | string;
    valid: QdasValidMeasure;
    featureUuid: string
}