import { initalFilterOptions } from './types';

namespace DeleteHelper {
    export async function  handleDeleteFilteredItems(statsThis:any){
        const { filteredItems } = statsThis.state;
        const take = 10;
        const total = Math.ceil(filteredItems.length/take);
        statsThis.setState({idle: true, idleProgress: 0});
        
        if (total) {
            for (let i = 0; i < total; i++) {
                const idProg = i === total - 1 || total === 1 ? 1 : i / total;
                statsThis.setState({idleProgress: idProg});
                const startIndex = i * take;
                const endIndex = startIndex + take;
                const takeItems = filteredItems.slice(startIndex,endIndex);
                await Promise.all(takeItems.map((item:any) => statsThis.props.thunkDeleteMeasurement(item.uuid)));
            }
            // Reset filter options
            statsThis.setState({filterOptions: initalFilterOptions},
                ()=>{
                    // update 
                    statsThis.updateFilteredItems();
                    // Reset filter dropdown
                    statsThis.setState({resetFilterDropdown:true},
                        ()=>statsThis.setState({resetFilterDropdown:false}));
                }
            );
            statsThis.setState({idle: false})
        }
    }
}

export default DeleteHelper;