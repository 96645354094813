import asciiData from './char-length.json';

export const getStringWidth = ( string: string ) => {
    let length = 0;
    for (const char of string) {
        const foundEntry = asciiData.find( d => d.char === char );
        if ( foundEntry ) {
            length = length + foundEntry.width;
        } else {
            length = length + 0.5;
        }
    } 
    return Math.round( length * 100 ) / 100;
}