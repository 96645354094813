import { MachineItem, NestItem } from './../machines/types';
import { PartItem } from "../parts/types";
import { ChannelItem } from '../channels/types';

// Describing the shape of the system's slice of state
export interface StatisticsState {
    measurements :  QueryResultInterface<MeasurementFlatItem>
}

export interface SensorValueItem {
    uuid: string;
    channelUuid: string;
    value: number;
}

export interface FeatureValueItem {
    uuid: string;
    measurementTimestamp: number
    measurementChart:string
    featureUuid: string;
    featureName: string;
    featureValue: number;
    featureUT?: number;
    featureOT?: number;
    featureNominal?: number;
    featureDecimalPlaces: number;
    sensors: SensorValueItem[];
    featureHistoryTimestamp?: number;
    featureIsRequired?: boolean;
    featureMaster?: number;
    featureWarnUT?: number;
    featureWarnOT?: number;
    featureType?: string;
    featureDiameterType?: string;

}

export interface FeatureValueExportItem extends FeatureValueItem {
    featureHistoryUuid: string
}

export interface MeasurementExportItem extends MeasurementItem {
    historyDesignNumber: string;
    historyPartNumber: string;
    
    partHistoryTimestamp: number;
    partHistoryId: string;
    partHistoryName: string;
    
    workbenchHistoryName:string;
    workbenchHistoryUuid: string;
    workbenchHistoryTimestamp: number;
    
    nestHistoryName: string;
    nestHistoryUuid: string;
    nestHistoryTimestamp: number;


    featurevalues: FeatureValueExportItem[];
}

export interface MeasurementItem {
    uuid: string;
    workbenchUuid: string;
    workbenchName: string;
    nestUuid: string;
    nestName: string;
    partId: string;
    partName: string;
    imageUrl: string;
    featurevalues: FeatureValueItem[];
    measurementTimestamp: number;
    data: string
}

export interface MeasurementFlatItem {
    partUuid: string;
    partHistoryId: string;
    workbenchUuid: string;
    workbenchHistoryUuid: string;
    workbenchName: string;
    nestUuid: string;
    nestHistoryUuid: string;
    nestName: string;
    partId: string;
    partName: string;
    imageUrl: string;
    measurementUuid: string;    // contains multiple measurements
    uuid: string;               // single measurement on single feature
    measurementTimestamp: number
    measurementChart:string
    featureUuid: string;
    featureHistoryUuid: string;
    featureName: string;
    featureValue: number;
    featureUT?: number;
    featureOT?: number;
    featureNominal?: number;
    featureDecimalPlaces: number;
    data: string
    attsHistory: string
}

export interface MeasurementChartItem {
    attributeUuid: string;
    measurementUuid: string;
    uuid: string;
    measurementChart: string
}

export interface QueryResultInterface<T> {
    items : T[];
    count: number;
    firstDate: number;
    lastDate: number;
    lastPage: number;
    fromDate: string;
    toDate: string;
    skip: number;
    take: number;
    workbenches: UuidAndName[];
    parts: UuidAndName[];
}

export interface UuidAndName {
    uuid: string
    name: string
}

// Describing the different ACTION NAMES available
export const LOAD_STATISTICS = "LOAD_STATISTICS";
export const ADD_MEASUREMENT ="ADD_MEASUREMENT"
export const DELETE_MEASUREMENT ="DELETE_MEASUREMENT"

interface LoadStatisticsAction {
    type: typeof LOAD_STATISTICS;
    payload: QueryResultInterface<MeasurementItem> //MeasurementItem[];
}

interface DeletMeasurementAction {
    type: typeof DELETE_MEASUREMENT;
    payload: string //MeasurementItem;
};

export type PartHistory = PartItem & {
    partId: string;
}

export type PartHistoryExport = {
    partUuid: string;
    partHistoryUuid: string;
    name: string;
    partNumber: string;
    designNumber: string;
    timestamp: number;
    timestampUntil?: number
  };

export type AttributeHistory = {
    uuid: string;
    isDeleted: boolean;
    attributeUuid: string;
    isRequired: boolean;
    name: string;
    isDraft: boolean;
    masterSize: number;
    upperWarnLimit?: number | null ;
    lowerWarnLimit?: number | null;
    withWarnlimits: boolean;
    sensorsCount: number;
    angleP1: number;
    angleP2: number;
    angleP3: number;
    ot: number;
    nominalSize: number;
    ut: number;
    type: string;
    setting: string;
    decimalPlaces: number;
    referenceTimestamp: number;
    isReferenced: boolean;
    measurementTimestamp: number;
    measurementChart: string;
    dataQdas: string;
    measurementValue: number;
    sensor_1_name: string;
    sensor_1_isReferenced: boolean;
    sensor_1_channelId?: number;
    sensor_1_referenceValue: number;
    sensor_1_measurementValue: number;
    sensor_1_isInvers: boolean;
    sensor_1_referenceFactor: number;
    sensor_2_name: string;
    sensor_2_isReferenced: boolean;
    sensor_2_channelId?: number;
    sensor_2_referenceValue: number;
    sensor_2_measurementValue: number;
    sensor_2_isInvers: boolean;
    sensor_2_referenceFactor: number;
    sensor_3_name: string;
    sensor_3_isReferenced: boolean;
    sensor_3_channelId?: number;
    sensor_3_referenceValue: number;
    sensor_3_measurementValue: number;
    sensor_3_isInvers: boolean;
    sensor_3_referenceFactor: number;
    timestamp: number;
    pocketConfigFinished: boolean;
    partId? : string;
}

export type AttributeHistoryExport = {
    attributeUuid: string;
    attributeHistoryUuid: string;
    partId: string;
    name: string;
    isRequired: boolean;
    upperThreshold: number;
    lowerThreshold: number;
    nominalValue: number;
    masterValue: number;
    lowerWarnValue?: number;
    upperWarnValue?: number;
    significantDecimals: number
    type: string;
    diameterType: string;
    timestamp: number;
    timestampUntil?: number;

    angleP1: number
    angleP2: number
    angleP3: number
    sensorsCount: number
    referenceTimestamp: number
    sensor1name: string
    sensor2name: string
    sensor3name: string
    sensor1channelId?: number
    sensor2channelId?: number
    sensor3channelId?: number
    sensor1referenceValue: number
    sensor2referenceValue: number
    sensor3referenceValue: number
    sensor1isInverse: boolean
    sensor1referenceFactor: number
    sensor2isInverse: boolean
    sensor2referenceFactor: number
    sensor3isInverse: boolean
    sensor3referenceFactor: number
  };

export type MachineHistory = MachineItem & {
    workbenchUuid: string;
}

export type WorkbenchHistoryExport = {
    workbenchUuid: string;
    workbenchHistoryUuid: string;
    name: string;
    timestamp: number;
    timestampUntil?: number
};

export type NestHistory = NestItem & {
    nestUuid: string;
}

export type NestHistoryExport = {
    nestUuid: string;
    nestHistoryUuid: string;
    name: string;
    timestamp: number;
    timestampUntil?: number
  };

export type StatisticsActionTypes = LoadStatisticsAction | DeletMeasurementAction

export type ChannelHistory = ChannelItem & {
    channelUuid: string;
}