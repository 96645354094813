import { MACHINE_DEFAULT, MACHINES_DEFAULT, NESTS_DEFAULT, NEST_DEFAULT } from './../../../constants/lang/translation-keys';
import axios from "axios";
import { serviceApi } from "../../../api/service-api";
import { defaultNamingObject, namingObjectType } from './naming-types';
import i18n from '../../../constants/i18n';


namespace NamingHelper {

    export async function loadNaming( isDev?: boolean ){
        let namingTemp: namingObjectType = { ...defaultNamingObject };
        let naming: namingObjectType = { ...defaultNamingObject };

        if (isDev) {
            try {
                const uri = `http://localhost:5100/naming`;
                const responseData = await (await axios.get(uri)).data;
                namingTemp = responseData.data.naming;
            } catch (error) {
                console.error('loadNaming isDev error: ', error);
            }
            
        } else {
            try {
                const uri = `/naming`;
                const responseData = await (await serviceApi.client.get(uri)).data;
                namingTemp = responseData.data.naming;
                if ( typeof namingTemp === 'string' ) {
                    namingTemp = JSON.parse(responseData.data.naming);
                }
            } catch (error) {
                console.error('loadNaming NoDev error: ', error);
            }
        }

        // this is to prevent the namingObject to have unwanted artifacts as properties
        naming.machine = namingTemp.machine;
        naming.machines = namingTemp.machines;
        naming.nest = namingTemp.nest;
        naming.nests = namingTemp.nests;
        
        return naming;
    }

    export async function setNaming( naming: namingObjectType ) {

        // const transFormNaming = transformNamingObject(naming);
        let response = null;
        const uri = `/naming/json`;
        try {
            const json = JSON.stringify(naming);
            response = await serviceApi.client.post(uri, { json });
        } catch (error) {
            console.log('setNaming error: ', error);
        }
        return response;
    }

    export const getNaming = ( key: string, namingObject: namingObjectType ) => {

        const naming = { ...namingObject };

        if (!namingObject.machine) {
            naming.machine = i18n.t(MACHINE_DEFAULT);
        }
        if (!namingObject.machines) {
            naming.machines = i18n.t(MACHINES_DEFAULT);
        }
        if (!namingObject.nest) {
            naming.nest = i18n.t(NEST_DEFAULT);
        }
        if (!namingObject.nests) {
            naming.nests = i18n.t(NESTS_DEFAULT);
        }

        return i18n.t( key, naming );
    }

}

export default NamingHelper;