export type PopUpWrapperType = {
    onClose: ()=>void
    children: any
    title: string
    check?: boolean
}

export const PopUpWrapper = ({ onClose, children, title, check }:PopUpWrapperType) => {
    const buttonCss = check ? 'btn btn--icon btn--icon-check btn--negative ml-auto' : 'btn btn--icon btn--icon-close btn--action-dark ml-auto'
    return (
        <div className='react-confirm-content w-100'>
            <div className="d-flex align-items-center mb-3">
                <h2 className="hsmall strong text-uppercase mb-0">{title}</h2>
                <button onClick={onClose} className={buttonCss} ></button>
            </div>
            {children}
        </div>
    )
}

export default PopUpWrapper;
