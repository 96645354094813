import React from 'react';
import { NestItem } from '../../store/machines/types';

interface NestProps {
    nest: NestItem
    onChangeHandler: any
    onDeleteHandler: any
    onBlurFocus: () => void
    show : boolean
    nestNaming: string
}

class Nest extends React.Component<NestProps> {
    render() {

        const { nestNaming, show, nest, onChangeHandler, onDeleteHandler, onBlurFocus } = this.props;
        
        const showBtn = show ? '' : 'd-none'
        const inputCss = show ? ' mr-3' : ' nest__input--alone'

        const label = nestNaming + ' Name';

        return(
            <div className="nest mb-2">
                <label htmlFor="nest" className="d-block mb-2">{label}</label>
                <div className="nest__content">
                    <input 
                        type="text" 
                        name="nest"
                        id="nest"
                        value={ nest.name }  
                        onChange= { (e:any) => {onChangeHandler(e)}}
                        placeholder={label}
                        className={'input-blue nest__input' + inputCss }
                        onFocus={ () => onBlurFocus() }
                        onBlur={ () => onBlurFocus() }
                    />
                    <button 
                        onClick={(e:any) => {onDeleteHandler(e, nest.uuid)}} 
                        className={ 'btn btn--icon btn--icon-close bg-action-dark nest__btn ' + showBtn }
                    ></button>
                </div>
            </div>
        );
    };
}

export default Nest;