import { ChannelItem } from "../../../store/channels/types";

export type RaspInvertFormProps = {
    channels: ChannelItem[];
    overlayToggle: any;
    thunkUpdateChannel: any
}

export type RaspInvertFormState = {
    realtimeChannels: number[],
    channelsFactorAV:string[],
    channelsFactorNP:string[],
    initialGet: boolean,
    upperSetValInput: string,
    nullSetValInput: string,
    lowerSetValInput: string,
    channelsCalibValues: channelsCalibValuesProps[]
}

export enum RaspDeviceMessageEnum {
    VERSION = 'version',
    START = 'start',
    STOP = 'stop',
    REFRESH_OPTIONS = 'refresh',
    GET_OPTIONS = 'getoptions',
    SET_OPTIONS = 'setoptions',
    GET_DATETIME = 'getdatetime',
    SET_DATETIME = 'setdatetime',
}

export type channelsCalibValuesProps = { upper: number | null, nullP: number | null, lower: number | null, av: number | null, np: number | null };

export const channelsCalibValuesInitSingle: channelsCalibValuesProps = { upper: null, nullP: null, lower: null, av: null, np: null };

export const channelsCalibValuesInit = (): channelsCalibValuesProps[] => Array.from( Array(8), ()=>({...channelsCalibValuesInitSingle}) );

export const channelsFactorInit = ['','','','','','','',''];

export const raspInvertFormStateInit: RaspInvertFormState = {
    realtimeChannels: [],
    channelsFactorAV: channelsFactorInit,
    channelsFactorNP: channelsFactorInit,
    initialGet: true,
    upperSetValInput: '1.0000',
    nullSetValInput: '0.0000',
    lowerSetValInput: '-1.0000',
    channelsCalibValues: channelsCalibValuesInit()
}