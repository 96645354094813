import React from 'react';
import { defaultNamingObject } from '../../components/settings/naming/naming-types';
import { NamingContextType } from './naming-context-types';

const condtextDefaultValue: NamingContextType = { naming: defaultNamingObject, setNaming: ()=>{} };

const Context = React.createContext(condtextDefaultValue);

const Provider = (props: any) => {

    const [naming, setNaming] = React.useState(condtextDefaultValue.naming);

    return (
        <Context.Provider value={{naming, setNaming}}>
            {props.children}
        </Context.Provider>
    )

}

const exportObj = { Context, Provider }; // fix waring: "Assign object to a variable before exporting as module default import/no-anonymous-default-export"
export default exportObj;