import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StatisticsEmptyView = () => {
    return (
        <>
            <Translation>{t =>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="h3">{t('NoMeasurementsYet')}</h2>
                                <Link to={'/'}>
                                    <span className="btn d-inline-block">{t('FirstMeasurement')}</span>
                                </Link>
                            </div>
                        </div>
                    </div>
            }</Translation>
        </>
    )
}

export default StatisticsEmptyView;

