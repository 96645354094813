import { TFunction, Translation } from "react-i18next"

import android_1_settings_de from "../../img/onboarding/android/android_1_settings_de.jpg"
import android_1_settings_en from "../../img/onboarding/android/android_1_settings_en.jpg"
import android_2_security_de from "../../img/onboarding/android/android_2_security_de.jpg"
import android_2_security_en from "../../img/onboarding/android/android_2_security_en.jpg"
import android_3_others_de from "../../img/onboarding/android/android_3_others_de.jpg"
import android_3_others_en from "../../img/onboarding/android/android_3_others_en.jpg"
import android_4_install_from_storage_de from "../../img/onboarding/android/android_4_install_from_storage_de.jpg"
import android_4_install_from_storage_en from "../../img/onboarding/android/android_4_install_from_storage_en.jpg"
import android_5_choose_type_de from "../../img/onboarding/android/android_5_choose_type_de.jpg"
import android_5_choose_type_en from "../../img/onboarding/android/android_5_choose_type_en.jpg"
import android_6_warning_de from "../../img/onboarding/android/android_6_warning_de.jpg"
import android_6_warning_en from "../../img/onboarding/android/android_6_warning_en.jpg"
import android_7_choose_folder_de from "../../img/onboarding/android/android_7_choose_folder_de.jpg"
import android_7_choose_folder_en from "../../img/onboarding/android/android_7_choose_folder_en.jpg"
import android_8_choose_file_de from "../../img/onboarding/android/android_8_choose_file_de.jpg"
import android_8_choose_file_en from "../../img/onboarding/android/android_8_choose_file_en.jpg"
import android_manual_install_1 from "../../img/onboarding/android/android_install_manually_1.png"
import android_manual_install_2_de from "../../img/onboarding/android/android_install_manually_2_de.png"
import android_manual_install_2_en from "../../img/onboarding/android/android_install_manually_2_en.png"

import macos_1_choose_cert_de from "../../img/onboarding/macOS/macos_1_choose_cert_de.png"
import macos_1_choose_cert_en from "../../img/onboarding/macOS/macos_1_choose_cert_en.png"
import macos_2_trust_cert_de from "../../img/onboarding/macOS/macos_2_trust_cert_de.png"
import macos_2_trust_cert_en from "../../img/onboarding/macOS/macos_2_trust_cert_en.png"

import win_open_file_de from "../../img/onboarding/windows/win_1_open_file_de.png"
import win_open_file_en from "../../img/onboarding/windows/win_1_open_file_en.png"
import win_certificate_install_de from "../../img/onboarding/windows/win_2_certificate_install_de.png"
import win_certificate_install_en from "../../img/onboarding/windows/win_2_certificate_install_en.png"
import win_welcome_de from "../../img/onboarding/windows/win_3_welcome_de.png"
import win_welcome_en from "../../img/onboarding/windows/win_3_welcome_en.png"
import win_storage_de from "../../img/onboarding/windows/win_4_storage_de.png"
import win_storage_en from "../../img/onboarding/windows/win_4_storage_en.png"
import win_cert_auth_de from "../../img/onboarding/windows/win_5_cert_auth_de.png"
import win_cert_auth_en from "../../img/onboarding/windows/win_5_cert_auth_en.png"
import win_confirm_de from "../../img/onboarding/windows/win_6_confirm_de.png"
import win_confirm_en from "../../img/onboarding/windows/win_6_confirm_en.png"

import ios_1_dowload_allow_de from "../../img/onboarding/iOS/ios_1_dowload_allow_de.png"
import ios_1_dowload_allow_en from "../../img/onboarding/iOS/ios_1_dowload_allow_en.jpeg"
import ios_2_confirm_load_de from "../../img/onboarding/iOS/ios_2_confirm_load_de.png"
import ios_2_confirm_load_en from "../../img/onboarding/iOS/ios_2_confirm_load_en.jpeg"
import ios_3_1_settings from "../../img/onboarding/iOS/ios_3_1_settings.png"
import ios_3_general_de from "../../img/onboarding/iOS/ios_3_general_de.png"
import ios_3_general_en from "../../img/onboarding/iOS/ios_3_general_en.jpeg"
import ios_4_profile_de from "../../img/onboarding/iOS/ios_4_profile_de.png"
import ios_4_profile_en from "../../img/onboarding/iOS/ios_4_profile_en.jpeg"
import ios_5_choose_profile_de from "../../img/onboarding/iOS/ios_5_choose_profile_de.png"
import ios_5_choose_profile_en from "../../img/onboarding/iOS/ios_5_choose_profile_en.jpeg"
import ios_6_install_profile_de from "../../img/onboarding/iOS/ios_6_install_profile_de.png"
import ios_6_install_profile_en from "../../img/onboarding/iOS/ios_6_install_profile_en.jpeg"
import ios_7_enter_code_de from "../../img/onboarding/iOS/ios_7_enter_code_de.jpeg"
import ios_7_enter_code_en from "../../img/onboarding/iOS/ios_7_enter_code_en.jpeg"
import ios_8_install_warning_de from "../../img/onboarding/iOS/ios_8_install_warning_de.png"
import ios_8_install_warning_en from "../../img/onboarding/iOS/ios_8_install_warning_en.jpeg"
import ios_9_profile_verified_de from "../../img/onboarding/iOS/ios_9_profile_verified_de.jpeg"
import ios_9_profile_verified_en from "../../img/onboarding/iOS/ios_9_profile_verified_en.jpeg"
import { template } from "./certificate";

import { confirmAlert } from "react-confirm-alert";

export const PopUpIsDownloaded = ({onClose}:{ onClose: ()=>void}) => {
    return (
        <Translation>{t =>
            <div className='react-confirm-content w-100'>
                <div className="row">
                    <div className="col-12 pt-5 pb-5 text-left">
                        <p style={{ textAlign: 'center', fontWeight:'bolder'}} >{t('Please check download folder')}</p>
                    </div>
                    <div className="col-6 ml-auto mr-auto">
                        <button type="button" className="btn btn--white w-100" onClick={onClose}>{t('close')}</button>
                    </div>
                </div>
            </div>}
        </Translation>
    )
}

export const downloadCertificate = () => {
    const downloadFile = async (cert:string) => {
        const fileName = 'promess_ca.crt';
        const blob = new Blob([cert], { type: 'application/pkix-cert' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const buffer = Buffer.from(template, "base64");
    downloadFile(buffer.toString())
    .then(()=>{
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <PopUpIsDownloaded onClose={onClose}/>
              );
            },
          });
    });
}

export const getAndroidOnboarding = (language:string, t: TFunction<string>) => {
    return (
        <>
            <h2 className="h3">{t('android - certificate-installation')}</h2>
            <ol>
                <li>
                    <p>{t('download certificate')}</p>
                </li>
                <li>
                    <p>{t('android - choose settings')}</p>
                    <img style={{width:'100px'}} src={language === 'de' ? android_1_settings_de : android_1_settings_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - choose security')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_2_security_de : android_2_security_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - choose others')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_3_others_de : android_3_others_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - install from storage')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_4_install_from_storage_de : android_4_install_from_storage_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - choose type')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_5_choose_type_de : android_5_choose_type_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - warning')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_6_warning_de : android_6_warning_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - enter pin')}</p>
                </li>
                <li>
                    <p>{t('android - choose folder')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_7_choose_folder_de : android_7_choose_folder_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - choose file')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? android_8_choose_file_de : android_8_choose_file_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('android - go back to browser')}</p>
                </li>
            </ol>
        </>
    )
}

export const getIosOnboarding = (language:string, t: TFunction<string>) => {
    return (
        <>
            <h2 className="h3">{t('ios - certificate-installation')}</h2>
            <ol>
                <li>
                    <p>{t('download certificate')}</p>
                    {/* <a  className="btn btn--white d-inline-block" target="_blank" href={AppConfig.certificate.downloadUrl}>Download</a> */}
                </li>
                <li>
                    <p>{t('ios - download_allow')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_1_dowload_allow_de : ios_1_dowload_allow_en} alt="1_first_confirm" />
                </li>
                <li>
                    <p>{t('ios - confirm_load')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_2_confirm_load_de : ios_2_confirm_load_en} alt="2_confirm_load" />
                </li>
                <li>
                    <p>{t('ios - settings')}</p>
                    <img style={{width:'100px'}} src={ios_3_1_settings} alt="3_general" />
                </li>
                <li>
                    <p>{t('ios - general')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_3_general_de : ios_3_general_en} alt="3_general" />
                </li>
                <li>
                    <p>{t('ios - profile')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_4_profile_de : ios_4_profile_en} alt="4_profile" />
                </li>
                <li>
                    <p>{t('ios - choose_profile')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_5_choose_profile_de : ios_5_choose_profile_en} alt="5_choose_profile" />
                </li>
                <li>
                    <p>{t('ios - install_profile')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_6_install_profile_de : ios_6_install_profile_en} alt="6_install_profile" />
                </li>
                <li>
                    <p>{t('ios - enter_code')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_7_enter_code_de : ios_7_enter_code_en} alt="7_install_profile_2" />
                </li>
                <li>
                    <p>{t('ios - install_warning')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_8_install_warning_de : ios_8_install_warning_en} alt="7_install_profile_2" />
                </li>
                <li>
                    <p>{t('ios - profile_verified')}</p>
                    <img style={{width:'200px'}} src={language === 'de' ? ios_9_profile_verified_de : ios_9_profile_verified_en} alt="7_install_profile_2" />
                </li>
            </ol>
        </>
    )
}

export const getMacOsOnboarding = (language:string, t: TFunction<string>) => {
    return (
        <>
            <h2 className="h3">{t('macos - certificate-installation')}</h2>
            <ol>
                <li>
                    <p>{t('download certificate')}</p>
                    {/* <a  className="btn btn--white d-inline-block" target="_blank" href={AppConfig.certificate.downloadUrl}>Download</a> */}
                </li>
                <li><p>{t('macos - open_downloads')}</p></li>
                <li>
                    <p>{t('macos - search_for_cert')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? macos_1_choose_cert_de : macos_1_choose_cert_en} alt="macOsChooseCert" />
                </li>
                <li>
                    <p>{t('macos - open_and_trust_cert')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? macos_2_trust_cert_de : macos_2_trust_cert_en} alt="macOsTrustCert" />
                </li>
            </ol>

        </>
    )
}

export const getWindowsOnboarding = (language:string, t: TFunction<string>) => {
    return (
        <>
            <h2 className="h3">{t('win - certificate-installation on windows')}</h2>
            <ol>
                <li>
                    <p>{t('download certificate')}</p>
                    {/* <a  className="btn btn--white d-inline-block" target="_blank" href={AppConfig.certificate.downloadUrl}>Download</a> */}
                </li>
                <li>
                    <p>{t('win - open certificate-file')}</p> 
                    <img style={{width:'400px'}} src={language === 'de' ? win_open_file_de : win_open_file_en} alt="open_file" />
                </li>
                <li>
                    <p>{t('win - install certificate')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? win_certificate_install_de : win_certificate_install_en} alt="certificate_install" />
                </li>
                <li>
                    <p>{t('win - choose current user')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? win_welcome_de : win_welcome_en} alt="welcome" />
                </li>
                <li>
                    <p>{t('win - choose storage')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? win_storage_de : win_storage_en} alt="storage" />
                </li>
                <li>
                    <p>{t('win - choose trusted authorities')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? win_cert_auth_de : win_cert_auth_en} alt="storage" />
                </li>
                <li>
                    <p>{t('win - finish certificate installation')}</p>
                    <img style={{width:'400px'}} src={language === 'de' ? win_confirm_de : win_confirm_en} alt="confirm" />
                </li>
                <li>
                    <p>{t('win - final restart')}</p>
                </li>
            </ol>
        </>
    )
}

export const getAndroidManualInstallation = (language:string, t: TFunction<string>) => {
    return (
        <>
            <h2 className="h3">{t('android - manual install')}</h2>
            <ol>
                <li>
                    <p>{t('android - manual install 1')}</p>
                    <img style={{width:'400px'}} src={android_manual_install_1} alt="open_file" />
                </li>
                <li>
                    <p>{t('android - manual install 2')}</p> 
                    <img style={{width:'400px'}} src={language === 'de' ? android_manual_install_2_de : android_manual_install_2_en} alt="open_file" />
                </li>
            </ol>
        </>
    )
}