import { RaspInvertFormState } from "./types";

export function isInputValid(newInput:string){
    let inputValid = true;
    let pointCounter = 0;
    for (let i = 0; i < newInput.length; i++) {
        const inpChar = newInput[i];
        if( !['1','2','3','4','5','6','7','8','9','0','.','-'].find(c=>c===inpChar) ) inputValid = false;
        if( inpChar==='.') pointCounter++;
    }
    if(pointCounter>1) inputValid = false;

    return inputValid;
}

export function handleSetValueButton(sv: 'upper'|'nullP'|'lower', index: number, state: RaspInvertFormState ){
        // console.log('handleSetValueButton: ', sv, index, state);

        const { 
            channelsCalibValues,
            realtimeChannels,
            upperSetValInput,
            nullSetValInput,
            lowerSetValInput,
        } = state;

        const newCalibVals = [...channelsCalibValues];
        const newCalObj = newCalibVals[index];

        // check input fields are valid
        const inputValid = upperSetValInput && nullSetValInput && lowerSetValInput;

        if (inputValid) {
            switch (sv) {
                 case 'upper':
                    newCalibVals[index].upper = realtimeChannels[index]
                     break;
                 case 'nullP':
                    newCalibVals[index].nullP = realtimeChannels[index]
                     break;
                 case 'lower':
                    newCalibVals[index].lower = realtimeChannels[index]
                     break;
                 default:
                     break;
             }
             if (newCalibVals[index].upper && newCalibVals[index].nullP && newCalibVals[index].lower) {
                //  caclulate new av and np
                 const upper = newCalibVals[index].upper ?? 999
                 const nullP = newCalibVals[index].nullP ?? 100
                 const lower = newCalibVals[index].lower ?? -999
                 
                 const factor = ( Number(upperSetValInput) - Number(lowerSetValInput) ) / ( upper - lower );
                 const offset = ( -1 * nullP + Number(nullSetValInput) ) * factor;

                 newCalibVals[index].av = factor;
                 newCalibVals[index].np = offset;
             }
         }
         newCalibVals[index] = newCalObj;
        //  console.log('newCalibVals: ', newCalibVals)
         return newCalibVals
    }