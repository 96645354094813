export enum QdasType {
    PART = 'PART',
    ATTRIBUTE = 'ATTRIBUTE'
}

type qdasMandatoryRowType = { key: string, type: QdasType, position: number, default: string };
export const qdasMandatoryRow: qdasMandatoryRowType[] = [ { key: 'K1004', type: QdasType.PART, position: 3, default: '0' } ];

type qdasLengthCatalogType = { key: string, maxLength: number, type: QdasType };
export const qdasLengthCatalog: qdasLengthCatalogType[] = [ { key: 'K1004', maxLength: 20, type: QdasType.PART}, { key: 'K2002', maxLength: 20, type: QdasType.ATTRIBUTE}, { key: 'K2003', maxLength: 80, type: QdasType.ATTRIBUTE} ];
