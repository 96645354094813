import React from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { Switch, Route } from 'react-router';
import { cloneDeep } from 'lodash';

// Components
import Header from '../layout/header/Header';
import Notifications from '../ui/Notifications';
import LanguageSwitch from '../ui/LanguageSwitch';
import DeviceStatus from '../device-status/DeviceStatus';
import Slider from '../ui/Slider/Slider';
import Parts from '../parts/Parts';
import Settings from '../settings/Settings';
import Attributes from '../attributes/Attributes';
import Machines from '../machines/Machines';
// import Statistics from '../statistics/Statistics';
import StatisticsNew from '../statistics/Statistics';
import { getPartLabel } from '../parts/partHelpers';

// Store
import { SystemState, UsedUnitsEnum  } from '../../store/system/types';
import { AppState } from '../../store/reducers';
import { getPart } from '../../store/parts/selectors';
import { updateSession, toggleSlider, overlayClose } from '../../store/system/action';
import { PartItem, PartsState } from '../../store/parts/types'
import { RaspState } from '../../store/rasp/types';
import { AttributeItem } from '../../store/attributes/types';
import { NamingContextType } from '../../context/naming/naming-context-types';
import NamingHelper from '../settings/naming/naming-helper';
import { FEATURES, MACHINES, MEASURE, PARTS, SETTINGS, STATISTICS } from '../../constants/lang/translation-keys';
import ConnectionHelper from '../../api/connection-helper';
import { showCertificateAlert } from '../device-status/deviceStatusFunctions';

type MyProps = {
    system: SystemState
    rasp: RaspState
    parts: PartsState
    history: any
    attributes: AttributeItem[]
    updateSession: typeof updateSession
    toggleSlider: typeof toggleSlider
    overlayClose: typeof overlayClose
    getPart: any
    namingContext: NamingContextType
};

type MyState = {
    title: string
    type: string
}




class Qumo extends React.Component<MyProps,MyState> {
    state: MyState = {
        title: this.getTitle(),
        type: 'app__content--default',
    }

    componentDidMount() {

        const { history, system, overlayClose, toggleSlider, updateSession } = this.props;

        //mode color
        this.setModeColor()

        //if user doesnt have permission to a page lets redirect him
        this.redirectNotAllowedUsers()
        
        // type for content type (we have different header types we need some cases to target css)
        this.evaluateType(history.location)

        //listen to history/route changes
		history.listen( (location:any, action:any) => {

            //close any overlay
            overlayClose()

            // type for content type (we have different header types we need some cases to target css)
            this.evaluateType(location)    

            //deactivate the slider always when its opened
            system.slider.show && toggleSlider()

            // on mobile close the menu
            if(system.showMenu){
                const newState = cloneDeep(system)
                newState.showMenu = false
                updateSession(newState)
            }

            //update the title
            this.setState({
                title: this.getTitle()
            })

        })

        // show pop-up if certificate expires with in the defined day ranges
        ConnectionHelper.getCertificateInfo()
        .then( certInfoObj =>showCertificateAlert(certInfoObj));
    }

    componentDidUpdate(prevProps:MyProps){
        if(prevProps.system.settings.appDarkMode !== this.props.system.settings.appDarkMode){
            this.setModeColor()
        }
        if(this.props.system.overlay.main.show !== prevProps.system.overlay.main.show ){
            this.setState({
                title : this.getTitle()
            })
            this.markAppOverlay()
        }
    }

    // UI
    setModeColor(){
        if(this.props.system.settings.appDarkMode){
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
        } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.add('light-mode');
        }
    }

    getTitle(): string {        
        let key = '';
        const { pathname } = this.props.history.location;

        switch (pathname) {
            case '/':
                key = MEASURE;
                break;
            case '/statistics':
                key = STATISTICS;
                break;
            case '/parts':
                key = PARTS;
                break;
            case '/part-edit':
                key = FEATURES;
                // we will show the part label in the status if editForm is opened
                if(this.props.system.overlay.main.show){
                    let idEvaluated = pathname.split('/part-edit/')[1] ? pathname.split('/part-edit/')[1] : this.props.parts.items[0]?.id
                    if(localStorage.getItem('promess-current-partId') && this.props.getPart(localStorage.getItem('promess-current-partId'))){
                        idEvaluated = localStorage.getItem('promess-current-partId')
                    }
                    const currentPart:PartItem = this.props.getPart(idEvaluated)

                    key = getPartLabel(currentPart)
                }
                break;
            case '/machines':
                 key = MACHINES;
                break;
            case '/features':
                key = FEATURES
                break;
            case '/settings':
                key = SETTINGS;
                break;
        
            default:
                break;
        }
        return key;
    }

    closeSidebarOnMobile(){
        if(this.props.system.showMenu) this.navToggle()
    }

    navToggle(){
        let updatedSystem: SystemState = {
            ...this.props.system
        }
        updatedSystem.showMenu = !updatedSystem.showMenu
        this.props.updateSession(updatedSystem)
    }
    
    markAppOverlay(){
        if(this.props.system.overlay.main.show){
            document.querySelector('body')?.classList.add('overflow-y-scroll')
        } else {
            document.querySelector('body')?.classList.remove('overflow-y-scroll')
        }
    }

    redirectNotAllowedUsers(){
        const isAdvancedMode = this.props.system.settings.advancedMode
        if(
            !isAdvancedMode && (
            this.props.history.location.pathname.includes('parts') ||
            this.props.history.location.pathname.includes('machines') ||
            this.props.history.location.pathname.includes('part-edit'))
        ){
            setTimeout(() => {
                this.props.history.push('/')
            }, 500);
        }
    }

    evaluateType(location:any){
        if(!location.pathname) return
        
        if(
            location.pathname.includes('part/') || 
            location.pathname.includes('part-edit') ||
            location.pathname === '/'
        ){
            this.setState({
                type: 'app__content--attribute'
            })
        } else if(
            location.pathname.includes('machines') ||
            location.pathname.includes('parts')
        ){
        } else if(
            location.pathname.includes('statistics')
        ){
            this.setState({
                type: 'app__content--statistics'
            })
        } else {
            this.setState({
                type: 'app__content--default'
            })
        }
    }


    render() {
        const { 
            appName, 
            userName, 
            overlay,
            slider,
        } = this.props.system;

        const {
            type,
            title,
        } = this.state;

        const appLoader:string = this.props.system.loading.attributes ? ' app--isLoading' : ''
        
        // spotlight for the slider
        const spotlightCss = slider.show ? 'slider__spot-light--visible ' : ''

        // is sidebar opened on mobile wrap the whole app
        const isSidebarOpened = this.props.system.showMenu

        //is dropdown overlay opened? 
        const dropdownCss = this.props.system.overlay.dropdown.show ? `app--dropdown app--dropdown-${this.props.system.overlay.dropdown.name} ` : ''

        const overlay_1 = overlay.main.show ? `app__overlay app__overlay--show${ this.props.system.overlay.main.positionFixed ? ' fixed' : '' }` : 'app__overlay'
        const overlayCss = this.props.history.location.pathname.includes('part-edit') ? ' app--part-edit ' : ''
        const sliderCss = slider.show ? ' app--sliderActive ' : ''

        const { naming } = this.props.namingContext;
        const displayTitle = NamingHelper.getNaming( title, naming );

        return (
                <Translation>{ t => (
                    <div className={ 'app ' + dropdownCss + overlayCss + sliderCss + appLoader}>

                        <Header 
                            appName={ appName } 
                            currentUser={userName} 
                            show = { isSidebarOpened }
                            namingContext={this.props.namingContext}
                        />

                        { 
                            //this will take care of the mobile sidebar and its toggling if clicked into the content
                            isSidebarOpened && 
                            <div 
                                className="app__spotlight--sidebar" 
                                onClick={() => {this.closeSidebarOnMobile()}}
                            ></div> 
                        }

                        <div className="nav__opener" onClick={() => {this.navToggle()}}></div>

                        <div className={ 'app__content ' + type }>
                            <Notifications 
                                show = {overlay.validation.show} 
                                notifications = { overlay.validation.notification }
                            />
                            <div className="app__status">
                                <div className="container-fluid d-flex align-items-center">
                                    <h3 className="hsmall strong mb-0 text-uppercase d-none d-md-block">
                                        { displayTitle }
                                    </h3>
                                    <div className="ml-auto">
                                        <LanguageSwitch />
                                    </div>
                                    <span className="ml-2 badge badge--fluid badge--secondary strong text-uppercase hsmall">
                                        {this.props.system.settings.usedUnits === UsedUnitsEnum.MM ? t('Millimeter') : t('Inch')}
                                    </span>
                                    <DeviceStatus urlToQumo={this.props.system.hostEndpoint} isPopUpEnabled={this.props.system.settings.advancedMode}/> 
                                </div>
                            </div>
                            <Switch>
                                <Route exact path="/" render={(props) => <Attributes {...props} namingContext={this.props.namingContext} />} />
                                <Route exact path="/parts" component={Parts} />
                                <Route path="/part/:id" render={(props) => <Attributes {...props} namingContext={this.props.namingContext} />}  />
                                <Route path="/part-edit/:id" render={(props) => <Attributes {...props} namingContext={this.props.namingContext} />} />
                                <Route path="/part-edit" render={(props) => <Attributes {...props} namingContext={this.props.namingContext} />}  />
                                <Route path="/settings" component={Settings} />                           
                                <Route path="/machines" component={Machines} />
                                <Route path="/statistics" component={StatisticsNew} />
                            </Switch>
                        </div>

                        {/* attribute reference slider */}
                        {
                            slider.show && 
                            <Slider 
                                show={slider.show} 
                                items = { this.props.attributes }
                            /> 
                        }
                        
                        <div className={spotlightCss + 'slider__spot-light'}></div>

                        {
                        //*******************************************************
                        // Overlays 
                        //*******************************************************
                        }
                            <div className={ overlay_1 }>{ overlay.main.component }</div>
                            

                    </div>
                )}</Translation>
            );
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    rasp: state.rasp,
    parts: state.parts,
    attributes: state.attributes.items,
    getPart: getPart(state),
});

const dispatchToProps = {
    updateSession, 
    toggleSlider,
    overlayClose
};

export default connect(mapStateToProps, dispatchToProps)(Qumo)