import React from 'react';
import { Translation } from 'react-i18next';
import { getChannelsAll } from '../../../store/channels/selectors';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import RaspChannel from './RaspChannel';
import { ChannelItem, MeasureObject } from '../../../store/channels/types';
import RadioToggleNaked from '../RadioToggleNaked';
import { Subscription } from 'rxjs';
import { MessageContext } from '../../../context';
import { overlayPositionToggle, overlayToggle } from '../../../store/system/action';
import { thunkUpdateChannel } from '../../../store/channels/thunks';
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep';
import { SystemState, UsedDelimiterEnum, UsedUnitsEnum } from '../../../store/system/types';
import LoadingOverlay from '../LoadingOverlay';
import { thunkGetAttributesByPartId, thunkResetAttributeRefByChannel } from '../../../store/attributes/thunks';
import { thunkGetParts } from '../../../store/parts/thunks';

interface RaspInvertFormProps {
    system: SystemState;
    channels: ChannelItem[];
    overlayToggle: any;
    overlayPositionToggle: any;
    thunkUpdateChannel: any
    thunkResetAttributeRefByChannel: any
    thunkGetParts: any
    thunkGetAttributesByPartId: any
}

interface RaspInvertFormState {
    realtimeChannels: number[],
    channelsFactor:number[],
    channelsFactorString:string[]
    channelsInverse:boolean[],
    showPopUp: boolean
}

class RaspInvertForm extends React.Component<RaspInvertFormProps, RaspInvertFormState> {

    constructor(props:RaspInvertFormProps){
        super(props);
        this.state = {
            realtimeChannels: new Array(this.props.channels.length).fill(0),
            channelsFactor: new Array(this.props.channels.length).fill(1),
            channelsFactorString: new Array(this.props.channels.length).fill('1'),
            channelsInverse: new Array(this.props.channels.length).fill(false),
            showPopUp: false
        }
    }

    static contextType = MessageContext; 
    private subChannels?:Subscription;

    componentDidMount(){
        this.startChannelsSubscription()
        this.copyOriginalsToState()
    }

    startChannelsSubscription(){
        this.subChannels = this.context.onChannels().subscribe((msg: MeasureObject) => {
            this.setState({
                realtimeChannels:msg.values,
            })
        });
    }

    stopChannelsSubscription(){
        this.subChannels?.unsubscribe()

        
    }

    copyOriginalsToState(){
        // fill the factors with the originals
        let newFactor:number[] = []
        let newFactorString:string[] = []
        let newFactorInverse:boolean[] = []
        this.props.channels.forEach((channel:ChannelItem) => {
            newFactor = [...newFactor, channel.inversFactor];
            newFactorString = [...newFactorString, channel.inversFactor.toString()]
            newFactorInverse = [...newFactorInverse, channel.invers]
        })
        this.setState({
            channelsFactor: [...newFactor],
            channelsFactorString: [...newFactorString],
            channelsInverse: [...newFactorInverse]
        })
    }

    handleCancel(){
        this.props.overlayToggle()
    }

    async handleSubmit(){
        this.setState( { showPopUp: true }, async () => {

        });
        let changeFlag = false;

        for (let index = 0; index < this.props.channels.length; index++) {
            const channel = this.props.channels[index];
            if(index === 0) continue;
            const newChannel:ChannelItem = cloneDeep(channel)
            newChannel.invers = this.state.channelsInverse[index]
            newChannel.inversFactor = this.state.channelsFactor[index]
            
            if(!isEqual(channel, newChannel)){
                // console.log('changed channel: ', channel.id)
                await this.props.thunkUpdateChannel(newChannel);
                // console.log('handleSubmit - channelUpd: ', channelUpd)
                await this.props.thunkResetAttributeRefByChannel(newChannel.id);
                // console.log('handleSubmit - attributeIds: ', attributeIds)
                changeFlag = true;
            }
        }
        if (changeFlag) {
            await this.props.thunkGetParts();
            const currentPartId = localStorage.getItem('promess-current-partId');
            this.props.thunkGetAttributesByPartId(currentPartId);
        };
        this.setState( { showPopUp: false }, () => {
            this.props.overlayToggle();
        });
        
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>, index:number, isDecimalPoint: boolean){
        e.preventDefault()
        const {value} = e.target
        if(this.isNumber(value, isDecimalPoint)){
            const newFactor = [...this.state.channelsFactor]
            const newFactorString = [...this.state.channelsFactorString] //[...this.state.channelsFactor.map(m => {return m.toString()})]
            newFactor[index] = value === '' ? 0 : parseFloat(value.replace(',','.'));
            newFactorString[index] = value;
            this.setState({
                channelsFactor:[...newFactor],
                channelsFactorString: [...newFactorString]
            })
        }
    }

    isNumber(factor:any, isDecimalPoint: boolean){
        // /^[0-9]+([\,\-]?[0-9]+)?$/
        // return isDecimalPoint ?  /^[0-9.]*$/.test(factor) : /^[0-9,]*$/.test(factor)
        return isDecimalPoint ?  /^[0-9.]*$/.test(factor) :/^[0-9,]*$/.test(factor);
    }

    toggleHandler(e:any){
        const index = e.target.value
        const newArray = [...this.state.channelsInverse]
        newArray[index] = !this.state.channelsInverse[index]
        this.setState({
            channelsInverse: [...newArray]
        })
    }

    transformValue(rawValue: any, factor: any, isMM: boolean = true, isDecPoint: boolean = true, invert: boolean = false): string {
        const unitFactor = isMM ? 1: 1/25.4;
        const rawVal = Number(rawValue);
        let facVal = 1;
        if (invert) {
            facVal = Number(factor) * -1;
        } else {
            facVal = Number(factor) * 1;
        }
        const formated = (rawVal * facVal * unitFactor).toFixed(isMM ? 3: 5);
        return  isDecPoint ? formated : formated.replace('.',',');
    }

    onFocusBlur(editThis: any){
        const isMobile = editThis.props.system.platform.android || editThis.props.system.platform.ios;
        isMobile && editThis.props.overlayPositionToggle()
    }
   
    render() {
        const {usedUnits, usedDelimiter} = this.props.system.settings;
        const isUnitMillimeter = usedUnits === UsedUnitsEnum.MM;
        const isDecimalPoint = usedDelimiter === UsedDelimiterEnum.DecimalPoint;

        return(
            <><Translation>
                {
                    t =>
                    <div className="container-fluid app__overlay-form">
                        <div className="row">
                            <div className="col-4 offset-8 col-md-3 offset-md-9 text-right">
                                <button 
                                    className="btn btn--icon btn--icon-close bg-action-dark mr-3" 
                                    onClick={ ()=> this.handleCancel() }
                                ></button>
                                <button 
                                    className="btn btn--icon btn--icon-check btn--negative" 
                                    type="submit"
                                    onClick={ () => { this.handleSubmit() } }
                                ></button>
                            </div>
                        </div>
                        <LoadingOverlay show={this.state.showPopUp} />
                        <div 
                            id="invert-sensors-form"
                            className="invert-sensors-wrapper mt-auto mb-auto"
                        >
                            {
                                this.props.channels.map((channel:ChannelItem, index:any) => {
                                    
                                    if(index === 0) return false
                                    
                                    return(
                                        <div key={'wrapper-' + index} className="rasp_channel">
                                            <RaspChannel 
                                                key = {'channel-' + index}
                                                channel = { channel }
                                                attribute = { null }
                                                value = { this.transformValue(this.state.realtimeChannels[index - 1], this.state.channelsFactor[index], isUnitMillimeter, isDecimalPoint, this.state.channelsInverse[index] ) }
                                                readonly = { true }
                                                styles="mb-3"
                                            />
                                            <RadioToggleNaked 
                                                key = {'toggle-' + index}
                                                value={ Number(index) }
                                                onChangeHandler={ this.toggleHandler.bind(this) }
                                                checked = { this.state.channelsInverse[index] }
                                                styles="mb-4"
                                                index = { index }
                                            />
                                            <input 
                                                className="input-blue" 
                                                type="text" 
                                                value= { isDecimalPoint ? this.state.channelsFactorString[index] : this.state.channelsFactorString[index].replace('.',',')}
                                                onChange= { (e:any) => {this.handleChange(e, index, isDecimalPoint) }}
                                                onFocus={ () => this.onFocusBlur(this) }
                                                onBlur={ () => this.onFocusBlur(this) }
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                }
            </Translation></>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
    channels: getChannelsAll(state),
    system: state.system
});

const dispatchToProps = {
    overlayToggle,
    overlayPositionToggle,
    thunkUpdateChannel,
    thunkResetAttributeRefByChannel,
    thunkGetParts,
    thunkGetAttributesByPartId
};

export default connect(mapStateToProps, dispatchToProps)(RaspInvertForm);