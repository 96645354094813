import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationAction } from '../../statistics/StatisticsBody';
import Button from '../Button';

type Props = {
    count: number;
    currentPage: number;
    maxPage: number;
    handlePagination: any
    setPagination: any
    idle: boolean
}

const PagingAction: React.FC<Props> = (props) => {

    const {t} = useTranslation()
    const {count, maxPage, currentPage, handlePagination, setPagination, idle} = props;

    const getPageFirst = () => {
        handlePagination(PaginationAction.FIRST)
        .then( (pagNew: any) => setPagination(pagNew) );
    }

    const getPageNext = () => {
        handlePagination(PaginationAction.NEXT)
        .then( (pagNew: any) => setPagination(pagNew) );
    }

    const getPagePrev = () => {
        handlePagination(PaginationAction.PREV)
        .then( (pagNew: any) => setPagination(pagNew) );
    }

    const getPageLast = () => {
        handlePagination(PaginationAction.LAST)
        .then( (pagNew: any) => setPagination(pagNew) );
    }

    return(
        <div className='paging d-flex'>
            <div className="paging__total hsmall strong">{count} {t('Records')}</div>
            <div className="paging__pages">
                <Button 
                    show = {true}
                    styles="btn btn--icon btn--action btn--icon-first"
                    clickHandler = { getPageFirst }
                    disabled = { idle || currentPage === 1 }
                />
                <Button 
                    show = {true}
                    styles="btn btn--icon btn--action btn--icon-prev"
                    clickHandler = { getPagePrev }
                    disabled = { idle || currentPage === 1 }
                />
                <span 
                    className={idle ? 'btn--loading btn btn--icon btn--seamless btn--info' : 'btn btn--icon btn--seamless btn--info'}
                >
                    {currentPage}/{maxPage}
                </span>
                <Button 
                    show = {true}
                    styles= { 'btn btn--icon btn--action btn--icon-next' }
                    clickHandler = { getPageNext }
                    disabled = { idle || currentPage === maxPage }
                />
                <Button 
                    show = {true}
                    styles="btn btn--icon btn--action btn--icon-last"
                    clickHandler = { getPageLast }
                    disabled = { idle || currentPage === maxPage }
                />
            </div>
        </div>
    );
}

export default PagingAction;
