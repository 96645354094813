import React from 'react';
import { connect } from 'react-redux';
import { PartItem } from '../../store/parts/types';
import { thunkCreatePart, thunkDeletePart } from '../../store/parts/thunks';
import Part from './Part';
import Button from '../ui/Button';
import {getPartsAll, getParts} from '../../store/parts/selectors';
import { AppState } from '../../store/reducers';
import { overlayToggle, overlayLoadComponent } from '../../store/system/action';
import PartEditForm from './PartEditForm';
import { v4 as uuidv4 } from 'uuid'
import { Translation } from 'react-i18next';
import { SystemState } from '../../store/system/types';
import { newPart } from './partHelpers';
import { qdasMandatoryRow } from '../statistics/export/data/constants';
import { PartQdas } from '../qdas/qdas-types';

interface PartsProps {
    history:any;
    items:  PartItem[];
    getItems: any;
    getParts(filter: any): any;
    thunkCreatePart(part: PartItem): any;
    thunkDeletePart(id: string): any;
    overlayToggle: typeof overlayToggle;
    overlayLoadComponent: typeof overlayLoadComponent;
    system: SystemState
}

interface PartsLocalState {
    isEditMode: boolean;
    currentFilter: string;
    filterItems: PartItem[];
}

class ConstructionParts extends React.Component<PartsProps, PartsLocalState> {

    constructor(props:PartsProps){
        super(props);
        this.state = {
            isEditMode: this.props.system.settings.advancedMode ? true : false,
            currentFilter: '',
            filterItems: this.props.getItems('')
        }
    }

    componentWillUnmount(){
        // we need to handle the cancel of part editing/adding 
        // f.ex. someone clicks in the nav while adding a part

        // fail early
        if(!this.props.system.overlay.main.show) return 

        //find the part that is still a draft (flag that we add when a new part is being added)
        const partsAdded:PartItem[] = this.props.items.filter((part) => part.isDraft === true)
        
        //lets delete all
        partsAdded.forEach(part => {
            this.props.thunkDeletePart(part.id)
        })
    }

    componentDidUpdate(prevProps:PartsProps){
        //if amount of items changed lets update the filtered items counter
        if(this.props.items.length !== prevProps.items.length){
            const filter = this.state.filterItems.length === 1 ? '' : this.state.currentFilter
            this.setState({
                currentFilter: filter,
                filterItems : this.props.getItems(filter)
            })            
        }
    }

    handleFilter = (event: any) => {
        const { value } = event.target;
        this.setState({
            currentFilter: value,
            filterItems : this.props.getItems(value)
        })        
    }

    toggleEditMode(e:any) {
        e.preventDefault()
        this.setState({
            isEditMode : !this.state.isEditMode
        })
    }

    addPart = () => {
        const newPartId = uuidv4();
        const qdasArray: PartQdas[] = [];
        for (const row of qdasMandatoryRow) {
            if ( row.default.length > 0 ) {
                qdasArray.push({ uuid: `${row.key}${uuidv4()}`, textRow: `${row.key} ${row.default}`, position: row.position, partId: newPartId });
            }
        }
        const qdasArrayString = JSON.stringify(qdasArray);
        const newPartMod = {...newPart, id: newPartId, dataQdas: qdasArrayString };
        this.props.overlayLoadComponent(<PartEditForm history={this.props.history} item={ newPartMod } />);
        this.props.overlayToggle();
    }

    renderList = () => {
        const {currentFilter} = this.state;
        let items: PartItem[] = this.props.getItems(currentFilter);  
        return(
            <Translation>{ t => 
                <>
                    { items.length === 0 && this.state.currentFilter !== '' &&
                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                <h2 className="h3">
                                    {t('NoResultsFound')}
                                </h2>
                            </div>
                        </div>
                    }

                    { items.length === 0 && this.state.currentFilter === '' &&
                        <div className="row pt-5">
                            <div className="col-12 text-center">
                                <h2 className="h3">
                                    {t('NoPartsDefinedYet')}
                                </h2>
                                <Button 
                                    styles = 'btn d-inline-block'
                                    show = { true }
                                    label = { t('DefineAPart') }
                                    clickHandler = { this.addPart }
                                />
                            </div>
                        </div>
                    }
                    
                    <div className="app__main-content container-fluid">
                        <div className="parts list">
                        {
                            items.map( item => {
                                
                                return (
                                    <Part
                                        id = { item.id }
                                        item = { item }
                                        key = { item.id }
                                        history = { this.props.history }
                                        isEditMode = { this.state.isEditMode }
                                    />
                                )
                            })
                        }
                        </div>
                    </div>
                </>
            }</Translation>
        )
    }

    renderHeader = () => {
        const {currentFilter} = this.state;
        let items: PartItem[] = this.props.getItems(currentFilter);  
        const partsExist = items.length !== 0
        
        if(!partsExist && this.state.currentFilter === '') return null
        
        return (
            <>
        <Translation>{
            t =>
            <div className="app__main-header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            {/* <div className="col-12 col-md-4">
                                <h2 className="h2 mb-0">{t('Parts')}</h2>
                                <h3 className="hsmall text-grey-dark mb-3 mb-md-0">{items.length} {t('Parts')}</h3>
                            </div> */}
                            <div className="col-12 col-md-8 d-flex justify-content-end ml-auto">
                                
                                <input 
                                    className="mr-auto mr-md-4 w-60 w-md-auto" 
                                    type="text" 
                                    placeholder={t('Search')+'..'}  
                                    onChange={this.handleFilter}
                                    value={ this.state.currentFilter }
                                />
                                
                                {/* <Button 
                                    styles = 'btn btn--icon btn--icon-edit mr-3' 
                                    isActive = { this.state.isEditMode }
                                    clickHandler = { (e:any) => this.toggleEditMode(e) }
                                    disabled = { false }
                                    show = { true }
                                /> */}

                                <Button 
                                    styles = 'btn btn--icon btn--icon-plus' 
                                    isActive = { false }
                                    clickHandler = { this.addPart }
                                    disabled = { false }
                                    show = { true }
                                />
                            </div>
                        </div>
                    </div>
                </div>
        }
        </Translation>
        </>
        )
    }

    render() {
        return(
                <div className="app__main">
                    {this.renderHeader()}
                    {this.renderList()}
                </div>
        );
    };
}

const mapStateToProps = (state: AppState) => ({    
    items : getPartsAll(state),
    getItems: getParts(state),
    system: state.system
});

const dispatchToProps = {
    thunkCreatePart, 
    thunkDeletePart,
    getParts, 
    overlayToggle, 
    overlayLoadComponent,
};

export default connect(mapStateToProps, dispatchToProps)(ConstructionParts);