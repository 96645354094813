import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Dictionary } from "lodash";
import { ExportBaseAbstract} from "../export-base"
import { MeasurementChartItem, MeasurementFlatItem } from "../../../../store/statistics/types";
import { excelTemplate } from "../data/template";
import { Device } from "../../../../store/rasp/types";
import { namingObjectType } from "../../../settings/naming/naming-types";
import { ChannelItem } from "../../../../store/channels/types";
import EH from '../export-helper';

export class ExportExcelJs extends ExportBaseAbstract {
  private readonly dictionary: Dictionary<string> = {
    'Date': 'Date',
    'Part': 'Part',
    'Machine': 'Machine',
    'Nest': 'Nest',
    'Feature': 'Feature',
    'Upper Tolerance': 'Upper Tolerance',
    'Lower Tolerance': 'Lower Tolerance',
    'Value': 'Value',
    'Diagram': 'Diagram',
    'Eval': 'Eval',
    'mm': 'mm',
    'inch': 'inch',
    "ExportedFromQumoOn": "ExportedFromQumoOn",
    "Page": "Page",
    "of": "of",
    "StatsDeletion": "StatsDeletion",
    "startDate": "startDate",
    "endDate": "endDate",
  }

  private measurementCharts: MeasurementChartItem[] = [];

  constructor(items: MeasurementFlatItem[], device: Device, channels: ChannelItem[], namingObject: namingObjectType, measurementCharts: MeasurementChartItem[]) {
    super(items, device, channels, namingObject)
    this.updateDictionary = this.dictionary;
    this.measurementCharts = measurementCharts;
  }
  
  async createExport() {
    const ts = new Date();
    // preparation
    const saveName = 'export';
    const exportedAt = this.dictionary["ExportedFromQumoOn"] + " " + ts.toISOString().slice(0, 10) + " " + ts.toISOString().slice(11, 19);
    // load template from base64 string
    const wb = new ExcelJS.Workbook();
    const buffer = Buffer.from(excelTemplate, "base64");
    await wb.xlsx.load(buffer);

    // select first worksheet
    const worksheet = wb.worksheets[0];
    worksheet.name = saveName;
    // worksheet.pageSetup.paperSize = 9;

    const unit = this.dictionary[ this.currentSettings.isMM ? "mm" : "inch" ] 

    worksheet.getCell("A6").value = this.dictionary["StatsDeletion"];
    worksheet.getCell("A6").style = {font:{bold: true}};
    worksheet.getCell("A7").value = exportedAt;
    worksheet.getCell("A9").value = '';
    worksheet.getCell("F11").value = unit;
    worksheet.getCell("G11").value = unit;
    worksheet.getCell("H11").value = unit;

    let maxDate = this._items[0].measurementTimestamp;
    let minDate = maxDate;

    const tableRows = this._items.map((m) => {
      let evalu = "-";
      if (m.measurementTimestamp > maxDate) {
        maxDate = m.measurementTimestamp;
      }
      if (m.measurementTimestamp < minDate) {
        minDate = m.measurementTimestamp;
      }
      if (m.featureOT && m.featureUT) {
        evalu =
          m.featureValue >= m.featureUT && m.featureValue <= m.featureOT
            ? "ok"
            : "nok";
      }
      return [
        new Date(m.measurementTimestamp).toISOString(),
        m.partName,
        m.workbenchName,
        m.nestName,
        m.featureName,
        this.formatNumber(m.featureUT as number, m.featureDecimalPlaces),
        this.formatNumber(m.featureOT as number, m.featureDecimalPlaces),
        this.formatNumber(m.featureValue as number, m.featureDecimalPlaces), 
        "",
        evalu,
      ];
    });

    const startDate = new Date(minDate);
    const endDate = new Date(maxDate);

    worksheet.headerFooter.differentFirst = true;
    // worksheet.headerFooter.differentOddEven = false;
    worksheet.headerFooter.firstHeader =
      "&L" +
      "&C" +
      startDate.toISOString().slice(0, 15) +
      " - " +
      endDate.toISOString().slice(0, 15) +
      "&R" +
      exportedAt;
    worksheet.headerFooter.firstFooter = this.dictionary["Page"] + " &P " + this.dictionary["of"] + " &N";

    worksheet.getCell("A10").value = this.dictionary["startDate"];
    worksheet.getCell("B10").value = startDate;
    worksheet.getCell("D10").value = this.dictionary["endDate"];
    worksheet.getCell("E10").value = endDate;
    worksheet.getCell("D9").value = '';

    const tableCol = "A";
    const tableRow = 12;
    const tableStart = tableCol + tableRow;
       
    worksheet.addTable({
      name: "ResultsTable",
      ref: tableStart,
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium4",
        showRowStripes: true,
      },
      columns: [
        {
          name:  this.dictionary["Date"],
          filterButton: true,
        },
        {
          name: this.dictionary["Part"],
          filterButton: true,
        },
        {
          name: this.dictionary["Machine"],
          filterButton: true,
        },
        {
          name: this.dictionary["Nest"],
          filterButton: true,
        },
        {
          name:  this.dictionary["Feature"],
          filterButton: true,
        },
        {
          name: this.dictionary["Lower Tolerance"],
          filterButton: false,
        },
        {
          name: this.dictionary["Upper Tolerance"],
          filterButton: false,
        },
        {
          name: this.dictionary["Value"],
          filterButton: false,
        },
        {
          name: this.dictionary["Diagram"],
          filterButton: false,
        },
        {
          name: this.dictionary["Eval"],
          filterButton: false,
        },
      ],
      rows: tableRows,
    });

    // add chart PNGs to the table
    const firstChartRow =  tableRow + 1;
    let i = 0;
    for (const m of this._items) {
      const measurementChartItem = this.measurementCharts.find( c => c.uuid === m.uuid );
      if (measurementChartItem) {
        const imageId = wb.addImage({
          base64: measurementChartItem.measurementChart,
          extension: 'png',
        });
        const rowNumb = firstChartRow + i;
        worksheet.addImage(imageId,`I${rowNumb}:I${rowNumb}`)
      }
      i++;
    }

    // save
    const buf = await wb.xlsx.writeBuffer();
    this._blob = new Blob([buf])
    
  }

  saveExport(filename: string = '') {
    const saveName = "results_" + EH.getIsoTimeFormated(new Date().getTime());
    saveAs(this._blob, filename.length > 0 ? filename :saveName + ".xlsx");
  }


}
