import { MACHINES, NESTS } from './../../../../constants/lang/translation-keys';
import { MeasurementFlatItem, PartHistory } from '../../../../store/statistics/types';
import { ExportBaseAbstract} from "../export-base";
import { Device } from "../../../../store/rasp/types";
import { saveAs } from "file-saver";
import { QdasRow, QdasWorkbench, QdasNest, QdasAttribute } from '../types';
import EH from '../export-helper';
import { Dictionary } from 'lodash';
import { namingObjectType } from '../../../settings/naming/naming-types';
import NamingHelper from '../../../settings/naming/naming-helper';
import { ChannelItem } from '../../../../store/channels/types';

export class  ExportQdas extends ExportBaseAbstract{

    private readonly dictionary: Dictionary<string> = {
        'unit_mm': 'qdas_unit_mm',
        'unit_inch': 'qdas_unit_inch',
        'catalog_wb': 'qdas_catalog_workbenches',
        'catalog_nests': 'qdas_catalog_nests',
      }

    private readonly rowEnd = '\r\n'; // crlf

    protected _partHistory: PartHistory;

    private _attributes: QdasAttribute[] = [];
    private _workbenches: QdasWorkbench[] = [];
    private _nests: QdasNest[] = [];
        
    constructor(items: MeasurementFlatItem[], device: Device, channels: ChannelItem[], namingObject: namingObjectType, partHistory: PartHistory, attributes:  QdasAttribute[], qdasWbs: QdasWorkbench[], qdasNests: QdasNest[]) {
            super(items, device, channels, namingObject);
            this._partHistory = partHistory;
            this._attributes = attributes;
            this._workbenches = qdasWbs;
            this._nests = qdasNests;
            this.updateDictionary = this.dictionary;
    }

    async createExport() {
        const data = await this.createQdas();
        this._blob = new Blob([data.buffer]);
    }

    async getFileText(){
        const data = await this.createQdas();
        return data.text;
    }

    getFileName(){
        const startDate = EH.getIsoTimeFormated( this._items[0].measurementTimestamp - new Date().getTimezoneOffset()*1000*60);
        const endDate = EH.getIsoTimeFormated( this._items[this._items.length-1].measurementTimestamp - new Date().getTimezoneOffset()*1000*60);
        return `${this._partHistory.name}_${startDate}__${endDate}.dfq`;
    }

    async saveExport(filename: string = '') {
        saveAs(this._blob, filename.length > 0 ? filename : this.getFileName() );
    }

    private async createQdas(): Promise<{buffer:Buffer, text: string}> {
        let retValue: string = '';
        retValue += await this.addBlockA();
        retValue += await this.addBlockB();
        retValue += this.addBlockC();
        retValue += this.addBlockD();
        return { buffer: Buffer.from(retValue, 'utf8'), text: retValue };
    }

    private addQdasRow({ key, ref, value }: QdasRow): string {
        let prefix = key === '' ? '' : ' ';
        if ( ref.length > 0 ) {
            prefix = '/' + ref + ' ';
        };
        return `${key}${prefix}${value}${this.rowEnd}`;
    }

    // HEADER
    private async addBlockA(): Promise<string> {
        let rowsA: QdasRow[] = [
            { key: 'K0100', ref: '', value: this._attributes.length.toString() },
            { key: 'K1001', ref: '', value: this._partHistory.partNumber },
            { key: 'K1002', ref: '', value: this._partHistory.name },
            { key: 'K1041', ref: '', value: this._partHistory.designNumber }
        ] ;
        rowsA = rowsA.concat( EH.getQdasPart(this._partHistory) );
        return this.addRows(rowsA);
    }

    // Merkmalsdaten
    private async addBlockB(): Promise<string> {
        const rowsB: QdasRow[] = [];

        for (const feature of this._attributes) {
            const a = feature.attribute;
            const ref = feature.ref;
            let rows =
                [
                    { key: 'K2001', ref: ref, value: ref },
                    { key: 'K2002', ref: ref, value: a.name.slice(0,80) },
                    { key: 'K2003', ref: ref, value: a.name.slice(0,20) },
                    { key: 'K2101', ref: ref, value: this.formVal(a.nominalSize) },
                    { key: 'K2110', ref: ref, value: this.formVal(a.ut) },
                    { key: 'K2111', ref: ref, value: this.formVal(a.ot) },
                    { key: 'K2142', ref: ref, value: this.currentSettings.isMM ? this.dictionary["unit_mm"] :this.dictionary["unit_inch"] },
                    { key: 'K2213', ref: ref, value: this.formVal(a.masterSize) },
                ];
            rows = rows.concat( EH.getQdasAttribute(a) );
            for (const r of rows) rowsB.push(r);
        }
        return this.addRows(rowsB);
    }

    // Verwaltungsdaten
    //
    private addBlockC():string {
        let retValue: string = '';

        // workbenches
        retValue += this.addQdasRow({ key: 'K4060', ref: '0', value: NamingHelper.getNaming(MACHINES,this._namingObject) });
        for (const wb of this._workbenches) {
            retValue += this.addQdasRow({ key: 'K4063', ref: EH.checkReference(wb.ref), value: wb.name });
        }

        // nests
        retValue += this.addQdasRow({ key: 'K4250', ref: '0', value: NamingHelper.getNaming(NESTS,this._namingObject) });
        for (const nest of this._nests) {
            retValue += this.addQdasRow({ key: 'K4253', ref: EH.checkReference(nest.ref), value: nest.name });
        }

        return retValue;
    }

    // Messwerte
    //
    private addBlockD():string {
        
        let rowsD: QdasRow[] = [];
        const qdasTimeblocks = EH.getQdasTimeBlocks( this._items, this._attributes, this._workbenches, this._nests );
        console.log('#### DEBUG MESSWERTE', qdasTimeblocks)
        
        for ( const tb of qdasTimeblocks ) {
            for (const feature of tb.features) {
                const ref = feature.featureRef;
                rowsD.push({ key: 'K0001', ref: ref, value: this.formVal(feature.featureValue) })
                rowsD.push({ key: 'K0002', ref: ref, value: feature.valid })
            }
            const rowsAdded: QdasRow[] = [];
            const timezoneOffset = new Date().getTimezoneOffset();
            const dateTime = new Date(tb.timestamp - timezoneOffset * 60 * 1000); // timestamp UTC minus local offset
            const date = dateTime.toISOString().slice(0,10);
            const time = dateTime.toISOString().slice(11,19);
            rowsAdded.push({ key: 'K0004', ref: '0', value: `${date}/${time}` });
            rowsAdded.push({ key: 'K0007', ref: '0', value: tb.nestRef });
            rowsAdded.push({ key: 'K0010', ref: '0', value: tb.wbRef });
            if (tb.data.length) {
                for (const entry of tb.data) {
                    rowsAdded.push({ key: entry.code, ref: '0', value: entry.textInput });
                }
            }
            rowsD = rowsD.concat(rowsAdded.sort((a,b)=>a.key<b.key ? -1 : 1));
        }
        return this.addRows(rowsD);
    }

    private addRows(list:QdasRow[]){
        let retValue: string = '';
        list.forEach(item=>{
            if (item.value.trim()) retValue += this.addQdasRow(item);
        }); // if value is empty, row is not listed
        return retValue;
    }

    private formVal(value: number | string){
        return this.formatNumber( Number(value), this.currentSettings.isMM ? 6 : 8 ).toString();
    }
    
}