import React from 'react'
import { thunkNavigatePart} from '../../../store/parts/thunks';
import { connect } from 'react-redux';
import DropdownListItem from './DropdownListItem';
import { updateSession } from '../../../store/system/action';
import { SystemState } from '../../../store/system/types';
import cloneDeep from 'lodash/cloneDeep';
import Button from '../Button';
import { Translation } from 'react-i18next';

interface DropdownProps {
    items: any
    label?: string
    labelIcon?: string // in case you need an icon instead of a string in the label button
    description :string
    onChangeHandler: any
    styles?: string
    hide?: boolean
    corners?: boolean
    updateSession: typeof updateSession;
    system: SystemState
    checkbox?: boolean
    onCheckboxApply?: any
    hideSearch?: boolean
    reference?: React.LegacyRef<HTMLButtonElement>
    onRef?:any //if you need the acces to the dropdown component from parent
    heightAuto?: boolean
}

interface DropdownState {
    show: boolean
    sublistIndex: number | null
    items: any
    filter: string
    activeCheckboxItems: any[]
    activeCheckboxItemsCurrent:any []
    applyCheckboxes: boolean
}

class Dropdown extends React.Component<DropdownProps, DropdownState> {

    constructor(props:DropdownProps){
        super(props)
        this.state = {
            show: false,
            sublistIndex: null, // we need this to handle eventual sublists (only 1 list should be active at a time)
            items: null, // we save the items in the local state to filter them via filter()
            filter: '',
            activeCheckboxItems: [],
            activeCheckboxItemsCurrent: [],
            applyCheckboxes: false
        }
    }

    // lifecycle
    //--------------------------------------------------------

        componentDidMount(){
            if(this.props.onRef) this.props.onRef(this)
            this.setState({
                items: cloneDeep(this.props.items)
            })
        }


    // methods
    //--------------------------------------------------------

        handleOverlayClick(e:any){
            //if not clicked into the content area close the overlay
            if(!e.target.closest('.dropdown__content')){
                this.close()
            }
        }

        getItemsActiveIndex(item:any){
            return this.state.activeCheckboxItems.findIndex((activeItem:any) => activeItem.uuid === item.uuid)
        }

        isItemInActiveItems(item:any){
            return this.getItemsActiveIndex(item) !== -1
        }

        hasSubitemsInActiveItems(item:any){
            if(item.items) return item.items.some((subItem:any) => this.isItemInActiveItems(subItem))
            return false
        }

        handleListItemClick(listItem:any){

            let subItems = null
            if(listItem.nests) subItems = listItem.nests
            if(listItem.items) subItems =  listItem.items


            // if checkboxes
            //--------------------------------------------------------

                if(this.props.checkbox) {
                    
                    let newItems:any = [...this.state.activeCheckboxItems]

                    if(listItem.subItem){
                        const indexInActive = this.getItemsActiveIndex(listItem.subItem)
                        const isItemInActiveItems = indexInActive !== -1
                        if(isItemInActiveItems){
                            newItems.splice(indexInActive, 1)
                        } else {
                            newItems = [...newItems, {...listItem.subItem}]
                        }
                    }

                    if(listItem.uuid && !listItem.items){
                        const indexInActive = this.getItemsActiveIndex(listItem)
                        const isItemInActiveItems = indexInActive !== -1
                        if(isItemInActiveItems){
                            newItems.splice(indexInActive, 1)
                        } else {
                            newItems = [...newItems, {...listItem}]
                        }
                    }
                    
                    this.setState({
                        activeCheckboxItems: newItems
                    })
                    
                }
            
            // if we have a sublist lets find the index of the clicked element to open it
            // sofar sublists only in case of machines where we have the nests[]
            // if the clicked item has one nest (they have always at least one) we reset the list

            if(subItems && subItems.length === 1 && listItem.nests){
                
                this.setState({
                    sublistIndex: null
                })

                this.props.onChangeHandler(listItem)

                if(!this.props.checkbox) {
                    setTimeout(() => {
                        this.close()
                    }, 400);
                }

            } else if(subItems && subItems.length >= 1){

                const index = this.props.items.findIndex((item:any) => {
                    return item.uuid === listItem.uuid 
                })

                this.setState({
                    // close the sublist if clicked on an opened element
                    // else open the sublist
                    sublistIndex: this.state.sublistIndex === index ? null : index 
                })

            } else {
                this.props.onChangeHandler(listItem)
                //@krzysztof there is a redux conflict in the state.system do we need a promise here?

                if(!this.props.checkbox) {
                    setTimeout(() => {
                        this.close()
                    }, 400);
                }
            }
        }

        setFilter(e:any){
            this.setState({
                filter: e.target.value,
                items: this.getFilteredItems(e.target.value)
            })
        }

        getFilteredItems(filter:string){
            const items = [...this.props.items]
            if(!items) return null
            if(filter === '') return items
            return  items.filter((item:any) => item.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
        }

        applyCheckboxes(){
            
            this.props.onCheckboxApply(this.state.activeCheckboxItems)

            this.setState({
                applyCheckboxes: true
            }, () => { this.close()})
        }

        resetActiveChecboxItems(){
            this.setState({
                activeCheckboxItems: []    
            }, () => {
                this.props.onCheckboxApply(this.state.activeCheckboxItems)
            })
        }


    // helpers
    //--------------------------------------------------------

        open(e:any):void{

            e.preventDefault()
            this.setState({
                filter: '',
                items: this.props.items
            })

            // we will mark the wrapper (via redux state.system.overlay) if the dropdown is opened
            // @krzysztof z-index issues, might want to refactor this and move the dropdown list into a 
            // separate global container outside of the wrapper (like slider or overlay)
            
            const updadatedSession = {...this.props.system }
            updadatedSession.overlay.dropdown.show = true
            updadatedSession.overlay.dropdown.name = this.props.description.replace(/ä/g,"a").replace(/ /g,"-").toLowerCase();
            this.props.updateSession(updadatedSession)
            
            this.setState({
                show:true
            })
        }

        close():void{

            // handle checboxes
            if(!this.state.applyCheckboxes){
                this.setState({
                    activeCheckboxItems: []
                })
            }

            const updadatedSession = {...this.props.system }
            updadatedSession.overlay.dropdown.show = false
            this.props.updateSession(updadatedSession)
            updadatedSession.overlay.dropdown.name = null

            this.setState({
                show:false
            })
        }


    // render functions
    //--------------------------------------------------------

        renderApplyButton(){
            return(
                <Translation>
                    { t =>
                        <div className="dropdown__footer">
                            <Button 
                                clickHandler = {() => {this.applyCheckboxes()}}
                                label = {t('Apply')}
                                styles = "btn btn--cta d-inline-block"
                                show = { true }
                            />
                        </div>
                    }
                </Translation>
            )
        }


    // render 
    //--------------------------------------------------------
    
        render() {

            if(this.props.hide) return null
            
            const corners = this.props.corners ? 'btn--dropdown-corners' : ''
            const labelButtonCss = this.props.labelIcon ? ' btn btn--icon ' + this.props.labelIcon : ' btn btn--dropdown dropdown__label '
            let wrapperCss = 'dropdown'
            if(this.props.styles) wrapperCss += ' ' + this.props.styles
            if(this.props.checkbox) wrapperCss += ' dropdown--checkbox'
            const { items } = this.state

            if(this.props.items?.length < 1){ 
                return (
                    <div className={wrapperCss}>
                        <div className={ 'btn--loading' + corners + labelButtonCss }>
                            {this.props.label}
                        </div>
                    </div>
                )
            } else {

                const { reference, label, checkbox, description, hideSearch, heightAuto, onCheckboxApply } = this.props;
                const dropdownCss = this.state.show ? ' dropdown__overlay--active' : ''
                const closeCss = this.props.hideSearch ? 'ml-auto' : ''
                const dropdownContentCss = this.props.onCheckboxApply ? 'dropdown__content--with-footer' : ''

                return(
                    <Translation>
                        { t =>
                    <div className={wrapperCss}>
                        <button 
                            className={ corners + labelButtonCss} 
                            onClick={ (e) => this.open(e)}
                            type="button"
                            ref={reference ? reference : null}
                        >
                            { this.state.activeCheckboxItems.length > 0 && <span className="btn__count">{this.state.activeCheckboxItems.length}</span> }
                            {label}
                        </button>

                        { 
                            checkbox && 
                            this.state.activeCheckboxItems.length > 0 &&
                            <button 
                                className="btn btn--icon btn--icon-close ml-2"
                                onClick={() => this.resetActiveChecboxItems()}
                                type="button"
                            ></button> }

                        <div className={ 'dropdown__overlay' + dropdownCss } onClick={(e) => { this.handleOverlayClick(e) }}>
                            <div className={'dropdown__content ' + dropdownContentCss}>
                            
                                <div className="row align-items-center dropdown__header">
                                    <div className="col-5 h3 strong mb-0 text-uppercase">{ description }</div>
                                    <div className="col-7 d-flex align-items-end">
                                        { !hideSearch && <input className="mr-2 mr-lg-4 ml-auto w-auto max-w-70" type="text" value={this.state.filter} onChange={ e => this.setFilter(e) } placeholder="Search"/> }   
                                        <span className={closeCss + ' btn btn--icon btn--icon-close'} onClick={ () => {this.close()} }></span>
                                    </div>
                                </div>

                                {this.state.show && 
                                    <div className="list dropdown__list" style={ heightAuto ? {  height: 'auto' } : undefined }>

                                        {
                                            items.length === 0 && 
                                            
                                            <h2 className="h3">{t('NoResultsFound')}</h2>
                                        }

                                        {items?.map( (item:any, index:any) => {
                                            return (
                                                <DropdownListItem 
                                                    key = { index }
                                                    item = { item } 
                                                    onClickHandler = { (item:any) => this.handleListItemClick(item) }
                                                    showSublist = { this.state.sublistIndex === index }
                                                    isInCheckedItems = {  this.isItemInActiveItems(item) || this.hasSubitemsInActiveItems(item) }
                                                    checkedSubitems = {this.state.activeCheckboxItems}
                                                />
                                            )
                                        })}
                                    </div>
                                }   

                                {
                                    onCheckboxApply && this.renderApplyButton()
                                }
                            </div>
                        </div>
                    </div>
                    }</Translation>
                );
            }
        };
}

const mapStateToProps = (state: any) => ({
    system: state.system,
});

const dispatchToProps = {
    thunkNavigatePart,
    updateSession
};

export default connect(mapStateToProps, dispatchToProps)(Dropdown);