import React from 'react'
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { validationReset } from '../../store/system/action';
import { Translation } from 'react-i18next';
import NamingContext from '../../context/naming/naming-context';
import NamingHelper from '../settings/naming/naming-helper';

interface NotificationProps {
  notifications: string[]
  show: boolean
  validationReset?: any
}

class Notifications extends React.Component<NotificationProps> {

  static contextType = NamingContext.Context;

  getNaming(key: string){
    return NamingHelper.getNaming(key, this.context.naming);
}

  onClick() {
    if (this.props.validationReset) {
      this.props.validationReset()
    }
  }

  render() {
    if (!this.props.show) return null

    return (
      <>
        <Translation>
          {(t) => (
            <div className="notification">
              <div className="notification__wrapper">
                <div className="notification__messages">
                  <div className="pb-4">
                    {this.props.notifications.map((notification, index) => {
                      return (
                        <p className="h4 mb-2" key={index}>
                          {this.getNaming(notification)}
                        </p>
                      )
                    })}
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn btn--white w-auto d-inline-block"
                      onClick={() => {
                        this.onClick()
                      }}
                    >
                      {t('Understood')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Translation>
      </>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: NotificationProps) => ({})

const dispatchToProps = {
  validationReset,
}

export default connect(mapStateToProps, dispatchToProps)(Notifications)
