import React from 'react';
import { Translation } from 'react-i18next';

interface AttributeReferenceInfoProps {
    timestamp: any,
    show: boolean,
}

class AttributeReferenceInfo extends React.Component<AttributeReferenceInfoProps> {

    render() {
        if(!this.props.show) return null
        if(this.props.timestamp){
            return(
                <Translation>{
                    t => 
                    <div className="card__value reference">
                        <h3 className="h3 mb-1 text-ellipsis">
                            { this.props.timestamp }
                        </h3>
                        <p className="text-grey-dark text-uppercase mb-0 text-ellipsis">{t('LastReferenced')}</p>
                    </div>
                    }
                </Translation>
            );
        } else {
            return null
        }
    };
}

export default AttributeReferenceInfo;