import { PartItem, LOAD_PARTS, DELETE_PART, ADD_PART, UPDATE_PART, INCR_PART_ATTR_COUNT } from "./types";

const loadParts = (parts: PartItem[]) => {
  return {
    type: LOAD_PARTS,
    payload: parts
  };
}

const deletePart = (id: string) => {
  return {
    type: DELETE_PART,
    payload: id
  }
}

const addPart = (part: PartItem) => {
  return {
    type: ADD_PART,
    payload: part
  }
}

const updatePart = (part: PartItem) => {
  return {
    type: UPDATE_PART,
    payload: part
  }
}

const incrPartAttributesCount = ( count: number, id: string ) => {
  return {
    type: INCR_PART_ATTR_COUNT,
    payload: { count, id }
  }
}


export { loadParts, deletePart, addPart,  updatePart, incrPartAttributesCount } 