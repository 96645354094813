import { AppState } from '../reducers'
import { PartItem } from './types';
import { SensorItem } from '../attributes/types';

// all
export function getPartsAll(state: AppState): PartItem[] {
    return state.parts.items.filter(part => !part.isDeleted);
}

// single, by id
export function getPart(state: AppState): (id?:string) => PartItem | null {
    return (id?:  string): PartItem | null => {
        const partItem = getPartsAll(state).find(item => item.id === id);
        if (!partItem) {
            return null;
        }
        return partItem
    }
}

// all, by filter
export function getParts(state: AppState): (id?:string) => PartItem[] {
    return (id:  string = ''): PartItem[] => {
        return getPartsAll(state).filter((item)=> {
            return (item.partNumber.toUpperCase().indexOf(id.toUpperCase()) >=0) || (item.designNumber.toUpperCase().indexOf(id.toUpperCase()) >=0) || (item.name.toUpperCase().indexOf(id.toUpperCase()) >=0);
        });
    }
}

export function getAttribute(state: AppState):(parentId?: string, id?:string) => any {
    
    return (parentId: string ='', id: string= '') => {
        
        const partItem = getPartsAll(state).find(item => item.id === parentId);    
        
        if (!partItem) {
            return null;
        }
        const attributes = partItem.attributes;

        const attributItem = attributes.find(item => item.uuid === id);
        if (attributItem) {
            return attributItem;
        }   
    }
}

export function getChannels(state: AppState): (parentId?: string, id?:string) => SensorItem[] {
    
    return (parentId: string ='', id: string= '') => {
        let retValue: SensorItem[] = [];
        const partItem = getPartsAll(state).find(item => item.id === parentId);
        
        if (!partItem) {
            return retValue;
        }
        const attributes = partItem.attributes;

        const attributItem = attributes.find(item => item.uuid === id);
        if (attributItem) {
            return attributItem.sensors;
        }
        return retValue;       
    }
}
