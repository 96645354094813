import React from 'react';

interface ButtonProps {
    styles: string
    isActive? : boolean
    label?: any
    labelLight?: string
    clickHandler? : any
    disabled?: boolean
    show: boolean
    icon?: 'btn--reload'
}

class Button extends React.Component<ButtonProps> {

    click(e:any){
        e.preventDefault()
        if(!this.props.clickHandler) return
        this.props.clickHandler(e)
    }

    render() {

        const styles = this.props.isActive ? `${this.props.styles} is-active` : this.props.styles
        const iconStyles = this.props.icon ? ' btn--double' : ''
        // const disabled = this.props.disabled ? 'disabled' : ''
        
        if(this.props.show === false) return null
        
        return(
            <button 
                className = { styles + iconStyles} 
                onClick = { (e) => this.click(e) } 
                disabled = { this.props.disabled }
            >
                { this.props.icon && <i className= {this.props.icon + ' btn' }></i> }
                { this.props.labelLight && <span className="font-regular">{this.props.labelLight}&nbsp;-&nbsp;</span> }
                { this.props.label }
            </button>
        );
    };
}

export default Button;