import React from 'react';
import { AttributeItem, AttributeSettingEnum, SensorItem, AttributeConfigItem } from '../../store/attributes/types';
import { overlayToggle, setAttributeEditFormMode, changeAttributeMode, validationLoad, updateSession, updateTempAttribute, overlayPositionToggle } from '../../store/system/action';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import ButtonGroupToggle from '../ui/ButtonGroupToggle';
import RadioToggle from '../ui/RadioToggle';
import Taster from '../ui/Taster/Taster';
import set from 'lodash/set';
import { getChannelsAll } from '../../store/channels/selectors'
import { SystemState, attributeEditFormMode, UsedUnitsEnum, UsedDelimiterEnum } from '../../store/system/types';
import { thunkUpdateAttribute, thunkDeleteAttribute, thunkAsyncCreateAttribute, thunkCreateAttributeNoRedux } from '../../store/attributes/thunks'
import { getAttribute } from '../../store/attributes/selectors'
import { Translation } from 'react-i18next';
import AttributeEditFormPocket from './AttributeEditFormPocket';
import Counter from '../ui/Counter';
import { markFirstFreeChannel } from './AttributeHelpers';
import cloneDeep from 'lodash/cloneDeep';
import { AppConfig } from '../../constants/app-config';
import { isStringUniqueInTheCollection } from '../../utils/validation';


interface AttributeEditFormProps {
    attribute?: any
    attributeId: string
    usedDelimeter?: UsedDelimiterEnum
    channels: any
    system: SystemState
    overlayToggle: typeof overlayToggle
    overlayPositionToggle: typeof overlayPositionToggle
    setAttributeEditFormMode: typeof setAttributeEditFormMode
    getAttribute: any
    validationLoad: any
    changeAttributeMode: any
    thunkUpdateAttribute: any
    thunkDeleteAttribute: any
    thunkAsyncCreateAttribute: any
    thunkCreateAttributeNoRedux: any
    updateSession: any
    updateTempAttribute: typeof updateTempAttribute
    attributes: AttributeItem[]
}

interface AttributeEditFormState {
    // initAttribute: AttributeItem | null
    mobileValuesShow: boolean
    config: AttributeConfigItem
    configEdited: boolean
    isUnitMillimeter: boolean
    validation: Validation
}

interface Validation {
    name: "input-blue" | "input-blue input-error",
    mastersize: "input-blue" | "input-blue input-error",
    ot: "input-blue" | "input-blue input-error",
    upperWarnLimit: "input-blue" | "input-blue input-error",
    nm: "input-blue" | "input-blue input-error",
    lowerWarnLimit: "input-blue" | "input-blue input-error",
    ut: "input-blue" | "input-blue input-error",
    angles: any[]
}

class AttributeEditForm extends React.Component<AttributeEditFormProps, AttributeEditFormState> {

    constructor(props:AttributeEditFormProps){
        super(props);
        const {system} = this.props;
        const isUnitMillimeter = system.settings.usedUnits === UsedUnitsEnum.MM;
        const unitFactor = isUnitMillimeter ? 1: 1/25.4;
        const attr = this.props.system.tempAttribute        
        this.state = {
            // initAttribute: null,
            mobileValuesShow: false,
            config:  attr ? this.transformConfig2Value(attr.config, unitFactor) : null,
            configEdited: false,
            isUnitMillimeter: isUnitMillimeter,
            validation: {
                name: "input-blue",
                mastersize: "input-blue",
                ot: "input-blue",
                upperWarnLimit: "input-blue",
                nm: "input-blue",
                lowerWarnLimit: "input-blue",
                ut: "input-blue",
                angles: ['', '', '']
            }
        }
    }

    //*******************************************************
    // lifecycle
    //*******************************************************

        componentDidMount(){
            // if(!this.state.initAttribute){
            //     const attr = cloneDeep(this.props.getAttribute(this.props.attributeId))
            //     this.setState({
            //         initAttribute: {
            //             ...attr
            //         }
            //     })
            // }
            window.addEventListener('beforeunload', (e) => this.handleBeforeUnload(e))
        }

        componentWillUnmount(){
            window.removeEventListener("beforeunload", this.handleBeforeUnload);
        }
        

    //*******************************************************
    // interaction
    //*******************************************************

        handleBeforeUnload(event:any){
            const e = event || window.event;
            e.preventDefault();
            if (e) {
                e.returnValue = ''; // Legacy method for cross browser support
            }
            return ''
        }

        changeInputTextValue(target:any, value:any, callback:any = null, e:any = null ){
            if(target === 'name' && value.length > 80){
                const validation = ['nameMustBeShorterThan80']
                this.props.validationLoad(validation)
                return
            }
            
            if(!this.props.system.tempAttribute) return
            let newValue = this.isString(target, value) ? value : '';
            const newAttribute = set(this.props.system.tempAttribute, target, newValue)
            this.props.updateTempAttribute(newAttribute, true)
            if(callback) callback()
        }

        changeInputTasterValue(target:any, value:any, callback:any = null, e:any = null ){
            let newValue = this.isString(target, value) ? value : '';
            const newAttribute = set(this.props.attribute, target, newValue.toString())
            this.props.updateTempAttribute(newAttribute, true)
            if(callback) callback()
        }

        changeAngleValue(payload:any){

            const {index, value} = payload
            const cleaned = value.trim().replace(/[^0-9.]/g,"").slice(0, 3) //the angle.lentgh cannot be > 3

            let angles = [...this.state.config.angles]            
            angles[index] = Number(cleaned)

            // the third angle always needs to add up to 360
            angles[0] = 360 - angles[1] - angles[2]
            
            const newConfig = {
                ...this.state.config,
                angles
            }
            this.setState({
                config : newConfig,
                configEdited: true
            })
        }

        changeInputParamValue(target:any, value:string){
            
            let cleaned = this.props.usedDelimeter === UsedDelimiterEnum.DecimalPoint ? value.trim().replace(/[^0-9.-]/g,"") : value.trim().replace(/[^0-9,-]/g,"");
            cleaned = cleaned.toString()

            //check if user starts typing with , or .
            if(cleaned === "," || cleaned==='.'){
                cleaned = ''
            }

            //check if multiple comas or dots in the string
            if(cleaned.split(",").length - 1 > 1){
                cleaned = cleaned.slice(0,cleaned.lastIndexOf(','))
            }
            if(cleaned.split(".").length - 1 > 1){
                cleaned = cleaned.slice(0,cleaned.lastIndexOf('.'))
            }

            const matchDot = cleaned.indexOf('.');
            if (matchDot >= 0 && (cleaned.length + matchDot) > 8) {
                cleaned = cleaned.slice(0,matchDot + 6 + 1)
            }
            const matchKomma = cleaned.indexOf(',');
            if (matchKomma >= 0 && (cleaned.length + matchKomma) > 8) {
                cleaned = cleaned.slice(0,matchKomma + 6 + 1)
            }

            if(cleaned === '') console.log('empty string ', cleaned);
            
            
            // const newConfig = set(this.state.config, target, cleaned);
            const newConfig:any = {
                ...this.state.config,
                [target]: cleaned
            }
            
            this.setState({
                config : newConfig,
                configEdited: true
            })
        
        }

        handleDecimalPlaces(increase:boolean){
            const newAttribute:AttributeItem = {
                ...this.props.attribute
            }
            if(increase){
                newAttribute.config.decimalPlaces = Number(newAttribute.config.decimalPlaces) + 1 
            } else {
                newAttribute.config.decimalPlaces = Number(newAttribute.config.decimalPlaces) - 1 
            }
            this.props.updateTempAttribute(newAttribute, true)
        }

        isString(target:any, value:any){
            return target === 'name' || value === '' || target === 'config.type' || target === 'config.setting'
        }

        transformValue(value: string | number): string {
            if(value === '') return '';
            try {
                const val = this.props.usedDelimeter === UsedDelimiterEnum.DecimalPoint ? value.toString() : value.toString().replace(',','.')
                return val.toString();
            } catch (error) {
                return '';
            }
        }
        
        transformValue2Save(value: string | number, unitFactor: number): number {
            let retValue: number = 0;
            try {
                const val = this.props.usedDelimeter === UsedDelimiterEnum.DecimalPoint ? value.toString() : value.toString().replace(',','.')
                retValue = parseFloat(val)/ unitFactor;
                retValue = parseFloat((Math.round(retValue * 100000000)/100000000).toFixed(6));
                return retValue
            } catch (error) {
                return  0;
            }

        }
        transformSave2Value(value: string | number, unitFactor: number): string {
            
            if(value === 'NaN' || value === '' || value === null){
                return '' // we need this to show an emtpy form input and not a NaN will be filled with this value
            }

            let retValue: number = 0;
            try {
                retValue = parseFloat(value.toString()) * unitFactor;
                retValue = parseFloat((Math.round(retValue * 100000000)/100000000).toFixed(6));
                const val2 =   this.props.usedDelimeter ===  UsedDelimiterEnum.DecimalPoint ?  retValue.toString() : retValue.toString().replace('.', ',')
                return val2;
            } catch (error) {
                console.log('error', error)
                return ''
            }

        }

        transformConfig2Value(newConfig: any, unitFactor:number = 1) {
             // make numbers out of strings if they should be numbers
             for (const key in newConfig) {
                if(['masterSize', 'ot', 'nm', 'ut', 'upperWarnLimit', 'lowerWarnLimit'].includes(key)){
                    newConfig[key] = this.transformSave2Value(newConfig[key], unitFactor)

                }
             }
             return newConfig
        }

        handleSubmit(e:any, unitFactor:number = 1):void {

            //submit works as follows:
            // the component will be called from <Attributes /> mostly
            // - We build an empty Attribute object (if adding a new attribute) / or copy an existing one (if editing)
            // - store it temporarely in the redux store
            // - the "config" part of the object is being edited in the state of this component

            e.preventDefault()
            const updateObject:AttributeItem = cloneDeep(this.props.attribute);
            // TODO: code
            updateObject.config.masterSize = this.transformValue(this.state.config.masterSize);
            updateObject.config.nm = this.transformValue(this.state.config.nm);
            updateObject.config.ot = this.transformValue(this.state.config.ot);
            updateObject.config.ut = this.transformValue(this.state.config.ut);
            updateObject.config.upperWarnLimit = this.transformValue(this.state.config.upperWarnLimit)
            updateObject.config.lowerWarnLimit = this.transformValue(this.state.config.lowerWarnLimit)
            updateObject.config.angles = this.state.config.angles

            if (this.isValid(updateObject)){

                // reset the reference
                    const reference = {
                        timestamp: 0,
                        isReferenced: false
                    }
                    updateObject.reference = reference

                // reset the sensors reference if they have been referencered before
                    let newSensors:SensorItem[] = []
                    updateObject.sensors.forEach(sensor => {
                        const newSensor = { ...sensor };
                        if(sensor.reference.isReferenced) { 
                            newSensor.reference.referenceValue = 0
                        }
                        newSensors.push(newSensor);
                    })
                    updateObject.sensors = newSensors

                // reset the measurement if they have been measured before
                    const newMeasurement = {
                        timestamp: 0,
                        attributeValue: 0,
                        measurementChart: ''
                    }
                    updateObject.measurement = newMeasurement


                // make numbers out of strings if they should be numbers
                    const newConfig:any = updateObject.config
                    for (const key in newConfig) {
                        if(['masterSize', 'ot', 'nm', 'ut', 'upperWarnLimit', 'lowerWarnLimit'].includes(key)){
                            // TODO: remove newConfig[key] = parseFloat(newConfig[key])
                            newConfig[key] = this.transformValue2Save(newConfig[key], unitFactor)
                        }
                    }
                    updateObject.config = newConfig

                    const updateCompareObject = {...updateObject, sensors: updateObject.sensors.map( s => {
                        return {
                            uuid: s.uuid,
                            name: s.name,
                            position: s.position,
                            active: true,
                            attributeId: s.attributeId,
                            reference: s.reference,
                        }
                    }) }
                
                if(updateCompareObject.isDraft === true){
                    // if the attribute was just created (doesnt exist in the DB)
                    // 1. set the flag 
                    updateCompareObject.isDraft = false
                    // 2. 
                    this.props.thunkAsyncCreateAttribute(updateCompareObject)
                } else {
                    // update the DB attribute
                    ( this.props.system.tempAttributeEdited || this.state.configEdited ) && this.props.thunkUpdateAttribute(updateObject)
                }

                // close the overlay
                this.props.overlayToggle()
                // clear the temporary attribute
                this.props.updateTempAttribute(null, false)

                // scroll to top
                window.scrollTo({
                    top: 0,
                });
            }
        }

        handleCancel():void {

            // hide the layer
                this.props.overlayToggle()

            // delete the temp attribute
                this.props.updateTempAttribute(null, false)

            // scroll to top
            window.scrollTo({
                top: 0,
            });
        }

        isValid(attribute:AttributeItem):any {

            const { sensors, name, uuid  } = attribute
            const { ot, ut, nm, lowerWarnLimit, upperWarnLimit, masterSize, sensorsCount, withWarnlimits, angles } = attribute.config
            
            let validationMessages:string[] = []
            let toBeValidated:any[] = []
            
            // angles
            //--------------------------------------------------------

                let sensorsToBeValidated

                if(
                    this.props.attribute.config.sensorsCount === 3 && 
                    (angles[1] >= 359 || angles[1] === 0)
                ){
                    sensorsToBeValidated = {sensors:['', 'input-error', '']}
                    validationMessages.push('ThisAngleMustBeSmallerOrEqual358AndBiggerThan0')
                }

                if(
                    this.props.attribute.config.sensorsCount === 3 && 
                    (angles[1] + angles[2] >= 360)
                ){
                    sensorsToBeValidated = {sensors:['', 'input-error', 'input-error']}
                    validationMessages.push('TheAnglesSumMustBeSmallerThan360')
                }

                if(sensorsToBeValidated) toBeValidated.push(sensorsToBeValidated)


            if(name.toString().trim() === ''){
                toBeValidated.push('name')
                validationMessages.push('ValidateNameRequired')
            }

            if(!isStringUniqueInTheCollection(name.toString().trim(), 'name', this.props.attributes, uuid)){
                validationMessages.push('ThisAttributeNameAlreadyExists')
                toBeValidated.push('name')
            }

            if(!ot){
                toBeValidated.push('ot')
                validationMessages.push('ValidateUpperToleranceRequired')
            }

            if(ut === ''){
                toBeValidated.push('ut')
                validationMessages.push( 'ValidateLowerToleranceRequired')
            }

            if(!nm) {
                toBeValidated.push('nm')
                validationMessages.push( 'ValidateNominalValueRequired')
            }

            if(!masterSize){
                toBeValidated.push('mastersize')
                validationMessages.push( 'ValidateMasterValueRequired')
            }
            
            if(
                ot && 
                ut &&
                Number(ot) <= Number(ut)
            ) {
                toBeValidated.push('ot', 'ut')
                validationMessages.push( 'ValidateUpperHigherThanLowerTolerance')
            }

            if(
                withWarnlimits && 
                upperWarnLimit !== '' && 
                lowerWarnLimit !== '' && 
                Number(upperWarnLimit) <= Number(lowerWarnLimit)
            ) {
                toBeValidated.push('upperWarnLimit', 'lowerWarnLimit')
                validationMessages.push( 'ValidateUpperHigherThanLowerWarnLimit')
            }

            if(
                withWarnlimits && 
                ot &&
                upperWarnLimit &&
                upperWarnLimit !== '' && 
                Number(ot) < Number(upperWarnLimit)
            ) {
                toBeValidated.push('upperWarnLimit', 'ot')
                validationMessages.push( 'ValidateUpperWarnLimitSmallerThanUpperTolerance')
            }

            if(
                withWarnlimits && 
                lowerWarnLimit !== '' && 
                Number(lowerWarnLimit) <= Number(ut)
            ) {
                toBeValidated.push('lowerWarnLimit', 'ut')
                validationMessages.push( 'ValidateLowerWarnLimitHigherThanLowerTolerance')
            }
            
            for (let i = 0; i < sensorsCount; i++) {
                const sensor = sensors[i]
                const isReferenced = sensor.reference.isReferenced
                if(!isReferenced) {
                    validationMessages.push('ValidateSensorRequired')
                    break
                }
            }

            if(validationMessages.length > 0){
                this.setAsNotValid(toBeValidated)
                this.props.validationLoad(validationMessages)
                return false
            } 

            return true
        }

        setAsNotValid(toBeValidated:string[]){
            
            let newValidation:any = {
                ...this.state.validation,
            }

            //if inputs
            Object.keys(newValidation).forEach((key, index) => {
                if (toBeValidated.includes(key)){
                    //if form inputs
                    newValidation[key] = "input-blue input-error"
                } else {
                    // reset validation classes
                    if(key === 'angles') newValidation[key] = ['', '', '']
                    else newValidation[key] = "input-blue"
                }
            });

            //if sensors
            toBeValidated.every((item:any) => {
                if(typeof item === 'object'){
                    newValidation.angles = item.sensors
                    return false
                }
                return true
            });

            this.setState({
                validation: {
                    ...newValidation
                }
            })

        }

        handleToggleOptions():void {
            const newAttribute:AttributeItem = {
                ...this.props.attribute
            }
            newAttribute.config.withWarnlimits = !newAttribute.config.withWarnlimits
            this.props.updateTempAttribute(newAttribute, true);        
        }        

        handleTasterAmount(value:any) {
            let amount:1|2|3 = 3
            if(value === AttributeSettingEnum.LENGTH){
                amount = 2
            }
            this.resetChannels(amount)
        }

        resetChannels(amount:1|2|3, e:any = undefined, ):void {

            if(e !== undefined) e.preventDefault()
            this.changeSensorCount(amount, e)
        }

        changeSensorCount(count:number, e:any){
            
            if(e !== undefined) e.preventDefault()

            const { config } = this.props.attribute

            //build new attribute
            const newAttribute:AttributeItem = cloneDeep(this.props.attribute)
            newAttribute.config.sensorsCount = Number(count)

            
            //if the attribute was configured before 
            // we have to set it to false becouse we have an additional channel
            if(Number(config.sensorsCount) === 2 && Number(count) === 3){
                newAttribute.config.pocketConfigFinished = false
            }

            // if from 3 to 2 sensors and two of the channels are stoppers
            // we need to make sure, the new config wont have to stoppers only
            if(
                Number(config.sensorsCount) === 3 && 
                Number(count) === 2 &&
                newAttribute.sensors[0].reference.channelId === 0 &&
                newAttribute.sensors[1].reference.channelId === 0 
            ){
                newAttribute.sensors[1].reference.channelId = newAttribute.sensors[2].reference.channelId 
            }

            //if two sensors -> lets clear the third unused sensor
            if(Number(config.sensorsCount) === 3 && Number(count) === 2){
                newAttribute.sensors[2].reference.channelId = null
                newAttribute.sensors[2].reference.isReferenced = false
            }

            this.props.updateTempAttribute(newAttribute, true);
        }

        toggleMobileValues(){
            this.setState({
                mobileValuesShow: !this.state.mobileValuesShow,
            })
        }

        resetActiveTasterChannel(){
            const newAttribute:AttributeItem = { ...this.props.attribute }
            newAttribute.sensors.forEach((sensor:SensorItem) => {
                sensor.localIsActive = false
            })
            this.props.updateTempAttribute(newAttribute, null);
        }

        updateFirstFreeChannel(){
            const newAttribute:AttributeItem = { ...this.props.attribute }
            this.props.updateTempAttribute(markFirstFreeChannel(newAttribute), null);
        }

    // Rasp
    //--------------------------------------------------------

        renderSubDecimals = () => {
            return (
                <Translation>
                    { t =>
                    <div className="mb-md-5">
                        <label className="d-none d-md-block mb-2">{t('Decimals')}</label>
                        <Counter 
                            min = {1}
                            max = {AppConfig.features.fixedDecimal}
                            current = {this.props.attribute?.config.decimalPlaces}
                            onIncrement = {() => {this.handleDecimalPlaces(true)}}
                            onDecrement = {() => {this.handleDecimalPlaces(false)}}
                            isMillimeter = {this.props.system.settings.usedUnits === UsedUnitsEnum.MM}
                        />
                    </div>
                    }
                </Translation>
            )
        }


    //*******************************************************
    // render
    //*******************************************************

    render() {

        const {system} = this.props
        const isUnitMillimeter = system.settings.usedUnits === UsedUnitsEnum.MM;
        const unitFactor = isUnitMillimeter ? 1: 1/25.4
        const isConfigActive = system.attributeEditFormMode === attributeEditFormMode.CONFIG
        const isModeConfigOptions = this.props.attribute?.config.withWarnlimits
        const cssForConfigOptions = isModeConfigOptions ? ' animate--appear' : ' animate--disappear'
        const showOnMobileIfValuesVisisble = this.state.mobileValuesShow ? 'd-block' : 'd-none d-md-block'
        const hideOnMobileIfValuesVisisble = this.state.mobileValuesShow ? 'd-none d-md-block' : 'd-block'
        const cssForWrapper = isConfigActive ? 'attribute_form--config' : 'attribute_form--default'

        const onFocusBlur = (editThis: any) => {
            const isMobile = editThis.props.system.platform.android || editThis.props.system.platform.ios;
            isMobile && editThis.props.overlayPositionToggle()
        }

        return(
            <>
            <Translation>
                { t =>
                    <form 
                        className="h-100 container-fluid p-0" 
                        onSubmit = { (e:any) => { this.handleSubmit(e, unitFactor) }} 
                        autoComplete="off"
                    >
                        <div className={'attribute_form ' + cssForWrapper} id="attribute-edit-form-wrapper">

                            {/* // header
                            //-------------------------------------------------------- */}

                            <div className= {'row no-gutters mb-3 attribute_form__item attribute_form__item--for-default attribute_form__header'}>
                                <div className={'col-7 col-lg-4 ml-auto'}>
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        data-target="name"
                                        placeholder= {t('FeatureName')}
                                        className={this.state.validation.name}
                                        value = { this.props.attribute?.name } 
                                        onChange = { (e:any) => this.changeInputTextValue(e.target.dataset.target, e.target.value,  null, e) }
                                        onFocus={ () => onFocusBlur(this) }
                                        onBlur={ () => onFocusBlur(this) }
                                    />
                                </div>
                                
                                <div className={ 'col-5 col-lg-4 text-right d-flex justify-content-end align-items-center '}>
                                    <button type="button" className="btn btn--icon btn--icon-close bg-action-dark mr-3" onClick={ () => this.handleCancel() }></button>
                                    <button className="btn btn--icon btn--icon-check btn--negative" type="submit"></button>
                                </div>
                                
                            </div>

                            {
                            // this is a mobile switch for the values and attribute config
                            }
                            <div className="d-md-none attribute_form__mobileSwitch">
                                <ButtonGroupToggle 
                                    options = { [
                                        {
                                            value: 'values',
                                            label: t('Values'),
                                        },
                                        {
                                            value: 'attribute',
                                            label: t('Feature'),
                                        },
                                    ] }
                                    onChangeHandler = { () => { this.toggleMobileValues() }}
                                    show = { true }
                                    current = { this.state.mobileValuesShow ? 'values' : 'attribute' }
                                    styles = 'btn--transparent btn--dark'
                                    wrapperStyles = 'group-btn--even group-btn--corners w-100'
                                />
                            </div>

                            <div className="text-right attribute_form__apply">
                                    <button 
                                        onClick = { (e) => { 
                                            e.preventDefault()
                                            this.resetActiveTasterChannel()
                                            this.props.setAttributeEditFormMode(attributeEditFormMode.DEFAULT) 
                                        }} 
                                        className="btn btn--negative d-inline-block"
                                    >
                                        {t('Apply')}
                                    </button>
                                </div>

                            {/* // main
                            //-------------------------------------------------------- */}
                            <div className="row attribute_form__main">
                                <div className={ hideOnMobileIfValuesVisisble + ' col-md-6 col-lg-3 order-1 order-md-0'}>
                                    <div className="attribute_form__item attribute_form__item--for-default attribute_form__toggles">
                                        <ButtonGroupToggle 
                                            options = { [
                                                {
                                                    value: 'DIAMETER',
                                                    label: t('Diameter'),
                                                },
                                                {
                                                    value: 'LENGTH',
                                                    label: t('Length'),
                                                },
                                            ] }
                                            onChangeHandler = { (target:any, value:any) => {
                                                this.changeInputTasterValue(target, value, () => {
                                                    this.handleTasterAmount(value) 
                                                }) 
                                            }}
                                            show = { true }
                                            current = { this.props.attribute?.config.setting }
                                            styles = 'btn--dark mb-3'
                                            target = 'config.setting'
                                            wrapperStyles = 'group-btn--even w-100'
                                        />
                                        <ButtonGroupToggle 
                                            options = { [
                                                {
                                                    value: 'OUTER',
                                                    label: t('OuterDimension'),
                                                },
                                                {
                                                    value: 'INNER',
                                                    label: t('InnerDimension'),
                                                },
                                            ] }
                                            onChangeHandler = { (target:any, value:any) => { this.changeInputTasterValue(target, value) } }
                                            show = { true }
                                            current = { this.props.attribute?.config.type }
                                            styles = 'btn--dark mb-3'
                                            target = 'config.type'
                                            wrapperStyles = 'group-btn--even w-100'
                                        />

                                        {
                                            this.props.attribute?.config.setting === AttributeSettingEnum.DIAMETER &&
                                            <ButtonGroupToggle 
                                                options = { [
                                                    {
                                                        value: 2,
                                                        css: 'btn--icon-taster2'
                                                    },
                                                    {
                                                        value: 3,
                                                        css: 'btn--icon-taster3'
                                                    },
                                                ] }
                                                onChangeHandler = { (value:any) => { this.resetChannels(value) }}
                                                show = { true }
                                                current = { this.props.attribute?.config.sensorsCount }
                                                styles = 'btn--dark mb-3'
                                                wrapperStyles = 'group-btn--even w-100'
                                            />
                                        }

                                        {this.renderSubDecimals()}

                                    </div>
                                </div>
                                <div className= { hideOnMobileIfValuesVisisble + ' col-md-8 offset-md-2 order-md-1 col-lg-4 offset-lg-1 order-lg-0' }>
                                    <div className="mb-3">
                                        {this.props.attribute &&
                                            <Taster 
                                                attribute = { this.props.system.tempAttribute }
                                                attributeId = { this.props.attributeId }
                                                showInputs =  { this.props.attribute?.config.sensorsCount > 2 }
                                                viewOnly = { false }
                                                hideChannelsDeleteIcons = { this.props.system.attributeEditFormMode === attributeEditFormMode.DEFAULT }
                                                angleHandler = {(e:any) => {this.changeAngleValue(e)}}
                                                angles = {this.state.config.angles}
                                                angleValidation = {this.state.validation.angles}
                                            />
                                        }
                                    </div>
                                    <div className="text-center ">
                                        <div className="mb-4 mb-md-3 attribute_form__item attribute_form__item--for-default attribute_form__assign">
                                            <button 
                                                onClick = { (e) => { 
                                                    e.preventDefault()
                                                    this.updateFirstFreeChannel()
                                                    this.props.setAttributeEditFormMode(attributeEditFormMode.CONFIG) 
                                                }} 
                                                className="btn btn--negative d-inline-block"
                                            >
                                                {t('SensorEdit')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                

                                {
                                // FORM
                                //--------------------------------------------------------
                                }
                                <div className={ showOnMobileIfValuesVisisble + ' col-md-6 col-lg-3 offset-lg-1' }>
                                    <div className="attribute_form__item attribute_form__item--for-default attribute_form__values">
                                        <p className="mb-3 mb-md-4 input-control-label">
                                            <label htmlFor="mastersize">{t('Mastersize')}</label>
                                            <input 
                                                className={this.state.validation.mastersize}
                                                type="text" 
                                                placeholder={t('Mastersize')}
                                                data-target="masterSize"
                                                value= { this.state.config?.masterSize === 'NaN' ? '' : this.state.config?.masterSize}
                                                onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                name="mastersize"
                                                onFocus={ () => onFocusBlur(this) }
                                                onBlur={ () => onFocusBlur(this) }
                                            />
                                        </p>
                                        <div className="strong">
                                            <RadioToggle 
                                                labelMain= {t('WarnLimits')}
                                                value="1"
                                                onChangeHandler={ () => { this.handleToggleOptions() }}
                                                checked = { this.props.attribute?.config.withWarnlimits }
                                                theme = 'blue'
                                            />
                                        </div>
                                        <p className="input-control-label">
                                            <label htmlFor="ot">{t('UpperTolerance')}</label>
                                            <input 
                                                className={this.state.validation.ot}
                                                type="text" 
                                                placeholder= {t('UpperTolerance')}
                                                data-target="ot"
                                                value= {this.state.config?.ot}
                                                onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                name="ot"
                                                onFocus={ () => onFocusBlur(this) }
                                                onBlur={ () => onFocusBlur(this) }
                                            />
                                        </p>
                                        {   
                                            <div className={'animate' + cssForConfigOptions}>
                                                <p className="input-control-label">
                                                    <label htmlFor="upperWarnLimit">{t('UpperWarnlimit')}</label>
                                                    <input 
                                                        className={this.state.validation.upperWarnLimit}
                                                        type="text" 
                                                        placeholder={t('UpperWarnlimit')}
                                                        data-target="upperWarnLimit"
                                                        value= {this.state.config?.upperWarnLimit}
                                                        onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                        name="upperWarnLimit"
                                                        onFocus={ () => onFocusBlur(this) }
                                                        onBlur={ () => onFocusBlur(this) }
                                                    />
                                                </p>
                                            </div>
                                        }
                                        <p className="input-control-label">
                                            <label htmlFor="nm">{t('Nominalsize')}</label>
                                            <input 
                                                className={this.state.validation.nm}
                                                type="text" 
                                                placeholder={t('Nominalsize')} 
                                                data-target="nm"
                                                value= {this.state.config?.nm}
                                                onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                name="nm"
                                                onFocus={ () => onFocusBlur(this) }
                                                onBlur={ () => onFocusBlur(this) }
                                            />
                                        </p>
                                        {   
                                            <div className={'animate' + cssForConfigOptions}>
                                                <p className="input-control-label">
                                                    <label htmlFor="lowerWarnLimit">{t('LowerWarnlimit')}</label>
                                                    <input 
                                                        className={this.state.validation.lowerWarnLimit}
                                                        type="text" 
                                                        placeholder={t('LowerWarnlimit')}
                                                        data-target="lowerWarnLimit"
                                                        value= {this.state.config?.lowerWarnLimit}
                                                        onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                        name="lowerWarnLimit"
                                                        onFocus={ () => onFocusBlur(this) }
                                                        onBlur={ () => onFocusBlur(this) }
                                                    />
                                                </p>
                                            </div>
                                        }
                                        <p className="input-control-label">
                                            <label htmlFor="ut">{t('LowerTolerance')}</label>
                                            <input 
                                                className={this.state.validation.ut}
                                                type="text" 
                                                placeholder={t('LowerTolerance')}
                                                data-target="ut"
                                                value= {this.state.config?.ut}
                                                onChange= { (e:any) => this.changeInputParamValue(e.target.dataset.target, e.target.value) }
                                                name="ut"
                                                onFocus={ () => onFocusBlur(this) }
                                                onBlur={ () => onFocusBlur(this) }
                                            />
                                        </p>
                                    </div>
                                </div>  
                            </div>

                            <AttributeEditFormPocket 
                                styles = "attribute_form__item taster__pocket"
                                channels = { this.props.channels }
                                attributeId = { this.props.attributeId }
                                attribute = { this.props.attribute }
                                subscription = { isConfigActive }
                                settings = { this.props.system.settings}
                            />
                        </div>
                    </form>
                }
            </Translation>            
            </>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
    channels: getChannelsAll(state),
    system: state.system,
    getAttribute: getAttribute(state),
    attribute: state.system.tempAttribute,
    attributes: state.attributes.items
});

const dispatchToProps = {
    overlayToggle,
    overlayPositionToggle,
    setAttributeEditFormMode,
    changeAttributeMode,
    validationLoad,
    thunkUpdateAttribute,
    thunkDeleteAttribute,
    thunkAsyncCreateAttribute,
    thunkCreateAttributeNoRedux,
    updateSession,
    updateTempAttribute
};

export default connect(mapStateToProps, dispatchToProps)(AttributeEditForm);