import React from 'react';
import { ChannelItem, MeasureObject } from '../../store/channels/types';
import { AttributeItem } from '../../store/attributes/types';
import RaspChannel from '../ui/Rasp/RaspChannel';
import { MessageContext } from '../../context';
import { Subscription } from 'rxjs';
import { AppSettings, UsedDelimiterEnum, UsedUnitsEnum } from '../../store/system/types';


interface LocalProps {
  styles: string
  channels: ChannelItem[]
  attributeId: string
  attribute: AttributeItem
  subscription : boolean
  settings : AppSettings
}

interface LocalState {
    channels: number[],
    timestamp: number,
}

class FormPocket extends React.Component<LocalProps, LocalState> {

    constructor(props:LocalProps){
        super(props);
        this.state = {
            channels: [],
            timestamp: 0,
        }
    }

    static contextType = MessageContext; 
    private subChannels?:Subscription;

    componentDidMount(){
        this.startChannelsSubscription()
    }

    componentDidUpdate(prevProps:LocalProps){

        if(prevProps.subscription !== this.props.subscription){
            if(this.props.subscription){
                this.startChannelsSubscription()
            } else {
                this.stopChannelsSubscription()
            }
        }
    }

    startChannelsSubscription(){
        this.subChannels = this.context.onChannels().subscribe((msg: MeasureObject) => {
            this.setState({
                channels:msg.values,
                timestamp: msg.timeStamp
            })
        });
    }

    stopChannelsSubscription(){
        this.subChannels?.unsubscribe()
    }

    getReference(channel:any){
        let reference = false

        // if pocket configuration is finished lets mark all of the channels as disabled
        if(this.props.attribute.config.pocketConfigFinished){
            reference = true

        // else only the ones that were already used 
        } else {
            this.props.attribute.sensors?.map( (sensor:any) => {
                if(sensor.reference.channelId === channel.id){
                    reference = true
                } 
                return {}
            })
        }
        
        return reference
    }
	
  	render() {

        if(!this.props.attribute) return null
        
		return (
			<div className={this.props.styles + ' taster__pocket '}>
                {
                    this.props.channels?.map( (channel:ChannelItem, index:number) => {
                        const rawValue = this.state.channels[index - 1] ? this.state.channels[index - 1]: 0;
                        const transValue = channel.invers ? rawValue * -1 * channel.inversFactor : rawValue * channel.inversFactor;
                        const dPlaces = this.props.settings.usedUnits === UsedUnitsEnum.MM ? this.props.attribute.config.decimalPlaces : this.props.attribute.config.decimalPlaces + 2;
                        const rawValueFormated = this.props.settings.usedDelimiter === UsedDelimiterEnum.DecimalPoint ? transValue.toFixed(dPlaces).toString() : rawValue.toFixed(dPlaces).toString().replace('.', ',')
                        return (
                            <RaspChannel
                                key={index} 
                                attributeId = { this.props.attributeId }
                                attribute = { this.props.attribute }
                                assignedTo = { this.getReference(channel) }
                                channel = { channel } 
                                value = { rawValueFormated }
                                styles="rasp_channel"
                            />
                        )
                    })
                }
            </div>
		);
  	}
}

export default FormPocket;