import { t } from 'i18next';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../constants/i18n';
import darkInfo from "../../../img/icons-png/icon-info-darkmode.png";
// import "../../../scss/components/naming.scss"

export type listItemsObjectType = { 
    singular: {
        value: string
        defaultValue: string
        onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    },
    plural: {
        value: string
        defaultValue: string
        onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    }
}

interface NamingRowItemProps {
    onFocusBlur: any
    labelMain?: string
    theme?: 'blue' | 'default'
    separator?: boolean
    styles?: string
    listItems?: listItemsObjectType
}

class NamingRowItem extends React.Component<NamingRowItemProps> {

    render() {

        const id =  uuidv4();
        let css = this.props.theme === 'blue' ? 'radio-toggle--blue' : '';
        let cssLabel = this.props.theme === 'blue' ? 'radio-toggle__label--reset ' : '';
        if(this.props.separator) css += ' radio-toggle--separator';
        if(this.props.styles) css += ' ' + this.props.styles;

        const { listItems, labelMain } = this.props;

        return(
            <div className= { css + ' radio-toggle radio-toggle--pointer mb-1' }>
                <label htmlFor= {id}>
                    
                    <div className="col d-flex align-items-center justify-content-center">
                        <div className="naming-row-container">

                            { labelMain &&
                                <span
                                    className={ cssLabel + 'radio-toggle__label mb-3 d-block'}
                                    style={  { fontSize:  '1.1em' } }
                                    >
                                        { `${i18n.t('Naming')} "${labelMain}"` }
                                </span>
                            }

                            { listItems &&
                                <div className='naming-row-input-container' style={{  justifyContent:'space-between'}}>
                                    <input
                                        className='input-blue naming-input top'
                                        name={'singular'}
                                        type="text"
                                        value={listItems.singular.value}
                                        placeholder={i18n.t('Singular')} 
                                        onChange={ listItems.singular.onChangeHandler }
                                        onFocus={ () => this.props.onFocusBlur() }
                                        onBlur={ () => this.props.onFocusBlur() }
                                    />
                                    <input
                                        className='input-blue naming-input'
                                        name={'plural'}
                                        type="text"
                                        value={listItems.plural.value}
                                        placeholder={i18n.t('Plural')} 
                                        onChange={ listItems.plural.onChangeHandler }
                                        onFocus={ () => this.props.onFocusBlur() }
                                        onBlur={ () => this.props.onFocusBlur() }
                                    />
                                </div>
                            }

                            { !listItems && 
                                <div className='info-block'>
                                        <img className='info-block info-block__img' src={darkInfo} alt="" />
                                        <div className='info-block info-block__div'>
                                            <p>{t('NamingExportInfo1')}</p>
                                            <p>{t('NamingExportInfo2')}</p>
                                        </div>
                                </div>
                            }

                        </div>
                    </div>
                </label>
            </div>
        );
    };
}

export default NamingRowItem;