// Describing the shape of the system's slice of state
export interface MachinesState {
    items : MachineItem[]
  }

export interface MachineItem {
    uuid: string,
    name: string,
    reference: string,
    imageUrl?: string,
    isDraft: boolean,
    nests: NestItem[],
    timestamp: number,
    isDeleted: boolean
}

export interface NestItem {
    uuid: string,
    name: string,
    reference: string,
    workbenchUuid: string,
    isDraft: boolean,
    timestamp: number,
}

  // Describing the different ACTION NAMES available
  export const LOAD_MACHINES = "LOAD_MACHINES";
  export const UPDATE_MACHINE = "UPDATE_MACHINE"
  export const ADD_MACHINE = "ADD_MACHINE";
  export const DELETE_MACHINE = "DELETE_MACHINE";
  export const ADD_MACHINE_NEST = "ADD_MACHINE_NEST";
  export const DELETE_MACHINE_NEST = "DELETE_MACHINE_NEST";
  export const UPDATE_MACHINE_NEST = "UPDATE_MACHINE_NEST"

interface LoadMachinesAction {
    type: typeof LOAD_MACHINES;
    payload: MachineItem[];
}

interface DeleteMachineAction {
    type: typeof DELETE_MACHINE;
    payload: string;
}

interface DeleteMachineNestAction {
    type: typeof DELETE_MACHINE_NEST;
    payload: {parentId: string, id: string};
}

interface AddMachineAction {
    type: typeof ADD_MACHINE;
    payload: MachineItem;
}

interface UpdateMachineAction {
    type: typeof UPDATE_MACHINE;
    payload: MachineItem;
}

interface AddMachineNestAction {
    type: typeof ADD_MACHINE_NEST;
    payload: NestItem;
}

interface UpdateMachineNestAction {
    type: typeof UPDATE_MACHINE_NEST;
    payload: NestItem;
}


export type MachinesActionTypes = LoadMachinesAction | AddMachineAction | DeleteMachineAction | UpdateMachineAction | AddMachineNestAction | DeleteMachineNestAction | UpdateMachineNestAction;
