import React from 'react';
import dark from './../../../img/icons-png/icon-user-darkmode.png'
import light from './../../../img/icons-png/icon-user-lightmode.png'

interface IconUserProps {
    light: boolean
}

const IconUser = (props:IconUserProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="user" />
)

export default IconUser