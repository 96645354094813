import { AttributeItem, LOAD_ATTRIBUTES, UPDATE_ATTRIBUTE, DELETE_ATTRIBUTE, ADD_ATTRIBUTE, UPDATE_LIVE_DATA } from "./types";
import { MeasureObject } from "../channels/types";

const loadAttributes = (attributes: AttributeItem[]) => {
  return {
    type: LOAD_ATTRIBUTES,
    payload: attributes
  };
}

const deleteAttribute = (id: string) => {
  return {
    type: DELETE_ATTRIBUTE,
    payload: id
  }
}

const addAttribute = (attr: AttributeItem) => {
  return {
    type: ADD_ATTRIBUTE,
    payload: attr
  }
}

const updateAttribute = (attribute: AttributeItem) => {
  console.log('UpdateAttribute:', attribute);
  
  return {
    type: UPDATE_ATTRIBUTE,
    payload: attribute
  }
}

const updateLiveData = (msg: MeasureObject) => {
  return { 
    type: UPDATE_LIVE_DATA,
    payload: msg
  }
}

export { loadAttributes, addAttribute, deleteAttribute, updateAttribute, updateLiveData } 