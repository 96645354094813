import React from 'react';
import { QdasMeasurementEntry } from '../qdas-types';
import RadioToggleNaked from '../../ui/RadioToggleNaked';
import { QdasInputType } from '../../settings/features/types';
import { useTranslation } from 'react-i18next';

const PopUpConfigMeasurementRow = ({ q, handleChange, isDarkTheme }:
    {
        q: QdasMeasurementEntry,
        handleChange: ( type: QdasInputType, text?: string ) => void,
        isDarkTheme: boolean
    }) => {
        const {t} = useTranslation();
        const pill1 = q.isActive ? t('ACTIVE') : t('INACTIVE');
        const pill2 = q.isRetain ? t('RETAIN') : t('NO RETAIN');

    const inputStyle: React.CSSProperties | undefined = undefined;

    return (
        <div
            className="col"
            >
            <div
                className="row"
                >
                <div className="col-2" style={{paddingTop:'0.5em'}} >
                    <p className="h3 strong" style={ isDarkTheme ? undefined : { color: '#001662'} } >{q.code}</p>
                </div>
                <div className="col-9" style={{paddingTop:'0.5em'}} >
                    <input 
                        type="text" 
                        id={q.uuid}
                        value={q.text}
                        placeholder={ q.name }
                        className={`input-blue mb-3`}
                        style={inputStyle}
                        onChange={e=>{e.stopPropagation(); handleChange( QdasInputType.TEXT_INPUT, e.target.value)}}
                        onClick={e => {
                            e.stopPropagation();
                        }}
                    />
                </div>
            </div>
            <div
                className="row"
                >
                <div className="col-2 "/>
                <div className="col-5 " >
                    <div className="row pl-3">
                        <span className="badge badge--for-toggle mr-2 mt-3 badge--fluid pl-3 pr-3">{ pill1 }</span>
                        <RadioToggleNaked
                            value={q.isActive}
                            checked={q.isActive}
                            index={q.uuid+'1'}
                            onChangeHandler={(e:any)=>{e.stopPropagation(); handleChange( QdasInputType.TOGGLE_ACTIVE, e.target.value)}}
                            />
                    </div>
                 </div>
                <div className="col-4 ">
                    <div className="row">
                        <span className="badge badge--for-toggle mr-2 mt-3 badge--fluid pl-3 pr-3">{ pill2 }</span>
                        <RadioToggleNaked
                            value={q.isRetain}
                            checked={q.isRetain}
                            index={q.uuid+'2'}
                            onChangeHandler={(e:any)=>{e.stopPropagation(); handleChange( QdasInputType.TOGGLE_RETAIN, e.target.value)}}
                            />
                    </div>
                 </div>
            </div>
            <div className="w-100 mt-3 mb-4" style={{height: 1, backgroundColor:'grey'}}></div>
        </div>
    )
}

export default PopUpConfigMeasurementRow;
