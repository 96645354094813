import React from 'react';
import dark from './../../../img/icons-png/icon-parts-darkmode.png'
import light from './../../../img/icons-png/icon-parts-lightmode.png'

interface IconPartsProps {
    light: boolean
}

const IconParts = (props:IconPartsProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="part" />
)

export default IconParts