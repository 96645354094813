import React from 'react';
import dark from './../../../img/icons-png/icon-measure-darkmode.png'
import light from './../../../img/icons-png/icon-measure-lightmode.png'

interface IconHomeProps {
    light?: boolean
}


const IconHome = (props:IconHomeProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="home" />
)

export default IconHome