import React from 'react'
import { connect } from 'react-redux';
import { TFunction, Translation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { updateSession } from '../../store/system/action';
import { thunkUpdateMachineRef, thunkUpdateMachineNestRef } from '../../store/machines/thunks';

import { SystemState } from '../../store/system/types';
import { PartItem } from '../../store/parts/types';
import { AttributeQdas, PartQdas, QdasEntry, QdasMachineEntry, QdasMeasurementExportEntry } from './qdas-types';
import { AttributeItem } from '../../store/attributes/types';
import { MachineItem } from '../../store/machines/types';
import { MeasurementFlatItem, QueryResultInterface } from '../../store/statistics/types';
import { thunkUpdatePart } from '../../store/parts/thunks';
import { thunkUpdateAttribute } from '../../store/attributes/thunks';

import Button from '../ui/Button';
import PopUpRow from './UI/PopUpRow';
import PopUpMachineRow from './UI/PopUpMachineRow';
import QdasButton from './UI/QdasButton';
import PopUpMeasurementRow from './UI/PopUpMeasurementRow';

import { refreshQdasAttributeEntries, refreshQdasMachineEntries, refreshQdasMeasurementExportEntries, refreshQdasPartEntries } from './helper/refresh-functions';
import { handleSaveAll } from './helper/save-delete';
import NamingHelper from '../settings/naming/naming-helper';
import NamingContext from '../../context/naming/naming-context'
import { MACHINE_NEST } from '../../constants/lang/translation-keys';
import { qdasMandatoryRow } from '../statistics/export/data/constants';


interface QdasPopUpProps {
    part?: PartItem
    attribute?: AttributeItem
    machine?: MachineItem
    measurements: QueryResultInterface<MeasurementFlatItem>
    currPartId?: string | null
    acceptMeasurement?: {
        measureHandler: ( data: string ) => void
        show: boolean
        saveButtonClasses: string
        isQdasMandatoryFieldsValid: ()=>boolean
    }
    isConfig?: boolean
    label?: string
    description :string
    updateSession: typeof updateSession;
    thunkUpdateMachineRef: any
    thunkUpdateMachineNestRef: any
    thunkUpdatePart: any
    thunkUpdateAttribute: any
    system: SystemState
    reference?: React.LegacyRef<HTMLButtonElement>
    pinButton?: boolean
    rowButton?: boolean
    t: TFunction<string>
    children?: any
}

interface QdasPopUpState {
    show: boolean
    optionalPartEntries: PartQdas[]
    optionalAttrEntries: AttributeQdas[]
    qdasEntriesCurr: string
    qdasEntriesTemp: QdasEntry[]
    qdasMachineEntriesCurr: QdasMachineEntry[]
    qdasMachineEntriesTemp: QdasMachineEntry[]
    qdasMeasurementExportEntries: QdasMeasurementExportEntry[]
}

class QdasPopUp extends React.Component<QdasPopUpProps, QdasPopUpState> {

    constructor(props:QdasPopUpProps){
        super(props)
        this.state = {
            show: false,
            optionalPartEntries: [],
            optionalAttrEntries: [],
            qdasEntriesCurr: '',
            qdasEntriesTemp: [],
            qdasMachineEntriesCurr: [],
            qdasMachineEntriesTemp: [],
            qdasMeasurementExportEntries: []
        }
    }

    // lifecycle
    //--------------------------------------------------------

        componentDidMount(){
            this.refreshAll();
        }



    // methods
    //--------------------------------------------------------

    refreshAll(){
        if (this.props.part) {
            refreshQdasPartEntries(this);
        }
        if (this.props.machine) {
            refreshQdasMachineEntries(this);
        }
        if (this.props.attribute) {
            refreshQdasAttributeEntries(this);
        }
        if (this.props.acceptMeasurement) {
            refreshQdasMeasurementExportEntries(this, this.props.system.qdasMeasureExportEntries, this.props.currPartId);
        }
    }

    handleChange(qdasRowUuid: string, text: string, selfThis: this ){
        const { part, attribute, machine, acceptMeasurement } = this.props;
        if ( part || attribute ) {
            let qdasEntriesTemp = [...selfThis.state.qdasEntriesTemp];
            const foundRow = qdasEntriesTemp.find(q=>q.uuid===qdasRowUuid);
            if (foundRow) {
                qdasEntriesTemp = qdasEntriesTemp.map(q=>{
                    if (q.uuid===qdasRowUuid) {
                        // if the row is mandatory, the first part of the text must not be edited, e.g. 'K1003 '
                        const foundMandatory = qdasMandatoryRow.find( r => r.key === qdasRowUuid.slice(0,5));
                        if (foundMandatory) {
                            const unchangebleText = foundMandatory.key + ' ';
                            if (text.slice(0,6)===unchangebleText) {
                                return { ...q, text };
                            } else {
                                return q;
                            }
                            
                        } else {
                            return { ...q, text };
                        }
                        
                    } else return q;
                })
                selfThis.setState({ qdasEntriesTemp });
            }
        }
        if ( machine ) {
            let qdasMachineEntriesTemp = [...selfThis.state.qdasMachineEntriesTemp];
            const foundRow = qdasMachineEntriesTemp.find(q=>q.uuid===qdasRowUuid);
            if (foundRow) {
                qdasMachineEntriesTemp = qdasMachineEntriesTemp.map(q=>{
                    if (q.uuid===qdasRowUuid) {
                        return { ...q, text };
                    } else return q;
                })
                selfThis.setState({ qdasMachineEntriesTemp });
            }
        }
        if ( acceptMeasurement ) {
            let qdasMeasurementExportEntries = [...selfThis.state.qdasMeasurementExportEntries];
            const foundRow = qdasMeasurementExportEntries.find(q=>q.uuid===qdasRowUuid);
            if (foundRow) {
                qdasMeasurementExportEntries = qdasMeasurementExportEntries.map(q=>{
                    if (q.uuid===qdasRowUuid) {
                        return { ...q, textInput: text };
                    } else return q;
                });
                selfThis.setState({ qdasMeasurementExportEntries });
            }
        }
        
    }

    handleAddRow( selfThis: this ){
        const qdasEntriesTemp = [...selfThis.state.qdasEntriesTemp];
        const lastPosition = qdasEntriesTemp[qdasEntriesTemp.length-1].position;
        qdasEntriesTemp.push({
            uuid: uuidv4(),
            position: lastPosition + 1,
            readonly: false,
            text: ``,
            isDeleted: false
        });
        selfThis.setState({ qdasEntriesTemp });
    }

    handleDeleteRow(qdasRowUuid: string, selfThis: this ){
        const qdasEntriesTempCurr = [...selfThis.state.qdasEntriesTemp];
        const qdasEntriesTemp: QdasEntry[] = [];
        const foundRow = qdasEntriesTempCurr.find(q=>q.uuid===qdasRowUuid);
        if (foundRow) {
            qdasEntriesTempCurr.forEach(q=>{
                if (q.uuid!==qdasRowUuid) {
                    qdasEntriesTemp.push({...q});
                }
            });
            selfThis.setState({ qdasEntriesTemp });
        }
    }

    handleClickSaveMeasurements(){
        if (this.props.acceptMeasurement) {
            const qdasMeasureExportEntries = this.state.qdasMeasurementExportEntries;
            this.props.updateSession({...this.props.system, qdasMeasureExportEntries });
            const jsonData = JSON.stringify(qdasMeasureExportEntries);
            setTimeout(() => {
                this.props.acceptMeasurement && this.props.acceptMeasurement.measureHandler(jsonData);
            }, 0);
        }
    }

    handleOverlayClick(e:any){
        e.stopPropagation();
        //if not clicked into the content area close the overlay
        if(!e.target.closest('.qdaspopup__content')){
            this.close(e)
        }
    }


    handleAcceptMeasurement(e: React.MouseEvent<HTMLButtonElement, MouseEvent>){
        // check part, measured attributes, machine and nest for qdas mandatory fields
        if (this.props.acceptMeasurement) {
            if ( this.props.acceptMeasurement.isQdasMandatoryFieldsValid() ) {
                if (this.state.qdasMeasurementExportEntries.length === 0) {
                    this.handleClickSaveMeasurements();
                } else {
                    this.open(e);
                }
            } else {
                const overlay = { ...this.props.system.overlay };
                overlay.validation.show = true;
                this.props.updateSession({ ...this.props.system, overlay })
            }
        }
    }


    // helpers
    //--------------------------------------------------------

    open(e:any):void{

        e.preventDefault();
        e.stopPropagation();
        
        const updadatedSession = {...this.props.system }
        updadatedSession.overlay.dropdown.show = true
        updadatedSession.overlay.dropdown.name = this.props.description.replace(/ä/g,"a").replace(/ /g,"-").toLowerCase();
        this.props.updateSession(updadatedSession)
        
        this.setState({
            show:true
        })

        this.refreshAll();
    }

    close(e: React.MouseEvent<HTMLSpanElement, MouseEvent>):void{

        e.stopPropagation();

        const updadatedSession = {...this.props.system }
        updadatedSession.overlay.dropdown.show = false
        this.props.updateSession(updadatedSession)
        updadatedSession.overlay.dropdown.name = null

        this.setState({
            show:false
        })

        this.refreshAll();
    }


    // render 
    //--------------------------------------------------------
    
    render() {
            

        let wrapperCss = 'qdaspopup';

        const qdaspopupCss = this.state.show ? ' qdaspopup__overlay--active' : '';

        const contentCss = this.props.acceptMeasurement ? 'qdaspopup__content qdaspopup__content--with-footer' : 'qdaspopup__content ';

        let show = this.props.acceptMeasurement?.show ?? false;
        const saveButtonClasses = this.props.acceptMeasurement?.saveButtonClasses ?? '';
        const saveButtonClassesPopUp = () => {
            let returnValue = '';
            this.state.qdasMeasurementExportEntries.forEach( e => {
                if ( !e.textInput.trim() ) {
                    returnValue = ' disabled';
                }
            } );
            return returnValue;
        } 

        const getNaming = NamingHelper.getNaming;

        return(
            <Translation>
                { t =><>
                    {
                        this.props.acceptMeasurement &&
                        <Button
                            styles={'btn mb-1 mb-md-0 w-100 w-md-auto mr-auto max-w-40 align-self-center' + saveButtonClasses}
                            label={t('QdasAcceptMeasurement')}
                            show={show}
                            clickHandler={ (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleAcceptMeasurement(e)}
                        />
                    }
                    {
                        this.props.pinButton && 
                            <div style={{position: 'absolute', bottom: '10px', right: '10px', lineHeight: 20 }}>
                                <QdasButton selfThis={this}/>
                            </div>
                    }
                    <div className={wrapperCss}>
                        
                        { this.props.rowButton && <QdasButton selfThis={this}/> }

                        <div className={ 'qdaspopup__overlay' + qdaspopupCss } onClick={(e) => { this.handleOverlayClick(e) }}>
                            <div className={contentCss}>
                                <div className="row align-items-center qdaspopup__header">
                                    <div className="col-5 h3 strong mb-0 text-uppercase">{ this.props.description }</div>
                                    <div className="col-7 d-flex justify-content-end">
                                        {!this.props.acceptMeasurement && <span className={' btn btn--icon btn--icon-check mr-2'} onClick={ e => {handleSaveAll(this).then(()=>this.close(e))} }></span>}
                                        <span className={' btn btn--icon btn--icon-close'} onClick={ e => this.close(e) }></span>
                                    </div>
                                </div>
                                {this.state.show && 
                                    <div className="list qdaspopup__list">
                                        {this.state.qdasEntriesTemp.map((q, i)=>{
                                            const inputClassName = q.readonly ? 'dark' : 'blue'
                                            if (q.isDeleted) return null;
                                            return (
                                                <PopUpRow
                                                    key={q.uuid+i}
                                                    q={q}
                                                    i={i}
                                                    handleChange={ ( uuid, text ) => this.handleChange( uuid, text, this ) }
                                                    handleDelete={ uuid => this.handleDeleteRow( uuid, this ) }
                                                    inputClassName={ inputClassName }
                                                    inputPlaceholder={t('qdas_entry_placeholder')}
                                                />    
                                            )
                                        }) }
                                        {
                                            this.state.qdasMachineEntriesTemp.length !== 0 && 
                                                <div key={`headline`}
                                                className="row"
                                                    >
                                                    <div className="col-7" >
                                                        <NamingContext.Context.Consumer>
                                                        { namingContext => <p style={{fontStyle: 'italic', borderWidth: 0, paddingLeft: 15 }}>{getNaming(MACHINE_NEST, namingContext.naming)}</p>}
                                                        </NamingContext.Context.Consumer>
                                                    </div>
                                                    <div className="col-5" >
                                                        <p style={{ fontStyle: 'italic', borderWidth: 0, paddingLeft: 15, marginBottom: 5 }}>{t('QdasReference')}</p>
                                                        <p style={{ fontStyle: 'italic', borderWidth: 0, paddingLeft: 15 }}>{t('(max. 10 characters)')}</p>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            this.state.qdasMachineEntriesTemp.map((q, i)=>{
                                                const inputClassName = q.readonly ? 'dark' : 'blue';
                                                return (
                                                    <PopUpMachineRow
                                                        key={q.uuid+i}
                                                        q={q}
                                                        i={i}
                                                        handleChange={ text => this.handleChange(q.uuid, text, this) }
                                                        inputClassName={inputClassName}
                                                        inputPlaceholder={t('qdas_machine_entry_placeholder')}
                                                    />    
                                                )
                                            })
                                        }
                                        {
                                            this.props.acceptMeasurement && 

                                                <>
                                                    {
                                                       this.state.qdasMeasurementExportEntries.length === 0 &&
                                                       <div className="d-flex flex-column h-100 w-100 align-items-center justify-content-center text-center">
                                                        <p className="h3 strong">{t('NoExtraQdasRows')}</p>
                                                        <p className="">{t('GoToQdasSettings')}</p>
                                                       </div>
                                                    }
                                                    {
                                                        this.state.qdasMeasurementExportEntries.map((q, i)=>{
                                                            return (
                                                                <PopUpMeasurementRow
                                                                    key={q.uuid+i}
                                                                    q={q}
                                                                    handleChange={ text => this.handleChange(q.uuid, text, this) }
                                                                />    
                                                            )
                                                        })
                                                    }
                                                    <div className="qdaspopup__footer">
                                                        <div className="qdaspopup__footer col .d-flex align-items-center ">
                                                            <div
                                                            className={'btn max-w-40' + saveButtonClassesPopUp()}
                                                            style={ saveButtonClassesPopUp() ? { cursor: 'not-allowed'} : undefined}
                                                            onClick={e=>{
                                                                if (!saveButtonClassesPopUp()) {
                                                                    this.close(e);
                                                                    this.handleClickSaveMeasurements();
                                                                }
                                                                
                                                            }}
                                                            >
                                                                {t('AcceptMeasurement')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        {
                                            ( this.props.part || this.props.attribute ) &&
                                                <div
                                                    className="btn mb-1 mb-md-0 w-100 w-md-auto mr-auto max-w-40 align-self-center"
                                                    onClick={()=>this.handleAddRow(this)}
                                                >
                                                    {t('AddNewRow')}
                                                </div>
                                        }
                                        
                                    </div>
                                }   
                            </div>
                        </div>
                    </div>
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = (state: any) => ({
    system: state.system,
    measurements: state.statistics.measurements,
});

const dispatchToProps = {
    thunkUpdateMachineRef,
    thunkUpdateMachineNestRef,
    thunkUpdatePart,
    thunkUpdateAttribute,
    updateSession
};

export default connect(mapStateToProps, dispatchToProps)(QdasPopUp);