import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { overlayLoadComponent, overlayToggle } from '../../store/system/action';
import PinForm from './Pin/PinForm';
import { sha256 } from 'js-sha256'

interface InfoProps {
    labelMain: string
    labelSecondary?: string
    value: any
    theme?: 'blue' | 'default'
    separator?: boolean
    styles?: string
    easterEgg: any
    overlayToggle: typeof overlayToggle;
    overlayLoadComponent: typeof overlayLoadComponent;
}

interface InfoState {
    clicks: number
    timer: any
    timerOn: boolean
}

class Info extends React.Component<InfoProps, InfoState> {

    constructor(props:InfoProps){
        super(props);
        this.state = {
            clicks: 0,
            timer: null,
            timerOn: false
        }
    }

    resetClicks = () => {
        this.setState({
            clicks: 0
        })
    }

    resetTimer = () => {
        if(this.state.timer){
            window.clearTimeout(this.state.timer)
        }
    }

    stopTimer = () => {
        this.setState({
            timerOn: false
        })

        if(this.state.timer){
            window.clearTimeout(this.state.timer)
        }
    }

    isClicksEarned = () => {
        return this.state.clicks >= 2
    }

    handleClick = () => {
        
        if(!this.props.easterEgg) return

        if(this.isClicksEarned()){
            this.resetClicks()
            this.resetTimer()
            confirmAlert({
                closeOnEscape: true,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => this.confirmAlertCustomUI(onClose),
            })
        } else {
            this.setState({
                clicks: this.state.clicks + 1
            }) 
        }

        if(!this.state.timerOn){
            this.setState({
                timerOn: true,
                timer: setTimeout(() => {
                    this.resetClicks()
                    this.setState({
                        timerOn: false
                    })
                }, 3000)
            })
        }
    }

    confirmAlertCustomUI(onClose:any){  

        const currentPin = sha256.hex('1299');

        return (
            <Translation>
                {(t) => (
                    <div className='react-confirm-content'>
                        <h2 className="hsmall strong text-uppercase">{t('PinVerification')}</h2>
                        <PinForm 
                            hideForgotPin = { true }
                            currentPin = {currentPin}
                            handleCancel = {() => {onClose()}}
                            handleSubmit = {() => {
                                this.props.overlayLoadComponent(this.props.easterEgg)
                                this.props.overlayToggle()
                                onClose()
                            }}
                        />
                    </div>
                )}
            </Translation>
        )
    }

    render() {
        return(
            <div className= "radio-toggle">
                <div className="row">
                    <div className="col-8 d-flex flex-column justify-content-center">
                        <label className="radio-toggle__label mb-1 d-block">{ this.props.labelMain }</label>
                        {this.props.labelSecondary && <span className="radio-toggle__sublabel text-grey-dark d-block">{ this.props.labelSecondary }</span>}
                    </div>
                    <div className="col-4 d-flex flex-column justify-content-center align-items-end">
                        <button type="button" onClick={() => this.handleClick()} className="btn btn--reset">{this.props.value}</button>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state: InfoState) => ({});

const dispatchToProps = {
    overlayToggle, 
    overlayLoadComponent,
};

export default connect(mapStateToProps, dispatchToProps)(Info);