import { AttributeHistoryExport, NestHistoryExport, PartHistoryExport } from './../../../../store/statistics/types';
import {
  ExportDefaultInterface,
  MeasurementTransferInterface,
  DeviceChannelInterface,
  MachineTransferInterface,
  NestTransferInterface,
  PartTransferInterface,
  FeatureTransferInterface,
  MeasurementValuesInterface,
  MeasurementSensorInterface,
  DeviceCalibrationInterface,
} from "./types";
import {
  MeasurementItem,
  FeatureValueItem,
  MeasurementExportItem,
  ChannelHistory,
  WorkbenchHistoryExport,
} from "../../../../store/statistics/types";
import { Device } from "../../../../store/rasp/types";
import { uniqWith, isEqual } from 'lodash';
import { ChannelItem } from "../../../../store/channels/types";

export class BasicExport {
  protected _version: string;
  private _data: ExportDefaultInterface;
  private _channelsRaw: ChannelItem[]

  constructor() {
    this._version = "2020-09-10";
    this._data = this.initData();
    this._channelsRaw = [];
  }

  public data(): ExportDefaultInterface {
    return this._data;
  }

  public updateDevice(device: Device, channels: ChannelItem[], channelHistories: ChannelHistory[]): void {
    this._data.device.id = device.deviceId;
    this._data.device.name = device.name;
    this._data.device.model = device.model ? device.model : "";
    this._data.device.version = device.version ? device.version : "";
    this._data.device.channels = this.createChannelsExport(channels, channelHistories);
    this._channelsRaw = channels;
  }

  private createChannelsExport(channels: ChannelItem[], channelHistories: ChannelHistory[]): DeviceChannelInterface[] {
    const items: DeviceChannelInterface[] = [];
    channels
      .forEach((ch) => {
        const singleChannelHistories = channelHistories.filter( c => c.channelUuid===ch.uuid ).sort( ( c1, c2 ) => c1.timestamp < c2.timestamp ? -1 : 1 );
        const calibrations: DeviceCalibrationInterface[] = singleChannelHistories.map( ( chanHist, i, array ) => {
          const calibration: DeviceCalibrationInterface = {
            // id: chanHist.uuid,
            "valid-from": this.dateToString(chanHist.timestamp),
            factor: chanHist.calFactor,
            offset: chanHist.calTerm,
            inverse: chanHist.invers,
            "inverse-factor": chanHist.inversFactor,
            "is-active": ch.active,
            sensor: {
              type: ch.type,
              model: ch.model,
            },
          }
          // if there is a timewise subswquent history, then the 'valid-until' field must be filled
          const subsequentHistory = array[i+1];
          if (subsequentHistory) {
            calibration["valid-until"] = this.dateToString(subsequentHistory.timestamp);
          }
          return calibration;
        });
          
          
        const ele: DeviceChannelInterface = {
          id: ch.uuid,
          position: ch.position,
          name: ch.name,
          calibrations
        };
        items.push(ele);
      });

    return items;
  }

  private createValuesExport(
    values: FeatureValueItem[]
  ): MeasurementValuesInterface[] {
    const items: MeasurementValuesInterface[] = [];
    values.forEach((v) => {
      const sensors: MeasurementSensorInterface[] = [];
      v.sensors.forEach((s) => {
        sensors.push({ "channel-id": s.channelUuid, value: s.value });
      });
      items.push({
        id: v.uuid,
        "measured-at": this.dateToString(v.measurementTimestamp),
        "feature-id": v.featureUuid,
        value: v.featureValue,
        sensors: sensors,
      });
    });
    return items;
  }

  public updateMeasurements(items: MeasurementItem[]): void {
    if (items.length === 0) {
      return;
    }
    this._data.measurements = [];
    const meas: MeasurementTransferInterface[] = [];
    let minTs = new Date().getTime();
    let maxTs = 0;
    items.forEach((m) => {
      if (minTs > m.measurementTimestamp) {
        minTs = m.measurementTimestamp;
      }
      if (maxTs < m.measurementTimestamp) {
        maxTs = m.measurementTimestamp;
      }
      
      if (m.featurevalues.length > 0) {
        meas.push({
          id: m.uuid,
          "finished-at": this.dateToString(m.measurementTimestamp),
          "machine-id": m.workbenchUuid,
          "nest-id": m.nestUuid,
          "part-id": m.partId,
          values: this.createValuesExport(m.featurevalues),
        });
      }
    });
    this._data.measurements = meas;
    this._data.export["measurements-from"] = this.dateToString(minTs);
    this._data.export["measurements-until"] = this.dateToString(maxTs);
  }

  public updateMachines(items: MeasurementExportItem[], wbHistoryItems: WorkbenchHistoryExport[], nestHistoryItems: NestHistoryExport[]): void {
    this._data.machines = this.createMachinesExport(items, wbHistoryItems)
    this._data.nests = this.createNestExport(items, nestHistoryItems);
  }

  public updatePartsAndFeatures( items: MeasurementExportItem[], partHistoryItems: PartHistoryExport[], attributeHistoryItems: AttributeHistoryExport[]): void {
    this._data.parts = this.createPartsExport(items, partHistoryItems);
    this._data.features = this.createFeatureHistory(items, attributeHistoryItems);
  }

  private createNestExport(items: MeasurementExportItem[], nestHistoryItems: NestHistoryExport[]): NestTransferInterface[] {

    const nestsInHistory = items.map( mmExpIt=> {
      const nestExportObject: NestTransferInterface = {
        id: mmExpIt.nestUuid,
        name: mmExpIt.nestHistoryName,
        "valid-from": this.dateToString(mmExpIt.nestHistoryTimestamp),
        "machine-id" : mmExpIt.workbenchUuid
      }
      const validUntil = nestHistoryItems.find( nhi => nhi.nestHistoryUuid === mmExpIt.nestHistoryUuid)?.timestampUntil;
      if (validUntil) nestExportObject['valid-until'] = this.dateToString(validUntil);
      return nestExportObject;
    })
    
    const uniqueNests = uniqWith<NestTransferInterface>(nestsInHistory,isEqual);
    return uniqueNests;
  }

  private createMachinesExport(items: MeasurementExportItem[], wbHistoryItems: WorkbenchHistoryExport[]): MachineTransferInterface[] {

    const workbenchExportItems: MachineTransferInterface[] = [];

    items.forEach( item => {
      const foundWbHistoryItem = wbHistoryItems.find( wbhi => wbhi.workbenchHistoryUuid===item.workbenchHistoryUuid );
      if (foundWbHistoryItem) {
        const newItem: MachineTransferInterface = {
          id: foundWbHistoryItem.workbenchUuid,
          "valid-from": this.dateToString(foundWbHistoryItem.timestamp),
          name: foundWbHistoryItem.name
        };
        if (foundWbHistoryItem.timestampUntil) {
          newItem['valid-until'] = this.dateToString(foundWbHistoryItem.timestampUntil);
        }
        workbenchExportItems.push(newItem);
      }
    })

    const uniqueMachines = uniqWith<MachineTransferInterface>(workbenchExportItems,isEqual).sort( (a,b)=> `${a.id}${a["valid-from"]}`<`${b.id}${b["valid-from"]}` ? -1 : 1 );

    return uniqueMachines;
  }

  private createFeatureHistory(items: MeasurementExportItem[], attributeHistoryItems: AttributeHistoryExport[]): FeatureTransferInterface[] {
    let featureItems: FeatureTransferInterface[] = [];

    const attributeHistoryIds: string[] = [];

    items.forEach( m => {
       m.featurevalues.forEach( fv => {
        const histId = fv.featureHistoryUuid;
        if ( !attributeHistoryIds.find( attHistId => attHistId === histId) ) {
          attributeHistoryIds.push(histId);
        }
      });
    })

    attributeHistoryIds.forEach( attHistId => {
      const foundAttributehistory = attributeHistoryItems.find( ahi => ahi.attributeHistoryUuid===attHistId );
      if (foundAttributehistory) {
        const newItem: FeatureTransferInterface = {
          id: foundAttributehistory.attributeUuid,
          "part-id": foundAttributehistory.partId,
          "valid-from": this.dateToString(foundAttributehistory.timestamp),
          name: foundAttributehistory.name,
          "is-required": Boolean(foundAttributehistory.isRequired),
          "upper-threshold": Number(foundAttributehistory.upperThreshold),
          "lower-threshold": Number(foundAttributehistory.lowerThreshold),
          "nominal-value": Number(foundAttributehistory.nominalValue),
          "master-value": Number(foundAttributehistory.masterValue),
          "significant-decimals": Number(foundAttributehistory.significantDecimals),
          type: String(foundAttributehistory.type).toLowerCase(),
          "diameter-type": String(foundAttributehistory.diameterType).toLowerCase(),
          "angle-p-1": Number(foundAttributehistory.angleP1),
          "angle-p-2": Number(foundAttributehistory.angleP2),
          "angle-p-3": Number(foundAttributehistory.angleP3),
          "sensors-count": Number(foundAttributehistory.sensorsCount),
          "reference-timestamp": Number(foundAttributehistory.referenceTimestamp),
          // "sensor-1-name": String(foundAttributehistory.sensor1name),
          // "sensor-2-name": String(foundAttributehistory.sensor2name),
          // "sensor-3-name": String(foundAttributehistory.sensor3name),
          "sensor-1-reference-value": Number(foundAttributehistory.sensor1referenceValue),
          "sensor-2-reference-value": Number(foundAttributehistory.sensor2referenceValue),
          "sensor-3-reference-value": Number(foundAttributehistory.sensor3referenceValue),
          // "sensor-1-is-inverse": Boolean(foundAttributehistory.sensor1isInverse),
          // "sensor-1-reference-factor": Number(foundAttributehistory.sensor1referenceFactor),
          // "sensor-2-is-inverse": Boolean(foundAttributehistory.sensor2isInverse),
          // "sensor-2-reference-factor": Number(foundAttributehistory.sensor2referenceFactor),
          // "sensor-3-is-inverse": Boolean(foundAttributehistory.sensor3isInverse),
          // "sensor-3-reference-factor": Number(foundAttributehistory.sensor3referenceFactor),
        };
        if (foundAttributehistory.timestampUntil) newItem['valid-until'] = this.dateToString(foundAttributehistory.timestampUntil);
        if (foundAttributehistory.upperWarnValue || foundAttributehistory.upperWarnValue === 0) newItem['upper-warn-value'] = Number(foundAttributehistory.upperWarnValue);
        if (foundAttributehistory.lowerWarnValue || foundAttributehistory.lowerWarnValue === 0) newItem['lower-warn-value'] = Number(foundAttributehistory.lowerWarnValue);
        
        if (foundAttributehistory.sensor1channelId || foundAttributehistory.sensor1channelId === 0) {
          const channel = this._channelsRaw.find( c => c.id === foundAttributehistory.sensor1channelId );
          if (channel) {
            newItem['sensor-1-channel-id'] = channel.uuid};
          } 
        if (foundAttributehistory.sensor2channelId || foundAttributehistory.sensor2channelId === 0) {
          const channel = this._channelsRaw.find( c => c.id === foundAttributehistory.sensor2channelId );
          if (channel) {
            newItem['sensor-2-channel-id'] = channel.uuid};
          }
        if (foundAttributehistory.sensor3channelId || foundAttributehistory.sensor3channelId === 0) {
          const channel = this._channelsRaw.find( c => c.id === foundAttributehistory.sensor3channelId );
          if (channel) {
            newItem['sensor-3-channel-id'] = channel.uuid};
          }
        featureItems.push(newItem);
      }
    })

    return uniqWith(featureItems, isEqual);
  }


  
  private createPartsExport( items: MeasurementExportItem[], partHistoryItems: PartHistoryExport[] ): PartTransferInterface[]  {
    
    const partItems: PartTransferInterface[] = [];

    items.forEach( item => {
      const foundParthistory = partHistoryItems.find( phi => phi.partHistoryUuid===item.partHistoryId );
      if (foundParthistory) {
        const newItem: PartTransferInterface = {
          id: foundParthistory.partUuid,
          "valid-from": this.dateToString(foundParthistory.timestamp),
          "part-number": foundParthistory.partNumber,
          "design-number": foundParthistory.designNumber,
          name: foundParthistory.name
        };
        if (foundParthistory.timestampUntil) {
          newItem['valid-until'] = this.dateToString(foundParthistory.timestampUntil);
        }
        partItems.push(newItem);
      }
    })
    
    const uniqueParts = uniqWith(partItems,isEqual);
    
    return uniqueParts;
  }

  private initData(): ExportDefaultInterface {
    return {
      export: {
        "measurements-from": "",
        "measurements-until": "",
        "exported-at": this.dateToString(),
        version: 1,
      },
      device: {
        id: "",
        name: "",
        model: "",
        version: "",
        channels: [],
      },
      machines: [],
      nests: [],
      parts: [],
      features: [],
      measurements: [],
    };
  }

  private dateToString(ts?: number): string {
    let dat = "";
    if (ts === 0) {
      return dat;
    }
    if (ts) {
      dat = new Date(ts).toISOString();
    } else {
      dat = new Date().toISOString();
    }
    if (dat.length >= 10) {
      dat = dat.slice(0, 19) + "+00:00";
    }
    return dat;
  }
}
