import { AttributeItem } from "../attributes/types";

// Describing the shape of the system's slice of state
  export interface PartsState {
      items : PartItem[]
      itemsLoaded: boolean
  }

  export interface PartItem {
    id: string,
    isDraft: boolean,
    partNumber: string,
    designNumber: string,
    name: string,
    dataQdas: string,
    imageUrl: string,
    attributes: AttributeItem[],
    attributesCount?: number;
    timestamp: number,
    isDeleted: boolean
  }

    // Describing the different ACTION NAMES available
    export const LOAD_PARTS = "LOAD_PARTS";
    export const DELETE_PART ="DELETE_PART";
    export const ADD_PART ="ADD_PART";
    export const UPDATE_PART ="UPDATE_PART";
    export const INCR_PART_ATTR_COUNT ="INCR_PART_ATTR_COUNT";

    
    interface LoadPartsAction {
      type: typeof LOAD_PARTS;
      payload: PartItem[];
    }

    interface DeletePartAction {
      type: typeof DELETE_PART;
      payload: string;
    }

    interface AddPartAction {
      type: typeof ADD_PART;
      payload: PartItem;
    }
    
    interface UpdatePartAction {
      type: typeof UPDATE_PART;
      payload: PartItem;
    }
    
    interface IncrPartAttributesCount {
      type: typeof INCR_PART_ATTR_COUNT;
      payload: { count: number, id: string };
    }

    
    export type PartsActionTypes = LoadPartsAction | DeletePartAction | AddPartAction | UpdatePartAction | IncrPartAttributesCount;