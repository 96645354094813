
import React from 'react';
import { connect } from 'react-redux';
import { updateTempAttribute } from '../../../store/system/action';
import { AppState } from '../../../store/reducers';
import { ChannelItem } from '../../../store/channels/types';
import { AttributeItem, SensorItem } from '../../../store/attributes/types';
import { Translation } from 'react-i18next';
import { isAttributePocketConfigured, markFirstFreeChannel } from '../../attributes/AttributeHelpers';

interface RaspChannelProps {
    channel: ChannelItem
    updateTempAttribute: any
    attributeId: any
    attribute: any
    assignedTo: boolean
    value?:number
    secondValue?: number
    styles?: any
    readonly?:boolean
    secondView?: boolean
}

interface RaspChannelState {
    isAnschlag: boolean
    useLimit : number
    css: string
}

class RaspChannel extends React.Component<RaspChannelProps, RaspChannelState> {

    constructor(props:RaspChannelProps){
        super(props);
        this.state = {
            isAnschlag: Number(this.props.channel.id) === 0,
            useLimit: Number(this.props.channel.id) === 0 && Number(this.props.attribute?.config.sensorsCount) === 3 ? 2 : 1,
            css: this.props.assignedTo ? 'rasp_channel--disabled' : ''
        }
    }

    //lets update the state if user changes the attribute config 
    componentDidUpdate(prevProps:RaspChannelProps){
        if(prevProps.attribute !== this.props.attribute){
            
            this.setState({
                useLimit: Number(this.props.channel.id) === 0 && Number(this.props.attribute.config.sensorsCount) === 3 ? 2 : 1,
            })

            this.isChannelAvailable()
        }
    }

    // interaction
    //--------------------------------------------------------
        
        onClickHandler(e:any){

            if((this.props.assignedTo && !this.state.isAnschlag) || this.props.readonly) return

            let newAttribute:AttributeItem = {
                ...this.props.attribute
            }
            
            //lets iterate through predefined sensors
            newAttribute.sensors.some((sensor:SensorItem, i:any) => {

                // console.log(i, sensor.localIsActive, this.isChannelAvailable());
                

                if(sensor.localIsActive && this.isChannelAvailable()){

                    const sensorActive:SensorItem = newAttribute.sensors[i]
                    sensorActive.reference = {
                        ...sensorActive.reference,
                        isReferenced: true,
                        channelId: this.props.channel.id
                    }

                    // disable the sensor referencing
                    sensorActive.localIsActive = false

                    // if all of the sensors are configured lets mark this
                    if(isAttributePocketConfigured(newAttribute)){
                        newAttribute.config.pocketConfigFinished = true
                    } else {
                        newAttribute.config.pocketConfigFinished = false
                    }

                    // get the next possible channel to assign
                    this.props.updateTempAttribute(markFirstFreeChannel(newAttribute), true)
                    
                    // update the whole attribute
                    this.props.updateTempAttribute(newAttribute, true)
                    return true;
                }
                return false;
            })
        }

    // helpers
    //--------------------------------------------------------

        isChannelAvailable(){
            // console.log('isChannelAvailable');
            
            let isAvailable = true
            let busyChannels = 0
            let anschlagCounter = 0
            let anschlagUsed = false
            let markedChannelExist = false

            //lets iterate through all 3 taster channels and gather some infos
            this.props.attribute.sensors.map( (sensor:SensorItem) => {

                if(sensor.reference.channelId === this.props.channel.id) {
                    anschlagCounter++
                }

                if(sensor.reference.channelId !== null) {
                    busyChannels++
                }

                if(sensor.reference.channelId === 0){
                    anschlagUsed = true
                }

                if(sensor.localIsActive){
                    markedChannelExist = true
                }

                return false

            })

            // console.log('busyChannels:', busyChannels, ' anschlagCounter:', anschlagCounter, ' anschlagUsed', anschlagUsed, 'markedChannelExist', markedChannelExist);
            
            
            if(anschlagCounter === this.state.useLimit || !markedChannelExist) isAvailable = false
            
            if(
                (this.props.attribute.config.pocketConfigFinished) || 
                (busyChannels === this.props.attribute.config.sensorsCount && this.state.isAnschlag && anschlagUsed) || 
                (this.props.assignedTo && !this.state.isAnschlag) ||
                !markedChannelExist
            ){ 
                this.setState({
                    css: 'rasp_channel--disabled'
                })
            } else if(isAvailable){
                this.setState({
                    css: ''
                })
            } else {
                this.setState({
                    css: 'rasp_channel--disabled'
                })
            }
            // console.log('isAvailable', isAvailable);
            // console.log('');
            
            
            return isAvailable
        }


    // render
    //--------------------------------------------------------

    render() {
        
        const channelIndexCss = this.state.isAnschlag ? 'rasp_channel__index--anschlag' : ''
        const style = this.props.styles ? this.props.styles + ' ' : ' '

        return(
            <>
            <Translation>
                {
                    t =>
                    <div 
                        className={ style + this.state.css }
                        onClick = { (e:any) => this.onClickHandler(e)}
                    >
                        <div className= { 'h3 rasp_channel__index ' + channelIndexCss }>
                            { this.props.channel.active && this.props.channel.id }
                        </div>
                        {
                            this.props.channel.active && 
                            <div className="h5 rasp_channel__value">
                                <span className={ this.state.isAnschlag ? 'badge badge--fluid' : 'badge strong' }> 
                                    { this.state.isAnschlag ? t('LimitStop') : this.props.value  }
                                </span>
                            </div>
                        }
                        {
                            (this.props.channel.active && this.props.secondView) && 
                            <div className="h5 rasp_channel__value">
                                <span className={ this.state.isAnschlag ? 'badge badge--fluid' : 'badge strong' }> 
                                    { this.state.isAnschlag ? t('LimitStop') : this.props.value  }
                                </span>
                            </div>
                        }
                    </div>
                }
            </Translation>

            </>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
});

const dispatchToProps = {
    updateTempAttribute
};

export default connect(mapStateToProps, dispatchToProps)(RaspChannel);