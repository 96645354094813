import { AppConfig } from "../../constants/app-config"
import { PartItem } from "../../store/parts/types"

export const newPart: PartItem = {
    id: '',
    isDraft: true,
    name: '',
    partNumber: '',
    designNumber: '',
    dataQdas: '',
    imageUrl: '',
    attributes: [],
    timestamp: 0,
    attributesCount: 0,
    isDeleted: false
}

export const getPartLabel = (item:PartItem) => {

    if(!item) return 'No Parts Yet'

    let { name, designNumber, partNumber } = item
    const afterfix = name.length > AppConfig.characterLimits.partName ? '...' : ''
    name = name ? name.slice(0, AppConfig.characterLimits.partName) + afterfix : ''
    designNumber = designNumber ? designNumber.slice(0, AppConfig.characterLimits.partName) + afterfix : ''
    partNumber = partNumber ? partNumber.slice(0, AppConfig.characterLimits.partName) + afterfix : ''

    let space1 = AppConfig.separators.nameSeparator
    let space2 = AppConfig.separators.nameSeparator

    if(!name) space1 = ''
    if(!designNumber) space2 = ''


    return `${name} ${space1} ${partNumber } ${space2} ${ designNumber }`
}