import React from 'react';
import dark from './../../../img/icons-png/icon-features-darkmode.png'
import light from './../../../img/icons-png/icon-features-lightmode.png'

interface IconObjectsProps {
    light: boolean
}

const IconObjects = (props:IconObjectsProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="icon"/>
)

export default IconObjects
 