import { LOAD_CHANNELS, ChannelsState, ChannelsActionTypes, UPDATE_CHANNEL, UPDATE_MEASUREMENT } from "./types";
import update from 'immutability-helper';

const EMPTY_MEASUREMENT = [1,2,3,4,5,6,7,8];

const initialState: ChannelsState = {
  items : [],
  measurement: {
    timeStamp: 0,
    values: EMPTY_MEASUREMENT,
  }
};

export function channelsReducer(
  state = initialState,
  action: ChannelsActionTypes
): ChannelsState {
  switch (action.type) {
    case LOAD_CHANNELS: {
      return Object.assign({}, state , {items : action.payload})
    }

    case UPDATE_CHANNEL: {
      const partIndex = state.items.findIndex(
        channel => channel.id === action.payload.id
      )
      return update(state, { items: {[partIndex]: {$set: action.payload}}})
    }

    case UPDATE_MEASUREMENT: {
      return {
        ...state,
        measurement: action.payload
      };
    }

    default:
      return state;
  }
}
