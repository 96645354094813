// Describing the shape of the system's slice of state
export interface ChannelsState {
    items : ChannelItem[],
    measurement: MeasureObject
  }

  export interface ChannelItem {
    id: number;
    uuid: string;
    position: number;
    name: string;
    calFactor: number;
    calTerm: number;
    active: boolean;
    invers: boolean;
    inversFactor: number;
    type: string;
    model: string;
    timestamp: number;
  }

  export interface MeasureObject {
    timeStamp: number;
    values: number[];
    rawValues?: number[]
  }

    // Describing the different ACTION NAMES available
    export const LOAD_CHANNELS = "LOAD_CHANNELS";
    export const UPDATE_CHANNEL ="UPDATE_CHANNEL"
    export const UPDATE_MEASUREMENT ="UPDATE_MEASUREMENT"

    interface LoadChannelsAction {
      type: typeof LOAD_CHANNELS;
      payload: ChannelItem[];
    }

    
    interface UpdateChannelsAction {
      type: typeof UPDATE_CHANNEL;
      payload: ChannelItem;
    }
   
    interface UpateMeasurementAction {
      type: typeof UPDATE_MEASUREMENT;
      payload: MeasureObject;
    }

    
    export type ChannelsActionTypes = 
      LoadChannelsAction | 
      UpdateChannelsAction |
      UpateMeasurementAction
      ;