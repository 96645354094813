import React from 'react';
import { Provider } from "react-redux";
import { Route, Switch } from 'react-router';
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from 'connected-react-router'
import { I18nextProvider } from "react-i18next";
import App from './components/App';
import i18n from "./constants/i18n";
import configureStore from "./configureStore";
import { history } from "./configureStore";
import { MessageContext } from './context/message/message-context';
import { SocketService } from './context/message/socket-service';
import NamingContext from './context/naming/naming-context'
import { AppConfig } from './constants/app-config';
import QumoFeaturesContext from './context/qumo-features/qumo-features-context';

const { persistor, store } = configureStore();
const messages = new SocketService(AppConfig.measurements.throttleTimeInMs);

export function ReduxRoot() {
	return (
        <NamingContext.Provider>
            <QumoFeaturesContext.Provider>
                <MessageContext.Provider value={messages}>
                    <I18nextProvider i18n={i18n}>
                        <Provider store={store}>
                            <PersistGate persistor={persistor} >
                                <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
                                    <> { /* your usual react-router v4/v5 routing */ }
                                        <Switch>
                                            <Route path="/" component={App} />
                                            {/* <Route exact path="/" render={()=> (<div>Match</div>)} /> */}
                                                <Route render={()=> (<div>Miss</div>)} />
                                        </Switch>
                                    </>
                                </ConnectedRouter>
                            </PersistGate>
                        </Provider>
                    </I18nextProvider>
                </MessageContext.Provider>
            </QumoFeaturesContext.Provider>
        </NamingContext.Provider>
	);
}
