import React from 'react'
import { connect } from 'react-redux';
import { TFunction, Translation } from 'react-i18next';

import { updateSession } from '../../../store/system/action';

import { SystemState } from '../../../store/system/types';
import { PopUpMode, QdasInputType } from './types';
import { QdasMeasurementEntry } from '../../qdas/qdas-types';
import { refreshQdasMeasurementEntries } from '../../qdas/helper/refresh-functions';
import PopUpConfigMeasurementRow from '../../qdas/UI/PopUpConfigMeasurementRow';
import { webapi } from '../../../api/web-api';
import Button from '../../ui/Button';


interface PopUpProps {
    label?: string
    description :string
    updateSession: typeof updateSession;
    system: SystemState
    reference?: React.LegacyRef<HTMLButtonElement>
    pinButton?: boolean
    t: TFunction<string>
    onRef?: any
    show: boolean
    closePopUp: ()=>void
    handleSubmitCode: ( event: React.MouseEvent<HTMLButtonElement, MouseEvent>, code: string )=>void
    popUpMode: PopUpMode
}

interface PopUpState {
    inputValue: string
    show: boolean
    qdasMeasurementEntries: QdasMeasurementEntry[]
    qdasMeasurementEntriesCurr: QdasMeasurementEntry[]
}

class FeaturesPopUp extends React.Component<PopUpProps, PopUpState> {

    constructor(props:PopUpProps){
        super(props)
        this.state = {
            inputValue: '',
            show: false,
            qdasMeasurementEntries: [],
            qdasMeasurementEntriesCurr: []
        }
    }

    // lifecycle
    //--------------------------------------------------------

        componentDidMount(){
            if (this.props.onRef) this.props.onRef(this);
            if (this.props.popUpMode===PopUpMode.CONFIG) {
                refreshQdasMeasurementEntries(this);
            }
        }



    // methods
    //--------------------------------------------------------

    handleChange( type: QdasInputType, uuid: string, selfThis: this, text?: string ){

        const stateTempEntries = [...selfThis.state.qdasMeasurementEntries];
        let entryWithRef = stateTempEntries.find(e=>e.uuid===uuid);
        if (entryWithRef !== undefined) {
            const entry = { ...entryWithRef };
            switch (type) {
                case QdasInputType.TEXT_INPUT:
                    if (text !== undefined) {
                        entry.text = text;
                    }
                    break;
                case QdasInputType.TOGGLE_ACTIVE:
                    entry.isActive = !entry.isActive;
                    break;
                case QdasInputType.TOGGLE_RETAIN:
                    entry.isRetain = !entry.isRetain;
                    break;
                default:
                    break;
            }

            const qdasMeasurementEntriesTemp = stateTempEntries.map( e => {
                if (e.uuid===uuid && entry) {
                    return entry;
                }
                return e;
            })

            selfThis.setState({ qdasMeasurementEntries: qdasMeasurementEntriesTemp });
        }
    }

    async handleSave( selfThis: this ){
        for (const tempEntry of selfThis.state.qdasMeasurementEntries) {
            const currEntry = selfThis.state.qdasMeasurementEntriesCurr.find( e => e.uuid===tempEntry.uuid);
            if ( currEntry && ( currEntry.text!== tempEntry.text || currEntry.isActive!== tempEntry.isActive  || currEntry.isRetain!== tempEntry.isRetain )) {
                const uri = `/template/${tempEntry.uuid}`
                try {
                    await webapi.client.put(uri, tempEntry)
                } catch (error) {
                    console.log('handleSave - Error: ', error);
                }
            }
        }
    }

    handleOverlayClick(e:any){
        e.stopPropagation();
        //if not clicked into the content area close the overlay
        if(!e.target.closest('.popup__content')){
            this.close(e)
            this.props.closePopUp();
        }

    }


    // helpers
    //--------------------------------------------------------

    open(e:any):void{

        e.preventDefault();
        e.stopPropagation();
        
        const updadatedSession = {...this.props.system }
        updadatedSession.overlay.dropdown.show = true
        updadatedSession.overlay.dropdown.name = this.props.description.replace(/ä/g,"a").replace(/ /g,"-").toLowerCase();
        this.props.updateSession(updadatedSession)


        if (this.props.popUpMode===PopUpMode.CONFIG) {
            refreshQdasMeasurementEntries(this);
        }
        
        this.setState({
            show:true
        })

    }

    close(e: React.MouseEvent<HTMLSpanElement, MouseEvent>):void{

        e.stopPropagation();

        const updadatedSession = {...this.props.system }
        updadatedSession.overlay.dropdown.show = false
        this.props.updateSession(updadatedSession)
        updadatedSession.overlay.dropdown.name = null

        this.setState({
            show:false
        })

        // refreshQdasMeasurementEntries(this);
    }


    // render functions
    //--------------------------------------------------------



    // render 
    //--------------------------------------------------------
    
    render() {
            
        const isDarkTheme = this.props.system.settings.appDarkMode;
        let wrapperCss = 'popup';

        const popupCss = this.props.show ? ' popup__overlay--active' : '';

        return(
            <Translation>
                { t =><>
                    
                    <div className={wrapperCss}>
                        
                        <div className={ 'popup__overlay' + popupCss } onClick={ e => this.handleOverlayClick(e) }>
                            <div className={'popup__content '} style={{maxHeight: '70%', overflow: 'scroll'}}>
                                <div className="row align-items-center popup__header">
                                    <div className="col-5 h3 strong mb-0 text-uppercase" style={ isDarkTheme ? undefined : { color: '#001662'} } >{ this.props.description }</div>
                                    <div className="col-7 d-flex justify-content-end">
                                        {this.props.popUpMode === PopUpMode.CONFIG && <span className={' btn btn--icon btn--icon-check mr-2'} onClick={ () => { this.props.closePopUp(); this.handleSave(this); } }></span>}
                                        <span className={' btn btn--icon btn--icon-close'} onClick={ () => this.props.closePopUp() }></span>  {/* CLOSE */}
                                    </div>
                                </div>
                                {
                                    this.props.popUpMode === PopUpMode.ACTIVATE &&
                                        <>
                                            <p className="text-center" style={ isDarkTheme ? undefined : { color: '#001662'} }>{t('InsertCode')}</p>
                                            <input className="w-80" value={this.state.inputValue} onChange={e=>this.setState({ inputValue: e.target.value })} type="text" />
                                            <Button
                                                styles="btn btn--card w-45 mt-2"
                                                clickHandler={(e:any)=>{
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    this.props.handleSubmitCode(e, this.state.inputValue);
                                                }}
                                                show
                                                label={t("SubmitCode")}
                                            />
                                        </>
                                }
                                <div className="w-100" style={{ overflow: 'scroll'}}>
                                {
                                    this.props.popUpMode === PopUpMode.CONFIG &&
                                        this.state.qdasMeasurementEntries.map((q, i)=>{
                                            return (
                                                <PopUpConfigMeasurementRow
                                                    key={q.uuid+i}
                                                    q={q}
                                                    handleChange={ (type, text) => this.handleChange(type, q.uuid, this, text)}
                                                    isDarkTheme={isDarkTheme}
                                                />    
                                            )
                                        })
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = (state: any) => ({
    system: state.system,
});

const dispatchToProps = {
    updateSession
};

export default connect(mapStateToProps, dispatchToProps)(FeaturesPopUp);