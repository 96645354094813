import { LOAD_PARTS, PartsState, PartsActionTypes, DELETE_PART, ADD_PART, UPDATE_PART, INCR_PART_ATTR_COUNT } from "./types";
import update from 'immutability-helper';

const initialState: PartsState = {
  items : [],
  itemsLoaded: false
};

export function partsReducer(
  state = initialState,
  action: PartsActionTypes
): PartsState {
  switch (action.type) {
    case LOAD_PARTS: {
      return Object.assign({}, state , {
        items : action.payload,
        itemsLoaded: true
      })
    }

    case DELETE_PART: {
      const filteredParts = state.items.filter(
        part => part.id !== action.payload
      );
      return update(state, {items: {$set: filteredParts}});
    }

    case ADD_PART: {
      const newPart = update(action.payload, {id: {$set: action.payload.id}})
      return update(state, {items: {$push: [newPart]}});
    }

    case UPDATE_PART: {
      const partIndex = state.items.findIndex(
        part => part.id === action.payload.id
      )
      return update(state, { items: {[partIndex]: {$set: action.payload}}})
    }

    case INCR_PART_ATTR_COUNT: {
      const partIndex = state.items.findIndex(
        part => part.id === action.payload.id
      )
      const updatedPart = state.items[partIndex];
      const attCount = updatedPart.attributesCount ? updatedPart.attributesCount : 0;
      updatedPart.attributesCount = attCount + action.payload.count;
      return update(state, { items: {[partIndex]: {$set: updatedPart}}})
    }

    default:
      return state;
  }
}
