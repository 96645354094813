export type ServerResponse = {
    status: boolean;
    message: string;
}

export enum ServerRespMessages {
    SUCCESS = 'UpdateSuccessful',
    UPDATE_FAILED = 'UpdateFailed',
    NO_FILE_UPLOADED = 'NoUpdateFile',
    SERVICE_ERROR = 'ServiceError',
    UNZIP_ERROR = 'UnzipError',
    FILE_INVALID = 'FileInvalid',
    UNKNOWN_ERROR = 'UnknownError'
}