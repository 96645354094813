import React from 'react';
import { Translation } from 'react-i18next';
import { AppSettings } from '../../store/system/types';
import PagingAction from '../ui/Paging/PagingAction';
import { TableBody } from './TableBody';
import SFH from './helper/sort-filter-helper';
import { AttributeHistory, MeasurementFlatItem } from '../../store/statistics/types';
import { TableHeader } from './TableHeader';
import EH from './export/export-helper';

export enum PaginationAction {
    FIRST = 'FIRST',
    PREV = 'PREV',
    NEXT = 'NEXT',
    LAST = 'LAST',
}

export type PageItem = {
    item: MeasurementFlatItem,
    attHistory: AttributeHistory | undefined
}

export type Pagination = {
    current: number
    total: number
    take: number
    pageItems: PageItem[],
}

type StatisticsBodyProps = {
    items: MeasurementFlatItem[]
    isLoading: boolean
    settings: AppSettings
    handleSortState: (_:SFH.SortOptions)=>void
    showSingleDeletePopUp: (statsThis: any, item: MeasurementFlatItem) => void
    statsThis: any
}

export const StatisticsBody: React.FC<StatisticsBodyProps> = (props) => {
    const { items, isLoading, settings, handleSortState, showSingleDeletePopUp, statsThis } = props;
    const { appDarkMode } = settings;
    const PAGINATION_TAKE = 10;
    const initialPagination: Pagination = {
        current: 1,
        total: 1,
        take: PAGINATION_TAKE,
        pageItems: []
    }
    const [pagination, setPagination] = React.useState(initialPagination);

    React.useEffect(()=>{
        handlePagination(PaginationAction.FIRST)
        .then( pagNew => setPagination(pagNew) );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[items]);

    const handlePagination = async (action: PaginationAction) => {
        let current = pagination.current;
        let pageItems: PageItem[] = [];
        switch (action) {
            case PaginationAction.PREV:
                if (pagination.current > 1) {
                    current = pagination.current - 1
                }
                break;
            case PaginationAction.NEXT:
                if (pagination.current < pagination.total) {
                    current = pagination.current + 1
                }
                break;
            case PaginationAction.LAST:
                current = pagination.total
                break;
            default: // FIRST
                current = 1;
                break;
        }
        const startIndex = (current - 1) * PAGINATION_TAKE;
        const endIndex = startIndex + PAGINATION_TAKE;
        // items only on the current page
        const pageSingleItems = items.slice(startIndex,endIndex);
        // get attribute history to display lower and upper warn limits
        let attrHistoryIds: string[] = [];
        pageSingleItems.forEach( pi => {
            const ahid = pi.featureHistoryUuid;
            const condition = attrHistoryIds.find( id => id===ahid)
            if (!condition) attrHistoryIds.push(ahid); // no duplicates
        })
        let attrHistoryArray: AttributeHistory[] = [];
        for (let i = 0; i < attrHistoryIds.length; i++) {
            const attributeHistory = await EH.getHistoryAttribute(attrHistoryIds[i]);
            attrHistoryArray.push(attributeHistory);
            
        }
        for (let i = 0; i < pageSingleItems.length; i++) {
            const item = pageSingleItems[i]
            const attHistory = attrHistoryArray.find( ath => ath.uuid === item.featureHistoryUuid );
            pageItems.push({ item, attHistory })
        }
        const pagNew = {
            current,
            total: Math.ceil(items.length/PAGINATION_TAKE),
            take: PAGINATION_TAKE,
            pageItems
        };
        return pagNew;
    }

    const initialState:{
        statisticsSortBy: string;
        statisticsOrder: 'ASC' | 'DESC';
    } = {
        statisticsSortBy: 'time',
        statisticsOrder: 'DESC'
    };
    const [state, setState] = React.useState(initialState);

    const handleSortOptions = (item:any)=>{
        const { statisticsSortBy, statisticsOrder } = state;
        let newOrder = statisticsOrder;
        if (statisticsSortBy===item.name) {
            newOrder = newOrder==='ASC' ? 'DESC' : 'ASC';
        }
        if (statisticsSortBy!==item.name) {
            switch (item.name) {
                case 'time':
                    newOrder = 'DESC'
                    break;
                default:
                    newOrder = 'ASC'
                    break;
            }
            
        }
        const options = SFH.createOptionsFromKeyAndOrder(item.name,newOrder);
        setState({
            statisticsSortBy: item.name,
            statisticsOrder: newOrder
        })
        handleSortState(options)
    }

    // const renderTableHeader = (t:TFunction<string>) => {
    //     return (
    //         <thead>
    //             <tr className="tr text-uppercase">
    //                 {
    //                     statisticsTableHeader.map((item, index: number) => {
    //                         const activeColor = appDarkMode ? 'white' : 'black'
    //                         const css = item.name === state.statisticsSortBy ? 'active' : ''
    //                         const color = item.name === state.statisticsSortBy ? activeColor : '#989DA2'
    //                         const spanCss = item.name === 'value' ? 'text-ellipsis limit' : ''
    //                         const headerCellWidth = {
    //                             width: `${item.width}%`,
    //                         };

    //                         return (
    //                             <th
    //                                 key={item.name + '-' + index}
    //                                 className={css + ' ' + (state.statisticsOrder).toLowerCase()}
    //                                 onClick={() => {
    //                                     if (!item.sort) return false
    //                                     handleSortOptions(item)
    //                                 }
    //                                 }
    //                                 style={headerCellWidth}
    //                             >
    //                                 <span className="d-flex">
    //                                     <span className={spanCss}>{t(item.label)}</span>
    //                                     {item.sort &&
    //                                         <span className="ml-2">
    //                                             <IconSort fill={color} />
    //                                         </span>
    //                                     }
    //                                 </span>
    //                             </th>
    //                         )
    //                     })
    //                 }
    //                 <th>{t('Chart')}</th>
    //             </tr>
    //         </thead>
    //     );
    // }

    const renderPagination = () => {

        return (
            <div className="app__main-sticky-footer">
                <div className="d-flex justify-content-center">
                    {
                        <PagingAction
                            count={items.length}
                            currentPage={pagination.current}
                            maxPage={pagination.total}
                            handlePagination={handlePagination}
                            setPagination={setPagination}
                            idle={isLoading}
                        />
                    }
                </div>
            </div>
        )
    }

    return (
        <Translation>
            { t =>
            <>
                <div className="app__main-content">
                    <div className="container-fluid">
                        <div className="table__wrapper mb-4">
                            <table className="table--promess">
                                        {/* {renderTableHeader(t)} */}
                                        <TableHeader appDarkMode={appDarkMode} handleSort={handleSortOptions} statisticsOrder={state.statisticsOrder} statisticsSortBy={state.statisticsSortBy}  />
                                        <TableBody items={pagination.pageItems} settings={settings} showSingleDeletePopUp={(s: any, measurement:MeasurementFlatItem)=>showSingleDeletePopUp(s, measurement)} statsThis={statsThis} />
                            </table>
                        </div>
                    </div>
                </div>
                {renderPagination()}
            </>
            } 
        </Translation>
    )
}
