import React from 'react';
import { TFunction, Translation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
// @ts-ignore
import Logo from '../ui/Logo';
import './browserLandingPage.scss';
import preval from 'preval.macro';
import axios from 'axios';
import { AppConfig } from '../../constants/app-config';
import { downloadCertificate, getAndroidManualInstallation, getAndroidOnboarding, getIosOnboarding, getMacOsOnboarding, getWindowsOnboarding } from '../connect/onboarding';
import LanguageSwitch from '../ui/LanguageSwitch';


interface BrowserLandingPageProps {
    osName: string;
    language: string
    setIsDev: (_:boolean)=>void
}

const BrowserLandingPage = ({osName, language, setIsDev}:BrowserLandingPageProps) => {
    const [ isLoading, setIsLoading ] = React.useState(true);
    const [isInstalling, setIsInstalling] = React.useState(false);
    const [isPwaInstalled, setPwaInstalled] = React.useState(true);
    const [deferredPrompt, setDeferredPrompt] = React.useState<any>();
    const [isCertificateValid, setIsCertificateValid] = React.useState(false);
    const refCertValid = React.useRef(isCertificateValid);
    refCertValid.current = isCertificateValid;
    // const [checkCertTimeout, setCheckCertTimeout] = React.useState(setTimeout(() => {}, 0));

    const beforeinstallpromptListener = (e: Event) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        console.log('beforeinstallprompt: ', e)
        // Stash the event so it can be triggered later.
        setPwaInstalled(false);
        setDeferredPrompt(e);
    }

    React.useEffect(()=>{

            console.log('adding PWA listener');

            // set theme to dark for proper design
            document.body.classList.add('dark-mode');
            document.body.classList.remove('light-mode');
    
            // listener: is app installed? event --> false
            window.addEventListener('beforeinstallprompt', beforeinstallpromptListener);

            let checkCertTimeout = setTimeout(() => {}, 0);
            const checkCertificate = () => {
                axios.get(AppConfig.certificate.checkUrl)
                .then(()=>{
                    setIsCertificateValid(true);
                })
                .catch(()=>{
                    setIsCertificateValid(false);
                })
                .finally(()=>{
                    if (!refCertValid.current) {
                        console.log('checkCertificate::result:', refCertValid.current);
                        const checktTimeout = setTimeout(() => {
                            checkCertificate();
                        }, 3000);
                        // setCheckCertTimeout(checktTimeout);
                        checkCertTimeout = checktTimeout;
                    }
                });
            }



        const loadTimeout = setTimeout(() => setIsLoading(false), 7000);
        checkCertificate();

        return ()=>{
            window.removeEventListener('beforeinstallprompt', beforeinstallpromptListener);
            clearTimeout(loadTimeout);
            clearTimeout(checkCertTimeout);
        }
    },[])

    const triggerPwaInstall = () => {
        console.log('triggerPwaInstall::invokingDeferredPrompt');
        setIsInstalling(true);
        deferredPrompt?.prompt()
        .then((res:any)=>{
            console.log('res: ', res);
            if (res.outcome === 'accepted') {
                console.log('triggerPwaInstall::userDialogAccepted');
                setTimeout(() => {
                    console.log('triggerPwaInstall::reloadingSite');
                    window.location.reload();
                }, AppConfig.onboarding.installTime); 
            }
            if (res.outcome === 'dismissed') {
                console.log('triggerPwaInstall::userDialogCanceled');
                setPwaInstalled(false);
                setIsInstalling(false);
            }
        })
        .catch((err:any)=>{
            console.log('triggerPwaInstallFailed::error:',err);
            setPwaInstalled(false);
            setIsInstalling(false);
        })
    }

    const isIOS = osName==='ios';

    const popUpCertInstallation = (os:string) => {
        return confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <Translation>{t =>
                        <div className='react-confirm-content w-100'>
                            <div className="row">
                                <div className="col-12 pt-5 pb-5 text-left">
                                    <div className="react-confirm-scroll">
                                        {os === 'windows' && getWindowsOnboarding(language, t)}
                                        {os === 'android' && getAndroidOnboarding(language, t)}
                                        {os === 'ios' && getIosOnboarding(language, t)}
                                        {os === 'macos' && getMacOsOnboarding(language, t)}
                                    </div>
                                </div>
                                <div className="col-6 ml-auto mr-auto">
                                    <button type="button" className="btn btn--white w-100" onClick={onClose}>{t('close')}</button>
                                </div>
                            </div>
                        </div>}
                    </Translation>
                );
            },
        })
    }

    const popUpManualInstallation = () => {
        return confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <Translation>{t =>
                        <div className='react-confirm-content w-100'>
                            <div className="row">
                                <div className="col-12 pt-5 pb-5 text-left">
                                    <div className="react-confirm-scroll">
                                        {getAndroidManualInstallation(language, t)}
                                    </div>
                                </div>
                                <div className="col-6 ml-auto mr-auto">
                                    <button type="button" className="btn btn--white w-100" onClick={onClose}>{t('close')}</button>
                                </div>
                            </div>
                        </div>}
                    </Translation>
                );
            },
        })
    }

    const renderIOS = (t: TFunction<string>) => {
        return (
            <p>{t('landingPage - iOS: ')}iOS: Anleitung Installation + App starten</p>
        )
    }

    const renderOther = (t: TFunction<string>) => {
        return (
            <>
                
                { !isPwaInstalled && !isInstalling &&
                    <>
                        <div>
                            <button onClick={()=>triggerPwaInstall()} className="btn btn--white w-auto d-inline-block">{t('landingPage - button - install app')}</button>
                        </div>
                    </>
                }
                { !isPwaInstalled && isInstalling &&
                    <>
                        <div>
                            <p className="strong">{t('landingPage - is installing')}</p>
                        </div>
                        <div className="divAnimateLoading"/>
                    </>
                }
                { isPwaInstalled && !isInstalling &&
                    <>
                        <div>
                            <p className="strong">{t('landingPage - app installed')}</p>
                            <p>{t('landingPage - leave browser to start')}</p>
                            <p><br /></p>
                            <p>{t('android - manual install hint')} <u style={{ textDecoration: 'underline'}} onClick={()=>popUpManualInstallation()}>{t('android - manual install')}</u></p>
                        </div>
                    </>
                }
            </>
        )
    }

    const renderLoading = (t: TFunction) => {
        return (
            <>
                <p className="strong">{t('landingPage - is loading')}</p>
                <div className="divAnimateLoading"/>
            </>
        )
    }

    const renderInstallCertificate = (t: TFunction) => {
        return (
            <>
                <p className="strong">{t('download cert and install')}</p>
                        <div className="col-12 d-flex ml-auto mr-auto">
                            <button style={{margin: '5px'}} type="button" className="btn btn--white w-50" onClick={downloadCertificate}>{t('button - download certificate')}</button>
                            <button style={{margin: '5px'}} type="button" className="btn btn--white w-50" onClick={()=>popUpCertInstallation(osName)}>{t('button - cert install instruction')}</button>
                        </div>
            </>
        )
    }

    return(
        <Translation>
            { t => (
                <div style={{ backgroundColor:'#4A5ABF', height: '100vh', width: '100%'}}>
                    <div className="app__status">
                        <div className="container-fluid d-flex align-items-center">
                            <div className="ml-auto padding-10px">
                                <LanguageSwitch />
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingTop: '10%', margin: 'auto', backgroundColor:'#4A5ABF', width: '80%'}}>
                        <Logo />
                        <h1 style={{marginTop: '30px', marginBottom: '60px'}}>{t('landingPage - welcome to app install')}</h1>
                        {isLoading
                            ? renderLoading(t)
                            : isCertificateValid
                                ? isIOS 
                                    ? renderIOS(t) 
                                    : renderOther(t) 
                                : renderInstallCertificate(t)
                        }
                        <p onDoubleClick={()=>{console.log('isDev = true');setIsDev(true)}} style={{marginTop:'100px'}} className="hsmall text-uppercase">Build Date: {preval`module.exports = new Date().toLocaleString('de-DE', {timeZone: 'Europe/Berlin'});`}. </p>
                    </div>
                    
                </div>
            )}
        </Translation>
    )
    
}

export default BrowserLandingPage