import React from 'react';
import { Translation } from 'react-i18next';
import moment from 'moment';
import { AppConfig } from '../../constants/app-config';
import { SelectedAttributes, StatisticsFilter } from './helper/types';
import { MeasurementFlatItem } from '../../store/statistics/types';

import NamingHelper from '../settings/naming/naming-helper';
import NamingContext from '../../context/naming/naming-context'
import { MACHINES } from '../../constants/lang/translation-keys';

type DeletePopUpProps = {
    filterOptions: StatisticsFilter,
    filteredItems: MeasurementFlatItem[],
    allItemsCount:number,
    handleDeleteAction: any
    onClose: any
    statsThis: any
    exportDropdownRef: any
}

export const DeletePopUp = (props: DeletePopUpProps) => {
    
    const { 
        filterOptions,
        filteredItems,
        allItemsCount,
        handleDeleteAction,
        onClose,
        statsThis,
        exportDropdownRef
        } = props;
    const { startTimestamp, endTimestamp, selectedAttributes } = filterOptions;

    const { naming } = React.useContext(NamingContext.Context);
    const machinesNaming = NamingHelper.getNaming(MACHINES,naming);

    const selectedParts:SelectedAttributes[] = [];
    const selectedWorkbenches:SelectedAttributes[] = [];
    selectedAttributes.forEach(a=>a.type==='workbench'? selectedWorkbenches.push(a): selectedParts.push(a));

    const handleDeleteButton = async () => {
        onClose();
        await handleDeleteAction(statsThis);
    }

    return (
        <Translation>{t =>
            <div className='react-confirm-content w-100'>
                <div className="d-flex align-items-center flex mb-3 justify-content-between">
                    <h2 className="hsmall strong text-uppercase mb-0 mr-2">
                        {
                            `${t('YouAreAboutToDeletePrefix')} ${allItemsCount > filteredItems.length ? `${filteredItems.length} ${t('outOf')} ${allItemsCount} ${t('Records(Dativ)')}` : `${filteredItems.length} ${t('Records')}`} ${t('YouAreAboutToDeletePostfix')}`
                        }
                    </h2>
                    <div>
                        <button onClick={onClose} className="btn btn--icon btn--icon-close btn--action-dark ml-auto"></button>
                    </div> 
                </div>
                <div className="row">
                    {(startTimestamp || endTimestamp || selectedParts.length !== 0 || selectedWorkbenches.length !== 0) &&
                        <div className="col-12 text-left mt-3 mb-3">
                            <h2 className="hsmall strong text-uppercase">{t('yourCurrentFilters')}</h2>

                            {startTimestamp &&
                                <div className="d-flex align-items-center mb-2">
                                    <span className="hsmall mr-1">{t('StartDate')}</span>
                                    <span className="badge badge--fluid badge--secondary strong text-uppercase hsmall">{moment(startTimestamp).format(AppConfig.time.date_format)}</span>
                                </div>}

                            {endTimestamp &&
                                <div className="d-flex align-items-center mb-2">
                                    <span className="hsmall mr-1">{t('EndDate')}</span>
                                    <span className="badge badge--fluid badge--secondary strong text-uppercase hsmall">{moment(endTimestamp).format(AppConfig.time.date_format)}</span>
                                </div>}

                            {selectedParts.length!==0 &&
                                <div className="d-flex align-items-center mb-2">
                                    <span className="hsmall mr-1">{t('Parts')}</span>
                                    {selectedParts.map(p => {
                                        return <span key={p.uuid} className="badge badge--fluid badge--secondary strong text-uppercase hsmall mr-1">{p.name}</span>
                                    })}
                                </div>}

                            {selectedWorkbenches.length !== 0 &&
                                <div className="d-flex align-items-center">
                                    <span className="hsmall mr-1">{machinesNaming}</span>
                                    {selectedWorkbenches.map(wb => {
                                        return <span key={wb.uuid} className="badge badge--fluid badge--secondary strong text-uppercase hsmall mr-1">{wb.name}</span>
                                    })}
                                </div>}
                        </div>
                    }
                    <div className="col-md-4 mb-2 order-md-2">
                        <button type="button" className="btn w-100" onClick={onClose}>{t('Cancel')}</button>
                    </div>
                    <div className="col-md-4 mb-2 order-md-2">
                        <button className="btn btn--white w-100" onClick={() => {
                            onClose();
                            exportDropdownRef.current.click();
                        }}>
                            Export
                        </button>
                    </div>
                    <div className="col-md-4 mb-2 order-md-1">
                        <button type="button" className="btn btn--danger w-100" onClick={() =>  handleDeleteButton() }>
                            {t('Delete')}
                        </button>
                    </div>
                </div>
            </div>}
        </Translation>
    )
}
