import React from 'react';
import { QdasMachineEntry } from '../qdas-types';

import NamingContext from '../../../context/naming/naming-context';
import NamingHelper from '../../settings/naming/naming-helper';
import { MACHINE, NEST } from '../../../constants/lang/translation-keys';


const PopUpMachineRow = ({ q, i, inputClassName, inputPlaceholder,
      handleChange }:
    {
        q: QdasMachineEntry,
        i: number,
        inputClassName: string,
        inputPlaceholder: string,
        handleChange: ( text: string ) => void
    }) => {

    const { naming } = React.useContext(NamingContext.Context);
    const getName = NamingHelper.getNaming;
    const catMachNest = q.isMachine ? getName(MACHINE, naming) :  getName(NEST, naming);
    const catValue = catMachNest + ': ' + q.key;

    const inputStyle: React.CSSProperties | undefined =
        q.text.trim().length === 0
            ? {backgroundColor: '#F1334F'}
            : undefined;

    return (
        <div key={`${q.uuid}${i}`}
            className="row"
            >
            <div className="col-7" >
                <input 
                    type="text" 
                    id={`input${q.uuid}`}
                    value={ catValue }  
                    className={`input-dark mb-3`}
                    disabled={true}
                />
            </div>
            <div className="col-5" >
                <input 
                    type="text" 
                    id={q.uuid}
                    value={q.text}
                    placeholder={inputPlaceholder}
                    className={`input-${inputClassName} mb-3`}
                    style={inputStyle}
                    disabled={q.readonly}
                    onChange={e=>{e.stopPropagation(); handleChange(e.target.value)}}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            </div>
        </div>
    )
}

export default PopUpMachineRow;
