import React from 'react';
import dark from './../../img/logo-darkmode.png'
import light from './../../img/logo-lightmode.png'

interface LogoProps {
    light?: boolean
}


const Logo = (props:LogoProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="logo" />
)

export default Logo