import React from 'react';
import { qdasMandatoryRow, QdasType } from '../../statistics/export/data/constants';


const QdasButton = ({
    selfThis
}:{
    selfThis: any
}) => {
    const labelButtonCss = 'btn btn--qdaspopup qdaspopup__label';

        const getCounterObj = () => {
            const { part, attribute, machine } = selfThis.props;
            let count = 0;
            let isValid = true;
            if (part) {
                count = selfThis.state.optionalPartEntries.length;
                for (const mand of qdasMandatoryRow) {
                    if (mand.type===QdasType.PART) {
                        let keyFound = false;
                        for (const it of selfThis.state.optionalPartEntries) {
                            const key = it.textRow.slice(0,5);
                            if (key===mand.key) {
                                keyFound = true;
                            }
                        }
                        if (!keyFound) {
                            isValid = false;
                        }
                    }
                }
            }
            if (attribute) {
                count = selfThis.state.optionalAttrEntries.length;
                for (const mand of qdasMandatoryRow) {
                    if (mand.type===QdasType.ATTRIBUTE) {
                        let keyFound = false;
                        for (const it of selfThis.state.optionalAttrEntries) {
                            const key = it.textRow.slice(0,5);
                            if (key===mand.key) {
                                keyFound = true;
                            }
                        }
                        if (!keyFound) {
                            isValid = false;
                        }
                    }
                }
            }
            if (machine) {
                for (const it of selfThis.state.qdasMachineEntriesCurr) {
                    if (!it.text) {
                        isValid = false;
                    } else {
                        count++;
                    }
                }
            }
            return { count, isValid };
        };

        return(
            <button 
                className={ labelButtonCss } 
                style={{ 
                    backgroundColor: getCounterObj().isValid ? '#4A5ABF' : '#F1334F'}}
                onClick={ (e) => selfThis.open(e)}
                type="button"
                ref={selfThis.props.reference ? selfThis.props.reference : null}
            >
                <div className="row d-flex align-items-center"
                >
                    {selfThis.props.label}
                    <div style={{
                        height: 20,
                        width: 20,
                        borderRadius: 10,
                        border: '1px solid white',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        marginLeft: 5
                    }}>
                        <p style={{ marginTop: 2.5, width: '100%', textAlign: 'center', fontSize: '11px', lineHeight: '13px' }}>
                                {getCounterObj().count}
                        </p>
                        
                    </div>
                </div>
            </button>
        )
}

export default QdasButton
