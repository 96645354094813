import { AttributeItem } from './../../../store/attributes/types';
import { NestItem } from './../../../store/machines/types';
import { AttributeQdas, PartQdas, QdasEntry, QdasMachineEntry } from '../qdas-types';
import { webapi } from '../../../api/web-api';
import { refreshQdasPartEntries, refreshQdasAttributeEntries } from './refresh-functions';
import { qdasMandatoryRow } from '../../statistics/export/data/constants';
import { PartItem } from '../../../store/parts/types';

export async function handleSaveMachineOrNest(uuid: string, text: string, selfThis: any, isMachine: boolean){
    const { machine } = selfThis.props;
    if (machine) {
        if (isMachine) {
            const updatedMachine = { ...machine, reference: text};
            await selfThis.props.thunkUpdateMachineRef(updatedMachine);
        } else {
            const nest = machine.nests.find((n: NestItem)=>n.uuid===uuid);
            if (nest) {
                const updatedNest = { ...nest, reference: text};
                await selfThis.props.thunkUpdateMachineNestRef(updatedNest);
            }
        }
    }
}

export async function handleDelete(qdasRowUuid: string, selfThis: any){
    const { part, attribute } = selfThis.props;
    let uri = '';
    if (part) uri = `/partqdas/${qdasRowUuid}`;
    if (attribute) uri = `/attqdas/${qdasRowUuid}`;
    try {
        await webapi.client.delete( uri );
        if (part) refreshQdasPartEntries(selfThis);
        if (attribute) refreshQdasAttributeEntries(selfThis);
    } catch (error) {
        console.error('delete err: ', error)
    }
}

export async function handleSaveAll( selfThis: any ){
    const { part, attribute, machine } = selfThis.props;

    if ( part ) {
        const savePartQdasArray: PartQdas[] = [];
        for (const entry of selfThis.state.qdasEntriesTemp as QdasEntry[]) {
            if (!entry.readonly) {
                const partId = part.id;
                const newPartQdas: PartQdas = {
                    uuid: entry.uuid,
                    textRow: entry.text,
                    position: entry.position,
                    partId
                };
                const isMandatory = qdasMandatoryRow.find(m=>m.key===entry.uuid.slice(0,5));
                const hasMandatoryText = entry.text.slice(6).trim();
                if ( isMandatory ) {
                    if ( hasMandatoryText ) {
                        savePartQdasArray.push(newPartQdas);
                    }
                } else {
                    if ( entry.text.trim() ) {
                        savePartQdasArray.push(newPartQdas);
                    }
                }
            }
        }
        const jsonString = JSON.stringify(savePartQdasArray);
        // compare with string before edit
        const stringHasChanged = jsonString !==selfThis.state.qdasEntriesCurr;
        if ( stringHasChanged ) {
            // rows have changed
            const updatedPart = { ...part, dataQdas: jsonString } as PartItem;
            await selfThis.props.thunkUpdatePart(updatedPart);
        } 
    }

    if ( attribute ) {
        const saveAttrQdasArray: AttributeQdas[] = [];
        for (const entry of selfThis.state.qdasEntriesTemp as QdasEntry[]) {
            if (!entry.readonly) {
                const attributeUuid = attribute.uuid;
                const updateAttrQdas: AttributeQdas = {
                    uuid: entry.uuid,
                    textRow: entry.text,
                    position: entry.position,
                    attributeUuid
                };
                const isMandatory = qdasMandatoryRow.find(m=>m.key===entry.uuid.slice(0,5));
                const hasMandatoryText = entry.text.slice(6).trim();
                if ( isMandatory ) {
                    if ( hasMandatoryText ) {
                        saveAttrQdasArray.push(updateAttrQdas);
                    }
                } else {
                    if ( entry.text.trim() ) {
                        saveAttrQdasArray.push(updateAttrQdas);
                    }
                }
            }
        }
        const jsonString = JSON.stringify(saveAttrQdasArray);
        // compare with string before edit
        const stringHasChanged = jsonString !==selfThis.state.qdasEntriesCurr;
        if ( stringHasChanged ) {
            // rows have changed
            const updatedAttr = { ...attribute, dataQdas: jsonString } as AttributeItem;
            await selfThis.props.thunkUpdateAttribute(updatedAttr);
        }
    }

    if ( machine ) {
        for (const entry of selfThis.state.qdasMachineEntriesTemp) {
            const { uuid, text, isMachine } = entry;
            const foundInCurr = selfThis.state.qdasMachineEntriesCurr.find((q: QdasMachineEntry)=>q.uuid===entry.uuid);
            if ( foundInCurr ) {
                const hasChanged = entry.text !== foundInCurr.text;
                if ( hasChanged ) {
                    await handleSaveMachineOrNest( uuid, text, selfThis, isMachine );
                }
            }
        }
    }
}