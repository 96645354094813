import axios from "axios";
import { serviceApi } from "../../api/service-api";
import { QumoFeatureItem } from './qumo-features-types';

namespace FeaturesHelper {

    export async function getFeatures( host?: string){
        let features: QumoFeatureItem[] = [];
        try {
            if (host) {
                const uri = `${host}/features`;
                const data = await (await axios.get(uri)).data;
                features = data.data.features;
            } else {
                const uri = `/features`;
                const data = await (await serviceApi.client.get(uri)).data;
                features = data.data.features;
            }
            
        } catch (error) {
            console.error('getFeatures error: ', error);
        }
        return features;
    }

    export async function activateFeature( id: string, code: string) {
        let response = null;
        const uri = `/feature`;
        const data = { id, code };
        try {
            response = await serviceApi.client.post(uri, data);
            // console.log('activateFeature response: ', response);
        } catch (error) {
            console.log('activateFeature error: ', error);
        }
        return response;
      }

      export function toggleFeature( id:string, currArray: string[], toggleOn?: boolean ) {
          let newArray = [...currArray];
          const found = newArray.find(s=>s===id);
          if (found) {
              if (!toggleOn) {
                  newArray = newArray.filter(s=>s!==id);
              }
          } else {
              newArray.push(id);
          }
          return newArray;
      }

}

export default FeaturesHelper;