import React from 'react';
import { connect } from 'react-redux';
import svgCableImage from '../../img/ios-cable.svg'
import svgClientImage from '../../img/ios-client.svg'
import { AppConfig } from '../../constants/app-config';
import preval from 'preval.macro';
import LanguageSwitch from '../ui/LanguageSwitch';
import { AppState } from '../../store/reducers';
import { confirmAlert } from 'react-confirm-alert';
import { TFunction, Translation } from 'react-i18next';
import { downloadCertificate, getAndroidOnboarding, getIosOnboarding, getMacOsOnboarding, getWindowsOnboarding } from './onboarding';
import ConnectionHelper from '../../api/connection-helper';
import { SystemState } from '../../store/system/types';
import DeviceStatus from '../device-status/DeviceStatus';
import { QumoFeatureItem } from '../../context/qumo-features/qumo-features-types';
import QumoFeaturesHelper from '../../context/qumo-features/qumo-features-helper';
import NamingHelper from '../settings/naming/naming-helper';
import  { namingObjectType } from '../settings/naming/naming-types';
import { NamingContextType } from '../../context/naming/naming-context-types';

interface ConnectProps {
    language:string
    osName: string
    connectHandler: any
    system: SystemState
    qumoFeaturesContext: {
        features: QumoFeatureItem[];
        setFeatures: React.Dispatch<React.SetStateAction<QumoFeatureItem[]>>;
    }
    namingContext: NamingContextType
}

interface ConnectState {
    urlToQumo: string;
    showIsSearching: boolean
    isDev: boolean
    pollingTimeoutRef: NodeJS.Timeout
}

class Connect extends React.Component<ConnectProps, ConnectState> {

    private _timeout:any;

    constructor(props:ConnectProps){
        super(props);
        this.state = {
            urlToQumo: '',
            showIsSearching: true,
            isDev: false,
            pollingTimeoutRef: setTimeout(() => {}, 0)
        }
    }

    componentDidMount() {

        const { hosts, showIsSearchingDuration, polling } = AppConfig.connection;

        // after e.g. 20 sec show troubleshooting
        this._timeout =setTimeout(() => {
            this.setState({showIsSearching: false})
        }, showIsSearchingDuration);

        ConnectionHelper.getHostUrl( hosts, polling, (url, timeoutRef)=>{
            if (url) {
                this.props.connectHandler(url)
                .then( ({
                    features,
                    naming
                }:{
                    features: QumoFeatureItem[];
                    naming: namingObjectType
                }) => {
                    this.props.qumoFeaturesContext.setFeatures(features);
                    this.props.namingContext.setNaming(naming);
                    this.setState({ urlToQumo: url,showIsSearching: false}) 
                });
            } else {
                this.setState({pollingTimeoutRef: timeoutRef})
            }
        });

        //mode color
        this.setModeColor()
    }

    componentWillUnmount(){
        clearTimeout(this._timeout);
    }

    componentDidUpdate(){
        if (this.props.system.isQumoOccupied) {
            this.popUpQumoOccupied();
        }
    }
    
    popUpQumoOccupied() {
        return confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <Translation>{t =>
                        <div className='react-confirm-content w-100'>
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p className='h3'>{t('QumoOccupied')}</p>
                                </div>
                                <div className="col-12 pt-2 pb-3">
                                    <p style={{ lineHeight: '1.5em'}}>{t('QumoOccupiedDetail')}</p>
                                </div>
                                <div className="col-6 ml-auto mr-auto">
                                    <button type="button" className="btn btn--white w-100" onClick={()=>window.location.reload()}>{t('Reconnect')}</button>
                                </div>
                            </div>
                        </div>}
                    </Translation>
                );
            },
        })
    }
    
    popUpCertInstallation (os:string) {
        return confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <Translation>{t =>
                        <div className='react-confirm-content w-100'>
                            <div className="row">
                                <div className="col-12 pt-5 pb-5 text-left">
                                    <div className="react-confirm-scroll">
                                        {os === 'windows' && getWindowsOnboarding(this.props.language, t)}
                                        {os === 'android' && getAndroidOnboarding(this.props.language, t)}
                                        {os === 'ios' && getIosOnboarding(this.props.language, t)}
                                        {os === 'macos' && getMacOsOnboarding(this.props.language, t)}
                                    </div>
                                </div>
                                <div className="col-6 ml-auto mr-auto">
                                    <button type="button" className="btn btn--white w-100" onClick={onClose}>{t('close')}</button>
                                </div>
                            </div>
                        </div>}
                    </Translation>
                );
            },
        })
    }

    // DEV
    async onClickHandler(host:string, index: number) {
        if (index===1 || index===2) {
            // demo or develop: stop polling
            clearTimeout(this.state.pollingTimeoutRef);            
        }
        if (index === 2) {
        // console.log('connect - index: : ', index)
            try {
                const host = AppConfig.connections[2].service;
                // console.log('host: ', host)
                const features = await QumoFeaturesHelper.getFeatures(host);
                this.props.qumoFeaturesContext.setFeatures(features);
            } catch (error) {
                console.error('connect - getFeatures error: ', error)
            }
            try {
                const naming = await NamingHelper.loadNaming(true);
                this.props.namingContext.setNaming(naming);
            } catch (error) {
                console.error('connect - getNaming error: ', error)
            }



        }

        this.props.connectHandler(host,index);
    }

    // DEV
    renderCnnSelection = () => {
        return (
            <>
                {
                    AppConfig.connections.map((api, index) => {
                        return (
                            <span
                                className="badge badge--secondary text-center mb-0 mr-2 strong hsmall text-uppercase"
                                key={index}
                                onClick={() => { this.onClickHandler(api.host, index) }}
                            >
                                {api.name}
                            </span>
                        )
                    })
                }
            </>
        );
    }

    renderConnection = () => {
        return (
            <div className="row">
                <div className="col-md-12 rasp-animation">
                    <div className="client">
                        <img src={svgClientImage} alt="" />
                    </div>
                    <div className="cable">
                        <img src={svgCableImage} alt="" />
                    </div>
                </div>
            </div>
        );
    }
    
    renderCheckConnectionAndCertificate (t: TFunction<string>) {
        return (
            <div style={{ alignItems: 'center'}}>
                <p className="strong">1. {t('connect - Qu-Mo switched on?')}<br/>2. {t('connect - Qu-Mo connected to this device?')}<br/>3. {t('connect - Certificate installed on this device?')}</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center'}}>
                    <p>{t('connect - Certificate')}:&nbsp;</p>
                    <p style={{textDecorationLine:'underline'}} onClick={downloadCertificate}>Download</p>
                    <p>&nbsp;{t('connect - and')}&nbsp;</p>
                    <p style={{textDecorationLine:'underline'}} onClick={()=>this.popUpCertInstallation(this.props.osName)}>{t('connect - How to install')}</p>
                </div>
            </div>
        )
    }

    setModeColor(){
      if(this.props.system.settings.appDarkMode){
          document.body.classList.add('dark-mode');
          document.body.classList.remove('light-mode');
      } else {
          document.body.classList.remove('dark-mode');
          document.body.classList.add('light-mode');
      }
  }

    render() {
        const classNameExtension = this.state.urlToQumo ? ' app--connectIsLoading' : ''
        return (
            <Translation>{ t => (
            <div className={'app app--connect container-fluid p-5 ' + classNameExtension}>
                <div className="app__status">
                    <div className="container-fluid d-flex align-items-center">
                        <span className="mr-auto d-flex align-items-center">
                            {/* Anzeige Headerzeile DEV */}
                            {this.state.isDev && this.renderCnnSelection()}
                        </span>
                        <div className="ml-auto">
                            <LanguageSwitch />
                        </div>
                        <span className="ml-2 d-flex">
                            <DeviceStatus urlToQumo={this.state.urlToQumo} isPopUpEnabled={false}/>
                        </span>
                    </div>
                </div>
                <div className="app__content d-flex justify-content-center">
                    <div className="app__main">
                        {this.renderConnection()}
                        <p></p>
                        <div 
                        className="text-center ml-auto mr-auto mt-3"
                        >
                            {this.state.showIsSearching
                                ? <><p className="strong" style={{opacity:.5}}>{t('connect - Searching for connection ...')}</p>
                                </>
                                :     
                                    !this.state.urlToQumo && 
                                    <>
                                        {this.renderCheckConnectionAndCertificate(t)}
                                    </>
                            }
                            <div style={{height:'50px'}}></div>
                            <p onDoubleClick={()=>this.setState({isDev:true})} className="hsmall strong text-uppercase">Build Date: {preval`module.exports = new Date().toLocaleString('de-DE', {timeZone: 'Europe/Berlin'});`}. </p>
                        </div>
                    </div>
                </div>
            </div>)}</Translation>
        );
    }
}

const mapStateToProps = (state: AppState, ownProps: any) => {
    return {
        language: state.system.settings.language,
        system: state.system,
    }
};

const dispatchToProps = {};

export default connect(mapStateToProps, dispatchToProps)(Connect);