import React from 'react';
import { Translation } from 'react-i18next';
import { getChannelsAll } from '../../../store/channels/selectors';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { overlayPositionToggle, overlayToggle, updateSession, validationLoad } from '../../../store/system/action';
import { thunkUpdateChannel } from '../../../store/channels/thunks';
import { SystemState } from '../../../store/system/types';

import NamingHelper from './naming-helper';
import { defaultNamingObject, namingObjectType } from './naming-types';
import NamingRowItem from './NamingRowItem';
import NamingContext from '../../../context/naming/naming-context';
import { confirmAlert } from 'react-confirm-alert';
import { NamingContextType } from '../../../context/naming/naming-context-types';
import { CatNameKeys, MACHINES_DEFAULT, NESTS_DEFAULT } from '../../../constants/lang/translation-keys';
import { getStringWidth } from '../../../helper/getStringWidth';


interface NamingProps {
    system: SystemState
    overlayToggle: any
    updateSession: typeof updateSession
    overlayPositionToggle: typeof overlayPositionToggle
    validationLoad: any
}

interface NamingState {
    tempNaming: namingObjectType
}

class Naming extends React.Component<NamingProps, NamingState> {

    static contextType = NamingContext.Context;

    constructor(props:NamingProps){
        super(props);
        this.state = {
            tempNaming: defaultNamingObject
        }
    }


    componentDidMount(){
        this.loadNaming();
    }

    async loadNaming(){
        const naming = await NamingHelper.loadNaming();
        console.log('loadNaming - naming: ', naming)
        const context = this.context as NamingContextType;
        context.setNaming(naming);
        this.setState({ tempNaming: naming })
    }



    handleClose(){
        this.props.overlayToggle();
    }

    onChangeHandler( e: React.ChangeEvent<HTMLInputElement>, key: CatNameKeys , thisCatName: this){
        const value = e.target.value;

        if ( getStringWidth(value.toUpperCase()) <= 9.5) {
            switch (key) {
                case CatNameKeys.MACHINE:
                    thisCatName.setState({tempNaming: {...thisCatName.state.tempNaming, machine: value }});
                    break;
                case CatNameKeys.MACHINES:
                    thisCatName.setState({tempNaming: {...thisCatName.state.tempNaming, machines: value }});
                    break;
                case CatNameKeys.NEST:
                    thisCatName.setState({tempNaming: {...thisCatName.state.tempNaming, nest: value }});
                    break;
                case CatNameKeys.NESTS:
                    thisCatName.setState({tempNaming: {...thisCatName.state.tempNaming, nests: value }});
                    break;
                default:
                    break;
            }
        } else {
            const validation = ['maxNamingLengthReached'];
            this.props.validationLoad(validation);
        }
    }

    async handleSubmit( e: React.MouseEvent<HTMLButtonElement, MouseEvent>, namingThis: this ){
        e.stopPropagation();
        const { tempNaming } = namingThis.state;
            let submissionSuccessful = false;
            try {
                const response = await NamingHelper.setNaming( tempNaming );
                if (response) {
                    const naming = response.data.data as namingObjectType;
                    submissionSuccessful = response.data.status;
                    if (submissionSuccessful) {
                        const context = namingThis.context as NamingContextType;
                        context.setNaming(naming);
                        this.props.overlayToggle();
                    }
                }
            } catch (error) {
                console.error('handleSubmitCode Error: ', error);
            }
            if (!submissionSuccessful) {
                confirmAlert({
                    closeOnEscape: true,
                    customUI: ({ onClose }) => {
                        return (
                            <Translation>{t =>
                                <div className='react-confirm-content w-100'>
                                    <div className="row mb-3">
                                        <div className="col-10 d-flex align-items-center">
                                            <h2 className="hsmall strong text-uppercase mb-0 text-left">{t('CategoryRenaming')}</h2>
                                        </div>
                                        <div className="col-2">
                                            <button onClick={onClose} className="btn btn--icon btn--icon-close btn--action-dark ml-auto"></button>
                                        </div>
                                    </div>
                                    <p className="hsmall strong mb-10">{submissionSuccessful ? t('success') : t('NOT successful')}</p>
                                    <div className="row">
                                        <div className={ 'col-8 ml-auto mr-auto'}>
                                            <button
                                                type="button"
                                                className='btn w-100'
                                                onClick={onClose}
                                            >
                                                {t('Understood')}
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>}
                            </Translation>
                        );
                    },
                })
            }
    }

    onFocusBlur(editThis: any){
        const isMobile = editThis.props.system.platform.android || editThis.props.system.platform.ios;
        isMobile && editThis.props.overlayPositionToggle()
    }
   
    render() {

        const getDefaultName = (key: CatNameKeys) => NamingHelper.getNaming(key,defaultNamingObject);
        const listItemObjectMachines = {
            singular: {
                value: this.state.tempNaming.machine,
                defaultValue: getDefaultName(CatNameKeys.MACHINE),
                onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, CatNameKeys.MACHINE, this)
            },
            plural: {
                value: this.state.tempNaming.machines,
                defaultValue: getDefaultName(CatNameKeys.MACHINES),
                onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, CatNameKeys.MACHINES, this)
            }
        }
        const listItemObjectNests = {
            singular: {
                value: this.state.tempNaming.nest,
                defaultValue: getDefaultName(CatNameKeys.NEST),
                onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, CatNameKeys.NEST, this)
            },
            plural: {
                value: this.state.tempNaming.nests,
                defaultValue: getDefaultName(CatNameKeys.NESTS),
                onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, CatNameKeys.NESTS, this)
            }
        }



        return(
            <><Translation>
                {
                    t =>
                    <div className="container-fluid app__overlay-form">
                        <div className="row">
                            <div className="col-4 offset-8 col-md-3 offset-md-9 text-right">
                                <button 
                                    className="btn btn--icon btn--icon-close bg-action-dark mr-3" 
                                    onClick={ ()=> this.handleClose() }
                                ></button>
                                <button 
                                    className="btn btn--icon btn--icon-check btn--negative" 
                                    type="submit"
                                    onClick={ e => { this.handleSubmit(e, this) } }
                                ></button>
                            </div>
                        </div>

                        <div className="mr-3" 
                        style={{marginTop:60}}
                        >
                            <NamingRowItem labelMain={t(MACHINES_DEFAULT)} listItems={listItemObjectMachines} onFocusBlur={ () => this.onFocusBlur(this) } />
                            <NamingRowItem labelMain={t(NESTS_DEFAULT)} listItems={listItemObjectNests} onFocusBlur={ () => this.onFocusBlur(this) } />
                            <NamingRowItem onFocusBlur={ () => this.onFocusBlur(this) }  />
                            
                        </div>

                        
                    </div>
                }
            </Translation></>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
    channels: getChannelsAll(state),
    system: state.system
});

const dispatchToProps = {
    overlayToggle,
    overlayPositionToggle,
    thunkUpdateChannel,
    updateSession,
    validationLoad
};

export default connect(mapStateToProps, dispatchToProps)(Naming);