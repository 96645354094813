import React from 'react';
import { PartItem } from '../../store/parts/types';
// import IconCamera from './../../img/icons/icon-camera.svg'
import Button from '../ui/Button';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { thunkNavigatePart, thunkDeletePart} from '../../store/parts/thunks';
import { overlayLoadComponent, overlayToggle } from '../../store/system/action';
import PartEditForm from './PartEditForm';
import { Translation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; // Import
import DeleteMask from '../ui/DeleteMask';
import { getPartLabel } from './partHelpers';
import { SystemState } from '../../store/system/types';
import QdasPopUp from '../qdas/QdasPopUp';
import QFC from '../../context/qumo-features/qumo-features-context';
import { QumoFeatureContextType, QumoFeatureItem } from '../../context/qumo-features/qumo-features-types';
import { FEATURE, FEATURES } from '../../constants/lang/translation-keys';



interface PartProps {
    item: PartItem;
    id: any;
    history: any;
    isEditMode: boolean;
    thunkNavigatePart(id: string): any;
    thunkDeletePart (id:string): any;
    overlayToggle: typeof overlayToggle;
    overlayLoadComponent: typeof overlayLoadComponent;
    system: SystemState
}

interface PartState {
    isToBeDeleted: boolean
}

class Part extends React.Component<PartProps, PartState> {

    static contextType = QFC.Context;

    constructor(props:PartProps){
        super(props);
        this.state = {
            isToBeDeleted: false,
        }
    }

    deletePart = (id:any) => {
        this.setState({isToBeDeleted: true })
        setTimeout(() => {
            this.props.thunkDeletePart(id)
            .then(()=> {
                localStorage.removeItem('promess-current-partId')
            });
        }, 1000);
    }

    handleDeletePart = (id: string) => {
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <DeleteMask 
                        delete = {() => this.deletePart(id)}
                        onClose = {() => onClose() }
                        nameOfDeletedItem = { this.props.item.partNumber }
                    />
                )
            }
        })
    }

    handleItemClick = (e:any) => {
        
        // exclude the redirect if user clicks on the delete or edit button
            if(e.target.nodeName === 'BUTTON') return

        // if edit mode lets edit and not redirect
            // console.log('editMode', this.state.editMode);
            if(this.props.isEditMode){    
                this.toggleEditMode()
                return
            }

        //redirect
            const { id, history } = this.props
            const path =  `/part/${id}`;

            setTimeout(() => {
                history.push(path)
            }, 1000);
    }

    toggleEditMode = ():any => {
        this.props.overlayLoadComponent(<PartEditForm item = { this.props.item } />)
        this.props.overlayToggle()
    }

    render() {        

        const { item } = this.props;
        const attributesCount = item.attributesCount ? item.attributesCount : 0;
        let editStyles = this.props.isEditMode ? ' editable--off ' : ' editable--on '
        let wrapperStyles = this.state.isToBeDeleted ? ' animate--disappear editable' : ' editable '
        const label = getPartLabel(item);

        const context = this.context as QumoFeatureContextType;
        
        return(
            <>
                <Translation>
                    {
                    t =>
                    <div className={ editStyles + wrapperStyles + ' list__item animate' }>
                        <div className="list__item-inner" onClick={ (e)=> this.handleItemClick(e)} >
                            <img className="icon icon--round icon--xl icon--border mr-3" src={item.imageUrl} alt="" />
                            <div className="text-ellipsis">
                                <h2 className="h3 strong mb-1 text-ellipsis">{label}</h2>
                                <h3 className="text-grey-dark hsmall mb-0">
                                    {attributesCount}  { attributesCount === 1 ? t(FEATURE) : t(FEATURES)}
                                </h3>
                            </div>
                            <div className="editable__hidden ml-auto">
                                {
                                this.props.system.settings.qumoActiveFeatures.find( f => f==='QDAS' ) &&
                                context.features?.find( (f: QumoFeatureItem) => f.id==='QDAS' )?.active &&
                                <div className='qdaspopup-wrapper'>                                
                                    <QdasPopUp label={'Q-DAS'} description={'Q-DAS'} part={this.props.item} t={t} rowButton/>
                                </div>
                                }
                                <Button styles='btn btn--pin btn--pin-edit' isActive={false} clickHandler={()=> {
                                    this.toggleEditMode() }}
                                    show = { true }
                                />
                                <Button styles='btn btn--pin btn--pin-trash' isActive={false} clickHandler={
                                    ()=>
                                    {this.handleDeletePart(this.props.id)}}
                                    show = { true }
                                />
                            </div>
                        </div>
                    </div>
                    }
                </Translation>

            </>
        );
    };
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
});

const dispatchToProps = {
    thunkDeletePart, 
    thunkNavigatePart, 
    overlayLoadComponent, 
    overlayToggle, 
};

export default connect(mapStateToProps, dispatchToProps)(Part);