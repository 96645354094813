import axios, { AxiosInstance }  from 'axios';
import https from 'https';

class ServiceApi {
  client: AxiosInstance;
  /**
   * ServiceApi access class
   */
  constructor() {
    this.client = axios.create({
      httpsAgent: new https.Agent({  
        rejectUnauthorized: false
      })
    })
  }
  setBaseUrl (baseUrl:string) {
    // console.log('ServiceApi - baseUrl: ', baseUrl)
    this.client.defaults.baseURL = baseUrl;
  }
}

export const serviceApi = new ServiceApi();