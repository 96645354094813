import { TFunction, Translation } from "react-i18next";
import { confirmAlert } from 'react-confirm-alert';
import icon_attention from '../../img/icons-png/icon-attention.png';
import { AppConfig } from "../../constants/app-config";
import ConnectionHelper from "../../api/connection-helper";

export const getTimeDiff = (qumoTimestamp:number, threshold: number) => {
    let returnValue = {output:'', isTimeDiffMinor:true};
    const timeDiff = Math.abs(new Date().getTime() - new Date(qumoTimestamp).getTime())
    const timediffSeconds = Math.round(timeDiff / 1000);
    const isTimeDiffMinor = timeDiff < threshold
    if (isTimeDiffMinor) {
        returnValue = {output:'0 s', isTimeDiffMinor:isTimeDiffMinor};
    } else {
        returnValue = {output: timediffSeconds.toString() + ' s', isTimeDiffMinor:isTimeDiffMinor};
    }
    return returnValue;
};

export type DeviceInfoSetType = React.Dispatch<React.SetStateAction<{
    name: string;
    value: string;
}[]>>;
export const extractData = (data: any, setQumoInfo: DeviceInfoSetType, setQumoServices: DeviceInfoSetType, t: TFunction<string>) => {
    if (data.hasOwnProperty('device')) {
        const {device, webapi, prtapi} = data;
        setQumoInfo([
            {name:t('DeviceID'), value: device.deviceID ?? '' },
            {name:t('DeviceName'), value:device.hostname},
            {name:t('DeviceModel'), value:device.release},
        ])
        setQumoServices([
            {name:t('Service'), value: device.version ? device.version : "0"},
            {name:t('Web API'), value: webapi.version ? webapi.version : "0"},
            {name:t('RealTime API'), value: prtapi.version ? prtapi.version : "0" },
        ])
    }
    return data;
}

export const renderCertExpiryBar = ( certInfo: ConnectionHelper.certInfoObjType ) => {
    // const warningDays = 350; // DEV
    const warningDays = AppConfig.certificate.warnTrigger;
    // const alertDays = 14; // DEV
    const alertDays = AppConfig.certificate.alertTrigger;
    const barColor = certInfo.validDays > warningDays
                        ? '#2c8446' // green
                        : certInfo.validDays > alertDays
                            ? '#ffa500' // orange
                            : '#bc283d'; // red
    const certExpiryBarObj = {
        // validDaysPercent:  '80%', // DEV
        validDaysPercent:  `${100-certInfo.validDays/certInfo.validDaysMax*100}%`,
        // barColor: 'orange' // DEV
        barColor
    }
    return (
        <div className='expiry-bar'>
            <div className='expiry-bar' style={{
            width: certExpiryBarObj.validDaysPercent,
            backgroundColor: certExpiryBarObj.barColor,
            }}/>
        </div>
    )
}

export const showUpdateFinished = ( isPopUpEnabled: boolean, text: string ) => {
    if (!isPopUpEnabled) {
        return
    }
    confirmAlert({
        closeOnEscape: true,
        customUI: ({ onClose }) => {
            return (
                <Translation>{t =>
                    <div className='react-confirm-content w-100'>
                        <div className="row mb-3">
                            <div className="col-10 d-flex align-items-center">
                                <h2 className="hsmall strong text-uppercase mb-0 text-left">{t('Qu-Mo Update')}</h2>
                            </div>
                        </div>
                        <p className="h4 mb-10">{t(text)}</p>
                        <div className="row">
                            <div className={ 'col-8 ml-auto mr-auto'}>
                                <button
                                    type="button"
                                    className='btn w-100'
                                    onClick={onClose}
                                >
                                    {t('Understood')}
                                </button>
                            </div>
                            
                        </div>
                    </div>}
                </Translation>
            );
        },
    })
}

export const showCertificateAlert = ( certInfo: { validToDate: string, validDays: number, validDaysMax: number, showPopup: boolean } ) => {
    if (!certInfo.showPopup) {
        return
    }
    confirmAlert({
        closeOnEscape: true,
        customUI: ({ onClose }) => {
            return (
                <Translation>{t =>
                    <div className='react-confirm-content w-100'>
                        <div className="row mb-3">
                            <h2 className="w-100 hsmall strong text-uppercase mb-0 text-center">{t('AlertUpdateCertificate_title')}</h2>
                        </div>
                        <img className="mt-4" style={{ height: 65 }} src={icon_attention} alt="" />
                        <p className="h4 mt-5"  style={{lineHeight: '1.3em'}}><strong>{t('AlertUpdateCertificate_text_1')}</strong></p>
                        <p className="h4 mt-5 mb-1"  style={{lineHeight: '1.3em'}}>{t('AlertUpdateCertificate_text_3')}: <strong>{`${certInfo.validDays} ${t('days')}`}</strong></p>
                        <div className="d-flex w-100 mb-5 justify-content-center">
                            <div style={{width: '75%'}} >{renderCertExpiryBar(certInfo)}</div>
                        </div>
                        <p className="h4 mb-5"  style={{lineHeight: '1.3em'}}>{t('AlertUpdateCertificate_text_2')}</p>
                        <div className="row mt-5">
                            <div className={ 'col-8 ml-auto mr-auto'}>
                                <button
                                    type="button"
                                    className='btn w-100'
                                    onClick={onClose}
                                >
                                    {t('Understood')}
                                </button>
                            </div>
                            
                        </div>
                    </div>}
                </Translation>
            );
        },
    })
}