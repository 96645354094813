export class triple<T> {
    private _a:T;
    private _b: T;
    private _c: T;
    constructor(a: T, b:T, c:T) {
        this._a =a;
        this._b = b;
        this._c = c;
    }

    public get a():T {
        return this._a;
    }
    public get b():T {
        return this._b;
    }
    public get c():T {
        return this._c;
    }
}