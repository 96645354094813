
import React from 'react';
import { getFormatNameLabel } from '../../statistics/helper/statisticsHelpers';

interface DropdownSublistItemProps {
    item: any,
    onClickHandler: any,
    isChecked: boolean
}

class DropdownSublistItem extends React.Component<DropdownSublistItemProps> {

    onClickHandler(){
        this.props.onClickHandler(this.props.item)
    }

    render() { 

        const css = this.props.isChecked ? 'dropdown__sublist-item--checked' : ''

        return(
            <div 
                className= {css + ' dropdown__sublist-item h4 font-regular text-ellipsis'}
                onClick = { () => { this.props.onClickHandler(this.props.item)} }
            >
                {getFormatNameLabel(this.props.item.name)}
            </div>
        );
    };
}

export default DropdownSublistItem