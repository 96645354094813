import { AttributeQdas, PartQdas } from '../qdas-types';
import { qdasMandatoryRow, QdasType } from '../../statistics/export/data/constants';
import { v4 as uuidv4 } from 'uuid';

export async function getOptionalQdasPartEntries( selfThis: any ):Promise<PartQdas[]>{
    const { part } = selfThis.props;
    if (part) {
        try {
            let partQdasArray: PartQdas[] = [];
            const result = part.dataQdas;
            if (result) {
                partQdasArray = JSON.parse(result) as PartQdas[];
            }
            let partQdasArraySorted = partQdasArray
            .sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
            selfThis.setState({ optionalPartEntries: partQdasArraySorted, qdasEntriesCurr: result });
            for (const mand of qdasMandatoryRow) {
                if (mand.type === QdasType.PART) {
                    const mandatoryField = `${mand.key} `;
                    const hasMandatField = partQdasArraySorted.find(r=>r.textRow.trim().slice(0,5)===mandatoryField.slice(0,5));
                    if ( !hasMandatField ) {
                        const mandatoryPartQdas: PartQdas  = {
                            uuid: mand.key + uuidv4(),
                            position: mand.position,
                            textRow: mandatoryField,
                            // timestamp: 0,
                            partId: part.id,
                        };
                        partQdasArraySorted = [mandatoryPartQdas].concat(partQdasArraySorted);
                    }
                }
            }
            return partQdasArraySorted;
        } catch (error) {
            return [];
        }
    } else return [];
    
}

export async function getOptionalQdasAttrEntries( selfThis: any ):Promise<AttributeQdas[]>{
    const { attribute } = selfThis.props;
    if (attribute) {
        try {
            let attrQdasArray: AttributeQdas[] = [];
            const result = attribute.dataQdas;
            if (result) {
                attrQdasArray = JSON.parse(result) as AttributeQdas[];
            }
            let attrQdasArraySorted = attrQdasArray
            .sort( (q1,q2) => q1.position<q2.position ? -1 : 1 );
            selfThis.setState({optionalAttrEntries: attrQdasArraySorted, qdasEntriesCurr: result });
            for (const mand of qdasMandatoryRow) {
                if (mand.type === QdasType.ATTRIBUTE) {
                    const mandatoryField = `${mand.key} `;
                    const hasMandatField = attrQdasArraySorted.find(r=>r.textRow.trim().slice(0,5)===mandatoryField.slice(0,5));
                    if ( !hasMandatField ) {
                        const mandatoryAttrQdas: AttributeQdas  = {
                            uuid: mand.key + uuidv4(),
                            position: mand.position,
                            textRow: mandatoryField,
                            // timestamp: 0,
                            attributeUuid: attribute.uuid,
                        };
                        attrQdasArraySorted = [mandatoryAttrQdas].concat(attrQdasArraySorted);
                    }
                }
            }
            return attrQdasArraySorted;
        } catch (error) {
            return [];
        }
    } else return [];
    
}