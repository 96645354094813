import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadChannels, updateChannel } from "./action";
import { ChannelItem } from './types';
import { AppState } from "../../store/reducers";
import { webapi } from '../../api/web-api';

export const thunkGetChannels  = (channels:ChannelItem[]): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  const asyncResp: ChannelItem[] = await getStorageChannels();
  dispatch(
    loadChannels(
      asyncResp
    )
  );
}

export const thunkUpdateChannel = (channel: ChannelItem): ThunkAction<void,AppState,unknown,Action<any>> => async dispatch => {
  // console.log('::: thunkUpdateChannel', channel)
  const asyncResp: ChannelItem = await putStorageChannel(channel);
  // console.log('::: thunkUpdatePart-asyncResp', asyncResp);
  // console.log('');
  dispatch(
    updateChannel(
      asyncResp
    )
  );

  return asyncResp
}

function getStorageChannels(): Promise<ChannelItem[]> {
  // console.log('getStorageParts invoked');
  return new Promise<ChannelItem[]>((resolve, reject) => {
    const uri =  '/channels';
    webapi.client.get<ChannelItem[]>(uri).then((resp) => {      
      // console.log('::: getStorageChannels', resp.data.length)
      resolve(resp.data);
    }).catch((err) => {
      reject(err);
    })

  });
}

function putStorageChannel(channel: ChannelItem): Promise<ChannelItem> {
  // console.log('getStorageParts invoked');
  return new Promise<ChannelItem>((resolve, reject) => {
    const uri =  '/channel/' + channel.id;
    webapi.client.put<ChannelItem>(uri, channel).then((resp) => {      
      // console.log('::: putStorageChannel result', resp.data)
      resolve(resp.data);
    }).catch((err) => {
      // console.log('::: putstorageChannel Err: ', err);
      reject(err);
    })
  });
}

