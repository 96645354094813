import { UuidAndName } from "../../../store/statistics/types"

export type SelectedAttributes = {
    name: string
    type: string
    uuid: string
}

export type StatisticsFilter = {
    selectedAttributes: SelectedAttributes[],
    machines: UuidAndName[],
    parts: UuidAndName[],
    firstDate: number | null; // timestamp of first entry in database
    lastDate: number | null;   // last entry
    startTimestamp: number | null, // selected start timestamp 00:00:00
    endTimestamp: number | null, //                            23:59:59
}

export const initalFilterOptions: StatisticsFilter =  {
    selectedAttributes: [],
    machines: [],
    parts: [],
    firstDate: null,
    lastDate: null,
    startTimestamp: null,
    endTimestamp: null,
}