import '../scss/main.scss';
import React from'react';
// import axios from 'axios';
import { connect } from 'react-redux';
import { SystemState  } from '../store/system/types';
import BrowserLandingPage from './browserLandingPage/BrowserLandingPage';
import { PartsState } from '../store/parts/types'
import { RaspState } from '../store/rasp/types';
import Connect from './connect/Connect';
// @ts-ignore
import platform from 'platform-detect';
import { AppState } from '../store/reducers';
import Qumo from './app/Qumo';

import { SocketService } from '../context/message/socket-service';
import { DeviceMessage } from '../context/message/types';
import { MessageContext } from '../context/index';
import QFC from '../context/qumo-features/qumo-features-context';
import NamingContext from '../context/naming/naming-context';
import { AppConfig } from '../constants/app-config';

import { thunkGetParts } from '../store/parts/thunks';
import { thunkGetMachines} from '../store/machines/thunks';
import { thunkGetChannels } from '../store/channels/thunks'
import { thunkGetDevice} from '../store/rasp/thunks';
import { updateDeviceConnection } from '../store/rasp/action';
import { updateSession } from '../store/system/action';
import { webapi } from '../api/web-api';
import { serviceApi } from '../api/service-api';
import ConnectionHelper from '../api/connection-helper';
import QumoFeaturesHelpers from '../context/qumo-features/qumo-features-helper';
import NamingHelper from './settings/naming/naming-helper';
import { defaultNamingObject } from './settings/naming/naming-types';

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

interface AppProps {
    system: SystemState
    rasp: RaspState
    parts: PartsState
    history: any
    updateSession: any;
    thunkGetDevice: any;
    thunkGetChannels: any;
    thunkGetParts: any;
    thunkGetMachines: any;
    updateDeviceConnection: typeof updateDeviceConnection;
}

interface LocalAppState {
    isPWA: boolean
    osName: string
    isDev: boolean
}

class App extends React.Component<AppProps, LocalAppState> {

    static contextType = MessageContext;    // RealtimeApi context type (required for context.init!)

    constructor(props:AppProps){
        super(props);
        this.state = {
            // isPWA: true, // DEV
            isPWA: development ? true : window.matchMedia('(display-mode: standalone)').matches,
            osName: ['macos','ios','android','windows'].find(os=>platform[os]) ?? '',
            isDev: false
        }
    }

	componentDidMount() { 
        const { osName, isPWA } = this.state;
        console.log(`Promess Qu-Mo started on OS: ${osName}, isPWA: ${isPWA}`);
    }

    componentDidUpdate(){
        if (this.state.isDev && !this.state.isPWA) {
            this.setState({isPWA:true})
        }
    }

    client?: SocketService;
    _initContext = (socketUri: string) => {
        // RealtimeApi disconnect
        this.client = this.context.init(socketUri);
        if (this.client) {
            const observable = this.context.onMessage();
            observable.subscribe((msg: DeviceMessage) => {
            console.log('_initContext: msg', msg)
                switch (msg.tag) {
                    // triggered when connction opened or closed
                    case 'connection':
                        if (msg.message === 'on') {
                        } else {
                            // Disconnect event
                            this.props.updateDeviceConnection(false);
                            this.stoppApp();
                        }
                        break;
                    case 'version':
                        if (Number(msg.message) > 1) {
                            console.log('####### Qu-Mo occupied')
                            this.props.updateSession({
                                isQumoOccupied: true
                            })
                        } else {
                            // Connect event
                            setTimeout(() => {
                                this.props.updateDeviceConnection(true); // isConnected -> true
                            }, 1000);
                        }
                        // console.log('realtime interface version', msg.message);
                        break;
                    default:
                        break;
                }
            });
        }
    };

    stoppApp(){
        console.log('stoppApp::connection lost');
        this.props.updateSession({
            hostEndpoint: '',
            serviceEndpoint: '',
            webApiEndpoint: '',
            realApiEndpoint: '',
            userName: '',
            userRole: '',
            loggedIn: false,
            appLoaded: false
        })

        webapi.setBaseUrl('');
        serviceApi.setBaseUrl('');
    }

    async startApp(host:string, devIndex?: number) {
        console.log('startApp::connecting to:', host);

        let serviceUrl = `https://${host}`;
        let prtApi = `${serviceUrl}:${AppConfig.connection.ports.prtapi}`
        let webApi = `${serviceUrl}:${AppConfig.connection.ports.webapi}`

        if (devIndex) {
            // DEVELOP
            serviceUrl = AppConfig.connections[devIndex].service;
            prtApi = AppConfig.connections[devIndex].realtime;
            webApi = AppConfig.connections[devIndex].database;
        } else {
            ConnectionHelper.setRaspDatetime(host);
        }

        webapi.setBaseUrl(webApi);
        serviceApi.setBaseUrl(serviceUrl);
        this._initContext(prtApi);

        this.props.updateSession({
            hostEndpoint: host,
            serviceEndpoint: serviceUrl,
            webApiEndpoint: webApi,
            realApiEndpoint: prtApi,
            userName: 'Workman',
            userRole: 'Workman',
            loggedIn: true,
        })

        const features = await QumoFeaturesHelpers.getFeatures();
        let naming = defaultNamingObject;
        if (devIndex===2) {
            naming = await NamingHelper.loadNaming(true);
        } else {
            naming = await NamingHelper.loadNaming();
        }
        

        setTimeout(() => {
            // ASYNCHRON
            this.props.thunkGetDevice();
            this.props.thunkGetChannels();
            this.props.thunkGetMachines();
            this.props.thunkGetParts(); // appLoaded -> true
        }, 200);

        return {
            features,
            naming
        }

    }

	render( ) {

        const { isPWA, osName } = this.state;
        const { settings, appLoaded } = this.props.system;
        const { isConnected } = this.props.rasp.device;

        // Landing Page Browser
        if (!isPWA) return <BrowserLandingPage osName={osName} language={settings.language} setIsDev={(bool)=>this.setState({isDev:bool})}/>

        // Landing Page PWA
        if(isPWA && !isConnected ){
            return (
                <QFC.Context.Consumer>
                    { qumoFeaturesContextValue =>
                        <NamingContext.Context.Consumer>
                            { namingContext =>
                                <Connect 
                                    osName={osName}
                                    connectHandler={this.startApp.bind(this)}
                                    qumoFeaturesContext={qumoFeaturesContextValue}
                                    namingContext={namingContext}
                                />
                            }
                        </NamingContext.Context.Consumer>
                    }
                </QFC.Context.Consumer>
            )
        }

        // QuMo App (Messen, etc.)
        if (isPWA && appLoaded && isConnected ) {
            return (
                <NamingContext.Context.Consumer>
                    { namingContext =>
                        <Qumo
                            history={this.props.history}
                            namingContext={namingContext}
                        />
                    }
                </NamingContext.Context.Consumer>)
        }

        // Fallback
        return null
    }        
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    rasp: state.rasp,
    parts: state.parts,
});

const dispatchToProps = {
    updateSession,
    updateDeviceConnection,
    thunkGetDevice,
    thunkGetChannels,
    thunkGetParts,
    thunkGetMachines,
};

export default connect(mapStateToProps, dispatchToProps)(App);