import { AppConfig } from './../constants/app-config';
import axios from 'axios';
import { ServerResponse } from '../components/device-status/constants';
import { serviceApi } from './service-api';

namespace ConnectionHelper {
    /**
     *                                                                          
     * @param hosts Array of string IP-adresses
     * @param config object of timeouts
     * @param setStateFunc callback which gets the pinged ip-adress 
     */
    export function getHostUrl( hosts:string[],config:{ pingTimeout:number, timeout:number }, setStateFunc: (urlToQumo: string, timeoutRef: NodeJS.Timeout)=>void){

        function checkHttpxConnection(url:string, timeout:number):Promise<boolean>{
            return new Promise<boolean>((resolve)=>{
                let returnValue = false;
                const client = axios.create({
                    baseURL: url,
                    timeout: timeout
                })
                client
                .get('/')
                .then(response => {
                    const { status, statusText } = response;
                    switch (status) {
                        case 200:
                            // CONNECT 
                            returnValue = true
                            break;
                        default:
                            console.error('unhandled status:', statusText);
                            break;
                    }
                })
                .catch((error) => {
                    // NOT CONNECTED
                })
                .finally(()=>{
                    resolve(returnValue)
                })
            })
        }
            
        const { pingTimeout,timeout } = config;
    
        let promises:Promise<boolean>[] = [];
        promises = hosts.map((host)=>{
            const urlHttps = `https://${host}`;
            return checkHttpxConnection(urlHttps, pingTimeout)
        })
    
        Promise
        .all(promises)
        .then((res)=>{
            let found = false;
            let selectedHost = '';
            for (let i = 0; i < res.length; i++) {
                const ele = res[i];
                if (ele && !found) {
                    found = true;
                    selectedHost = hosts[i]
                }
            }
            if (found) {
                const emptyTimeoutRef = setTimeout(() => {}, 0);
                setStateFunc( selectedHost, emptyTimeoutRef);
            } else {
                const timeoutRef = setTimeout(() => {
                    getHostUrl( hosts, config, setStateFunc );
                }, timeout);
                setStateFunc( selectedHost, timeoutRef );
                console.log('invalid connection');
            }
        })
    };

    /**
     * syncronizes the time and date on qumo (host) with device (client)
     * @param host qumo host ip-address 
     */
    export function setRaspDatetime (host: string) {
        const uri = `https://${host}/time-sync`;
        const data = {timestamp: new Date().getTime()+ 500}
        axios.post(uri, data)
        .then(()=>console.log('Qu-Mo DateTime sucessfully syncronized'))
        .catch((err)=>console.log('Qu-Mo DateTime failed::err:',err))
        // TODO:
        // executeSubject.next('setdatetime')
    }

    export function getQumoServiceInfo (urlToQumo:string) {
        // const url = `https://${urlToQumo}/ping`;
        const url = urlToQumo.slice(0,4) === 'http'
            ? `${urlToQumo}/ping`
            : `https://${urlToQumo}/ping`;
        return new Promise<any>(resolve=>{
            axios.get(url)
            .then((res)=>{
                resolve(res.data);
            })
            .catch(()=>{
                resolve({});
            })
        })
    }

    /**
     * uploads file to Qumo for update services
     * @param e 
     * @param urlToQumo 
     */
    export function uploadUpdateFile (e: React.ChangeEvent<HTMLInputElement>, urlToQumo: string, setProgress: ( progress: number ) => void, setServerResponseArrived: ( resp: null | ServerResponse ) => void) {
        if (e.target.files){
            const file = e.target?.files[0];
            console.log('handleUpload::file: ', file)
            let formData = new FormData();
            formData.append('update', file);
            const url = urlToQumo.slice(0,9) === 'localhost'
                ? `http://localhost:5100/upload-update` // DEV
                : `https://${urlToQumo}/upload-update`;
                
            axios
            .post( url,formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                setProgress(progress);
              }
            })
            .then((res)=>{
                console.log('handleUpload::res: ', res);
                setServerResponseArrived(res.data);
            })
            .catch((err)=>{
                console.error('handleUpload::err: ', err);
                setServerResponseArrived({ status: false, message: 'upload ERROR' });
            })
        }
    }

    /**
     * get timestamp from Qumo
     * @param urlToQumo 
     * @returns timestamp in Promise<number>
     */
    export function getRaspTimestamp (urlToQumo:string) {
        const url = `https://${urlToQumo}/time-sync`;
        return new Promise<number>(resolve=>{
            axios.get(url)
            .then((res)=>{
                resolve(res.data.data.currentTimestamp);
            })
            .catch(()=>{
                resolve(0);
            })
        })
    }

    export type certInfoObjType = { validToDate: string, validDays: number, validDaysMax: number, showPopup: boolean }
    export const certInfoObjDefault: certInfoObjType = { validToDate: '', validDays: 0, validDaysMax: 0, showPopup: false }

    export async function getCertificateInfo() {
        // get certificate information from qu-mo service
        let certInfoObj = { ...certInfoObjDefault };
        try {
            const resp = await serviceApi.client.get('/cert-info');
            const certificate = resp.data.certificate;

            const validFrom = new Date(certificate.valid_from);
            const validFromTimestamp = validFrom.getTime();

            const validTo = new Date(certificate.valid_to);
            const validToDate = validTo.toLocaleString();
            const validToTimestamp = validTo.getTime();

            const validDaysMax = Math.round(( validToTimestamp - validFromTimestamp ) / 1000 / 60 / 60 / 24);
            const nowTimestamp = new Date().getTime();
            const validDays = Math.round(( validToTimestamp - nowTimestamp ) / 1000 / 60 / 60 / 24);

            const showPopup = validDays <= AppConfig.certificate.warnTrigger;

            certInfoObj = { validToDate, validDays, validDaysMax, showPopup };
        } catch (error) {
            console.error('cert-valid-to ERR: ', error);
        }
        return certInfoObj;
    }
}

export default ConnectionHelper;