export class ImageResizer {
    protected MAX_WIDTH: number;
    protected MAX_HEIGHT: number;
    
    constructor(width: number = 100, height: number = 100) {
        this.MAX_WIDTH = width;
        this.MAX_HEIGHT = height;
    }

    resize(e:any, fileType: any): Promise<any> {
        return new Promise<any>((resolve) => {
            var img = new Image();
                    img.src =  e.target.result;
                    img.onload = event => {
                        var canvas = document.createElement("canvas");
                        canvas.width = this.MAX_WIDTH;
                        canvas.height = this.MAX_HEIGHT;
                        var ctx = canvas.getContext("2d");
                        
                        ctx?.drawImage(img, 0, 0, this.MAX_WIDTH,this.MAX_HEIGHT);
        
                        var width = img.width;
                        var height = img.height;
        
                        if (width > height) {
                            if (width > this.MAX_WIDTH) {
                                height *= this.MAX_WIDTH / width;
                                width = this.MAX_WIDTH;
                            }
                        } else {
                            if (height > this.MAX_HEIGHT) {
                                width *= this.MAX_HEIGHT / height;
                                height = this.MAX_HEIGHT;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;
                        var ctx2 = canvas.getContext("2d");
                        ctx2?.drawImage(img, 0, 0, width, height);
        
                        var dataurl = ctx2?.canvas.toDataURL(fileType);
        
                        resolve(dataurl);
                    }
                
        });

    }
}