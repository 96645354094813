import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import Moment from 'moment';
import 'moment/locale/de'  // without this line de moment translations didn't work
import resourcesDe from "./lang/de.json";
import resourcesEn from "./lang/en.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    // lng: "de",
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
  i18n.addResourceBundle('de', 'translation', resourcesDe);
  i18n.addResourceBundle('en', 'translation', resourcesEn);
  Moment.locale(i18n.language);

export default i18n;
