export const onKeyDown = (prevKey:any, setPrevKey:any, refs:any, e:any, i:number) => {

    const key = e.code || e.nativeEvent.key
    // console.log(key);
    // console.log(refs);
    
    const value = e.target.value
    // const isNoNavButton = key !== 'Backspace' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab' && key !== 'ShiftLeft'
    
    if((key === 'Backspace' && !value) || (key === 'ArrowLeft' && !value) || (key === 'ArrowLeft' && prevKey === 'ArrowLeft') ){
        if(i !== 0){
            refs[i - 1].current?.focus() 
            refs[i - 1].current?.select() 
            setPrevKey('')
        }
        return false
    } else if ((key === 'ArrowRight' && !value) || (key === 'ArrowRight' && prevKey ==='ArrowRight')){
        if(i !== refs.length - 1){
            refs[i + 1].current?.focus() 
            refs[i + 1].current?.select() 
            setPrevKey('')
        }
        return false
    }         
    setPrevKey(key)
}