import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../store/reducers';
import DropdownSublistItem from './DropdownSublistItem';
import { Translation } from 'react-i18next';
import { getPartLabel } from '../../parts/partHelpers';
import NamingContext from '../../../context/naming/naming-context';
import NamingHelper from '../../settings/naming/naming-helper';
import { FEATURE, FEATURES, NEST, NESTS } from '../../../constants/lang/translation-keys';

interface DropdownListItemProps {
    item: any
    onClickHandler: any
    showSublist?: boolean
    onSublistClickHandler?:any
    isInCheckedItems: boolean
    checkedSubitems: any
}

interface DropdownListItemState {
    showSublist: boolean
}

class DropdownListItem extends React.Component<DropdownListItemProps, DropdownListItemState> {

    static contextType = NamingContext.Context;

    onClickHandler(){
        this.props.onClickHandler(this.props.item)
    }

    onSublistClickHandler(subItem:any){
        const combinedListItem = {
            subItem,
            item: { ...this.props.item }
        }
        this.props.onClickHandler(combinedListItem)
    }

    isChecked(item:any){    
        return this.props.checkedSubitems.findIndex((activeItem:any) =>  activeItem.uuid === item.uuid) !== -1
    }

    getNaming(key: string) {
        return NamingHelper.getNaming(key, this.context.naming);
    }

    render() {
        
        const { item } = this.props
        
        const collapsableCss = this.props.showSublist ? ' collapsable--opened' : ''
        let subItems:any = null
        if(item.nests) subItems = item.nests
        if(item.items) subItems = item.items
        // for parts
        let attributesCount: undefined | number = undefined;
        if (item.attributes) {
            attributesCount = item.attributesCount ? item.attributesCount : 0
        }
        const name = item.partNumber ? getPartLabel(item) : this.getNaming(item.name);
        

        return(
            <div className={ this.props.isInCheckedItems ? 'list__item list__item--checked' : 'list__item' }>
                <div
                    data-id = { item.id }
                    className="row no-gutters align-items-center pt-3 pb-3 text-left"
                    data-name= { item.name }
                    onClick= { () => this.onClickHandler() }
                >
                    {(item.image || item.imageUrl) && 
                        <div className="col-3 col-sm-2 col-md-3 col-lg-2 d-flex justify-content-center">
                            {item.image &&  <span className="icon icon--round icon--md icon--border">{item.image}</span>}
                            {item.imageUrl && <img className="icon icon--round icon--md icon--border" src={item.imageUrl} alt=""/>}
                        </div>
                    }
                    <div className="col-9">
                        <Translation>{
                            t => 
                            <div>
                            {
                                name && 
                                <h3 className="h3 mb-0 text-ellipsis">{name}</h3>
                            }
                            {
                                subItems && 
                                item.nests &&
                                <h3 className="hsmall font-regular text-third mb-0">
                                    {subItems.length} { subItems.length > 1 ? this.getNaming(NESTS) : this.getNaming(NEST)} 
                                </h3>
                            }
                            {
                                subItems && 
                                item.items && 
                                <h3 className="hsmall font-regular text-third mb-0">
                                    {subItems.length} { subItems.length > 1 ? this.getNaming(item.name) : this.getNaming(item.nameSingular) }
                                </h3>
                            }
                            {
                                item.attributes &&
                                <h4 className="hsmall font-regular text-third mb-0">{ attributesCount } { attributesCount === 1 ? t(FEATURE) : t(FEATURES)}</h4>
                            }
                            </div>
                            }
                           
                        </Translation>
                    </div>
                </div>
                {
                    subItems && subItems.length >= 1 && (
                        <div className={'dropdown__sublist row no-gutters collapsable text-left ' + collapsableCss}>
                            <div className="offset-3 offset-sm-2 offset-md-3 offset-lg-2 col-9 col-sm-10 col-md-9 col-lg-10">
                                <div>
                                    {
                                        subItems.map((subItem:any, index:any) => {
                                            return (
                                                <DropdownSublistItem
                                                    key = { index }
                                                    item = { subItem }
                                                    onClickHandler = { this.onSublistClickHandler.bind(this) }
                                                    isChecked = {this.isChecked(subItem)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    };
}

const mapStateToProps = (state: AppState) => ({
    
});

const dispatchToProps = {
    
};

export default connect(mapStateToProps, dispatchToProps)(DropdownListItem);