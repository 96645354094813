export const MEASURE = 'Measure';
export const STATISTICS = 'Statistics';
export const PARTS = 'Parts';
export const PART = 'Part';
export const FEATURES = 'Features';
export const FEATURE = 'Feature';
export const SETTINGS = 'Settings';

export enum CatNameKeys {
    MACHINE = 'Machine',
    MACHINES = 'Machines',
    NEST = 'Nest',
    NESTS = 'Nests'
}

export const MACHINE = CatNameKeys.MACHINE;
export const MACHINES = CatNameKeys.MACHINES;
export const NEST = CatNameKeys.NEST;
export const NESTS = CatNameKeys.NESTS;
export const MACHINE_DEFAULT = 'Machine_default';
export const MACHINES_DEFAULT = 'Machines_default';
export const NEST_DEFAULT = 'Nest_default';
export const NESTS_DEFAULT = 'Nests_default';

export const MACHINE_NEST = 'MachineNest';

export const CHOOSE_MACHINE = 'ChooseMachine';
export const CREATE_MACHINE = 'CreateMachine';
export const CREATE_MACHINE_TO_ACCEPT_MEASUREMENT = 'CreateMachineToAcceptMeasurement';
export const ADD_NEST = 'AddNest';