import React from 'react';
import { Translation } from 'react-i18next';
import { getChannelsAll } from '../../../store/channels/selectors';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { overlayToggle, updateSession } from '../../../store/system/action';
import { thunkUpdateChannel } from '../../../store/channels/thunks';
import { SystemState } from '../../../store/system/types';
import QFH from '../../../context/qumo-features/qumo-features-helper';
import { QumoFeatureItem, QumoFeatureContextType } from '../../../context/qumo-features/qumo-features-types';
import RadioToggle from '../../ui/RadioToggle';

import FeaturesPopUp from './FeaturesPopUp';
import QFC from '../../../context/qumo-features/qumo-features-context';
import { confirmAlert } from 'react-confirm-alert';
import { PopUpMode } from './types';

interface FeaturesProps {
    system: SystemState
    overlayToggle: any
    updateSession: typeof updateSession
}

interface FeaturesState {
    features: QumoFeatureItem[]
    currFeature: QumoFeatureItem | null
    showPopUp: boolean
    popUpMode: PopUpMode
}

class Features extends React.Component<FeaturesProps, FeaturesState> {

    static contextType = QFC.Context;

    constructor(props:FeaturesProps){
        super(props);
        this.state = {
            features: [],
            currFeature: null,
            showPopUp: false,
            popUpMode: PopUpMode.CONFIG
        }
    }


    componentDidMount(){
        this.loadFeatures()
    }

    async loadFeatures(){
        const features = await QFH.getFeatures();
        const context = this.context as QumoFeatureContextType;
        context.setFeatures(features);
    }



    handleClose(){
        this.props.overlayToggle()
    }

    handleOpenPopUp( feature: QumoFeatureItem, popUpMode: PopUpMode ) {
        this.setState({ popUpMode })
        this.setState({ currFeature: feature })
        this.setState({ showPopUp: true })
    }

    async handleSubmitCode( e: React.MouseEvent<HTMLButtonElement, MouseEvent>, code: string, thisFeatures: any ){
        e.stopPropagation();
        const { currFeature } = thisFeatures.state;
        let isActivated = false;
        let timeString = '';
        if ( currFeature !== null ) {
            const { id, name } = currFeature;
            try {
                const response = await QFH.activateFeature( id, code );
                // console.log('handleSubmitCode - response: ', response);
                if (response) {
                    const features = response.data.data as QumoFeatureItem[];
                    isActivated = response.data.status;
                    if (isActivated) {
                        const foundItem = features.find(f=>f.id===currFeature.id)
                        if (foundItem) {
                            timeString = new Date(foundItem.expireAt-24*60*60*1000).toLocaleDateString();
                        }
                        const context = thisFeatures.context as QumoFeatureContextType;
                        context.setFeatures(features);
                        let settings = { ...thisFeatures.props.system.settings };
                        const qumoActiveFeatures = QFH.toggleFeature( id, settings.qumoActiveFeatures, true );
                        settings = {...settings, qumoActiveFeatures};
                        thisFeatures.props.updateSession({ ...thisFeatures.props.system, settings });
                    }
                }
            } catch (error) {
                console.error('handleSubmitCode Error: ', error);
            }
            thisFeatures.closePopUp(thisFeatures);
            confirmAlert({
                closeOnEscape: true,
                customUI: ({ onClose }) => {
                    return (
                        <Translation>{t =>
                            <div className='react-confirm-content w-100'>
                                <div className="row mb-3">
                                    <div className="col-10 d-flex align-items-center">
                                        <h2 className="hsmall strong text-uppercase mb-0 text-left">{name}</h2>
                                    </div>
                                    <div className="col-2">
                                        <button onClick={onClose} className="btn btn--icon btn--icon-close btn--action-dark ml-auto"></button>
                                    </div>
                                </div>
                                <p className="hsmall strong mb-10">{isActivated ? t('FeatureActivated') : t('FeatureNotActivated')}</p>
                                {isActivated && timeString && <p className="hsmall strong mb-10">{ t('ValidUntil') + ': ' + timeString }</p>}
                                <div className="row">
                                    <div className={ 'col-8 ml-auto mr-auto'}>
                                        <button
                                            type="button"
                                            className='btn w-100'
                                            onClick={onClose}
                                        >
                                            {t('Understood')}
                                        </button>
                                    </div>
                                    
                                </div>
                            </div>}
                        </Translation>
                    );
                },
            })
        }
    }

    closePopUp( thisFeatures: any ){
        thisFeatures.setState({ showPopUp: false })
    }

    onChangeHandler( e: React.ChangeEvent<HTMLInputElement> , thisFeatures: this){
        const id = e.target.value;
        let settings = { ...thisFeatures.props.system.settings };
        const qumoActiveFeatures = QFH.toggleFeature(id, settings.qumoActiveFeatures);
        settings = {...settings, qumoActiveFeatures};
        thisFeatures.props.updateSession({ ...thisFeatures.props.system, settings });
    }



   
    render() {
        const { qumoActiveFeatures } = this.props.system.settings;

        const context = this.context as QumoFeatureContextType;
        return(
            <><Translation>
                {
                    t =>
                    <div className="container-fluid app__overlay-form">
                        <div className="row">
                            <div className="col-4 offset-8 col-md-3 offset-md-9 text-right">
                                <button 
                                    className="btn btn--icon btn--icon-close bg-action-dark mr-3" 
                                    onClick={ ()=> this.handleClose() }
                                ></button>
                            </div>
                        </div>
                        
                        <FeaturesPopUp
                            t={t}
                            description={this.state.currFeature?.name ?? 'Qu-Mo Feature'}
                            show={this.state.showPopUp}
                            closePopUp={()=>this.closePopUp(this)}
                            handleSubmitCode={(e,c)=>this.handleSubmitCode(e, c, this)}
                            popUpMode={this.state.popUpMode}
                        />

                        <div className="mr-3" 
                        style={{marginTop:60}}
                        >
                            {
                                context.features.map( (f: QumoFeatureItem) => {
                                    const labelSecondary = f.active
                                        ? `${t('ValidUntil')}: ${new Date(f.expireAt-24*60*60*1000).toLocaleDateString()}`
                                        : undefined
                                    return <RadioToggle 
                                                key={f.id}
                                                labelMain={f.name}
                                                labelSecondary={ labelSecondary }
                                                value={f.id}
                                                onChangeHandler={ e => this.onChangeHandler(e, this) }
                                                checked = { !!qumoActiveFeatures.find(q=>q===f.id) }
                                                separator = { true }
                                                pillShow = { true }
                                                pillOn = {t('On')}
                                                pillOff = {t('Off')}
                                                addButton1={!f.active ? { label: t('Activate'), onClickHandler: ()=>this.handleOpenPopUp(f,PopUpMode.ACTIVATE)} : null}
                                                addButton2={f.active ? { label: t('CONFIG'), onClickHandler: ()=>this.handleOpenPopUp(f,PopUpMode.CONFIG)} : null}
                                            />
                                })
                            }
                        </div>

                        
                    </div>
                }
            </Translation></>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
    channels: getChannelsAll(state),
    system: state.system
});

const dispatchToProps = {
    overlayToggle,
    thunkUpdateChannel,
    updateSession
};

export default connect(mapStateToProps, dispatchToProps)(Features);