import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface RadioToggleProps {
    labelMain: string
    labelSecondary?: string
    value: string
    onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    checked: boolean
    theme?: 'blue' | 'default'
    separator?: boolean
    styles?: string
    pillShow?: boolean
    pillOn?: string
    pillOff?: string
    addButton1?: { label: string, onClickHandler: ()=>void } | null
    addButton2?: { label: string, onClickHandler: ()=>void } | null
}

interface RadioToggleState {
    checked: boolean
    windowInnerWidth: number
}

class RadioToggle extends React.Component<RadioToggleProps, RadioToggleState> {

    constructor(props:RadioToggleProps){
        super(props)
        this.state = {
            checked: this.props.checked,
            windowInnerWidth: window.innerWidth
        }
    }

    componentDidUpdate(prevProps:RadioToggleProps){
        if(this.props.checked !== prevProps.checked){
            this.setState({
                checked: this.props.checked
            })
        }
    }

    componentDidMount(){
        window.addEventListener("resize", ()=>this.updateWindowSizeDependencies());
        return window.removeEventListener("resize", ()=>this.updateWindowSizeDependencies());
    };

    updateWindowSizeDependencies(){
        const windowInnerWidth = window.innerWidth;
        this.setState({ windowInnerWidth })
    }

    onChange(e:any){
        this.setState({
            checked: !this.state.checked
        })
        this.props.onChangeHandler(e)
    }

    render() {

        const id =  uuidv4()
        let css = this.props.theme === 'blue' ? 'radio-toggle--blue' : ''
        let cssLabel = this.props.theme === 'blue' ? 'radio-toggle__label--reset ' : ''
        if(this.props.separator) css += ' radio-toggle--separator'
        if(this.props.styles) css += ' ' + this.props.styles
        const pill = this.state.checked ? this.props.pillOn : this.props.pillOff;

        const { addButton1, addButton2 } = this.props;


        const colLeftWidth = addButton2 && this.state.windowInnerWidth < 500 ? 4 : 6;
        const colRightWidth = addButton2 && this.state.windowInnerWidth < 500 ? 8 : 6;
        const cssLeftCol = `col-${colLeftWidth} d-flex flex-column justify-content-center`;
        const cssRightCol = `col-${colRightWidth} d-flex align-items-center justify-content-end`;

        return(
            <div className= { css + ' radio-toggle radio-toggle--pointer' }>
                <label htmlFor= {id}>
                    <div className="row">
                        <div className={cssLeftCol}>
                            <span className={ cssLabel + 'radio-toggle__label mb-1 d-block'}>{ this.props.labelMain }</span>
                            {
                                this.props.labelSecondary && 
                                <span className="radio-toggle__sublabel text-grey-dark d-block">{ this.props.labelSecondary }</span>
                            }
                        </div>
                        <div className={cssRightCol}>
                            {
                                addButton1 && 
                                    <button
                                        className={'btn btn--auto btn--small btn--white d-inline-block'}
                                        onClick={()=>addButton1 && addButton1.onClickHandler()}
                                    >
                                        {addButton1.label}
                                    </button>
                            }
                            {
                                addButton2 &&
                                <button
                                        className={'btn btn--auto btn--small btn--white d-inline-block mr-4'}
                                        onClick={()=>addButton2 && addButton2.onClickHandler()}
                                    >
                                        {addButton2.label}
                                    </button>
                            }
                            
                            {
                                !addButton1 &&
                                    <>
                                        {/* FIXME: responsive mobile */}
                                        { 
                                            this.props.pillOn && 
                                            this.props.pillOff && 
                                            this.props.pillShow &&
                                            <span className="badge badge--for-toggle mr-2 badge--fluid">{ pill }</span>
                                        }
                                        <input 
                                            className="radio-toggle__input" 
                                            type="checkbox" 
                                            name= {this.props.labelMain} 
                                            id = {id} 
                                            value= { this.props.value } 
                                            checked = { this.state.checked }
                                            onChange={ e => {
                                                this.onChange(e)
                                            }} 
                                        />
                                    </>
                            }
                            <span className="radio-toggle__mask"></span>
                        </div>
                    </div>
                </label>
            </div>
        );
    };
}

export default RadioToggle;