import React from 'react';
import { useTranslation } from 'react-i18next';
import ConnectionHelper from '../../api/connection-helper';
import { AppConfig } from '../../constants/app-config';
import { extractData, getTimeDiff, renderCertExpiryBar, showCertificateAlert } from './deviceStatusFunctions';
import PopUpWrapper from '../ui/PopUpWrapper';
// import { downloadCertificate } from '../connect/onboarding';

type PopUpProps = {
    onClose: ()=>void
    urlToQumo: string
    handleAction: (str:string, e?:React.ChangeEvent<HTMLInputElement>)=>void
}

type RenderAttribute = {
    name: string;
    value: string | number
}

export const DeviceStatusPopUp: React.FC<PopUpProps> = (props) => {
    const { onClose, urlToQumo, handleAction } = props;
    const {t} = useTranslation();
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [qumoInfo, setQumoInfo] = React.useState([
        {name:t('DeviceName'), value:'N/D'},
        {name:t('DeviceModel'), value:'N/D'},
    ]);
    const [qumoTimestamp, setQumoTimestamp] = React.useState(0);
    const timeDiffObj = getTimeDiff(qumoTimestamp,AppConfig.measurements.timeSyncDiffInMs);
    const qumoTimeArray = [
        {name:t('DeviceDateTime'), value: new Date(qumoTimestamp).toUTCString()},
        {name:t('Difference'), value: timeDiffObj.output},
    ];
    const [qumoServices, setQumoServices] = React.useState([
        {name:t('RealTime API'), value:'N/D'},
        {name:t('Web API'), value:'N/D'},
        {name:t('Service'), value:'N/D'},
    ]);

    const [certInfo, setCertInfo] = React.useState({ ...ConnectionHelper.certInfoObjDefault });
    const certInfoArray = [
        {name:t('CertExpiry'), value: `${certInfo.validDays} ${t('days')} ( ${certInfo.validToDate} )`},
    ]

    React.useEffect(()=>{

        // get timestamp from qumo
        ConnectionHelper.getRaspTimestamp(urlToQumo)
        .then(timestamp=>{
            setQumoTimestamp(timestamp)});

        // get qumo service details
        ConnectionHelper.getQumoServiceInfo(urlToQumo)
        .then(data=>{
            extractData(data, setQumoInfo, setQumoServices, t);
        });

        ConnectionHelper.getCertificateInfo()
        .then( certInfoObj =>setCertInfo(certInfoObj));

    },[urlToQumo,t]);

    const handleTimeSyncOnClick = () => handleAction('time-sync');
    const handleUpdateOnClick = () => inputRef.current?.click();
    const handleUpdateInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => handleAction('update', e);

    const renderContentButton = (name:string, buttonTitle:string, onClick:any) => {
        return (
            <tr>
                <td>{name}</td>
                <td>
                    <div className="col-8 ml-auto mr-auto">
                        <button onClick={onClick} className="d-inline-block btn btn--white w-100">{buttonTitle}</button>
                    </div>
                </td>
            </tr>
        )
    }
    const renderContentUpdateButton = () => {
        return (
            <tr >
                <td>{t('Qu-Mo Update')}</td>
                <td>
                    <div className="col-8 ml-auto mr-auto">
                        <form action="" method="get"></form>
                        <button className="d-inline-block btn btn--white w-100" onClick={handleUpdateOnClick}>{t('Upload Update-File')}</button>
                        <input hidden ref={inputRef}  onChange={handleUpdateInputOnChange} type="file" name="file"/>
                    </div>
                </td>
            </tr>
        )
    }
    const renderContentInfo = (atts:RenderAttribute[], showProgress?: boolean) => {
        return atts.map((att, index)=>{
            return (
                <tr key={att.name+index} >
                    <td width="30%">{att.name}</td>
                    <td>
                        <strong>{att.value}</strong>
                        { showProgress && <div onDoubleClick={()=>showCertificateAlert( { ...certInfo, showPopup: true } )}>{renderCertExpiryBar(certInfo)}</div> }
                    </td>
                    
                </tr>
            )
        })
    }
    const renderContent = () => {
        return (
            <div className="react-confirm-scroll">
                <div className="text-left mb-3">
                    <table className="table--white">
                        <tbody>
                            {renderContentInfo(qumoInfo)}
                            {renderContentInfo(certInfoArray, true)}
                            {renderContentInfo(qumoTimeArray)}
                            {/* {renderContentButton(t('DEV'),t('download cert'),()=>downloadCertificate())} */}
                            {!timeDiffObj.isTimeDiffMinor && renderContentButton(t('TimeSync'),t('sync now'),handleTimeSyncOnClick)}
                            {renderContentUpdateButton()}
                            {renderContentInfo(qumoServices)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    return (
        <PopUpWrapper onClose={onClose} title={t('DeviceInfo')}>
            {renderContent()}
        </PopUpWrapper>
    )
}

