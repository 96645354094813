import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadStatistics, addMeasurement, deleteMeasurement } from "./action";
import {
  MeasurementExportItem,
  MeasurementFlatItem,
  MeasurementItem,
  QueryResultInterface,
} from "./types";
import { AppState } from "../../store/reducers";
import { webapi } from '../../api/web-api';
import { SetStatsLoader } from "../system/action";

const defaultParams: thunkGetPagedStatisticsDefaultType = {
  take: 10,
  skip: 0,
  sortBy: "time",
  order: "DESC",
  wfilter: null,
  pfilter: null,
  refresh: false,
  fromDateTimestamp: null,
  toDateTimestamp: null,
};

interface thunkGetPagedStatisticsDefaultType {
  take: number;
  skip: number;
  sortBy: string;
  order: string;
  wfilter: null | string;
  pfilter: null | string;
  refresh: boolean;
  fromDateTimestamp: null | number;
  toDateTimestamp: null | number;
}

export interface thunkGetPagedStatisticsCustomType {
  take?: number;
  skip?: number;
  sortBy?: string;
  order?: string;
  wfilter?: null | string;
  pfilter?: null | string;
  refresh?: boolean;
  fromDateTimestamp?: null | number;
  toDateTimestamp?: null | number;
}

export const thunkGetPagedStatistics = (
  customParams: thunkGetPagedStatisticsCustomType
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  const params = {
    ...defaultParams,
    ...customParams,
  };
  const {
    take,
    skip,
    sortBy,
    order,
    wfilter,
    pfilter,
    refresh,
    fromDateTimestamp,
    toDateTimestamp,
  } = params;

  await dispatch(SetStatsLoader(true));

  const asyncResp: QueryResultInterface<MeasurementFlatItem> = await getStoragePagedStatistics(
    take,
    skip,
    sortBy,
    order,
    wfilter,
    pfilter,
    refresh,
    fromDateTimestamp,
    toDateTimestamp
  );
  dispatch(loadStatistics(asyncResp));
  await dispatch(SetStatsLoader(false));

  return asyncResp.items.length;
};

const defaultParamsData: thunkGetStorageStatisticsDataDefaultType = {
  refresh: false,
  fromDateTimestamp: null,
  toDateTimestamp: null,
};

interface thunkGetStorageStatisticsDataDefaultType {
  refresh: boolean;
  fromDateTimestamp: null | number;
  toDateTimestamp: null | number;
}

export interface thunkGetStorageStatisticsDataCustomType {
  refresh?: boolean;
  fromDateTimestamp?: null | number;
  toDateTimestamp?: null | number;
}

export const thunkGetStorageStatisticsData = (
  customParams: thunkGetStorageStatisticsDataCustomType
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  const params = {
    ...defaultParamsData,
    ...customParams,
  };
  const {
    refresh,
    fromDateTimestamp,
    toDateTimestamp,
  } = params;

  await dispatch(SetStatsLoader(true));

  const asyncResp: QueryResultInterface<MeasurementFlatItem> = await getStorageStatisticsData(
    refresh,
    fromDateTimestamp,
    toDateTimestamp
  );
  dispatch(loadStatistics(asyncResp));
  await dispatch(SetStatsLoader(false));

  return asyncResp.items.length;
};

const defaultLastParams: thunkGetLastStatisticsDefaultType = {
  take: 10,
  skip: 0,
  refresh: true,
};

export interface thunkGetLastStatisticsCustomType {
  take?: number;
  skip?: number;
  refresh?: boolean;
}

export interface thunkGetLastStatisticsDefaultType {
  take: number;
  skip: number;
  refresh: boolean;
}

export const thunkGetLastStatistics = (
  customParams: thunkGetLastStatisticsCustomType
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  const params = {
    ...defaultLastParams,
    ...customParams,
  };
  const { take, skip, refresh } = params;
  const asyncResp: QueryResultInterface<MeasurementFlatItem> = await getStorageLastStatistics(
    take,
    skip,
    refresh
  );
  return asyncResp.items;
};

export const thunkGetMeasurementsExport = (
  customParams: thunkGetLastStatisticsCustomType
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  const params = {
    ...defaultParams,
    ...customParams,
  };
  const { fromDateTimestamp, toDateTimestamp } = params;
  // console.log("### PARAMS", params);
  const asyncResp: QueryResultInterface<MeasurementExportItem> = await getStorageMeasurementsExport(
    fromDateTimestamp,
    toDateTimestamp
  );
  return asyncResp.items;
};

export const thunkCreateMeasurement = (
  newItem: MeasurementItem
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  // console.log("::: thunkCreateMeasurement", newItem);
  const asyncResp: MeasurementItem = await postStorageMeasurement(newItem);
  // console.log("::: thunkCreateMeasurement-asynResp", asyncResp);
  // console.log("");
  dispatch(addMeasurement(asyncResp));
};

export const thunkDeleteMeasurement = (
  id: string
): ThunkAction<void, AppState, unknown, Action<any>> => async (dispatch) => {
  // console.log("::: thunkDeleteMeasurement", id);
  await delStorageMeasurementFeaturevalue(id);
  // console.log("::: thunkDeleteMeasurement-DONE");
  dispatch(deleteMeasurement(id));
};

function getStoragePagedStatistics(
  take: number,
  skip: number,
  sortBy: string,
  order: string,
  wfilter: string | null,
  pfilter: string | null,
  refresh: boolean,
  fromDateTimestamp: null | number,
  toDateTimestamp: null | number
): Promise<QueryResultInterface<MeasurementFlatItem>> {
  return new Promise<QueryResultInterface<MeasurementFlatItem>>(
    (resolve, reject) => {
      const workbenchFilter = wfilter ? "&wfilter=" + wfilter : "";
      const partFilter = pfilter ? "&pfilter=" + pfilter : "";
      const fromDateFilter = fromDateTimestamp
        ? "&fromDate=" + fromDateTimestamp
        : "";
      const toDateFilter = toDateTimestamp ? "&toDate=" + toDateTimestamp : "";

      const uri = `/measurementsquery?take=${take}&skip=${skip}&sortBy=${sortBy}&order=${order}&refresh=${refresh}${workbenchFilter}${partFilter}${toDateFilter}${fromDateFilter}`;
      
      // console.log("::: getStoragePagedStatistics::uri:", uri);
      webapi.client
        .get<QueryResultInterface<MeasurementFlatItem>>(uri)
        .then((resp) => {
          // console.log("::: getStoragePagedStatistics", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
}

function getStorageStatisticsData(
  refresh: boolean,
  fromDateTimestamp: null | number,
  toDateTimestamp: null | number
): Promise<QueryResultInterface<MeasurementFlatItem>> {
  return new Promise<QueryResultInterface<MeasurementFlatItem>>(
    (resolve, reject) => {
      const fromDateFilter = fromDateTimestamp
        ? "&fromDate=" + fromDateTimestamp
        : "";
      const toDateFilter = toDateTimestamp ? "&toDate=" + toDateTimestamp : "";

      const uri = `/statsdata?&refresh=${refresh}${toDateFilter}${fromDateFilter}`;
      
      // console.log("::: getStoragePagedStatistics::uri:", uri);
      webapi.client
        .get<QueryResultInterface<MeasurementFlatItem>>(uri)
        .then((resp) => {
          // console.log("::: getStoragePagedStatistics", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
}

function getStorageMeasurementsExport(
  fromDateTimestamp: null | number = null,
  toDateTimestamp: null | number = null
): Promise<QueryResultInterface<MeasurementExportItem>> {
  return new Promise<QueryResultInterface<MeasurementExportItem>>(
    (resolve, reject) => {
      const fromDateFilter = fromDateTimestamp
        ? "fromDate=" + fromDateTimestamp
        : "";
      const toDateFilter = toDateTimestamp ? "&toDate=" + toDateTimestamp : "";

      const uri = `/measurementsexport?${fromDateFilter}${toDateFilter}`;
      webapi.client
        .get<QueryResultInterface<MeasurementExportItem>>(uri)
        .then((resp) => {
          // console.log("::: getStorageMeasurementsExport", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
}

function getStorageLastStatistics(
  take: number,
  skip: number,
  refresh: boolean
): Promise<QueryResultInterface<MeasurementFlatItem>> {
  return new Promise<QueryResultInterface<MeasurementFlatItem>>(
    (resolve, reject) => {
      const uri = `/measurementslast?take=${take}&skip=${skip}&refresh=${refresh}`;
      webapi.client
        .get<QueryResultInterface<MeasurementFlatItem>>(uri)
        .then((resp) => {
          // console.log("::: getStorageLastStatistics", resp.data);
          resolve(resp.data);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );
}

function postStorageMeasurement(
  newItem: MeasurementItem
): Promise<MeasurementItem> {
  // console.log("postStorageAttributeMeasurement invoked");
  return new Promise<MeasurementItem>((resolve, reject) => {
    const uri = "/measurement";
    webapi.client
      .post<MeasurementItem>(uri, newItem)
      .then((resp) => {
        // console.log("::: postStorageMeasurement", resp.data);
        resolve(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function delStorageMeasurementFeaturevalue(uuid: string): Promise<void> {
  // console.log('getStorageParts invoked');
  return new Promise<void>((resolve, reject) => {
    const uri = "/featurevalue/" + uuid;
    webapi.client
      .delete(uri)
      .then((resp) => {
        // console.log("::: deleteStorageMeasurement", resp);
        resolve();
      })
      .catch((err) => {
        // console.log("::: delStorageMeasurement Err: ", err);
        reject(err);
      });
  });
}
