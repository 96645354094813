import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { TFunction, Translation } from 'react-i18next';
import Dropdown from '../ui/Dropdown/Dropdown';
import Button from '../ui/Button';
import IconMachines from '../ui/Icons/IconMachines';
import IconParts from '../ui/Icons/IconParts';
import Moment from 'moment';
import { StatisticsFilter } from './helper/types';
import { ExportTypeEnum, ExportTypeItem, exportTypes } from './export/list';
import { AppSettings } from '../../store/system/types';
import { MACHINE, MACHINES, PART, PARTS } from '../../constants/lang/translation-keys';
import QFC from '../../context/qumo-features/qumo-features-context';


type StatisticsHeaderProps = {
    filterOptions: StatisticsFilter
    settings: AppSettings
    handleFilterDropdownApply: any
    handleFilterDatePickerApply: any
    showMultiDeletePopUp: any
    resetFilterDropdown: boolean
    handleExport: any
    statsThis: any
    exportDropdownRef: React.LegacyRef<HTMLButtonElement>
}

export const StatisticsHeader:React.FC<StatisticsHeaderProps> = (props) => {

    const qumoFeatures = React.useContext(QFC.Context)

    const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null);

    const { filterOptions, settings, handleFilterDropdownApply, handleFilterDatePickerApply, showMultiDeletePopUp, resetFilterDropdown, handleExport, statsThis, exportDropdownRef } = props;
    const { startTimestamp, endTimestamp, firstDate, lastDate } = filterOptions;
    const { appDarkMode, advancedMode, allowUserStatsDeletion, qumoActiveFeatures } = settings;
    const isUserAllowedToDelete = advancedMode || allowUserStatsDeletion;

    const startDate = startTimestamp===null ? null : Moment( startTimestamp);
    const endDate = endTimestamp===null ? null : Moment( endTimestamp);

    const startDateRange = Moment(firstDate);
    const endDateRange = Moment(lastDate);

    const [isInnerWidthSmall, setIsInnerWidthSmall] = React.useState(false);
    const isInnerWidthSmallRef = React.useRef(false);
    isInnerWidthSmallRef.current = isInnerWidthSmall;

    React.useEffect(()=>{
        const listenerFunc = ()=>{
            const w = window.innerWidth;
            const smallCond = w < 480;
            if (  smallCond && !isInnerWidthSmallRef.current ) setIsInnerWidthSmall(true);
            if ( !smallCond &&  isInnerWidthSmallRef.current ) setIsInnerWidthSmall(false);
        }
        listenerFunc();
        window.addEventListener('resize', listenerFunc );
    },[])

    const isOutsideRange = (day: any) => {
        return day.unix() < startDateRange.startOf('day').unix() || day.unix() > endDateRange.endOf('day').unix();
    }

    // Ref FilterDropdown
    const [filterDropRef, setFilterDropRef] = React.useState({
        resetActiveChecboxItems:()=>{}
    })
    React.useEffect(()=>{
        if (resetFilterDropdown) {
            filterDropRef.resetActiveChecboxItems()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resetFilterDropdown])

    const getOrientation = () => {
        const orientation = window.matchMedia("(max-width: 768px)").matches ? 'vertical' : 'horizontal'
        return orientation
    }

    const renderFilterOptions = (t: TFunction<string>) => {
        const filterItems = [
            {
                uuid: '1',
                image: <IconMachines light={!appDarkMode} />,
                name: MACHINES,
                nameSingular: MACHINE,
                items: filterOptions.machines.map(m => {
                    return { ...m, type: 'workbench' }
                })
            },
            {
                uuid: '2',
                image: <IconParts light={!appDarkMode} />,
                name: PARTS,
                nameSingular: PART,
                items: filterOptions.parts.map(m => {
                    return { ...m, type: 'part' }
                })
            }
        ]

        return (
            <Dropdown // FILTER
                items={filterItems}
                description={t('SetTheFilters')}
                label='Filter'
                onChangeHandler={() => false}
                styles='d-flex mr-3'
                checkbox={true}
                onCheckboxApply={handleFilterDropdownApply}
                hideSearch={true}
                onRef={(ref: any) => setFilterDropRef(ref)}
            />
        )
    }
    
    const renderDateOptions = (t: TFunction<string>) => {
        return (
            <DateRangePicker    
                isOutsideRange={isOutsideRange}
                startDatePlaceholderText={t('StartDate')}
                endDatePlaceholderText={t('EndDate')}
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => handleFilterDatePickerApply(startDate, endDate)} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                readOnly
                onClose={() => {
                    console.log('close daterange');
                }}
                minimumNights={0}
                orientation={getOrientation()}
                hideKeyboardShortcutsPanel
                noBorder
                showClearDates
            />
        )
    }
    
    const renderDeleteOptions = (t: TFunction<string>) => {
        const stylesAddOn = isInnerWidthSmall ? "mb-1" : "mr-3";
        return (
            <Button // DELETE
                label={t('delete')}
                styles={`btn btn--danger ${stylesAddOn}`}
                clickHandler={() => showMultiDeletePopUp(statsThis)}
                show
            />
        )
    }

    

    
    const renderExportOptions = (t: TFunction<string>) => {
        const dropdownItems: ExportTypeItem[] = [];
        for (const item of exportTypes) {
            
            // JSON, EXCEL, PDF
            if ( item.name !== ExportTypeEnum.QDAS ) dropdownItems.push(item);
            
            // Q-DAS 
            const isQdasActive = item.name === ExportTypeEnum.QDAS &&
                                    qumoActiveFeatures.find(q=>q==='QDAS') &&
                                    qumoFeatures.features.find( f => f.id === 'QDAS')?.active;
            if (isQdasActive) dropdownItems.push(item);
        }
        return (
            <Dropdown // EXPORT
                items={dropdownItems}
                label='export'
                description={t('PleaseChooseTheExportType')}
                onChangeHandler={(e:any)=>handleExport(e,statsThis)}
                styles='dropdown--parts w-auto'
                hideSearch={true}
                reference={exportDropdownRef}
                heightAuto
            />
        )
    }

    const rightHeaderBoxClass = isInnerWidthSmall ? 'flex-column col-2 d-flex align-items-end justify-content-end' : 'col-6 d-flex align-items-center justify-content-end';

    return (
        <div className="app__main-header">
            <div className="container-fluid">
                <Translation>
                    {
                        t => <>
                            <div className="row no-gutters align-items-center justify-content-between">
                                <div className="col-6 d-flex align-items-center">
                                    {renderFilterOptions(t)}
                                    {renderDateOptions(t)}
                                </div>
                                <div className={rightHeaderBoxClass}>
                                        {isUserAllowedToDelete && renderDeleteOptions(t)}
                                        {renderExportOptions(t)}
                                </div>
                            </div>
                        </>
                    }
                </Translation>
            </div>
        </div>
    )
}
