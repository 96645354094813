import { SensorSettingEnum } from "../types/sensor-setting-enum";
const math = require( 'mathjs');

export interface Sensor1PointConfigInterface {
    masterSize: number;
    refrences: {
        rp1: number;
    }
    setting: SensorSettingEnum;
}
export class Sensor1Point {
    private readonly PRECISION = 14;
    private _masterSize: number;
    private _zeroValues: number = 0;
    private _sensorValue: number;
    private _sensorSetting: SensorSettingEnum;
    
    constructor(config: Sensor1PointConfigInterface) {
        this._masterSize = config.masterSize;
        const {rp1 } = config.refrences;
        this._zeroValues = rp1;
        this._sensorValue = 0;
        this._sensorSetting = config.setting;
    }

    public get MasterSize(): number {
        return this._masterSize;
    }

    public setReferenceValues(an: number = 0 ): void {
        this._zeroValues = an;
    }

    public calcReferenced(Ta: number): number {
        this._sensorValue = Ta;
        const term =  math.chain(this._sensorValue).subtract(this._zeroValues).done();
        let retValue = 0;
        if (this._sensorSetting !== SensorSettingEnum.INNER) {
            retValue = math.chain(this._masterSize).add(term).done();
        } else {
            retValue = math.chain(this._masterSize).subtract(term).done();
        }
        const roundedValue = math.round(retValue,this.PRECISION);
        return roundedValue;
    }

    public calc (masterSize: number, setting: SensorSettingEnum, Ta:number, Ra:number): number {
        // console.log(`### debug masterSize:${masterSize}setting:${setting}value:${Ta}reference:${Ra}`);
        this._masterSize = masterSize;
        this._sensorSetting = setting;
        this._zeroValues = Ra;
        return this.calcReferenced(Ta)
    }

    


}