import React from 'react';
import dark from './../../../img/icons-png/icon-stats-darkmode.png'
import light from './../../../img/icons-png/icon-stats-lightmode.png'

interface IconStatisticsProps {
    light: boolean
    items: number
}

const IconStatistics = (props:IconStatisticsProps) => (
    <span className={props.light ? 'dark position-relative' : 'light position-relative'}>
        { props.items > 0 && <span className="badge badge--micro hsmall">{ props.items }</span> }
        <img src={props.light ? light : dark} alt="statistics" />
    </span>
)

export default IconStatistics