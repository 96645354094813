import React from 'react';
import { Translation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { sha256 } from 'js-sha256'
import { onKeyDown } from './PinHelpers';


type Props = {
    closeModal: any
    handleSubmit: any
    resetPin: any
}

const PukForm: React.FC<Props> = (props) => {

    // local state
    //--------------------------------------------------------

        const [puk, setPuk] = React.useState<string[]>(['', '', '', '', '', ''])
        const [pukVerified, setPukVerified] = React.useState<boolean>(false)
        const [validation, setValidation] = React.useState<string>('')
        const {t} = useTranslation();
        const currentPuk = sha256.hex('123499');
        const [prevKey, setPrevKey] = React.useState<string>('')


    // vars
    //--------------------------------------------------------
    
        const inputs = [0, 1, 2, 3, 4, 5]
        const refs = [
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null), 
            React.useRef<HTMLInputElement>(null)
        ]



    // methods
    //--------------------------------------------------------
        
        const handleSubmit = (e:any) => {
            e.preventDefault()
            const newPuk = puk.join('')
            const newPukSha = sha256.hex(newPuk)

            
            if(!currentPuk) {
                console.log('no puk defined');
            } else if (currentPuk === newPukSha) {
                console.log('correct');
                props.resetPin()
                setPukVerified(true)
            } else {
                console.log('false');
                setValidation(t('PukNotCorrect'))
                setPuk(['', '', '', '', '', ''])
                refs[0].current?.focus() 
            }
        }

        const handleCancel = (e:any) => {
            console.log('handle cancel');
            e.preventDefault()
            props.closeModal()
        }

        const onChange = (e:any, index:number) => {
            e.preventDefault()
            if(validation) setValidation('')

            const value = Number(e.target.value)
            if(isNaN(value)) return false
            const newPuk = [...puk]
            newPuk[index] = e.target.value
            setPuk(newPuk)
            if(value && index !== 5){
                refs[index + 1].current?.focus() 
            }            
        }

        const getValue = (index:number) => {
            return puk[index]
        }

        const confirmSuccessfullPuk = () => {
            props.handleSubmit()
        }


    //*******************************************************
    // Render
    //*******************************************************

        return(
            <Translation>
                { t => 
                    <form 
                        onSubmit={ (e) => handleSubmit(e) }
                        autoComplete="off"
                    >
                        {!pukVerified && 
                            <>
                                <div className="row mb-4">
                                    <div className="pin pin--puk">
                                        {
                                            inputs.map(input => {
                                                return (
                                                    <div className="pin__item">
                                                        <input 
                                                            type="password" 
                                                            pattern="[0-9]*"
                                                            inputMode="numeric"
                                                            maxLength={1} 
                                                            className="input-blue" 
                                                            onChange={ (e) => onChange(e, input) } 
                                                            value={ getValue(input) }
                                                            onFocus={e => e.currentTarget.select()}
                                                            onKeyDown={e => onKeyDown(prevKey, setPrevKey, refs, e, input)}
                                                            ref={refs[input]}
                                                            autoFocus = {input === 0}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    { 
                                    validation && 
                                        <div className="col-12 mt-3 h3 text-dark-danger-negative text-center">
                                            {validation}
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <button type="button" className="btn btn--white btn--white-negative w-100" onClick={(e) => {handleCancel(e)}}>{t('Cancel')}</button>
                                    </div>
                                    <div className="col-6">
                                        <input type="submit" className="btn btn--white w-100" value="Ok" />
                                    </div>
                                </div>
                            </>
                        }

                        {
                            pukVerified && 
                            <div>
                                <p>{t('PUKsuccess')}</p>
                                <p>{t('YouWillBeRedirectedAfterPukSuccess')}</p>
                                <div className="text-center">
                                    <button 
                                        onClick={ () => { confirmSuccessfullPuk() }}
                                        className="btn btn--white d-inline-block"
                                    >
                                        {t('Understood')}
                                    </button>
                                </div>
                            </div>    
                        }
                    </form>
                }
            </Translation>
        );
    }

    export default PukForm;
