import { LOAD_DEVICE, RaspState, RaspActionTypes, Device, LOAD_CHANNELS, LOAD_MEASUREMENTS, UPDATE_CONNECTION, UPDATE_DEVICE_PIN } from "./types";

const device: Device = {
  deviceId: '',
  name: '',
  model: '',
  version: '1',
  isConnected: false,
  channelCount: 0
}

const initialState: RaspState = {
    device: device,
    channels: [],
    results: []
};

export function raspReducer(
  state = initialState,
  action: RaspActionTypes
): RaspState {
  switch (action.type) {
    case LOAD_DEVICE: {
      return Object.assign({}, state , {device : action.payload})
    }
    case LOAD_CHANNELS: {
      return Object.assign({}, state , {channels : action.payload})
    }
    case LOAD_MEASUREMENTS: {
      return Object.assign({}, state , {results : action.payload})
    }
    case UPDATE_CONNECTION: {
      const updateObj = Object.assign({}, state.device, {isConnected : action.payload})
      return Object.assign({}, state , {device : updateObj})
      // return {
      //   ...state,
      //   device : { ...device, isConnected : action.payload}
      // };
    }
    case UPDATE_DEVICE_PIN: {
      return {
        ...state,
        device : { ...device, pin : action.payload}
      };
    }

    default:
      return state;
  }
}
