import React from 'react';
import { NavLink, Link, withRouter, RouteComponentProps } from 'react-router-dom';
import IconHome from '../../ui/Icons/IconHome'
import IconSettings from '../../ui/Icons/IconSettings';
import Logo from '../../ui/Logo';
import IconParts from '../../ui/Icons/IconParts';
import IconObjects from '../../ui/Icons/IconObjects';
import IconMachines from '../../ui/Icons/IconMachines';
import IconStatistics from '../../ui/Icons/IconStatistics';
import { updateSession, saveSettings } from '../../../store/system/action';
import { connect } from 'react-redux';
import { SystemState } from '../../../store/system/types';
import { Translation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { AppState } from '../../../store/reducers';
import { RaspState } from '../../../store/rasp/types';
import { sha256 } from 'js-sha256';
import PinForm from '../../ui/Pin/PinForm';
import IconUser from '../../ui/Icons/IconUser';
import { thunkUpdatePin } from '../../../store/rasp/thunks';
import { NamingContextType } from '../../../context/naming/naming-context-types';
import NamingHelper from '../../settings/naming/naming-helper';
import { MACHINES } from '../../../constants/lang/translation-keys';

interface Props {
    updateSession: any
    saveSettings: any
    currentUser: string
    system: SystemState
    rasp: RaspState
    thunkUpdatePin:any
    namingContext: NamingContextType
}

class LoggedInHeader extends React.Component<Props & RouteComponentProps> {

    switchMode(callback:any){
        const newSettings = {
            ...this.props.system.settings,
            advancedMode: !this.props.system.settings.advancedMode
        }
        this.props.saveSettings(newSettings)

        if(callback){
            callback()
        }
    }

    changeUserMode(){

        const isAdvancedMode = this.props.system.settings.advancedMode
        const isDefaultPin = this.props.rasp.device.pin === sha256.hex('0000')
        
        console.log('isDeafultPin: 0 0 0 0 - ', isDefaultPin);
        
        // no PIN auth if defualt PIN or switching from advanced to basic mode
        if(isAdvancedMode || isDefaultPin || !this.props.rasp.device.pin){
            const newSettings = {
                ...this.props.system.settings,
                advancedMode: !this.props.system.settings.advancedMode
            }
            this.props.saveSettings(newSettings)
            
            // redirect user if he doesnt have rights to see the page
            if(
                isAdvancedMode && 
                (this.props.history.location.pathname.includes('parts') ||
                this.props.history.location.pathname.includes('machines') ||
                this.props.history.location.pathname.includes('part-edit'))
            ){
                console.log('redirect');
                setTimeout(() => {
                    this.props.history.push('/')
                }, 500);  
            } 
            return
        }

        // else use PIN auth.
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => this.confirmAlertCustomUI(onClose),
        })
    }

    confirmAlertCustomUI(onClose:any){  
        return (
            <Translation>
                {(t) => (
                    <div className='react-confirm-content'>
                        <h2 className="hsmall strong text-uppercase">{t('Pin Verification')}</h2>
                        <PinForm 
                            currentPin = {this.props.rasp.device.pin}
                            handleCancel = {() => {onClose()}}
                            handleSubmit = {() => {this.switchMode(onClose())}}
                            resetPin = {() => {this.props.thunkUpdatePin()}}
                        />
                    </div>
                )}
            </Translation>
        )
    }

    clickIconAction(){
        // scroll to top
        window.scrollTo({
            top: 0,
        });
        // close sidebar on mobile
        if(this.props.system.showMenu) {
            let updatedSystem: SystemState = {
                ...this.props.system
            };
            updatedSystem.showMenu = false;
            this.props.updateSession(updatedSystem);
        }
    }

    render(){

        const isAdvancedMode = this.props.system.settings.advancedMode;
        const modeLabel = isAdvancedMode ? 'CreationMode' : 'UserMode';
        
        if(!this.props.currentUser) return null;

        const namingObj = this.props.namingContext.naming;
        const machinesNaming = NamingHelper.getNaming(MACHINES, namingObj);

        return (
            <>
            <Translation>
                                
                {
                    t =>
                    
                    <div>
        
                        <ul className="nav">
        
                            <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                <Link to="/">
                                    <span className="nav__icon logo">
                                        <Logo light={true}/>
                                        <Logo light={false}/>
                                    </span>
                                </Link>
                            </li>
        
                            <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                <NavLink 
                                    to="/"
                                    isActive={(match, location) => {
                                        if (location.pathname === '/' || location.pathname.includes('/part/') ) {
                                            return true;
                                        } else {
                                            return false
                                        }
                                    }}
                                    >
                                    <span className="nav__icon">
                                        <IconHome light={false} />
                                        <IconHome light={true} />
                                    </span>
                                    <span className="nav__label">{t('Measure')}</span>
                                </NavLink>
                            </li>

                            <li className="nav__item nav__item--statistics" onClick={()=>this.clickIconAction()}>
                                <NavLink exact to="/statistics">
                                    <span className="nav__icon">
                                        <IconStatistics light={false} items = {this.props.system.measurementCounter} />
                                        <IconStatistics light={true} items = {this.props.system.measurementCounter} />
                                    </span>
                                    <span className="nav__label">{t('Statistics')}</span>
                                </NavLink>
                            </li>

                            { this.props.system.settings.advancedMode && 
                                <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                    <NavLink exact to="/parts">
                                        <span className="nav__icon">
                                            <IconParts light={false} />
                                            <IconParts light={true} />
                                        </span>
                                        <span className="nav__label">{t('Parts')}</span>
                                    </NavLink>
                                </li>
                            }

                            { this.props.system.settings.advancedMode && 
                                <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                    <NavLink 
                                        exact 
                                        to="/part-edit"
                                        isActive={(match, location) => location.pathname.includes('/part-edit')}
                                    >
                                        <span className="nav__icon">
                                            <IconObjects light={false} />
                                            <IconObjects light={true} />
                                        </span>
                                        <span className="nav__label">{t('Features')}</span>
                                    </NavLink>
                                </li>
                            }
        
                            { this.props.system.settings.advancedMode && 
                                <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                    <NavLink exact to="/machines">
                                        <span className="nav__icon">
                                            <IconMachines light={false} />
                                            <IconMachines light={true} />
                                        </span>
                                        <span className="nav__label">{machinesNaming}</span>
                                    </NavLink>
                                </li>
                            }
    
                            <li className="nav__item" onClick={()=>this.clickIconAction()}>
                                <NavLink exact to="/settings">
                                    <span className="nav__icon">
                                        <IconSettings light={true} />
                                        <IconSettings light={false} />
                                    </span>
                                    <span className="nav__label">{t('Settings')}</span>
                                </NavLink>
                            </li>
                        </ul>
        
                        <ul className="nav">
                            <li className="nav__item nav__item--empty" onClick={ () => {this.changeUserMode()} }>
                                <span className="nav__icon">
                                    <IconUser light={!this.props.system.settings.appDarkMode} />
                                </span>
                                <span className="nav__label">{ t(modeLabel) }</span>
                            </li>
        
                        </ul>
        
                    </div>
                }
            </Translation>
            </>
           
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    system: state.system,
    rasp: state.rasp
});

const dispatchToProps = {
    updateSession, 
    saveSettings,
    thunkUpdatePin
};

export default connect(
    mapStateToProps, 
    dispatchToProps
)(withRouter(LoggedInHeader));