
import React from 'react';

interface IconCameraProps {
    fill: string
}


const IconCamera = (props:IconCameraProps) => (
    <svg id="Layer_1" version="1.1" viewBox="0 0 64 64">
	    <g fill={props.fill} id="Icon-Camera" transform="translate(178.000000, 530.000000)"><path d="M-126.5-478.5h-39c-2.3,0-4.2-1.9-4.2-4.2v-25.1c0-2.3,1.9-4.2,4.2-4.2h8.4     c0.2,0,0.7-0.3,0.8-0.5l1.5-3.1c0.6-1.1,2-2,3.3-2h11.1c1.3,0,2.7,0.9,3.3,2l1.5,3.1c0.1,0.2,0.5,0.5,0.8,0.5h8.4     c2.3,0,4.2,1.9,4.2,4.2v25.1C-122.3-480.4-124.2-478.5-126.5-478.5L-126.5-478.5z M-165.5-509.1c-0.8,0-1.4,0.6-1.4,1.4v25.1     c0,0.8,0.6,1.4,1.4,1.4h39c0.8,0,1.4-0.6,1.4-1.4v-25.1c0-0.8-0.6-1.4-1.4-1.4h-8.4c-1.3,0-2.7-0.9-3.3-2l-1.5-3.1     c-0.1-0.2-0.5-0.5-0.8-0.5h-11.1c-0.2,0-0.7,0.3-0.8,0.5l-1.5,3.1c-0.6,1.1-2,2-3.3,2H-165.5L-165.5-509.1z" id="Fill-21_1_"/><path d="M-146-484.1c-6.9,0-12.5-5.6-12.5-12.5s5.6-12.5,12.5-12.5s12.5,5.6,12.5,12.5     S-139.1-484.1-146-484.1L-146-484.1z M-146-506.4c-5.4,0-9.8,4.4-9.8,9.8s4.4,9.8,9.8,9.8s9.8-4.4,9.8-9.8     S-140.6-506.4-146-506.4L-146-506.4z" id="Fill-22_1_"/><path d="M-130.7-506.4c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4     S-130.7-507.1-130.7-506.4" id="Fill-23_1_"/><polygon className="st0" id="Fill-24_1_" points="-164.1,-514.7 -158.5,-514.7 -158.5,-513.3 -164.1,-513.3    "/><path className="st0" d="M-146-502.2v-1.4c-3.8,0-7,3.1-7,7h1.4C-151.6-499.7-149.1-502.2-146-502.2"/></g>
    </svg>
)

export default IconCamera