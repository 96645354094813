import { LOAD_DEVICE, LOAD_MEASUREMENTS, Message, SEND_MESSAGE, DELETE_MESSAGE, Device, ChannelValue, UPDATE_CONNECTION, UPDATE_DEVICE_PIN } from "./types";

export function updateDevice(newDevice: Device) {
  return {
    type: LOAD_DEVICE,
    payload: newDevice
  };
}

export function updateDeviceConnection(newState: boolean) {
  return {
    type: UPDATE_CONNECTION,
    payload: newState
  };
}

export function updateDevicePin(newState: string) {
  return {
    type: UPDATE_DEVICE_PIN,
    payload: newState
  };
}

export function updateMeasurements(values: ChannelValue[]) {
  return {
    type: LOAD_MEASUREMENTS,
    payload: values
  };
}
export function sendMessage(newMessage: Message) {
  return {
    type: SEND_MESSAGE,
    payload: newMessage
  };
}

export function deleteMessage(timestamp: number) {
  return {
    type: DELETE_MESSAGE,
    meta: {
      timestamp
    }
  };
}