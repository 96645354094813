export interface AppConfigInterface {
  onboarding: {
    installTime: number
  };
  connection: {
    hosts: string[];
    updateServiceUrl:string;
    ports: {
      webapi: number;
      prtapi: number
    };
    delay: number;
    polling: {
      timeout: number;
      pingTimeout: number
    };
    showIsSearchingDuration: number;
  },
  connections: AppConfigConnectionInterface[];
  measurements: AppConfigMeasurementsInterface;
  features: {
    fixedDecimal:number
  },
  characterLimits: {
    machineNameBtn: number,
    machineNestNameBtn:number,
    partName:number,
    deleteMask:number
  },
  separators: {
    nameSeparator: string
  },
  time: {
    date_format: string
    hour_format_de: string
    hour_format_en: string
    fullFormat: any
    hourFormat:any
  },
  certificate: {
    downloadUrl: string,
    checkUrl: string,
    warnTrigger: number,
    alertTrigger: number
  }
  referencing: {
    sliderDelay: number
  }
}



export interface AppConfigMeasurementsInterface {
  throttleTimeInMs: number;
  timeSyncDiffInMs: number;
}
export interface AppConfigConnectionInterface {
  name: string;
  host: string;
  service: string;
  database: string;
  realtime: string;
}

export const AppConfig: AppConfigInterface = {
  onboarding: {
    installTime: 12000
  },
  connection: {
    hosts: ["192.168.230.1","192.168.231.1","192.168.232.1"],
    updateServiceUrl: "",
    ports: {
      webapi: 5001,
      prtapi: 5002
    },
    delay: 2000,
    polling: {
      timeout: 3000,
      pingTimeout: 1000
    },
    showIsSearchingDuration: 20000
  },
  connections: [
    {
      name: "rasp",
      host: "192.168.230.1",
      service: "https://192.168.230.1",
      database: "https://192.168.230.1:5001",
      realtime: "https://192.168.230.1:5002",
    },
    {
      name: "demo",
      host: "smart.1sicht.de",
      service: "https://smart.1sicht.de",
      database: "https://sgsweb.1sicht.de",
      realtime: "https://sgsapi.1sicht.de",
    },
    {
      name: "develop",
      host: "localhost:5000",
      service: "http://localhost:5100",
      database: "http://localhost:5101",
      realtime: "http://localhost:5102",
    },
  ],
  measurements: {
    throttleTimeInMs: 50,
    timeSyncDiffInMs: 5000,
  },
  features: {
    fixedDecimal:3
  },
  characterLimits: {
    machineNameBtn: 20,
    machineNestNameBtn:20,
    partName:30,
    deleteMask: 20
  },
  separators: {
    nameSeparator: '|'
  },
  time:{
    date_format : 'D MMM',
    hour_format_de : 'HH:mm:ss',
    hour_format_en : 'h:mm:ss a',
    hourFormat(lang:'en'|'de'){
      return lang === 'en' ? this.hour_format_en : this.hour_format_de
    },
    fullFormat(lang:'en'|'de'){
      return lang === 'en' ? this.date_format + ' ' + this.hour_format_en : this.date_format + ' ' + this.hour_format_de
    }
  },
  referencing: {
    sliderDelay: 2000
  },
  certificate: {
    downloadUrl: 'http://download.qu-mo-app.de/promess_ca.crt',
    checkUrl: 'https://certtest.qu-mo-app.de/',
    warnTrigger: 60, // days
    alertTrigger: 14
  }
};