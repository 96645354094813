import React from 'react';
import { useTranslation } from 'react-i18next'
import { AppConfig } from '../../constants/app-config';

interface DeleteMaskProps {
    delete: any,
    onClose:any,
    nameOfDeletedItem: any 
}

const DeleteMask: React.FC<DeleteMaskProps> = (props) => {
    const {t} = useTranslation();
    let name = props.nameOfDeletedItem
    const afterfix = name.length > AppConfig.characterLimits.deleteMask ? '...' : ''
    name = name ? name.slice(0, AppConfig.characterLimits.deleteMask) + afterfix : ''
    
    return (
        <div className='react-confirm-content'>
            <h3 className="h2">{t('AreYouSure')}</h3>
            {/* <p className="h4 mb-4">{t('YouAreAboutToDelete', {name: props.nameOfDeletedItem})}</p> */}
            <p className="h4 mb-4">{t('YouAreAboutToDeletePrefix')} <strong>{name}</strong> {t('YouAreAboutToDeletePostfix')}</p>
            <div className="row">
                <div className="col-6">
                    <button className="btn btn--white w-100" onClick={props.onClose}>{t('Cancel')}</button>
                </div>
                <div className="col-6">
                    <button className="btn btn--danger w-100" onClick={() => {
                        props.delete()
                        props.onClose()
                    }}>{t('Yes')}</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteMask