import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import ConnectionHelper from '../../api/connection-helper';
import LoadingOverlay from '../ui/LoadingOverlay';
import { ServerRespMessages, ServerResponse } from './constants';
import { showUpdateFinished } from './deviceStatusFunctions';
import { DeviceStatusPopUp } from './DeviceStatusPopUp';

interface DeviceStatusProps {
    urlToQumo: string
    isPopUpEnabled: boolean
}

const DeviceStatus: React.FC<DeviceStatusProps> = (props) => {
    const { urlToQumo, isPopUpEnabled } = props;
    const {t} = useTranslation();
    const [serverResponseArrived, setServerResponseArrived] = React.useState<null | ServerResponse>(null);
    const [showOverlay, setShowOverlay] = React.useState(false);
    const [uploadProgress, setUploadProgress] = React.useState<null|number>(null);
    const [uploadProgressInfo, setUploadProgressInfo] = React.useState<null|{ text1: string, text2: string, text3: string }>(null);
    const css = urlToQumo ? 'status--active ' : '';

    const getFinalMessage = (serverRespObj: ServerResponse) => {
        if (serverRespObj.status) {
            return ServerRespMessages.SUCCESS;
        } else {
            switch (serverRespObj.message) {
                case ServerRespMessages.UPDATE_FAILED:
                    return ServerRespMessages.UPDATE_FAILED;
                case ServerRespMessages.NO_FILE_UPLOADED:
                    return ServerRespMessages.NO_FILE_UPLOADED;
                case ServerRespMessages.SERVICE_ERROR:
                    return ServerRespMessages.SERVICE_ERROR;
                case ServerRespMessages.UNZIP_ERROR:
                    return ServerRespMessages.UNZIP_ERROR;
                case ServerRespMessages.FILE_INVALID:
                    return ServerRespMessages.FILE_INVALID;
                default:
                    return ServerRespMessages.UNKNOWN_ERROR;
            }
        }
    }

    const uploadProgressInfoText = { text1: 'UpdateRunning', text2: 'UpdateProcessDuration', text3: 'UpdateDoNotRemovePower' };
    // prevent missing dependency warning
    const uploadProgressInfoTextRef = React.useRef(uploadProgressInfoText);
    uploadProgressInfoTextRef.current = uploadProgressInfoText;

    React.useEffect(() => {
      if ( uploadProgress !== null && uploadProgress >= 100 ) {
          setUploadProgressInfo(uploadProgressInfoTextRef.current);
          setUploadProgress(null);
        }
    }, [uploadProgress])

    // prevent missing dependency warning
    const isPopUpEnabledRef = React.useRef(isPopUpEnabled);
    isPopUpEnabledRef.current = isPopUpEnabled;
    
    React.useEffect(() => {
        if (serverResponseArrived) {
            setShowOverlay(false);
            showUpdateFinished(isPopUpEnabledRef.current, getFinalMessage(serverResponseArrived));
            setUploadProgressInfo(null);
        }
    }, [serverResponseArrived])
    

    const handleClick = (e:any) => {
        e.preventDefault();
        if (!isPopUpEnabled) {
            return
        }
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                const handleAction = (actionType: string, e?: React.ChangeEvent<HTMLInputElement>) => {
                    handleActionType(actionType, onClose, e)
                }
                return <DeviceStatusPopUp handleAction={(actionType, e)=>handleAction(actionType, e)} onClose={onClose} urlToQumo={urlToQumo} />;
            }
        })
    }

    const handleActionType = (actionType: string, onCloseFunc:()=>void, e?: React.ChangeEvent<HTMLInputElement>) => {
        switch (actionType) {
            case 'time-sync':
                ConnectionHelper.setRaspDatetime(urlToQumo);
                onCloseFunc();
                break;
            case 'update':
                if ( e ) {
                    setShowOverlay(true);
                    ConnectionHelper.uploadUpdateFile(e, urlToQumo, setUploadProgress, setServerResponseArrived);
                }
                onCloseFunc();
                break;
            default:
                break;
        }
    }

    return (    
        <span  
            className={css + 'hsmall status strong ml-2'}
            onClick={ (e) => {handleClick(e)} }
        >
            {urlToQumo ? t('ConnectedToHardware') : t('DisconnectedFromHardware')}
            <LoadingOverlay show={showOverlay} uploadProgress={uploadProgress} text1={uploadProgressInfo?.text1} text2={uploadProgressInfo?.text2} text3={uploadProgressInfo?.text3} />
        </span>
    )
}

export default DeviceStatus
