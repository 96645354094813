import { MeasurementItem, LOAD_STATISTICS, ADD_MEASUREMENT, DELETE_MEASUREMENT, QueryResultInterface, MeasurementFlatItem } from "./types";

const loadStatistics = (measurements: QueryResultInterface<MeasurementFlatItem>) => {
  return {
    type: LOAD_STATISTICS,
    payload: measurements
  };
}

const addMeasurement = (item: MeasurementItem) => {
  return {
    type: ADD_MEASUREMENT,
    payload: item
  }
}
const deleteMeasurement = (id: string) => {
  return {
    type: DELETE_MEASUREMENT,
    payload: id
  }
}

export { loadStatistics, addMeasurement, deleteMeasurement } 