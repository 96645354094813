import React from 'react'
import { Translation, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux"
import RadioToggle from '../ui/RadioToggle'
import { AppState } from '../../store/reducers'
import {UsedUnitsEnum, UsedDelimiterEnum} from '../../store/system/types'
import Moment from 'moment'
import 'moment/locale/de'  // without this line de moment translations didn't work
import RaspInvertForm from '../ui/Rasp/RaspInvertForm'
import RadioButtonOverlay from '../ui/RadioButtonOverlay'
import Info from '../ui/Info'
import PinEditForm from '../ui/Pin/PinEditForm'
import RaspFactorySettingsForm from '../ui/Rasp/RaspFactorySettingsForm'
import Naming from './naming/Naming';

import Features from './features/Features';


const Settings: React.FC = () => {

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    
    //get vars from the redux store
    //--------------------------------------------------------

        const { system } = useSelector((state: AppState) => state)
        const { rasp } = useSelector((state: AppState) => state)
        const isLanguageEn = system.settings.language === 'en' ? true: false;
        const isUnitMillimeter = system.settings.usedUnits === UsedUnitsEnum.MM;
        const isDelimiterPoint = system.settings.usedDelimiter === UsedDelimiterEnum.DecimalPoint;
        


    // methods
    //--------------------------------------------------------
        
        const onChangeHandler = (e: any) => {
            const isChecked = e.target.checked
            const value = e.target.value
            console.log( ':::: toggle switch: ', isChecked, value);
            switch (value) {
                case 'dark_mode':
                    dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, appDarkMode: isChecked}} )
                    break;
                case 'allow_stats_deletion':
                    dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, allowUserStatsDeletion: isChecked}} )
                    break;
                case 'inch':
                    dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, usedUnits: isUnitMillimeter ? UsedUnitsEnum.INCH: UsedUnitsEnum.MM}} )
                    break;
                case 'decimal':
                    dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, usedDelimiter: isDelimiterPoint ? UsedDelimiterEnum.DecimalKomma: UsedDelimiterEnum.DecimalPoint}} )
                    break;
                case 'time':
                    dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, timeAutoSync: isChecked}} )
                    break;
                default:
                    break;
            }
        }


    const changeLanguage = (e:any) => {
        const lang = e.target.checked ? 'en' : 'de'
        dispatch({type: 'SAVE_SETTINGS', payload:{...system.settings, language: lang}} )
        i18n.changeLanguage(lang);
        Moment.locale(lang);
    }

    const renderAppSettings = () => {

        const {appDarkMode} = {appDarkMode: system.settings.appDarkMode}
        
        return(
            <>
            <Translation>
                {
                    t =>
                    <div>
                        <div className="h4 font-regular mb-5">
                            <h3 className="hsmall text-third text-uppercase mb-2">{t('Measurement')}</h3>

                            {system.settings.advancedMode && 
                                <RadioToggle 
                                    labelMain={t('Unit')}
                                    labelSecondary={isUnitMillimeter ? t('Millimeter'): t('Inch')}
                                    value="inch"
                                    onChangeHandler={ onChangeHandler }
                                    checked = { isUnitMillimeter }
                                    separator = { true }
                                    pillShow = { true }
                                    pillOn = {t('Millimeter')}
                                    pillOff = {t('Inch')}
                                    
                                />
                            }

                            <RadioToggle 
                                labelMain={t('Localization')}
                                labelSecondary={isDelimiterPoint ? t('DecimalPoint'): t('DecimalComma')}
                                value="decimal"
                                onChangeHandler={ onChangeHandler }
                                checked = { isDelimiterPoint }
                                separator = { true }
                                pillShow = { true }
                                pillOn = {t('DecimalPoint')}
                                pillOff = {t('DecimalComma')}
                            />

                            {system.settings.advancedMode && 
                                <RadioButtonOverlay 
                                    labelMain={t('Inverting')}
                                    labelSecondary={t('InvertingSensorValues')}
                                    separator={ true }
                                    content={ <RaspInvertForm /> }
                                />
                            }

                            {system.settings.advancedMode && 
                                <RadioButtonOverlay 
                                    labelMain={t('Qu-Mo Features')}
                                    labelSecondary={t('Qu-Mo Feature') + ' ' + t('Settings')}
                                    separator={ true }
                                    content={ <Features /> }
                                />
                            }

                            {system.settings.advancedMode && 
                                <RadioButtonOverlay 
                                    labelMain={t('CategoryNaming')}
                                    labelSecondary={t('CategoryNaming') + ' ' + t('Settings')}
                                    separator={ true }
                                    content={ <Naming /> }
                                />
                            }

                        </div>
                        <div className="h4 font-regular mb-5">

                            <h3 className="hsmall text-third text-uppercase mb-2">{t('App')}</h3>
                            {system.settings.advancedMode && 
                                <RadioButtonOverlay 
                                    labelMain={t('Security')}
                                    labelSecondary={t('SecurityPinDescription')}
                                    separator={ true }
                                    verification = {true} 
                                    content={ <PinEditForm /> }
                                />
                            }

                            <RadioToggle 
                                labelMain={t('Design')}
                                labelSecondary={appDarkMode ? t('DarkModeDescription'): t('LightModeDescription')}
                                value="dark_mode"
                                onChangeHandler={ onChangeHandler }
                                checked = { appDarkMode }
                                separator = { true }
                                pillShow = { true }
                                pillOn = "Dark"
                                pillOff = "Light"
                            />

                            <RadioToggle 
                                labelMain= {t('Language')}
                                labelSecondary={t('SelectedLanguage')}
                                value="en"
                                onChangeHandler={ changeLanguage }
                                checked = { isLanguageEn }
                                separator = { true }
                                pillShow = { true }
                                pillOn = "EN"
                                pillOff = "DE"
                            />

                            {system.settings.advancedMode && 
                                <RadioToggle 
                                    labelMain={t('StatsDeletion')}
                                    labelSecondary={t('AllowUserStatsDeletion')}
                                    value="allow_stats_deletion"
                                    onChangeHandler={ onChangeHandler }
                                    checked = { system.settings.allowUserStatsDeletion }
                                    separator = { true }
                                    pillShow = { true }
                                    pillOn = {t('On')}
                                    pillOff = {t('Off')}
                                    
                                />
                            }

                            <Info 
                                labelMain= { t('Version')}
                                labelSecondary= {t('VersionDescription') + ' - ' + system.appName}
                                value= { rasp.device.version }
                                separator = { true }
                                easterEgg = {<RaspFactorySettingsForm />}
                            />
                        </div>
                    </div>
                }
            </Translation>

            </>
        );
    }


    //*******************************************************
    // Render
    //*******************************************************

        return(
            <>
            <Translation>{
                t=> 
                <div className={'app__main'}>
                    <div className="container-fluid">
                        <div id="preferences" className="overflow-hidden preferences">
                            {renderAppSettings()}
                        </div>
                    </div>
                </div>
                }
            </Translation>
            
            </>
        );
    }

    export default Settings;
