import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.scss'
import { useSelector, useDispatch } from "react-redux";
import { AppState } from '../../../store/reducers';
import { getPart } from '../../../store/parts/selectors';
import { PartItem } from '../../../store/parts/types';
import { AttributeItem } from '../../../store/attributes/types';
import Taster from '../Taster/Taster';
import {thunkUpdateAttribute} from '../../../store/attributes/thunks'
import { Translation } from 'react-i18next';
import { Subscription } from 'rxjs';
import { useMessages } from '../../../context';
import { ChannelItem, MeasureObject } from '../../../store/channels/types';
import Button from '../Button';
import moment from 'moment'
import { AppConfig } from '../../../constants/app-config';
import i18n from '../../../constants/i18n';

type Props = {
    show: boolean,
    items: AttributeItem[]
}
 
const Slider: React.FC<Props> = (props) => {

    //*******************************************************
    // Variables
    //*******************************************************

        //get vars from the redux store
        
        const items = useSelector((state: AppState) => state.attributes.items.filter(attribute => !attribute.isDeleted))
        // const items = props.items
        const initIndex = useSelector((state: AppState) => state.system.slider.initialSlideIndex)
        const getPartById = useSelector((state: AppState) => getPart(state))
        const dispatch = useDispatch()
        const swiper = React.useRef<any>(null)
        const compMounted = React.useRef(true)
        const [name, setName] = useState('')
        const [part, setPart] = React.useState<PartItem | null>(null)
        const [attribute, setAttribute] = React.useState<AttributeItem | null>(null)
        const [measure, setMeasure] = React.useState<boolean>(false)
        const [realChannels, setChannels] = React.useState<any>({
            timestamp: 0,
            values: [0, 0, 0, 0, 0, 0, 0, 0]
        })
        const channels = useSelector((state: AppState) => state.channels.items)
        const [subChannels, setSubChannels] = React.useState<Subscription | null>(null)
        // const store = useStore();
        
        const observable = useMessages().onChannels();

    //*******************************************************
    // lifecycle
    //*******************************************************

        // run only once after swiper state has changed
        //--------------------------------------------------------

            useEffect(() => {
                if(swiper.current && swiper.current.swiper){
                    // set the part
                    const partId = getCurrentAttribute(swiper.current.swiper).partId
                    const part = getPartById(partId)
                    setPart(part)

                    //attribute
                    setAttribute(getCurrentAttribute(swiper.current.swiper))
                    updateSlideName()
                    handleDataFlow(getCurrentAttribute(swiper.current.swiper))
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [swiper.current]);


        // run after every component update
        //--------------------------------------------------------

            useEffect(() => {
                if(swiper.current && swiper.current.swiper){
                    
                    setAttribute(getCurrentAttribute(swiper.current.swiper))

                    swiper.current.swiper.on("slideChangeTransitionEnd", () => {
                        setAttribute(getCurrentAttribute(swiper.current.swiper))
                        updateSlideName()
                        handleDataFlow(getCurrentAttribute(swiper.current.swiper))
                    });
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
                //////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////
                // we should refactor this this gets fired with every realtime api update :/
                //////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////
                // eslint-disable-next-line react-hooks/exhaustive-deps
            },[swiper.current, items]); // run effect only if an attribute changes


            useEffect(()=>{
                return ()=>{compMounted.current = false};
            },[])

    //*******************************************************
    // Methods
    //*******************************************************

        const handleReference = () => {
            // get the current measurement from the observable
            //--------------------------------------------------------
                const measurement = realChannels
            // build the updated sensors
            //--------------------------------------------------------
                let updatedSensors:any = []
                attribute?.sensors.map(sensor => {
                    let index = sensor.reference.channelId;
                    if(index !== null) {
                        index = Number(index);
                        const updatedSensor = {
                            ...sensor,
                            reference: {
                                ...sensor.reference,
                                referenceValue: index === 0 ? 0 : measurement.values[index - 1], //stop limit (Anschlag) fallback
                                // new 14102020
                                isInvers: index === 0 ? 0 : channels[index - 1].invers,
                                referenceFactor: index === 0 ? 1 : channels[index - 1].inversFactor
                            }
                        }
                        updatedSensors.push(updatedSensor)
                    } else {
                        updatedSensors.push(sensor)
                    }
                    return null;
                })

            // build the updated attribute
            //--------------------------------------------------------
                let updatedAttribute = Object.assign({}, attribute)
                updatedAttribute.reference = {
                    timestamp: measurement.timeStamp,
                    isReferenced: true
                }
                updatedAttribute.sensors = updatedSensors
                

            // update the attribute
            //--------------------------------------------------------
                dispatch(thunkUpdateAttribute(updatedAttribute))


            // set the measurement state to false to update the visuals
            //--------------------------------------------------------
                setMeasure(false)

                if(AppConfig.referencing.sliderDelay !== 0) {
                    setTimeout(() => {
                        compMounted.current && goNext()
                    }, AppConfig.referencing.sliderDelay);
                }
        }
    
        const goNext = () => {
            if(swiper.current && swiper.current.swiper) {
                swiper.current.swiper.slideNext();
            }
        };
    
        const goPrev = () => {
            if(swiper.current && swiper.current.swiper) {
                swiper.current.swiper.slidePrev();
            }
        };

        const closeSlider = (e:any) => {
            e.preventDefault()
            subChannels?.unsubscribe()
            dispatch({ type: 'TOGGLE_SLIDER' })
        }

        const getCurrentAttribute = (swiper:any) => {
            const index = swiper.activeIndex
            return items[index]
        }

        const updateSlideName = () => {
            const name = getCurrentAttribute(swiper.current.swiper).name
            setName(name)
        }

        const isSlideVisible = (index:number) => {
            if(swiper.current && swiper.current.swiper) return swiper.current.swiper.activeIndex === index
            return false
        }

        const rerunReference = () => {
            let updatedAttribute = Object.assign({}, attribute)
            updatedAttribute.measurement = {
                attributeValue: 0,
                timestamp: 0,
                measurementChart: ''
            }
            updatedAttribute.reference = {
                timestamp: 0,
                isReferenced: false
            }
            dispatch(thunkUpdateAttribute(updatedAttribute))
            setMeasure(true)

            console.log( 'new items: ', items);
            
        }

        const handleDataFlow = (currentAttribute:any) => {
            const initMeasure = currentAttribute.reference.timestamp ? false : true
            setMeasure(initMeasure)
        }
    
    //*******************************************************
    // Render
    //*******************************************************

        const transInvers = (ch: number[], channels: ChannelItem[]) => {
            
            return ch.map( (c, idx) => {
                const fac = channels[idx + 1].inversFactor;
                const trans = channels[idx + 1].invers ? c * -1 * fac  : c * fac
                return trans
            })
        }

        const params = {
            spaceBetween: 100,
            initialSlide: initIndex,
            slidesPerView: 1.5,
            centeredSlides: true,
            keyboard: true,
            on: {
                'init': () => {
                    const channelSubscription = observable.subscribe((msg: MeasureObject) => {
                        setChannels({ timeStamp: msg.timeStamp, values: transInvers(msg.values, channels)})
                    });
                    setSubChannels(channelSubscription)
                }
            }
        }        

        const referenceTimestamp = attribute?.reference.timestamp ? new Date(attribute.reference.timestamp) : null
        const referenceDate = referenceTimestamp ? moment(referenceTimestamp).format(AppConfig.time.fullFormat(i18n.language)) : null
        const hasReferenceValue = attribute?.reference.timestamp ? true : false        

        if(!props.show){return (null)}

        return (
            <>
            <Translation>{
                    t => 
            <div className="referencer">
                <div className="referencer__header mb-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="d-none d-md-flex col-md-3 align-items-center align-self-start">
                                <img className="d-none d-md-block flag icon icon--round icon--for-btn mr-2" src={part?.imageUrl} alt=""/>
                                <span className="btn btn--info btn--seamless">{ part?.designNumber } / { part?.partNumber } </span>
                            </div>

                            <div className="col-12 col-md-6 text-center">
                                <div className="mb-3 d-flex justify-content-center">
                                    <button className="referencer__prev btn btn--icon btn--icon-prev btn--action mr-2" onClick={goPrev}></button>
                                    <div className="referencer__attribute-label btn btn--info d-inline-block pl-5 pr-5 mr-2">{name}</div>
                                    <button className="referencer__next btn btn--icon btn--icon-next btn--action" onClick={goNext}></button>
                                </div>
                                <h3 className="h4 mb-1">
                                    { 
                                        hasReferenceValue && referenceDate 
                                    }
                                    {      
                                        !hasReferenceValue && t('MissingReference') 
                                    }
                                </h3>
                                { 
                                    <div className="text-uppercase">
                                        {
                                            hasReferenceValue && t('LastReferenced')
                                        }
                                        {
                                            !hasReferenceValue && t('MissingReference') 
                                        }
                                    </div>
                                }
                            </div>

                            <div className="d-none d-md-block col-3 text-right">
                                <button 
                                    className="btn btn--icon btn--icon-check btn--active" 
                                    type="submit"
                                    onClick={(e) => closeSlider(e)}
                                ></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="referencer__slider mb-4">
                    <Swiper ref={swiper} { ...params}>
                        {
                            items.map((item, index) => {
                                return(
                                    <div key={index}>
                                        <div className="referencer__slide">
                                            {
                                                attribute !== null && isSlideVisible(index) &&
                                                <Taster 
                                                    attributeId = { attribute?.uuid }
                                                    showInputs = { false }
                                                    attribute = { attribute }
                                                    viewOnly = { true }
                                                    measure = { measure }
                                                    channelValues = { realChannels.values }
                                                />
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Swiper>
                </div>
                <div className="referencer__cta">
                    <Button 
                        styles = 'btn d-inline-block'
                        show = { measure }
                        clickHandler = { handleReference }
                        label = {t('Referencing')}
                    />

                    <Button 
                        styles = 'btn btn--seamless'
                        show = { !measure }
                        clickHandler = { rerunReference }
                        label = { referenceDate }
                        icon = 'btn--reload'
                    />

                    <Button 
                        styles = 'btn btn--icon btn--icon-check btn--active d-md-none ml-3'
                        clickHandler = { closeSlider }
                        show = { true }
                    />

                    {/* <button 
                        className="btn btn--icon btn--icon-check btn--active" 
                        type="submit"
                        onClick={(e) => closeSlider(e)}
                    ></button> */}
                    
                </div>
            </div>
            }
            </Translation>
            </>
        );
};
 
export default Slider;