import React from 'react';
import { connect } from 'react-redux';
import { TFunction, Translation, withTranslation } from 'react-i18next';
import Moment from 'moment';
import { initalFilterOptions, StatisticsFilter, SelectedAttributes } from './helper/types';

// helper
import SFH from './helper/sort-filter-helper';
import DTH from './helper/date-time-helper';
import DelH from './helper/delete-helper';
import EH from './export/export-helper';
import { ExportTypeEnum } from './export/list';

// components
import { StatisticsHeader } from './StatisticsHeader';
import { StatisticsBody } from './StatisticsBody';
import StatisticsEmptyView from './StatisticsEmptyView';
import { DeletePopUp } from './DeletePopUp';
import { DeletePopUpSingle } from './DeletePopUpSingle';
import { confirmAlert } from 'react-confirm-alert';

// redux
import { SystemState } from '../../store/system/types';
import { AppState } from '../../store/reducers';
import { thunkDeleteMeasurement, thunkGetMeasurementsExport, thunkGetPagedStatistics, thunkGetStorageStatisticsData } from '../../store/statistics/thunks';
import { MeasurementChartItem, MeasurementFlatItem, QueryResultInterface } from '../../store/statistics/types';
import { measurementCounterReset } from '../../store/system/action';
import { Device } from '../../store/rasp/types';
import { PopUpIsDownloaded } from '../connect/onboarding';
import { webapi } from '../../api/web-api';
import { PartItem } from '../../store/parts/types';
import { MachineItem } from '../../store/machines/types';

import NamingContext from '../../context/naming/naming-context';
import { ChannelItem } from '../../store/channels/types';


export type StatisticsLocalProps = {
    device: Device
    channels: ChannelItem[]
    measurements: QueryResultInterface<MeasurementFlatItem>
    parts: PartItem[]
    workbenches: MachineItem[]
    system: SystemState
    thunkGetPagedStatistics: any
    thunkGetStorageStatisticsData: any
    thunkDeleteMeasurement: any
    thunkGetMeasurementsExport: any
    measurementCounterReset: any
    t: TFunction<string>
}

export type StatisticsLocalState = {
    filterOptions: StatisticsFilter
    sortOptions: SFH.SortOptions
    filteredItems: MeasurementFlatItem[]
    resetFilterDropdown: boolean
    idle: boolean
    idleProgress: number
}

class Statistics extends React.Component<StatisticsLocalProps,StatisticsLocalState> {

    static contextType = NamingContext.Context;


    constructor(props:StatisticsLocalProps) {
        super(props);
        this.state = {
            filterOptions: initalFilterOptions,
            sortOptions: {
                sortByFirst: SFH.SortKey.TIME,
                sortBySecond: SFH.SortKey.FEATURE,
                order: 'DESC',
            },
            filteredItems: [],
            resetFilterDropdown: false,
            idle: false,
            idleProgress: 0,
        }
    }


    // Ref export button click
    exportDropdownRef = React.createRef<HTMLButtonElement>();

    componentDidMount(){
        this.getStatisticsFromWebapi();

        // this will reset the counter (badge) in the navigation
        this.props.measurementCounterReset()
    }

    getStatisticsFromWebapi () {
        this.props.thunkGetStorageStatisticsData()
        .then(()=>{
            this.updateFilteredItems();
        })
    }

    //
    // SORT AND FILTER

    handleSortState(sortOptions: SFH.SortOptions){
        this.setState({
            sortOptions: sortOptions
        },()=>this.updateFilteredItems());
    }

    handleFilterDropdownApply(data: SelectedAttributes[]){
        // update filter options from filter dropdown
        this.setState({filterOptions: {...this.state.filterOptions, selectedAttributes: data}
        },()=>this.updateFilteredItems())
    }

    handleFilterDatePickerApply (startDate: Moment.Moment | null, endDate: Moment.Moment | null) {
        // update filter options from date picker

        const startTimestamp = startDate !== null ? DTH.momentToStartDayTs(startDate) : null;
        const endTimestamp = endDate !== null ? DTH.momentToEndDayTs(endDate) : null;
        
        this.setState({filterOptions: {
            ...this.state.filterOptions,
            startTimestamp: startTimestamp,
            endTimestamp: endTimestamp
        }
        }, () => {
            this.updateFilteredItems();
        })
    }

    updateFilteredItems(){
        // update filteredItems from current filterOptions
        const filterObj = SFH.updateFilteredItems(this.state.filterOptions, this.state.sortOptions, this.props.measurements.items)
        this.setState({
            filteredItems: filterObj.filteredItems,  
            filterOptions: filterObj.filterOptions
        },()=>{
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
    }

    //
    // DELETE

    async handleDeleteItem (statsThis:any, measurement: any) {
        await statsThis.props.thunkDeleteMeasurement(measurement.uuid)
        // update 
        statsThis.updateFilteredItems();
    }

    showSingleDeletePopUp(statsThis:any,measurement: any){
        const params = 
        {
            handleDeleteAction: ()=>this.handleDeleteItem(statsThis, measurement),
            statsThis: this,
            measurement: measurement,
            settings: this.props.system.settings
        };
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <DeletePopUpSingle {...params} onClose={onClose} />
                );
            }
        })
    }

    showMultiDeletePopUp(statsThisRef:any){
        const { filteredItems, filterOptions } = statsThisRef.state;
        const allItemsCount = statsThisRef.props.measurements.items.length;
        const params = 
        {
            filterOptions: filterOptions,
            filteredItems: filteredItems,
            allItemsCount: allItemsCount,
            handleDeleteAction: DelH.handleDeleteFilteredItems,
            statsThis: this,
            exportDropdownRef: statsThisRef.exportDropdownRef
        };
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <DeletePopUp {...params} onClose={onClose} />
                );
            }
        })
    }

    //
    // EXPORT

    async exportHandler(e:any, statsThis:any){
        let measurementCharts: MeasurementChartItem[] = [];
        if (e.name === ExportTypeEnum.PDF || e.name === ExportTypeEnum.EXCEL) {
            const uri = `/statsimages`;
            const obj = await webapi.client.get<QueryResultInterface<MeasurementChartItem>>(uri);
            measurementCharts = obj.data.items;
        }
      
        EH.handleExport(e,statsThis,measurementCharts,this.context.naming)
        .then(()=>
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <PopUpIsDownloaded onClose={onClose}/>
                    );
                },
            })
        );
    }

    //
    //  RENDER

    renderHeader(){
        const { filterOptions, resetFilterDropdown } = this.state;
        const { settings } = this.props.system;
        const params = {
            filterOptions,
            settings,
            resetFilterDropdown,
            handleFilterDropdownApply: (data:any)=>this.handleFilterDropdownApply(data),
            handleFilterDatePickerApply: (s:any,e:any)=>this.handleFilterDatePickerApply(s,e),
            showMultiDeletePopUp: (sT:any)=>this.showMultiDeletePopUp(sT),
            handleExport: (e:any, statsThis:any)=>this.exportHandler(e,statsThis),
            statsThis: this,
            exportDropdownRef: this.exportDropdownRef,
        }
        return <StatisticsHeader {...params} />
    }

    renderBody(){
        const { filteredItems } = this.state;
        const { settings } = this.props.system;
        const { stats: isLoading } = this.props.system.loading;
        const params = {
            items: filteredItems,
            isLoading,
            settings, 
            handleSortState: (o: SFH.SortOptions)=>this.handleSortState(o),
            showSingleDeletePopUp: (sT: any, measurement: MeasurementFlatItem)=>this.showSingleDeletePopUp(sT, measurement),
            statsThis: this
        }
        return  <StatisticsBody {...params} />
    }

    renderNoFilteredItems(){
        return (
            <Translation>
                {t =>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 style={{marginTop:'100px'}} className="h3">{t('no filtered measurements')}</h2>
                        </div>
                    </div>
                </div>
                }
            </Translation>
        )
    }

    renderStatistics(){
        const { stats: isLoading } = this.props.system.loading;
        const loadingCss = isLoading ? ' isLoading' : '';
        const { filteredItems } = this.state;
        return (
            <>
                <div className={'app__main' + loadingCss}>
                        {this.renderHeader()}
                        {filteredItems.length !== 0
                            ? this.renderBody()
                            : this.renderNoFilteredItems()}
                </div>
            </>
        )
    }

    // main
    render(){
        const items = this.props.measurements?.items ?? [];
        const { stats: isLoading } = this.props.system.loading;
        const { idle, idleProgress } = this.state;
        // deleting progress
        if(idle) return (
            <Translation>
                {t =>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <p className="h3">{t('Is deleting ...')}</p>
                                <h2 className="h3">{Math.round(idleProgress * 100)} %</h2>
                            </div>
                        </div>
                    </div>
                }
            </Translation>)
        return (
            <>
                {
                isLoading 
                    ? <div className={'app__main isLoading'}/>
                    : items.length === 0
                        ? <StatisticsEmptyView/>
                        : this.renderStatistics()
                }
            </>
        )
    }
}


const mapStateToProps = (state: AppState) => ({
    measurements: state.statistics.measurements,
    system: state.system,
    device: state.rasp.device,
    channels: state.rasp.channels,
    parts: state.parts.items,
    workbenches: state.machines.items
});

const mapDispatchToProps = {
    thunkGetPagedStatistics,
    thunkGetStorageStatisticsData,
    thunkDeleteMeasurement,
    measurementCounterReset,
    thunkGetMeasurementsExport
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Statistics));