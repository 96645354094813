import React from 'react';
import { overlayToggle, overlayLoadComponent } from '../../store/system/action';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { Translation } from 'react-i18next';
import PinForm from './Pin/PinForm';
import { sha256 } from 'js-sha256';
import { thunkUpdatePin } from '../../store/rasp/thunks';

interface RadioButtonOverlayProps {
    labelMain: string
    labelSecondary?: string
    separator?: boolean
    content?: any
    externalClickHandler?:any
    overlayToggle: typeof overlayToggle;
    overlayLoadComponent: typeof overlayLoadComponent;
    verification?: boolean
    rasp: any
    thunkUpdatePin: any
}

interface RadioButtonOverlayState {
    showList: boolean
}

class RadioButtonOverlay extends React.Component<RadioButtonOverlayProps, RadioButtonOverlayState> {

    constructor(props:RadioButtonOverlayProps){
        super(props)
        this.state = {
            showList: false
        }
    }

    clickHandler(e:any){
        e.preventDefault()

        if(this.props.externalClickHandler){
            this.props.externalClickHandler()
            return
        }
        
        const isDefaultPin = this.props.rasp.device.pin === sha256.hex('0000')
        if(!this.props.verification || isDefaultPin || !this.props.rasp.device.pin){
            this.props.overlayLoadComponent(this.props.content)
            this.props.overlayToggle()
        } else {
            confirmAlert({
                closeOnEscape: true,
                customUI: ({ onClose }) => this.confirmAlertCustomUI(onClose),
            })
        }
    }

    confirmAlertCustomUI(onClose:any){  
        return (
            <Translation>
                {(t) => (
                    <div className='react-confirm-content'>
                        <h2 className="hsmall strong text-uppercase">{t('PinVerification')}</h2>
                        <PinForm 
                            currentPin = {this.props.rasp.device.pin}
                            handleCancel = {() => {onClose()}}
                            handleSubmit = {() => {
                                this.props.overlayLoadComponent(this.props.content)
                                this.props.overlayToggle()
                                onClose()
                            }}
                            resetPin = { this.props.thunkUpdatePin}
                        />
                    </div>
                )}
            </Translation>
        )
    }

    render() {
        let css = 'radio-toggle'
        if(this.props.separator) css += ' radio-toggle--separator'
        if(this.props.externalClickHandler || this.props.content) css += ' radio-toggle--pointer'

        return(
            <div className= { css } onClick={(e) => {this.clickHandler(e)}}>
                <div className="row">
                    <div className="col-8 d-flex flex-column justify-content-center">
                        <span className="radio-toggle__label mb-1 d-block">{ this.props.labelMain }</span>
                        {
                            this.props.labelSecondary && 
                            <span className="radio-toggle__sublabel text-grey-dark d-block">{ this.props.labelSecondary }</span>
                        }
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center">
                        <button className="btn btn--icon btn--icon-next btn--naked" type="button"></button>
                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = (state: AppState) => ({
    rasp: state.rasp
});

const dispatchToProps = {
    overlayToggle, 
    overlayLoadComponent,
    thunkUpdatePin
};

export default connect(mapStateToProps, dispatchToProps)(RadioButtonOverlay);