//*******************************************************
// will work only with a global state, use redux ;)
//*******************************************************

import React from 'react'

interface ButtonGroupToggleProps {
    options: any
    onChangeHandler: any
    show: boolean
    current: any
    styles?: string
    target?: string
    wrapperStyles?: string
}

class ButtonGroupToggle extends React.Component<ButtonGroupToggleProps> {

    onChange(e:any){
        e.preventDefault()
        if(this.isStateToBeUpdated(this.props.target)){
            if(this.props.target) this.props.onChangeHandler(this.props.target, e.target.value)
            else this.props.onChangeHandler(e.target.value)
        }
    }

    isStateToBeUpdated(value:any){
        return this.props.current !== value
    }

    render() {

        let css = 'group-btn'
        if(this.props.wrapperStyles) css += ' ' + this.props.wrapperStyles

        if(this.props.show){
            return(
                <div className= { css }>
                    {
                        this.props.options.map((option:any, index:any) => {
                            
                            const individualStyles = option.css ? option.css : ''
                            const styles = this.props.styles ? this.props.styles : ''
                            const css = this.props.current === option.value ? `btn--active btn ${styles}`: `btn ${styles}`

                            return (
                                <button 
                                    onClick={ (e:any) => this.onChange(e) } 
                                    value = { option.value } 
                                    key = { index } 
                                    className= { css + ' ' + individualStyles }
                                    data-target = { option.target }
                                > {option.label ? option.label : ''} </button>
                            )
                        })
                    }
                </div>
            );
        } else {
            return null
        }
    };
}

export default ButtonGroupToggle;