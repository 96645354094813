import React from 'react';
import { Translation } from 'react-i18next';
import { getChannelsAll } from '../../../store/channels/selectors';
import { AppState } from '../../../store/reducers';
import { connect } from 'react-redux';
import RaspChannel from './RaspChannel';
import { MeasureObject } from '../../../store/channels/types';
import { Subscription } from 'rxjs';
import { MessageContext } from '../../../context';
import { overlayToggle } from '../../../store/system/action';
import { DeviceMessage } from '../../../context/message/types';
import { handleSetValueButton, isInputValid } from './functions';
import { RaspInvertFormProps, RaspInvertFormState, RaspDeviceMessageEnum, channelsCalibValuesInitSingle, channelsCalibValuesInit, raspInvertFormStateInit } from './types';

class RaspInvertForm extends React.Component<RaspInvertFormProps, RaspInvertFormState> {

    static contextType = MessageContext; 


    constructor(props:RaspInvertFormProps){
        super(props);
        this.state = raspInvertFormStateInit;
    }

    private subChannels?:Subscription;
    private subMessages?: Subscription;

    componentDidMount(){
        this.setState({channelsCalibValues: channelsCalibValuesInit()}) // FIXME: this should not be nessecary
        this.startSubscriptions()
    }

    componentWillUnmount() {
        this.stopSubscriptions()
    }

    componentDidUpdate(){
        if (this.state.realtimeChannels.length && this.state.initialGet) {
            this.sendRaspRequestCommand(RaspDeviceMessageEnum.GET_OPTIONS)
            this.setState({initialGet: false})
        }
    }

    startSubscriptions(){
        this.subChannels = this.context.onChannels().subscribe((msg: MeasureObject) => {
            this.setState({
                realtimeChannels: msg.values,
            })
        });
        this.subMessages = this.context.onMessage().subscribe((msg: DeviceMessage) => {
            this.handleApiMessages(msg);
        });
    }

    stopSubscriptions(){
        console.log('unsubscribe subscriptions')
        this.subChannels?.unsubscribe();
        this.subMessages?.unsubscribe();
    }

    handleSubmit(){
        this.props.overlayToggle()
    }

    private handleApiMessages(msg: any): void {
        console.log('handleApiMessages: ', msg)
        switch (msg.tag) {
            case RaspDeviceMessageEnum.GET_OPTIONS:
                this.setState({
                    channelsFactorAV: msg.message.av,
                    channelsFactorNP: msg.message.np
                })
                break;
        
            default:
                console.log('unhandled api message', msg);        
                break;
        }
    }

    private sendRaspRequestCommand(command: RaspDeviceMessageEnum): void {
        const msg: DeviceMessage = {
            tag: command,
            message: 'request'
        }
        this.context.send(msg);
    }

    

    handleSaveAndReset(index: number, mode: 'save' | 'reset'){

        const { channelsFactorAV, channelsFactorNP, channelsCalibValues } = this.state;

        const av = [...channelsFactorAV];
        const np = [...channelsFactorNP];
        switch (mode) {
            case 'save':
                av[index] = String(channelsCalibValues[index].av);
                np[index] = String(channelsCalibValues[index].np);
                break;
            case 'reset':
                av[index] = '1';
                np[index] = '0';
                break;
            default:
                break;
        }

        const msg: DeviceMessage = {
            tag: RaspDeviceMessageEnum.SET_OPTIONS,
            message: av + ',' + np
        }
        this.context.send(msg);

        let newChannelsCalibValues = [...channelsCalibValues];
        newChannelsCalibValues[index] = { ...channelsCalibValuesInitSingle };
        this.setState({ channelsCalibValues: newChannelsCalibValues})

        this.sendRaspRequestCommand(RaspDeviceMessageEnum.GET_OPTIONS)

    }

    onChangeInput(e: React.ChangeEvent<HTMLInputElement>){
        const newInput = e.target.value;
        if (isInputValid(newInput)) {
            switch (e.target.name) {
                case 'upper':
                    this.setState({upperSetValInput: newInput})
                    break;
                case 'np':
                    this.setState({nullSetValInput: newInput})
                    break;
                case 'lower':
                    this.setState({lowerSetValInput: newInput})
                    break;
                default:
                    break;
            }   
        }
    }

    onBlurInput(e: React.FocusEvent<HTMLInputElement>){
        const newInput = Number(e.target.value).toFixed(4);
        console.log('newInput: ', newInput)
            switch (e.target.name) {
                case 'upper':
                    this.setState({upperSetValInput: newInput})
                    break;
                case 'np':
                    this.setState({nullSetValInput: newInput})
                    break;
                case 'lower':
                    this.setState({lowerSetValInput: newInput})
                    break;
                default:
                    break;
            }   
    }

    private renderTopButtons() {
        return (
        <div className="row">
            <div className="col-4 offset-8 col-md-3 offset-md-9 text-right">
                <button className="btn btn--icon btn--icon-check btn--negative" type="submit" onClick={ ()=> {
                    this.handleSubmit() } }
                    ></button>
            </div>
        </div>
        );
    }

    private renderForm() {
        const { channelsFactorAV, channelsFactorNP,
            upperSetValInput, nullSetValInput, lowerSetValInput,
            channelsCalibValues
             } = this.state;
        const state = { ...this.state };
        const convSV = (val:number|null) => val !== null ? Math.round(val*10000)/10000 : '-';

        return (
            <><Translation>
                {
                    t =>
                    <div id="invert-sensors-form">
                        <div className="row mb-3">
                            <div className="col-sm  mb-4 mb-md-0">
                                <div className="mb-2 text-center">
                                    <label>{t('Unterer Vorgabewert')}</label>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <input className="input-blue" type="text"
                                    value={lowerSetValInput}
                                    onChange={e=>this.onChangeInput(e)}
                                    onBlur={e=>this.onBlurInput(e)}
                                    name="lower"
                                     />
                                </div>
                            </div>
                            <div className="col-sm ">
                                <div className="mb-2 text-center">
                                    <label>{t('Nullpunkt')}</label>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <input className="input-blue" type="text" 
                                    value={nullSetValInput}
                                    onChange={e=>this.onChangeInput(e)}
                                    onBlur={e=>this.onBlurInput(e)}
                                    name="np"
                                     />
                                </div>
                            </div>
                            <div className="col-sm ">
                                <div className="mb-2 text-center">
                                    <label>{t('Oberer Vorgabewert')}</label>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <input className="input-blue" type="text" 
                                    value={upperSetValInput}
                                    onChange={e=>this.onChangeInput(e)}
                                    onBlur={e=>this.onBlurInput(e)}
                                    name="upper"
                                     />
                                </div>
                            </div>
                        </div>

                        <div className="invert-sensors-wrapper">
                            {
                                    channelsCalibValues.map( (calibObj, index) => {
                                    return(
                                        <div key={'wrapper-' + index} className="rasp_channel">
                                            <RaspChannel 
                                                key={'channel-' + index} 
                                                channel={ this.props.channels[index + 1] } 
                                                attribute={ null } 
                                                value={
                                                    String(convSV(this.state.realtimeChannels[index]))
                                                }
                                                readonly={ true }
                                                styles="mb-3" 
                                            />
                                            <div className="badge mb-2" style={{ width: '8em'}}>
                                                <span className="text-white05 hmini strong">Faktor</span><br/>
                                                <span className="strong">Ist: {convSV(Number(channelsFactorAV[index]))}</span><br/>
                                                <span className="strong">Neu: {convSV(calibObj.av)}</span>
                                            </div>
                                            <div className="badge mb-2" style={{ width: '8em'}}>
                                                <span className="text-white05 hmini strong">Offset</span><br/>
                                                <span className="strong">Ist: {convSV(Number(channelsFactorNP[index]))}</span><br/>
                                                <span className="strong">Neu: {convSV(calibObj.np)}</span>
                                            </div>
                                            

                                            <div className="mb-2">
                                                <div className="btn btn--white m-auto d-flex justify-content-between w-80"
                                                onClick={()=>this.setState({channelsCalibValues: handleSetValueButton('upper', index, state)}) }
                                                >
                                                    <span>Ober: {convSV(calibObj.upper)}</span>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="btn btn--white m-auto d-flex justify-content-between w-80"
                                                onClick={()=>this.setState({channelsCalibValues: handleSetValueButton('nullP', index, state)}) }
                                                >
                                                    <span>NullP: {convSV(calibObj.nullP)}</span>
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="btn btn--white m-auto d-flex justify-content-between w-80"
                                                onClick={()=>this.setState({channelsCalibValues: handleSetValueButton('lower', index, state)}) }
                                                >
                                                    <span>Unter: {convSV(calibObj.lower)}</span>
                                                </div>
                                            </div>
                                            <div hidden={!(calibObj.upper&&calibObj.np&&calibObj.lower)}
                                            onClick={()=>this.handleSaveAndReset(index, 'save')}
                                            className="mb-2">
                                                <div className="btn btn m-auto d-flex justify-content-between w-80">
                                                    <span>Speichern</span>
                                                </div>
                                            </div>
                                            <div
                                            onClick={()=>this.handleSaveAndReset(index, 'reset')} className="mb-2">
                                                <div className="btn btn m-auto d-flex justify-content-between w-80">
                                                    <span>Reset</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
    
                }
            </Translation>
            </>
        )
    }

    // private renderBottomButtons() {
    //     return (
    //         <div className="row w-100 justify-content-center">
    //             <div className="col-md-2">
    //                 <button className="btn btn--white mr-3 w-100" onClick={()=>this.sendRaspRequestCommand(RaspDeviceMessageEnum.GET_OPTIONS)} >GetOptions</button>
    //             </div>
    //             <div className="col-md-2">
    //                 <button 
    //                     className="btn btn--white mr-3 w-100" 
    //                     onClick={()=>{console.log('channelsCalibValuesInitSingle: ', channelsCalibValuesInitSingle);
    //                     console.log('channelsCalibValues: ', this.state.channelsCalibValues)
    //                     console.log('channelsCalibValuesInit: ', channelsCalibValuesInit())
                    
    //                 }}
    //                 >
    //                     log dev
    //                 </button>
    //             </div>
    //             <div className="col-md-2">
    //                 <button 
    //                     className="btn btn--white mr-3 w-100" 
    //                     onClick={ ()=> {
    //                         this.sendRaspRequestCommand(RaspDeviceMessageEnum.REFRESH_OPTIONS)
    //                     }}
    //                 >
    //                     Refresh
    //                 </button>
    //             </div>
    //         </div>
    //     );
    // }
   
    render() {
        return(
            <div className="container-fluid pb-4 app__overlay-form">
                {this.renderTopButtons()}
                {this.renderForm()}
                {/* {this.renderBottomButtons()} */}
            </div>
        );
    };
}

const mapStateToProps = (state: AppState, props:any) => ({
    channels: getChannelsAll(state),
});

const dispatchToProps = {
    overlayToggle,
};

export default connect(mapStateToProps, dispatchToProps)(RaspInvertForm);