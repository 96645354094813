import React from 'react';
import { useTranslation } from 'react-i18next';
import { QdasMeasurementExportEntry } from '../qdas-types'

const PopUpMeasurementRow = ({ q, handleChange }:
    {
        q: QdasMeasurementExportEntry,
        handleChange: ( text: string ) => void
    }) => {

    const { t } = useTranslation();

    const inputStyle: React.CSSProperties | undefined =
        q.textInput.trim().length === 0
            ? { backgroundColor: '#F1334F' }
            : undefined;

            const catName = q.text.trim() ? q.text : q.name;

    return (
        <div
            className="row"
            >
            <div className="col-5" >
                <input 
                    type="text" 
                    id={`input${q.uuid}`}
                    value={ catName }  
                    className={`input-dark mb-3`}
                    disabled={true}
                />
            </div>
            <div className="col-7" >
                <input 
                    type="text" 
                    id={q.uuid}
                    value={q.textInput}
                    placeholder={ t('InsertText') }
                    className={`input-blue mb-3`}
                    style={inputStyle}
                    onChange={e=>{e.stopPropagation(); handleChange(e.target.value)}}
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            </div>
        </div>
    )
}

export default PopUpMeasurementRow;
