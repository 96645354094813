import React from 'react';
import dark from './../../../img/icons-png/icon-settings-darkmode.png'
import light from './../../../img/icons-png/icon-settings-lightmode.png'

interface IconSettingsProps {
    light?: boolean
}

const IconSettings = (props:IconSettingsProps) => (
    <img className={props.light ? 'dark' : 'light'} src={props.light ? light : dark} alt="settings"/>
)

export default IconSettings