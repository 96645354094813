import React from 'react';
import Button from '../ui/Button';
import { AppState } from '../../store/reducers';
import { connect } from 'react-redux';
import { thunkDeleteMachine} from '../../store/machines/thunks';
import { overlayLoadComponent, overlayToggle } from '../../store/system/action';
import MachineEditForm from './MachineEditForm';
import { MachineItem } from '../../store/machines/types';
import { Translation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; // Import
import DeleteMask from '../ui/DeleteMask';
import QdasPopUp from '../qdas/QdasPopUp';
import { SystemState } from '../../store/system/types';
import QumoFeaturesContext from '../../context/qumo-features/qumo-features-context';
import { QumoFeatureContextType, QumoFeatureItem } from '../../context/qumo-features/qumo-features-types';
import NamingHelper from '../settings/naming/naming-helper';
import { NamingContextType } from '../../context/naming/naming-context-types';
import { NEST, NESTS } from '../../constants/lang/translation-keys';

interface MachineProps {
    machine: MachineItem
    css: string
    isEditMode: boolean
    overlayLoadComponent: any
    overlayToggle: any
    thunkDeleteMachine(id: string):any
    system: SystemState
    namingContext: NamingContextType
}

interface MachineState {
    editMode: boolean,
    isToBeDeleted: boolean
}

class Machine extends React.Component<MachineProps, MachineState> {

    static contextType = QumoFeaturesContext.Context;

    constructor(props:MachineProps){
        super(props);
        this.state = {
            editMode: false,
            isToBeDeleted: false,
        }
    }

    deleteItem = (id:any) => {
        this.setState({isToBeDeleted: true })
        setTimeout(() => {
            this.props.thunkDeleteMachine(id)
        }, 1000);
    }

    handleDeletePart = (id: string) => {
        confirmAlert({
            closeOnEscape: true,
            customUI: ({ onClose }) => {
                return (
                    <DeleteMask 
                        delete = {() => this.deleteItem(id)}
                        onClose = {() => onClose() }
                        nameOfDeletedItem = { this.props.machine.name }
                    />
                )
            }
        })
    }

    handleClick = (e:any) => {
        
        // exclude the redirect if user clicks on the delete or edit button
            if(e.target.nodeName === 'BUTTON') return
            if(this.props.isEditMode) this.loadEditForm()
    }

    loadEditForm = ():any => {
        const item = {...this.props.machine}
        this.props.overlayLoadComponent(<MachineEditForm addingNewMachine={ false } item={ item } />)
        this.props.overlayToggle()    
    }

    getNaming(key: string){
        return NamingHelper.getNaming(key, this.props.namingContext.naming);
    }

    render() {        
        
        let wrapperStyles = ''
        if(this.state.isToBeDeleted){
            wrapperStyles = this.props.css + ' animate--disappear'
        } else if(this.state.editMode) {
            wrapperStyles = this.props.css + ' editable'
        } else {
            wrapperStyles = this.props.css + ' editable'
        }

        const { nests } = this.props.machine;

        const context = this.context as QumoFeatureContextType;
        
        return(
            <>
            <Translation>
            {
                t =>
                <div className = { wrapperStyles + ' list__item animate' }>
                    <div className="list__item-inner" onClick={ (e) => this.handleClick(e)} >
                        <img className="icon icon--round icon--xl icon--border mr-3" src={this.props.machine.imageUrl} alt=""/>
                        <div className="text-ellipsis">
                            <h2 className="h3 strong mb-1 text-ellipsis">{this.props.machine.name}</h2>
                            <h3 className="text-grey-dark hsmall mb-0">
                                {
                                    nests && nests.length === 1 && nests.length + ' ' + this.getNaming(NEST)
                                }
                                {
                                    nests && nests.length !== 1 && nests.length + ' ' + this.getNaming(NESTS)
                                }
                            </h3>
                        </div>
                        <div className="editable__hidden ml-auto">
                            {
                            this.props.system.settings.qumoActiveFeatures.find( f => f==='QDAS' ) &&
                            context.features?.find( (f: QumoFeatureItem) => f.id==='QDAS' )?.active &&
                            <div className='qdaspopup-wrapper'>                                
                                <QdasPopUp label={'Q-DAS'} description={'Q-DAS'} machine={this.props.machine} t={t} rowButton/>
                            </div>
                            }
                            <Button 
                                styles = 'btn btn--pin btn--pin-edit' 
                                isActive = {false}
                                clickHandler = {() => this.loadEditForm() }
                                show = { true }
                            />
                            <Button 
                                styles = 'btn btn--pin btn--pin-trash' 
                                isActive = {false}
                                clickHandler = { () => {this.handleDeletePart(this.props.machine.uuid)}}
                                show = { true }
                            />
                        </div>
                    </div>
                </div>
            }
            </Translation>
            </>
            
        );
    };
}

const mapStateToProps = (state: AppState) => ({
    system: state.system
});

const dispatchToProps = {
    thunkDeleteMachine,
    overlayLoadComponent, 
    overlayToggle, 
};

export default connect(mapStateToProps, dispatchToProps)(Machine);